import {createSlice, PayloadAction} from '@reduxjs/toolkit'

const initialState = {
    reportId: '' as string,
}

type StateType = typeof initialState;

export const reportDetailsSlice = createSlice({
    name: 'REPORT_DETAILS',
    initialState: initialState as StateType,
    reducers: {
        setReportId: (state: StateType , action: PayloadAction<string>) => {
            state.reportId = action.payload;
        },  
    }
});
