import { AppDispatch } from "../../store";
import { errorActionRequest } from "../Errors/actions";
import { ConversionsService, DeploymentsService, PoliciesService } from "core-lib";
import { PAGINATION_FIRST_PAGE, PAGINATION_HUGE_SIZE } from "../../constants";

export const fetchPolicy = (id: string) => {
    return async (_dispatch: AppDispatch) => {
        return PoliciesService.policiesExportPolicy(id)
            .catch(error => {
                errorActionRequest(error);
            })
    }
}

export const fetchDeployments = (id: string) => {
    return async (_dispatch: AppDispatch) => {
        return DeploymentsService.deploymentsListDeployments(
            undefined,
            [id],
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            PAGINATION_FIRST_PAGE,
            PAGINATION_HUGE_SIZE)
            .catch(error => {
                errorActionRequest(error);
            })
    }
}

export const fetchConversions = (id: string) => {
    return async (_dispatch: AppDispatch) => {
        return ConversionsService.conversionsListConversions(
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            [id],
            PAGINATION_FIRST_PAGE,
            PAGINATION_HUGE_SIZE)
            .catch(error => {
                errorActionRequest(error);
            })
    }
}