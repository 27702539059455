import styled from "styled-components";

export const GraySpan = styled.span`
  color: #6C778C;
`
export const FullHeight = styled.div`
  height: 100%;
`
export const ProgressText = styled.div`
  color: #4F73FF;
  font-weight: 700;
  font-size: 24px;
  line-height: 36px;
`