import {CommandStatusCheck} from "./CommandStatusCheck";

const COMMANDS = {} as {[commandId: string]: CommandStatusCheck}

export class CommandStatusCheckManager {

    static readonly getCommandRunner = (commandId: string) => {
        if(COMMANDS[commandId]) {
            return COMMANDS[commandId]
        }
        return CommandStatusCheck.getChecker(commandId).run(() => {
            if(COMMANDS[commandId]) {
                delete COMMANDS[commandId]
            }
        })
    }

    static readonly stopCommands = (commandsIds: string[]) => {
        commandsIds.forEach(commandId => {
            if(COMMANDS[commandId]) {
                COMMANDS[commandId].stop()
                delete COMMANDS[commandId]
            }
        })
    }

}