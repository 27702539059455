import React from "react";
import styled from "styled-components";
import {Icon, Tooltip} from "@emerald/react";

const StyledLabel = styled.label`

  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.128px;
  display: flex;
  align-items: center;

  &.required::after {
    position: relative;
    display: inline-block;
    margin: 0 0 0 2px;
    background: transparent;
    color: #F94627;
    content: "*";
  }
  
  > div, > span {
    display: flex;
    align-items: center;
    margin-left: 2px;
  }
`

export const Label = (props: {children: string, required?: boolean, tip?: string}) => {
    const labelProps = props.required ? {className: 'required'} : {};
    return <StyledLabel {...labelProps}>
        {props.children}
        {props.tip && <Tooltip zIndex={1000}
            target={<Icon size="xs" icon="app.information"/>}
            content={props.tip}
            />}
    </StyledLabel>
}