import React, {useCallback, useState} from "react";
import {useSelector} from "react-redux";
import {conversionResultsSelector, policyDeployIsOpenSelector, policyDeployProvidersTypeSelector} from "./selectors";
import {useAppDispatch} from "../../store";
import {policyDeploySlice} from "./reducer";
import {Drawer100} from "../../components/styled/Drawer";
import {SelectProvidersScreen} from "./stages/SelectProvidersScreen";
import {ConversionSummaryScreen} from "./stages/ConversionSummaryScreen";
import {EndpointsSelectScreen} from "./stages/EndpointsSelectScreen";
import {ResultScreen} from "./stages/ResultScreen";
import {ConversionResultScreen} from "./stages/ConversionResultScreen";
import {EndpointsStaticScreen} from "./stages/EndpointsStaticScreen";
import {cleanAllDeploymentsScreenCommands} from "./actions";
import { addProviderSlice } from "../ProviderEdit/reducer";
import { policyDetailsSlice } from "../PolicyDetails/reducer";
import { policiesListSlice } from "../PoliciesList/reducer";

enum STAGES {
    SELECT_PROVIDERS = 'SELECT_PROVIDERS',
    SUMMARY = 'SUMMARY',
    CONVERSION = 'CONVERSION',
    ENDPOINTS_SELECT = 'ENDPOINTS_SELECT',
    ENDPOINTS_STATIC = 'ENDPOINTS_STATIC',
    RESULT = 'RESULT'
}

const STAGES_AVAILABLE = [
    STAGES.SELECT_PROVIDERS,
    STAGES.SUMMARY,
    STAGES.CONVERSION,
    STAGES.ENDPOINTS_SELECT,
    STAGES.ENDPOINTS_STATIC,
    STAGES.RESULT
]

export const PolicyDeployDrawerComponent = () => {

    const isOpen = useSelector(policyDeployIsOpenSelector)
    const dispatch = useAppDispatch()
    const [stage, setStage] = useState(STAGES_AVAILABLE[0])
    const conversionResults = useSelector(conversionResultsSelector)
    const providerTypesToDeploy = useSelector(policyDeployProvidersTypeSelector)
    const convertedCount = providerTypesToDeploy.reduce((value, current) => {
        return value + ((conversionResults[current || ''] || conversionResults[current || ''] === false) ? 1 : 0);
    }, 0)
    const totalToDeploy = providerTypesToDeploy.length
    const isConversionReport = totalToDeploy === convertedCount && stage === STAGES.SUMMARY

    const onAddProviderClick = useCallback(() => {
        dispatch(addProviderSlice.actions.setEditProviderData({
            providerId: '',
            closeConnectionText: 'Back to deploy'
        }))
    }, [dispatch])

    const setNextStage = () => {
        const nextStage = STAGES_AVAILABLE[STAGES_AVAILABLE.indexOf(stage) + 1]
        if (!nextStage) {
            return onFinish()
        }
        setStage(nextStage)
    }
    const setPrewStage = () => {
        const nextStage = STAGES_AVAILABLE[STAGES_AVAILABLE.indexOf(stage) - 1]
        if (!nextStage) {
            return onDrawerClose()
        }
        setStage(nextStage)
    }

    const onDrawerClose = useCallback(() => {
        setStage(STAGES_AVAILABLE[0])
        dispatch(cleanAllDeploymentsScreenCommands())
        dispatch(policyDeploySlice.actions.clean())
    }, [setStage, dispatch])

    const onFinish = useCallback(() => {
        dispatch(policiesListSlice.actions.resetPolicies())
        dispatch(policyDetailsSlice.actions.setPolicyId(null))
        onDrawerClose();
    }, [onDrawerClose, dispatch])

    return <>
        <Drawer100 open={isOpen} {...(isConversionReport ? { width: '950px' } : null)} hideActionsRow hideBreadcrumbs onAfterClose={onDrawerClose}>
            {stage === STAGES.SELECT_PROVIDERS && <SelectProvidersScreen onBack={setPrewStage} onNext={setNextStage}
                                                                         onAddProviderClick={onAddProviderClick} />}
            {stage === STAGES.SUMMARY && <ConversionSummaryScreen  onBack={setPrewStage} onNext={setNextStage}/>}
            {stage === STAGES.CONVERSION && <ConversionResultScreen onBack={setPrewStage} onNext={setNextStage}/>}
            {stage === STAGES.ENDPOINTS_SELECT && <EndpointsSelectScreen onBack={setPrewStage} onNext={setNextStage}/>}
            {stage === STAGES.ENDPOINTS_STATIC && <EndpointsStaticScreen onBack={setPrewStage} onNext={setNextStage}/>}
            {stage === STAGES.RESULT && <ResultScreen onBack={setPrewStage} onNext={setNextStage} />}
        </Drawer100>
    </>
}