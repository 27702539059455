/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { secretCreateRequest } from '../models/secretCreateRequest';
import type { secretListResponse } from '../models/secretListResponse';
import type { secretResponse } from '../models/secretResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class SecretsService {

    /**
     * @param orderName
     * @param orderOnPremise
     * @param orderCreatedAt
     * @param orderUpdatedAt
     * @param filterName
     * @param filterOnPremise
     * @param filterAgentId
     * @param filterProviderType
     * @param page
     * @param size
     * @returns secretListResponse A successful response.
     * @throws ApiError
     */
    public static secretsListSecrets(
        orderName: 'DESCENDING' | 'ASCENDING' = 'DESCENDING',
        orderOnPremise: 'DESCENDING' | 'ASCENDING' = 'DESCENDING',
        orderCreatedAt: 'DESCENDING' | 'ASCENDING' = 'DESCENDING',
        orderUpdatedAt: 'DESCENDING' | 'ASCENDING' = 'DESCENDING',
        filterName?: Array<string>,
        filterOnPremise?: Array<boolean>,
        filterAgentId?: Array<string>,
        filterProviderType?: Array<string>,
        page?: number,
        size?: number,
    ): CancelablePromise<secretListResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/secrets',
            query: {
                'order.name': orderName,
                'order.onPremise': orderOnPremise,
                'order.createdAt': orderCreatedAt,
                'order.updatedAt': orderUpdatedAt,
                'filter.name': filterName,
                'filter.onPremise': filterOnPremise,
                'filter.agentId': filterAgentId,
                'filter.providerType': filterProviderType,
                'page': page,
                'size': size,
            },
            errors: {
                400: `Invalid argument`,
                401: `Returned when the user does not have permission to access the resource.`,
                404: `Resource was not found.`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * @param body
     * @returns secretResponse A successful response.
     * @throws ApiError
     */
    public static secretsCreateSecret(
        body: secretCreateRequest,
    ): CancelablePromise<secretResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/secrets',
            body: body,
            errors: {
                400: `Invalid argument`,
                401: `Returned when the user does not have permission to access the resource.`,
                404: `Resource was not found.`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * @param id
     * @param force
     * @returns any A successful response.
     * @throws ApiError
     */
    public static secretsDeleteSecret(
        id: string,
        force?: boolean,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/v1/secrets/{id}',
            path: {
                'id': id,
            },
            query: {
                'force': force,
            },
            errors: {
                400: `Invalid argument`,
                401: `Returned when the user does not have permission to access the resource.`,
                404: `Resource was not found.`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * @param id
     * @param body
     * @returns secretResponse A successful response.
     * @throws ApiError
     */
    public static secretsUpdateSecret(
        id: string,
        body: {
            data?: Record<string, string>;
        },
    ): CancelablePromise<secretResponse> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/v1/secrets/{id}',
            path: {
                'id': id,
            },
            body: body,
            errors: {
                400: `Invalid argument`,
                401: `Returned when the user does not have permission to access the resource.`,
                404: `Resource was not found.`,
                500: `Internal server error`,
            },
        });
    }

}
