import {providerProviderType, reportReportType} from "core-lib";
import nap24 from "../assets/Providers/nap24.png";
import waf24 from "../assets/Providers/waf24.png";
import xc24 from "../assets/Providers/xc24.png";

declare global {
    var API_URI: string
}
export const API_URI = typeof process.env.REACT_APP_API_HOST === 'undefined' ? window.API_URI : process.env.REACT_APP_API_HOST

export const PAGINATION_HUGE_SIZE = 999999
export const PAGINATION_FIRST_PAGE = 1

type ProvidersInfoType = {
    [key: string]: {key: providerProviderType, name: string, icon: any}
}

export const PROVIDERS_INFO: ProvidersInfoType = {
    [providerProviderType.AWAF]: {key: providerProviderType.AWAF, name: 'BIG-IP', icon: nap24},
    [providerProviderType.XC]: {key: providerProviderType.XC, name: 'Distributed Cloud', icon: waf24},
    [providerProviderType.NAP]: {key: providerProviderType.NAP, name: 'NGINX', icon: xc24},
}

export const PROVIDER_TYPES_OPTIONS = [{items: Object.values(PROVIDERS_INFO).map(info => ({
    value: info.key,
    label: info.name
}))}]

export const POLICIES_PROVIDERS_INFO: ProvidersInfoType = {
    ...PROVIDERS_INFO,
    [providerProviderType.ONEWAF]: {key: providerProviderType.ONEWAF, name: 'CDP/OneWAF', icon: waf24}
}

export const REPORT_TYPES_NAMES = {
    [reportReportType.CONVERSION]: 'Conversion Only',
    [reportReportType.DEPLOYMENT]: 'Deployment',
    [reportReportType.INGESTION]: 'Ingestion',
    [reportReportType.NONE]: '',
}

export const AGENT_INSTALLERS_PACKAGES_LINK = 'https://gitlab.policysupervisor.io/api/v4/projects/34/packages?sort=desc'
export const GITLAB_URL = 'https://gitlab.policysupervisor.io'