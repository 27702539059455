/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { workspaceCreateRequest } from '../models/workspaceCreateRequest';
import type { workspaceListResponse } from '../models/workspaceListResponse';
import type { workspaceResponse } from '../models/workspaceResponse';
import type { workspaceUpdateRequest } from '../models/workspaceUpdateRequest';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class WorkspacesService {

    /**
     * @returns workspaceResponse A successful response.
     * @throws ApiError
     */
    public static workspacesGetWorkspace(): CancelablePromise<workspaceResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/workspace',
            errors: {
                400: `Invalid argument`,
                401: `Returned when the user does not have permission to access the resource.`,
                404: `Resource was not found.`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * @param orderName
     * @param orderCreatedAt
     * @param orderUpdatedAt
     * @param filterName
     * @param page
     * @param size
     * @returns workspaceListResponse A successful response.
     * @throws ApiError
     */
    public static workspacesListWorkspaces(
        orderName: 'DESCENDING' | 'ASCENDING' = 'DESCENDING',
        orderCreatedAt: 'DESCENDING' | 'ASCENDING' = 'DESCENDING',
        orderUpdatedAt: 'DESCENDING' | 'ASCENDING' = 'DESCENDING',
        filterName?: Array<string>,
        page?: number,
        size?: number,
    ): CancelablePromise<workspaceListResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/workspaces',
            query: {
                'order.name': orderName,
                'order.createdAt': orderCreatedAt,
                'order.updatedAt': orderUpdatedAt,
                'filter.name': filterName,
                'page': page,
                'size': size,
            },
            errors: {
                400: `Invalid argument`,
                401: `Returned when the user does not have permission to access the resource.`,
                404: `Resource was not found.`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * @param force
     * @returns any A successful response.
     * @throws ApiError
     */
    public static workspacesDeleteWorkspace(
        force?: boolean,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/v1/workspaces',
            query: {
                'force': force,
            },
            errors: {
                400: `Invalid argument`,
                401: `Returned when the user does not have permission to access the resource.`,
                404: `Resource was not found.`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * @param body
     * @returns workspaceResponse A successful response.
     * @throws ApiError
     */
    public static workspacesCreateWorkspace(
        body: workspaceCreateRequest,
    ): CancelablePromise<workspaceResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/workspaces',
            body: body,
            errors: {
                400: `Invalid argument`,
                401: `Returned when the user does not have permission to access the resource.`,
                404: `Resource was not found.`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * @param body
     * @returns workspaceResponse A successful response.
     * @throws ApiError
     */
    public static workspacesUpdateWorkspace(
        body: workspaceUpdateRequest,
    ): CancelablePromise<workspaceResponse> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/v1/workspaces',
            body: body,
            errors: {
                400: `Invalid argument`,
                401: `Returned when the user does not have permission to access the resource.`,
                404: `Resource was not found.`,
                500: `Internal server error`,
            },
        });
    }

}
