import {RootState} from "../../store";
import {policyPolicyInfo} from "core-lib";
import {createSelector} from "@reduxjs/toolkit";
import {policiesListItem} from "./types";

export const policiesListSelector = (state: RootState): Array<policyPolicyInfo> | null => state.policiesList.data;
export const policiesItemsToDeleteSelector = (state: RootState): Array<string> => state.policiesList.itemsToDelete;
export const policiesListArraySelector = createSelector([policiesListSelector],
    (list: Array<policyPolicyInfo> | null) : policiesListItem[] | null => {
        return list?.map((item: policyPolicyInfo) => ({
            ...item,
            metadata: {
                ...item.metadata,
                lastModifiedTimestamp: item.metadata?.modifiedTime ? new Date(item.metadata.modifiedTime).getTime() : 0
            }
        })) || null
    })
