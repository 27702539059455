/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { agentAgent } from '../models/agentAgent';
import type { agentCreateAgentRequest } from '../models/agentCreateAgentRequest';
import type { agentGetRunnerRegistrationTokenResponse } from '../models/agentGetRunnerRegistrationTokenResponse';
import type { agentListAgentResponse } from '../models/agentListAgentResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class AgentsService {

    /**
     * @param filterName
     * @param orderName
     * @param orderCreatedAt
     * @param orderUpdatedAt
     * @param page
     * @param size
     * @returns agentListAgentResponse A successful response.
     * @throws ApiError
     */
    public static agentsListAgents(
        filterName?: Array<string>,
        orderName: 'DESCENDING' | 'ASCENDING' = 'DESCENDING',
        orderCreatedAt: 'DESCENDING' | 'ASCENDING' = 'DESCENDING',
        orderUpdatedAt: 'DESCENDING' | 'ASCENDING' = 'DESCENDING',
        page?: number,
        size?: number,
    ): CancelablePromise<agentListAgentResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/agents',
            query: {
                'filter.name': filterName,
                'order.name': orderName,
                'order.createdAt': orderCreatedAt,
                'order.updatedAt': orderUpdatedAt,
                'page': page,
                'size': size,
            },
            errors: {
                400: `Invalid argument`,
                401: `Returned when the user does not have permission to access the resource.`,
                404: `Resource was not found.`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * @param body
     * @returns agentAgent A successful response.
     * @throws ApiError
     */
    public static agentsCreateAgent(
        body: agentCreateAgentRequest,
    ): CancelablePromise<agentAgent> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/agents',
            body: body,
            errors: {
                400: `Invalid argument`,
                401: `Returned when the user does not have permission to access the resource.`,
                404: `Resource was not found.`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * @returns agentGetRunnerRegistrationTokenResponse A successful response.
     * @throws ApiError
     */
    public static agentsGetRunnerRegistrationToken(): CancelablePromise<agentGetRunnerRegistrationTokenResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/agents/runner-registration-token',
            errors: {
                400: `Invalid argument`,
                401: `Returned when the user does not have permission to access the resource.`,
                404: `Resource was not found.`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * @param id
     * @returns agentAgent A successful response.
     * @throws ApiError
     */
    public static agentsGetAgent(
        id: string,
    ): CancelablePromise<agentAgent> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/agents/{id}',
            path: {
                'id': id,
            },
            errors: {
                400: `Invalid argument`,
                401: `Returned when the user does not have permission to access the resource.`,
                404: `Resource was not found.`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * @param id
     * @param force
     * @returns any A successful response.
     * @throws ApiError
     */
    public static agentsDeleteAgent(
        id: string,
        force?: boolean,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/v1/agents/{id}',
            path: {
                'id': id,
            },
            query: {
                'force': force,
            },
            errors: {
                400: `Invalid argument`,
                401: `Returned when the user does not have permission to access the resource.`,
                404: `Resource was not found.`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * @param id
     * @param body
     * @returns agentAgent A successful response.
     * @throws ApiError
     */
    public static agentsUpdateAgent(
        id: string,
        body: {
            name?: string;
        },
    ): CancelablePromise<agentAgent> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/v1/agents/{id}',
            path: {
                'id': id,
            },
            body: body,
            errors: {
                400: `Invalid argument`,
                401: `Returned when the user does not have permission to access the resource.`,
                404: `Resource was not found.`,
                500: `Internal server error`,
            },
        });
    }

}
