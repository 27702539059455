import React, { useCallback, useMemo, useState } from "react";
import styled from "styled-components";
import { Col } from "./Col";
import { Button, Icon } from "@emerald/react";
import { Row } from "./Row";

const BorderedPanel = styled.div`
  padding: 10px;
  border: 1px solid #CFD4E2;
  border-radius: 4px;
`
const Header = styled.div`
  width: 100%;
`
const Body = styled.div`
  width: 100%;
  overflow-y: hidden;
`

const ButtonIcon = styled(Icon)`
  font-size: 16px !important;
  height: 16px !important;
  line-height: 16px !important;
  color: #9EA7B8
`

type Props = {
    opened?: boolean;
    initialOpened?: boolean;
    children: any;
    header: any;
    onToggle?: (opened: boolean) => void;
    onExport?: () => void;
    holdRightSpace?: boolean;
    borderColor?: string | null;
    alwaysShowBody?: boolean;
}

export const CollapsiblePanel = ({ opened: openedProp, onToggle, onExport, borderColor, alwaysShowBody, initialOpened, holdRightSpace, header, children }: Props) => {

    const [isOpened, setIsOpened] = useState(initialOpened || false)

    const style = useMemo(() => ({
        borderColor: borderColor ? borderColor : '#CFD4E2'
    }), [borderColor])

    const isPanelOpened = useMemo(() => {
        return openedProp || isOpened
    }, [openedProp, isOpened])

    const onToggleClick = useCallback(() => {
        if (typeof openedProp !== 'undefined') {
            onToggle && onToggle(!openedProp)
        } else {
            setIsOpened(!isOpened)
        }
    }, [openedProp, isOpened, onToggle, setIsOpened])

    const bodyStyle = useMemo(() => {
        return {
            height: (isPanelOpened || alwaysShowBody) ? 'auto' : 0
        }
    }, [isPanelOpened, alwaysShowBody])


    return holdRightSpace ? <BorderedPanel style={style}>
        <Row>
            <Col flex={1}>
                <Header>{header}</Header>
                <Body style={bodyStyle}>{children}</Body>
            </Col>
            <Col rightDirection>
                <Button displayMode="ghostInk" onClick={onExport}><ButtonIcon size="small" icon="app.export" /><span>Export</span></Button>
            </Col>
            <Col rightDirection>
                <Button size='small' displayMode="linkInk" onClick={onToggleClick}>
                    <Icon icon={isPanelOpened ? 'app.chevronUp' : 'app.chevronDown'} size='xs' />
                </Button>
            </Col>
        </Row>
    </BorderedPanel> : <BorderedPanel style={style}>
        <Header>
            <Row>
                <Col flex={1}>
                    {header}
                </Col>
                <Col rightDirection>
                    <Button displayMode="ghostInk" onClick={onExport}><ButtonIcon size="small" icon="app.export" /><span>Export</span></Button>
                </Col>
                <Col rightDirection>
                    <Button size='small' displayMode="linkInk" onClick={onToggleClick}>
                        <Icon icon={isPanelOpened ? 'app.chevronUp' : 'app.chevronDown'} size='xs' />
                    </Button>
                </Col>
            </Row>
        </Header>
        <Body style={bodyStyle}>{children}</Body>
    </BorderedPanel>
}
