import React, { useCallback, useMemo, memo } from "react";
import {
    BodyFormFull,
    Footer,
    Header,
    HeaderText,
} from "../../../components/styled/Drawer";
import { Divider } from "../../../components/Divider";
import { Align, Button, FormField, Input, Tag } from "@emerald/react";
import { Accordion } from "../../../components/Accordion";
import { Col } from "../../../components/Col";
import { Row } from "../../../components/Row";
import styled from "styled-components";
import { useAppDispatch } from "../../../store";
import { ScrollableContent } from "../../../components/PageScrollable";
import { useSelector } from "react-redux";
import { providerIngestionCommitMessageSelector, providerIngestionPoliciesSelectedListSelector, providerIngestionProfilesBot, providerIngestionProfilesDos, providerIngestionProviderSelector, providerIngestionSelectedPolicyProfilesSelector } from "../selectors";
import { PROVIDERS_INFO } from "../../../constants";
import { providerIngestionSlice } from "../reducer";
import { GraySpan } from "../../../components/styled";
import { providerProviderType } from "core-lib";

const DataGridContainer = styled.div`
    padding: 4px 22px;
    border: 0.5px solid #E6E9F3;
    border-radius: 3px;
    margin-top: 16px;
`

const Label = styled.div`
    font-size: 14px;
    line-height: 20px;
`

const HelpMessage = styled(GraySpan)`
    font-size: 12px;
    line-height: 18px;
`

const LetterCount = styled.div`
    font-size: 12px;
    line-height: 16px;
`

const StyledTag = styled(Tag)`
    white-space: nowrap;
    margin: 0;
`
const SelectionTag = styled(StyledTag)`
    background-color: #F7F8FA;
    color: #6C778C;
`
const FormWrapper = styled.div`
    width: 80%;
`

const DataEntryWrapper = styled.div`
    overflow: hidden;
    display: flex;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    margin: 18px 0;
`
const DataEntryTitle = styled.div`
    color: #6C778C;
    flex: 1 0 40%;
`
const DataEntryChildren = styled.div`
    flex: 1 0 60%;
`
const PolicyTitle = styled.div`
    font-weight: 500;
    color: #0B1640;
`

const ProfileWrapper = styled.div`
    display: flex;
    color: #6C778C;
    div {
        border-radius: 0;
    }
    div:first-child {
        border-radius: 4px 0 0 4px;
    }
    div:last-child {
        border-radius: 0 4px 4px 0;
    }
    div:not(:first-child):not(:last-child) {
        border-right: 1px solid #afbae3;
    }
`

const Profile = (props: {policyId: string, type: string}) => {
    const {policyId, type = "dos"} = props
    
    const profilesBot = useSelector(providerIngestionProfilesBot);
    const profilesDos = useSelector(providerIngestionProfilesDos);
    const selectedProfiles = useSelector(providerIngestionSelectedPolicyProfilesSelector)
    const { dosProfile, botProfile } = selectedProfiles[policyId] || {}

    const profile = type === "dos" ? dosProfile : botProfile
    const servers =  (type === "dos" ? profilesDos : profilesBot)?.find(item => item.name === profile)?.virtualServers || []
    const hasServers = servers.length > 0

    if(!profile) return <ProfileWrapper>--</ProfileWrapper>

    return <ProfileWrapper>
        <SelectionTag isActionable={false}>{profile.length > 20 ? `${profile.substring(0, 20)}...` : profile}</SelectionTag>
        {hasServers &&
            servers.map((server: string) => 
                <StyledTag isActionable={false} key={server}>{server}</StyledTag>
            )
        }
    </ProfileWrapper>
}
const MemoizedProfile = memo(Profile)

const DataEntry = (props: {title: string, children: React.ReactNode}) => {
    const {title, children} = props

    return <DataEntryWrapper>
        <DataEntryTitle>{title}</DataEntryTitle>
        <DataEntryChildren>{children}</DataEntryChildren>
    </DataEntryWrapper>
}

type Props = {
    onBack: () => void,
    onNext: () => void
}

const MAX_COMMIT_MESSAGE_LENGTH = 50;

export const IngestionSummaryScreen = (props: Props) => {
    const provider = useSelector(providerIngestionProviderSelector);
    const dataSource = useSelector(providerIngestionPoliciesSelectedListSelector);
    const commitMessage = useSelector(providerIngestionCommitMessageSelector);
    const dispatch = useAppDispatch();

    const isAWAF = provider?.type === providerProviderType.AWAF

    const setCommitMessage = useCallback((e: any) => {
        const value = e.target.value;
        if (value.length <= MAX_COMMIT_MESSAGE_LENGTH) {
            dispatch(providerIngestionSlice.actions.setCommitMessage(e.target.value))
        }
    }, [dispatch])    

    return <BodyFormFull>
        <Header pr={20} pl={20}>
            <Col centerVertical fullHeight>
                <HeaderText>Ingest Policies</HeaderText>
            </Col>
        </Header>
        <Divider />
        <ScrollableContent ml={0} mb={47} mt={18}>
            <Accordion title="Summary" initialOpened>
                <DataEntry title="Provider">
                    {PROVIDERS_INFO[provider?.type || '']?.name}
                </DataEntry>
                <DataEntry title="Total Policies Selected">
                    {dataSource.length}
                </DataEntry>

                {dataSource.map(policy => 
                    <DataGridContainer key={policy.id}>
                        <DataEntry title="Policy Name">
                            <PolicyTitle>
                                {policy.name}
                            </PolicyTitle>
                        </DataEntry>
                        <DataEntry title="DoS Defense Profile">
                            <MemoizedProfile policyId={policy.id} type="dos" />
                        </DataEntry>
                        {isAWAF && <DataEntry title="Bot Protection Profile">
                            <MemoizedProfile policyId={policy.id} type="bot" />
                        </DataEntry>}
                    </DataGridContainer>
                )}
                
                <FormWrapper>
                    <Row mt={32}>
                        <Col fullWidth><Label>Ingest Poilcies Note (commit message)*</Label></Col>
                        <Col fullWidth rightDirection><HelpMessage>Required</HelpMessage></Col>
                    </Row>
                    <Input onChange={setCommitMessage} value={commitMessage} />
                    <Row>
                        <Col fullWidth rightDirection><LetterCount><b>{commitMessage.length}</b>/{MAX_COMMIT_MESSAGE_LENGTH}</LetterCount></Col>
                    </Row>
                </FormWrapper>
            </Accordion>
        </ScrollableContent>
        <Footer>
            <Divider />
            <Col flex={1} rightDirection mr={20}>
                <Row spacing={20} mt={10}>
                    <Align>
                        <Button accent={true} displayMode="outlined" onClick={props.onBack}>Back</Button>
                    </Align>
                    <Align>
                        <Button disabled={!commitMessage} accent={true} onClick={props.onNext}>Save &
                            Ingest Policy</Button>
                    </Align>
                </Row>
            </Col>
        </Footer>
    </BodyFormFull>
}