import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {
    providerResponse,
} from "core-lib";
import { CommandStatusCheckManager } from '../../core/commands/CommandStatusCheckManager';
import { AppDispatch, RootState } from '../../store';
import { providerIngestionCommandIdsSelector } from './selectors';
import { ingestionPoliciesListItem, ingestionStatus, policyIngetstionStatusAction, profilePolicyType } from './types';

const initialState = {
    providerForIngestion: null as providerResponse | null,
    policies: null as ingestionPoliciesListItem[] | null,
    selectedPolicyIds: [] as string[],
    selectedPolicyProfileIds: {} as profilePolicyType,
    commitMessage: '' as string,
    reportGroupId: '' as string,
    ingestionStatus: {} as ingestionStatus,
    commandIds: [] as string[],
    profilesBot: null as any[] | null,
    profilesDos: null as any[] | null,
}

type StateType = typeof initialState;

export const providerIngestionSlice = createSlice({
    name: 'PROVIDER_INGESTION',
    initialState: initialState as StateType,
    reducers: {
        openProviderIngestion: (state: StateType , action: PayloadAction<providerResponse | null>) => {
            state.providerForIngestion = action.payload;
        },
        loadPolicies: (state: StateType , action: PayloadAction<ingestionPoliciesListItem[] | null>) => {
            state.policies = action.payload;
        },
        loadProfilesBot: (state: StateType , action: PayloadAction<any[] | null>) => {
            state.profilesBot = action.payload;
        },
        loadProfilesDos: (state: StateType , action: PayloadAction<any[] | null>) => {
            state.profilesDos = action.payload;
        },
        selectPolicyIds: (state: StateType , action: PayloadAction<string[]>) => {
            state.selectedPolicyIds = action.payload;
        },
        selectPolicyProfileIds: (state: StateType , action: PayloadAction<any | null>) => {
            const {policyId, ...rest} = action.payload || {}
            const prev = state.selectedPolicyProfileIds[policyId as keyof profilePolicyType]
            if(!prev) state.selectedPolicyProfileIds[policyId as keyof profilePolicyType] = rest
            else state.selectedPolicyProfileIds[policyId as keyof profilePolicyType] = {...prev, ...rest}
        },
        setCommitMessage: (state: StateType , action: PayloadAction<string>) => {
            state.commitMessage = action.payload;
        },
        setReportGroupId: (state: StateType , action: PayloadAction<string>) => {
            state.reportGroupId = action.payload;
        },
        setPolicyIngestStatus: (state: StateType , action: PayloadAction<policyIngetstionStatusAction>) => {
            const { policyId, completed, error} = action.payload
            state.ingestionStatus = {
                ...state.ingestionStatus,
                [policyId] : {completed, error}
            }
        },
        addCommandId: (state: StateType , action: PayloadAction<string>) => {
            state.commandIds = [
                ...state.commandIds,
                action.payload
            ]
        },
        clean: (state: StateType, _action: PayloadAction<void>) => {
            state.providerForIngestion = null
            state.policies = null
            state.selectedPolicyIds = []
            state.selectedPolicyProfileIds = {}
            state.commitMessage = ''
            state.reportGroupId = ''
            state.ingestionStatus = {}
            state.commandIds = []
            state.profilesBot = []
            state.profilesDos = []
        }
    }
});

export const cleanAllIngestionScreenCommands = () => {
    return async (dispatch: AppDispatch, getState: () => RootState) => {
        const commandsIds = providerIngestionCommandIdsSelector(getState())
        CommandStatusCheckManager.stopCommands(commandsIds)
    }
}
