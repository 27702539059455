import {createSlice} from '@reduxjs/toolkit'

const initialState = {
    open: false as boolean,
}

type StateType = typeof initialState;

export const policyImportSlice = createSlice({
    name: 'POLICY_IMPORT',
    initialState: initialState as StateType,
    reducers: {
        open: (state: StateType) => {
            state.open = true;
        },
        close: (state: StateType) => {
            state.open = false;
        }
    }
});
