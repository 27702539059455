import React, { useCallback, useEffect, useState } from "react";
import { Align, Button, ContentStatus, Drawer, FormField, Input, Select } from "@emerald/react";
import { PROVIDER_TYPES_OPTIONS } from "../../constants";
import { Divider } from "../../components/Divider";
import { Col } from "../../components/Col";
import { Row } from "../../components/Row";
import { AgentSelect } from "./AgentSelect";
import { SecretSelect } from "./SecretSelect";
import { providerResponse, providerProviderType } from "core-lib";
import { TestConnectionScreen } from "./TestConnectionScreen";
import {
    BodyForm,
    Header,
    Footer,
    Drawer100,
    HeaderHeader,
    HeaderSubText
} from "../../components/styled/Drawer";
import { FullHeight } from "../../components/styled";
import { TagsField } from "../../components/TagsField";
import { AddAgentComponent } from "./AddAgentComponent";
import { fetchAgents, fetchSecrets, getProvider } from "./actions";
import { useAppDispatch } from "../../store";
import { AddSecretComponent } from "./AddSecretComponent";
import { ScrollableContent } from "../../components/PageScrollable";
import { useSelector } from "react-redux";
import { addProviderProviderIdSelector } from "./selectors";
import { addProviderSlice } from "./reducer";

type FormState = {
    showTestConnectionButton: boolean;
    showTestConnectionScreen?: boolean;
    provider: providerResponse;
}

const EMPTY_PROVIDER = {
    id: '',
    type: '' as providerProviderType,
    name: '',
    url: '',
    labels: [] as Array<string>,
    agentId: '',
    secretId: ''
} as providerResponse

const INITIAL_STATE = {
    showTestConnectionButton: false,
    showTestConnectionScreen: false,
    provider: EMPTY_PROVIDER
}

export const EditProviderForm = () => {
    const dispatch = useAppDispatch()
    const providerId = useSelector(addProviderProviderIdSelector);
    const [loadingProvider, setLoadingProvider] = useState<boolean>(false);
    const [isAddAgentOpened, setIsAddAgentOpened] = useState(false)
    const [isAddSecretOpened, setIsAddSecretOpened] = useState(false)

    const [state, setValues] = useState<FormState>(INITIAL_STATE);

    const onClose = () => {
        setValues(INITIAL_STATE)
        dispatch(addProviderSlice.actions.close())
    }

    useEffect(() => {
        if (!!providerId) {
            setLoadingProvider(true);
            dispatch(getProvider(providerId)).then(provider => {
                if (provider) {
                    setValues({
                        ...INITIAL_STATE,
                        provider,
                        showTestConnectionButton: true
                    })
                    setLoadingProvider(false);
                }
            });
        }
    }, [dispatch, providerId])

    const onAddAnotherClick = () => {
        setValues({ ...INITIAL_STATE })
    }

    const onAddAgentClick = useCallback(() => {
        setIsAddAgentOpened(true)
    }, [setIsAddAgentOpened])
    const onAddAgentFinished = useCallback(() => {
        setIsAddAgentOpened(false)
        dispatch(fetchAgents())
    }, [dispatch, setIsAddAgentOpened])

    const onAddSecretClick = useCallback(() => {
        setIsAddSecretOpened(true)
    }, [setIsAddSecretOpened])
    const onAddSecretFinished = () => {
        setIsAddSecretOpened(false)
        dispatch(fetchSecrets(state.provider.type !== providerProviderType.XC, state?.provider?.agentId))
    }

    const setType = (value: any) => {
        setValues({
            ...state, provider: {
                ...state.provider, type: value, agentId: '', secretId: ''
            }, showTestConnectionButton: false
        })
    }

    const setAgent = (value: string) => {
        setValues({
            ...state, provider: {
                ...state.provider, agentId: value, secretId: ''
            }, showTestConnectionButton: false
        })
        dispatch(fetchSecrets(state.provider.type !== providerProviderType.XC, value))
    }

    const setSecret = (value: string) => {
        setValues({
            ...state, provider: {
                ...state?.provider, secretId: value
            }, showTestConnectionButton: false
        })
    }

    const onContinue = async () => {
        setValues({ ...state, showTestConnectionButton: true })
    }

    const setName = (event: any) => {
        setValues({
            ...state, provider: {
                ...state.provider,
                name: event.target.value
            }
        })
    }
    const setURL = (event: any) => {
        setValues({
            ...state, provider: {
                ...state.provider,
                url: event.target.value
            }
        })
    }
    const setLabels = (selectedTags: string[]) => {
        setValues({
            ...state, provider: {
                ...state.provider,
                labels: selectedTags
            }
        })
    }

    const onTestConnection = () => {
        setValues({ ...state, showTestConnectionScreen: true })
    }

    const onEditButtonClick = (provider: providerResponse) => {
        setValues({ ...state, provider: provider, showTestConnectionScreen: false })
    }

    const addForm = <BodyForm>
        <Header>
            <HeaderHeader>{state.provider.id ? 'Edit provider' : 'Add Providers'}</HeaderHeader>
            <HeaderSubText>Providers are remote instances that provide WAF services,
                such as NGINX App Protect and BIG-IP AWAF.</HeaderSubText>
        </Header>
        <ScrollableContent>
            <FormField required={true} label="Provider Type">
                <Select options={PROVIDER_TYPES_OPTIONS}
                    value={state.provider.type}
                    onChange={setType}
                    allowClearSelection={false}
                    allowSelectAll={false}
                    allowSearch={false}
                />
            </FormField>
            {state.provider.type && state.provider.type !== providerProviderType.XC && <AgentSelect
                onAddAgentClick={onAddAgentClick}
                providerType={state.provider.type}
                value={state.provider.agentId}
                onChange={setAgent}
            />}
            {state.provider.type && (state.provider.type === providerProviderType.XC || state.provider.agentId) &&
                <SecretSelect
                    agentId={state.provider.agentId}
                    type={state.provider.type}
                    value={state.provider.secretId}
                    onAddSecretClick={onAddSecretClick}
                    onChange={setSecret}
                />}
            {state.showTestConnectionButton && <div>
                <FormField required={true} label="Provider Name">
                    <Input value={state.provider.name} onChange={setName} />
                </FormField>
                <FormField required={true} label={state.provider.type === providerProviderType.NAP ? "SSH Server and Port" : "Provider URL"}>
                    <Input placeholder={state.provider.type === providerProviderType.NAP ? "127.0.0.1:22" : "https://127.0.0.1"} value={state.provider.url} onChange={setURL} />
                </FormField>
                <FormField label="Labels">
                    <TagsField value={state.provider.labels || []} onChange={setLabels} />
                </FormField>
            </div>}
        </ScrollableContent>
        <Footer>
            <Divider />
            <Col flex={1} rightDirection>
                <Row spacing={20} style={{ marginTop: 10 }}>
                    <Align>
                        <Button accent={true} displayMode="outlined" onClick={onClose}>Close</Button>
                    </Align>
                    <Align>
                        {!state.showTestConnectionButton &&
                            <Button accent={true}
                                disabled={!state.provider.secretId}
                                onClick={onContinue}>
                                Continue
                            </Button>}
                        {state.showTestConnectionButton &&
                            <Button accent={true} disabled={!state.provider.name || !state.provider.url}
                                onClick={onTestConnection}>
                                Test Connection
                            </Button>}
                    </Align>
                </Row>
            </Col>
        </Footer>
    </BodyForm>

    return <>
        <Drawer100 open={providerId !== null} hideActionsRow onAfterClose={onClose} hideBreadcrumbs>
            <FullHeight>
                {state.showTestConnectionScreen && <FullHeight>
                    <TestConnectionScreen
                        provider={state.provider}
                        onClose={onClose}
                        onAddAnotherClick={onAddAnotherClick}
                        onEditButtonClick={onEditButtonClick}
                    />
                </FullHeight>}
                <FullHeight style={{ display: !state.showTestConnectionScreen ? 'block' : 'none' }}>{addForm}</FullHeight>
            </FullHeight>
            <ContentStatus
                isVisible={loadingProvider}
                spinnerSize={"medium"}
                defaultMessage={<span>Loading provider</span>}
                fillOuterContainer={false}
            />
        </Drawer100>
        <Drawer open={isAddAgentOpened} hideActionsRow hideBreadcrumbs>
            <AddAgentComponent onFinish={onAddAgentFinished} />
        </Drawer>
        <Drawer open={isAddSecretOpened} hideActionsRow hideBreadcrumbs>
            <AddSecretComponent onFinish={onAddSecretFinished} />
        </Drawer>
    </>
}