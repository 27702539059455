import styled from "styled-components";

export const Dot = styled.div<{ size: number, color: string }>`
  height: ${props => `${props.size}px`};
  width: ${props => `${props.size}px`};
  background-color: ${props => props.color ? `${props.color}` : 'black'};
  border-radius: 50%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
`