import type monaco from 'monaco-editor';
import { Range, Position } from 'monaco-editor/esm/vs/editor/editor.api'


export const findObjectBoundaryFromPosition = (model: monaco.editor.ITextModel, position: monaco.Position) => {
    let isObject = true;
    let endPosition = position.clone(), startPosition = position.clone();
    const minStartPosition = new Position(1, 1);

    for (let checkPosition = position.clone(); startPosition.isBeforeOrEqual(checkPosition);) {
        const a = model.findPreviousMatch("}", checkPosition, false, false, null, false)
        const b = model.findPreviousMatch("{", startPosition, false, false, null, false)

        if (b === null) {
            isObject = false;
            break;
        }

        checkPosition = a ? new Position(a.range.startLineNumber, a.range.startColumn) : minStartPosition
        startPosition = new Position(b.range.startLineNumber, b.range.startColumn)

        if (position.isBeforeOrEqual(checkPosition)) {
            checkPosition = minStartPosition;
        }

        if (position.isBeforeOrEqual(startPosition)) {
            isObject = false;
            break;
        }
    }

    const fullRange = model.getFullModelRange()
    const maxEndPosition = new Position(fullRange.endLineNumber, fullRange.endColumn)

    for (let checkPosition = position.clone(); checkPosition.isBeforeOrEqual(endPosition);) {
        const a = model.findNextMatch("{", checkPosition, false, false, null, false)
        const b = model.findNextMatch("}", endPosition, false, false, null, false)

        if (b === null) {
            isObject = false;
            break;
        }

        checkPosition = a ? new Position(a.range.endLineNumber, a.range.endColumn) : maxEndPosition
        endPosition = new Position(b.range.endLineNumber, b.range.endColumn)


        if (checkPosition.isBeforeOrEqual(position)) {
            checkPosition = maxEndPosition;
        }

        if (endPosition.isBeforeOrEqual(position)) {
            isObject = false;
            break;
        }
    }

    return {
        isObject,
        range: new Range(startPosition.lineNumber, startPosition.column, endPosition.lineNumber, endPosition.column)
    }
}

export const findObjectPositions = (model: monaco.editor.ITextModel, position: monaco.Position) => {
    const beforePositionRange = {
        startLineNumber: 1,
        startColumn: 1,
        endLineNumber: position.lineNumber,
        endColumn: position.column,
    }

    const textBeforePosition = model.getValueInRange(beforePositionRange)

    let match = !!textBeforePosition.match(
        /"TrustLists"\s*:\s*\{\s*("[^"]*"\s*:\s*"[^"]*"\s*,\s*)*([^"]*)?$/
    );

    let rangeToReplace = null;

    if (match) {
        let { isObject, range } = findObjectBoundaryFromPosition(model, position);
        rangeToReplace = range;
        match = isObject;
    }


    return { match, rangeToReplace }
}

