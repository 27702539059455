import {BodyForm, Header, HeaderHeader, HeaderSubText, Legend} from "../../../components/styled/Drawer";
import {Divider} from "../../../components/Divider";
import {Align, Button, Icon} from "@emerald/react";
import {Col} from "../../../components/Col";
import {Row} from "../../../components/Row";
import React, {useCallback, useEffect, useMemo} from "react";
import {useSelector} from "react-redux";
import {
    commitMessageSelector,
    conversionCommandsIdsSelector,
    deployResultsSelector,
    policyDeployEndpointsSelector,
    policyDeployPolicySelector,
    policyDeployProvidersForDeploySelectedTypesSelector, reportGroupIdSelector
} from "../selectors";
import {useAppDispatch} from "../../../store";
import {CircularProgress} from "@mui/material";
import {GraySpan} from "../../../components/styled";
import styled from "styled-components";
import onboardingIMG from "../../../assets/onboarding.png";
import warningIMG from "../../../assets/warning.png";
import {mapEndpointsByGroup} from "../helpers";
import {providerProviderType} from "core-lib";
import { Link } from "../../../components/styled/Link";
import {deployPolicyAWAF, deployPolicyNAP, deployPolicyXC} from "../actions";
import {LOCATIONS, PATHS} from "../../../navigation";
import {ScrollableContent} from "../../../components/PageScrollable";
import { useNavigate } from "react-router-dom";

const SpinnerRow = styled(Row)`
  padding: 40px;
  justify-content: center;
`
const CenterRow = styled(Row)`
  justify-content: center;
`
const SuccessText = styled.div`
  margin-top: 10px;
  color: #3183B2;
  font-weight: 700;
  font-size: 24px;
  line-height: 36px;
`
const IncompleteText = styled.div`
  margin-top: 10px;
  color: #FFC400;;
  font-weight: 700;
  font-size: 24px;
  line-height: 36px;
`
const ReportText = styled.div`
  padding: 40px 0;
`

type Props = {
    onBack: () => void,
    onNext: () => void,
}

export const ResultScreen = (props: Props) => {

    const navigate = useNavigate();
    const dispatch = useAppDispatch()
    const policy = useSelector(policyDeployPolicySelector)
    const providersToDeploySelected = useSelector(policyDeployProvidersForDeploySelectedTypesSelector)
    const endpoints = useSelector(policyDeployEndpointsSelector)
    const deployResults = useSelector(deployResultsSelector)
    const reportGroupId = useSelector(reportGroupIdSelector)
    const commitMessage = useSelector(commitMessageSelector)
    const conversionCommands = useSelector(conversionCommandsIdsSelector)


    const endpointCount = useMemo(() => Object.values(endpoints).reduce((count, current) => {
        return count + current.length
    }, 0), [endpoints])

    const providersCount = useMemo(() => providersToDeploySelected.reduce((count, provider) => {
        return endpoints[provider.id || '']?.length || provider.type !== providerProviderType.XC ? count + 1 : count
    }, 0), [providersToDeploySelected, endpoints])

    const providersWithResultCount = useMemo(() =>
        Object.keys(deployResults).reduce((count, providerId) => {
            const providerEndpoints = endpoints[providerId]
            const mapByGroup = mapEndpointsByGroup(providerEndpoints, providerId)
            const groupsToDeploy = Object.keys(mapByGroup)
            const groupsReady = Object.keys(deployResults[providerId])
            return groupsToDeploy.length === groupsReady.length ? count + 1 : count
        }, 0), [endpoints, deployResults])

    const providersWithSuccessCount = useMemo(() =>
        Object.keys(deployResults).reduce((count, providerId) => {
            const providerEndpoints = endpoints[providerId]
            const mapByGroup = mapEndpointsByGroup(providerEndpoints, providerId)
            const groupsToDeploy = Object.keys(mapByGroup)
            const groupsSuccessCount = Object.keys(deployResults[providerId]).reduce((groupsSuccessCount, currentGroup) => {
                return deployResults[providerId][currentGroup] ? groupsSuccessCount + 1 : groupsSuccessCount
            }, 0)
            return groupsToDeploy.length === groupsSuccessCount ? count + 1 : count
        }, 0), [endpoints, deployResults])

    const endpointsWithResultCount = useMemo(() =>
        Object.keys(deployResults).reduce((count, providerId) => {
            const providerEndpoints = endpoints[providerId]
            const mapByGroup = mapEndpointsByGroup(providerEndpoints, providerId)
            const endpointResultCountForProvider = Object.keys(deployResults[providerId]).reduce(
                (endpointResultCountForProvider, currentGroup) => {
                    return (typeof deployResults[providerId][currentGroup] !== 'undefined') ?
                        endpointResultCountForProvider + (mapByGroup[currentGroup]?.length || 0) :
                        endpointResultCountForProvider
                }, 0)
            return count + endpointResultCountForProvider
        }, 0), [endpoints, deployResults])

    const endpointsWithSuccessCount = useMemo(() =>
        Object.keys(deployResults).reduce((count, providerId) => {
            const providerEndpoints = endpoints[providerId]
            const mapByGroup = mapEndpointsByGroup(providerEndpoints, providerId)
            const endpointSuccessCountForProvider = Object.keys(deployResults[providerId]).reduce(
                (endpointsSuccessCount, currentGroup) => {
                    return deployResults[providerId]?.[currentGroup] ?
                        endpointsSuccessCount + mapByGroup[currentGroup]?.length :
                        endpointsSuccessCount
                }, 0)
            return count + endpointSuccessCountForProvider
        }, 0), [endpoints, deployResults])

    useEffect(() => {
        Object.keys(conversionCommands).forEach(type => {
            const providersByType = providersToDeploySelected.filter(provider => provider.type === type)
            const endpointsForProviders = providersByType.reduce((result, provider) => {
                const providerEndpoints = endpoints[provider.id || '']
                if(provider.id) {
                    if (providerEndpoints || type !== providerProviderType.XC) {
                        const mapByGroup = providerEndpoints ? mapEndpointsByGroup(providerEndpoints, provider.id) : {}
                        return {
                            ...result,
                            [provider.id]: mapByGroup
                        }
                    }
                }
                return result
            }, {} as {[providerId: string]: {[groupId: string]: Array<string>}})

            if (providersByType.length > 0) {
                if (type === providerProviderType.AWAF) {
                    dispatch(deployPolicyAWAF(conversionCommands[type], endpointsForProviders))
                } else if (type === providerProviderType.XC && Object.keys(endpointsForProviders).length > 0) {
                    dispatch(deployPolicyXC(conversionCommands[type], endpointsForProviders))
                } else if (type === providerProviderType.NAP) {
                    dispatch(deployPolicyNAP(conversionCommands[type], endpointsForProviders))
                }
            }
        })
    }, [dispatch, conversionCommands, providersToDeploySelected, endpoints])

    const onReportClick = useCallback(() => {
        navigate(`${PATHS[LOCATIONS.reports]}#${reportGroupId}`)
    }, [reportGroupId, navigate])

    const isLoading = useMemo(() => 
        endpointCount !== endpointsWithResultCount || providersWithResultCount !== providersCount
    , [endpointCount, endpointsWithResultCount, providersCount, providersWithResultCount])

    const isSuccess = useMemo(() => 
    endpointCount === endpointsWithSuccessCount && providersWithSuccessCount === providersCount
, [endpointCount, endpointsWithSuccessCount, providersCount, providersWithSuccessCount])

    return <BodyForm>
        <Header>
            <HeaderHeader>Deployment</HeaderHeader>
            <HeaderSubText>Completed</HeaderSubText>
        </Header>
        <ScrollableContent>
            <Divider mb={17}></Divider>
            <Legend>Policy: {policy?.metadata?.name}</Legend>
            {isLoading && <div>
                <SpinnerRow>
                    <CircularProgress
                        variant="indeterminate"
                        disableShrink
                        sx={{
                            color: '#3183B2'
                        }}
                        size={85}
                        thickness={2}
                    />
                </SpinnerRow>
                <CenterRow><GraySpan>Providers</GraySpan>&nbsp;{providersWithResultCount}/{providersCount}
                </CenterRow>
                <CenterRow><GraySpan>Endpoints</GraySpan>&nbsp;{endpointsWithResultCount}/{endpointCount}
                </CenterRow>
            </div>}
            {!isLoading && <div>
                <div>
                    <SpinnerRow>
                        {isSuccess ? <Col center>
                            <img src={onboardingIMG} alt='success'/>
                            <SuccessText>Success!</SuccessText>
                        </Col> : <Col center>
                            <img src={warningIMG} alt='incomplete'/>
                            <IncompleteText>Incomplete!</IncompleteText>
                        </Col>}
                    </SpinnerRow>
                    <CenterRow><GraySpan>Providers</GraySpan>&nbsp;{providersWithSuccessCount}/{providersCount}
                    </CenterRow>
                    <CenterRow><GraySpan>Endpoints</GraySpan>&nbsp;{endpointsWithSuccessCount}/{endpointCount}
                    </CenterRow>
                    <CenterRow>
                        <ReportText>
                            Deployment(s) saved as: <Link target="_blank"
                                                        to={`${PATHS[LOCATIONS.reports]}#${reportGroupId}`}>
                            {commitMessage} <Icon icon='app.externalLink'/>
                        </Link>
                        </ReportText>
                    </CenterRow>
                </div>
            </div>}
            <Col flex={1} center>
                <Row spacing={20} style={{marginTop: 10}}>
                    <Align>
                        <Button accent={true} displayMode="outlined" onClick={props.onNext}>Back to
                            Overview</Button>
                    </Align>
                    {!isLoading && <Align>
                        <Button accent={true} onClick={onReportClick}>View Report</Button>
                    </Align>}
                </Row>
            </Col>
        </ScrollableContent>
    </BodyForm>
}