import React, { useMemo, useState } from "react";
import styled from "styled-components";
import { Col } from "./Col";
import { Button, Icon } from "@emerald/react";
import iconWarning from "../assets/icon-status-warning.svg";
import iconError from "../assets/icon-status-error.svg";
import { Row } from "./Row";

const Container = styled.div`
 padding:12px 8px 12px 8px;
 width: 100%;
 border-radius: 4px;
`
const CloseIcon = styled(Icon)`
  color: #6C778C;
  font-size: 8px !important;
  height: 8px !important;
  line-height: 8px !important;
`

const TextContainer = styled.div`
font-size: 14px;
line-height: 20px;
word-break: break-word;
`

const TitleContainer = styled(TextContainer)`
font-weight: 700
`

type Props = {
    type: "WARNING" | "ERROR";
    title?: string;
    text?: string;
    open?: boolean;
    onClose?: () => void;
}

export const DissmisibleMessage = ({ type, title, text, open, onClose }: Props) => {

    const [isOpenInternal, setIsOpenInternal] = useState<boolean>(true)

    const { icon, backgroundColor, alt } = useMemo(() => {
        switch (type) {
            case "WARNING":
                return {
                    alt: "warning",
                    icon: iconWarning,
                    backgroundColor: "#FFF6D9"
                }
            default:
                return {
                    alt: "error",
                    icon: iconError,
                    backgroundColor: "#FEECE9"
                }
        }
    }, [type])

    const onCloseClick = () => {
        setIsOpenInternal(false)
        onClose && onClose()
    }

    const isOpen = open === undefined ? isOpenInternal : open

    return isOpen ? <Container style={{ backgroundColor }}>
        <Row spacing={8}>
            <Col width={32}>
                <img width={20} height={20} src={icon} alt={alt} />
            </Col>
            <Col spacing={8} fullWidth>
                {title && <TitleContainer>{title}</TitleContainer>}
                {text && <TextContainer>{text}</TextContainer>}
            </Col>
            <Col width={32} height={20}>
                <Button size='mini' displayMode="linkInk" onClick={onCloseClick}><CloseIcon icon='app.close' /></Button>
            </Col>
        </Row>
    </Container> : null
}
