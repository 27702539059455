import {AppDispatch} from "../../store";
import {ProvidersService} from "core-lib";
import {errorActionRequest} from "../Errors/actions";
import {providersListSlice} from "./reducer";
import {PAGINATION_FIRST_PAGE, PAGINATION_HUGE_SIZE} from "../../constants";

export const fetchProviders = () => {
    return async (dispatch: AppDispatch) => {
        return ProvidersService.providersListProviders(
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            PAGINATION_FIRST_PAGE,
            PAGINATION_HUGE_SIZE)
            .then(response => {
                dispatch(providersListSlice.actions.setProviders(response.providers || []));
            })
            .catch(error => {
                errorActionRequest(error);
            })
    }
}

export const removeProviders = (providersIds: string[]) => {
    return async (dispatch: AppDispatch) => {
        return Promise.all(providersIds.map(id => ProvidersService.providersDeleteProvider(id)
            .then(_response => {
                dispatch(providersListSlice.actions.resetProviders());
            }))).catch(error => {
            errorActionRequest(error);
        })
    }
}