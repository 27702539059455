import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {userResponse} from 'core-lib'

const initialState = {
    user: null as userResponse | null,
    isLoading: true
}

type StateType = typeof initialState;

export const authSlice = createSlice({
    name: 'AUTH',
    initialState: initialState as StateType,
    reducers: {
        setIsLoading: (state: StateType, action: PayloadAction<boolean>) => {
            state.isLoading = action.payload
        },
        setUser: (state: StateType, action: PayloadAction<userResponse | null>) => {
            state.user = action.payload
        }
    }
});
