import React, {useEffect} from 'react'
import {useAppDispatch} from "../../store";
import {loadApp} from "./actions";
import {getWorkspaceIdFromURL} from "core-lib";

type StartContainerProps = {
    children: JSX.Element
}

const workspaceId = getWorkspaceIdFromURL();

export function StartContainer(props: StartContainerProps) {

    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(loadApp());
    }, [dispatch]);

    return <>
        {workspaceId && props.children}
    </>
}
