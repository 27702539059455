/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { tokenCreateRequest } from '../models/tokenCreateRequest';
import type { tokenCreateResponse } from '../models/tokenCreateResponse';
import type { tokenListResponse } from '../models/tokenListResponse';
import type { tokenResponse } from '../models/tokenResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class TokensService {

    /**
     * @param orderName
     * @param orderRole
     * @param orderCreatedAt
     * @param filterName
     * @param filterRole
     * @param page
     * @param size
     * @returns tokenListResponse A successful response.
     * @throws ApiError
     */
    public static tokensListTokens(
        orderName: 'DESCENDING' | 'ASCENDING' = 'DESCENDING',
        orderRole: 'DESCENDING' | 'ASCENDING' = 'DESCENDING',
        orderCreatedAt: 'DESCENDING' | 'ASCENDING' = 'DESCENDING',
        filterName?: Array<string>,
        filterRole?: Array<string>,
        page?: number,
        size?: number,
    ): CancelablePromise<tokenListResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/tokens',
            query: {
                'order.name': orderName,
                'order.role': orderRole,
                'order.createdAt': orderCreatedAt,
                'filter.name': filterName,
                'filter.role': filterRole,
                'page': page,
                'size': size,
            },
            errors: {
                400: `Invalid argument`,
                401: `Returned when the user does not have permission to access the resource.`,
                404: `Resource was not found.`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * @param body
     * @returns tokenCreateResponse A successful response.
     * @throws ApiError
     */
    public static tokensCreateToken(
        body: tokenCreateRequest,
    ): CancelablePromise<tokenCreateResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/tokens',
            body: body,
            errors: {
                400: `Invalid argument`,
                401: `Returned when the user does not have permission to access the resource.`,
                404: `Resource was not found.`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * @param id
     * @returns tokenResponse A successful response.
     * @throws ApiError
     */
    public static tokensGetToken(
        id: string,
    ): CancelablePromise<tokenResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/tokens/{id}',
            path: {
                'id': id,
            },
            errors: {
                400: `Invalid argument`,
                401: `Returned when the user does not have permission to access the resource.`,
                404: `Resource was not found.`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * @param id
     * @param force
     * @returns any A successful response.
     * @throws ApiError
     */
    public static tokensDeleteToken(
        id: string,
        force?: boolean,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/v1/tokens/{id}',
            path: {
                'id': id,
            },
            query: {
                'force': force,
            },
            errors: {
                400: `Invalid argument`,
                401: `Returned when the user does not have permission to access the resource.`,
                404: `Resource was not found.`,
                500: `Internal server error`,
            },
        });
    }

}
