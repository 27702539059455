import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../../store";
import { policiesListArraySelector, policiesItemsToDeleteSelector } from "./selectors";
import { downloadPolicy, fetchPolicies, removePolicies } from "./actions";
import { policyPolicyStatus } from "core-lib";
import moment from "moment";
import { ElapsedTimeComponent } from "../../components/ElapsedTimeComponent";
import { Button, Icon, Select, Spinner } from "@emerald/react";
import { Stack } from "../../components/Stack";
import { EmptyPoliciesComponent } from "./EmptyPoliciesComponent";
import styled from "styled-components";
import { policyDeploySlice } from "../PolicyDeploy/reducer";
import { Tag } from "../../components/Tag";
import { Row } from "../../components/Row";
import { Col } from "../../components/Col";
import { policiesListSlice } from "./reducer";
import { policiesListItem } from "./types";
import { policyEditSlice } from "../PolicyEdit/reducer";
import { policyDetailsSlice } from "../PolicyDetails/reducer";
import { policyConversionSlice } from "../PolicyConversion/reducer";
import { policyImportSlice } from "../PolicyImport/reducer";
import { DataGrid } from "../../components/DataGrid";
import { useLocation } from "react-router-dom";
import { DeleteItemsDialog } from "../../components/DeleteItemsDialog";

const Header = styled.div`
  font-weight: 700;
  font-size: 24px;
  line-height: 36px;
  margin-bottom: 10px;
`
const Content = styled.div`
  height: 100%
`
const SpinnerIcon = styled(Spinner)`
  vertical-align: baseline;
`
const IconIcon = styled(Icon)`
  vertical-align: baseline;
`
const StatusLine = styled.div`
  height: 20px;
`

const DateLine = styled.div`
  color: #9EA7B8;
`

const StatusIcon = styled.span`
  margin-right: 5px;
  vertical-align: middle;
`
const NameSpan = styled.div`
  color: #4F73FF;
  cursor: pointer;
  vertical-align: middle;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  line-height: 20px;
  height: 20px;
`

const StatusText = styled.div`
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
`

enum CreateButtonType {
    CREATE = 'CREATE',
    IMPORT = 'IMPORT',
    INGEST = 'INGEST',
}

const POLICY_STATUSES = {
    [policyPolicyStatus.SAVED]: 'Saved',
    [policyPolicyStatus.ATTACHED]: 'Attached',
    [policyPolicyStatus.DEPLOYING]: 'Deploying',
    [policyPolicyStatus.DEPLOYED]: 'Deployed',
    [policyPolicyStatus.CONVERTING]: 'Converting',
    [policyPolicyStatus.CONVERTED]: 'Converted',
    [policyPolicyStatus.CONVERT_FAILED]: 'Conversion Failed',
    [policyPolicyStatus.DEPLOY_FAILED]: 'Deploy Failed',
}

const PoliciesStatusIconComponent = ({status} :{ status?: string }) => {
    return <StatusIcon>
        {status === policyPolicyStatus.DEPLOYING && <SpinnerIcon size="small" />}
        {status === policyPolicyStatus.DEPLOYED && <IconIcon size="small" icon="status.success" />}
        {status === policyPolicyStatus.ATTACHED && <IconIcon size="small" icon="app.pin" />}
        {status === policyPolicyStatus.SAVED && <IconIcon size="small" icon="app.save" />}
        {status === policyPolicyStatus.CONVERTING && <SpinnerIcon size="small" />}
        {status === policyPolicyStatus.CONVERTED && <IconIcon size="small" icon="status.success" />}
        {status === policyPolicyStatus.CONVERT_FAILED && <IconIcon size="small" icon="status.error" />}
        {status === policyPolicyStatus.DEPLOY_FAILED && <IconIcon size="small" icon="status.error" />}
    </StatusIcon>
}

const PolicyNameCell = (props: { row: policiesListItem, onClick: (id: string) => void }) => {
    const { row, onClick } = props;

    const onNameClick = useCallback(() => {
        row.id && onClick(row.id)
    }, [row, onClick])

    return <NameSpan onClick={onNameClick}>{row.metadata.name}</NameSpan>
}

export function PoliciesListComponent() {

    const location = useLocation()
    const initialPolicyId = location.hash ? unescape(location.hash.substr(1)) : ''

    const dataSource = useSelector(policiesListArraySelector);
    const itemsToDelete = useSelector(policiesItemsToDeleteSelector);
    const [deleting, setDeleting] = useState<boolean>(false)
    const dispatch = useAppDispatch();
    const [itemsSelected, setItemsSelected] = useState([] as string[])
    const isLoading = dataSource === null || deleting;

    const refresh = useCallback(() => {
        dispatch(policiesListSlice.actions.resetPolicies());
    }, [dispatch])

    useEffect(() => {
        if (dataSource === null) {
            dispatch(fetchPolicies()).then(() => {
                setDeleting(false);
                setItemsSelected([])
            });
        }
    }, [dispatch, dataSource]);

    const removeAction = useCallback((ids: string[]) => {
        dispatch(policiesListSlice.actions.setItemsToDelete(ids));
        dispatch(policyDetailsSlice.actions.setPolicyId(null))
    }, [dispatch])
    const onRemoveConfirmed = useCallback(() => {
        if (itemsToDelete) {
            setDeleting(true)
            dispatch(removePolicies(itemsToDelete))
        }
    }, [dispatch, setDeleting, itemsToDelete])
    const onRemoveCanceled = useCallback(() => {
        dispatch(policiesListSlice.actions.setItemsToDelete([]));
    }, [dispatch])

    const edit = useCallback((id: string) => {
        dispatch(policyEditSlice.actions.setPolicyId(id));
    }, [dispatch])

    const openDetails = useCallback((policyId) => {
        dispatch(policyDetailsSlice.actions.setPolicyId(policyId))
    }, [dispatch])

    const handleSelectionChange = useCallback(
        (_changed: any[], selected: any[]) => {
            setItemsSelected(selected)
        }, []
    );

    useEffect(() => {
        if (initialPolicyId) {
            openDetails(initialPolicyId)
        }
    }, [initialPolicyId, openDetails])

    const openPolicyImport = useCallback(() => {
        dispatch(policyImportSlice.actions.open())
    }, [dispatch])

    const onAddPolicyMenuClick = useCallback((value: any) => {
        switch (value) {
            case CreateButtonType.CREATE:
                edit('')
                break
            case CreateButtonType.IMPORT:
                openPolicyImport()
                break
        }
    }, [edit, openPolicyImport])
    const onAddPolicyClick = useCallback(() => {
        edit('')
    }, [edit])
    const onRefreshClick = useCallback(() => {
        refresh()
    }, [refresh])
    const onRemoveClick = useCallback(() => {
        removeAction(itemsSelected)
    }, [removeAction, itemsSelected])
    const onExportClick = useCallback((exportIds: string[]) => {
        const itemsToExport = dataSource?.filter(item => exportIds.indexOf(item.id || '') > -1);
        if (itemsToExport) {
            itemsToExport.forEach((item) => {
                if (item.id) {
                    dispatch(downloadPolicy(item.id, `${item.metadata.name} CDP.json`))
                }
            })
        }
    }, [dispatch, dataSource])

    const columns: any = useMemo(() => [
        {
            accessor: (row: policiesListItem) => row.metadata.name,
            width: 400,
            title: 'Policy name',
            sortable: true,
            filterable: true,
            renderer: (row: policiesListItem) => {
                return <PolicyNameCell row={row} onClick={() => row.id && openDetails(row.id)} />
            }
        },
        {
            accessor: (row: policiesListItem) => row.id,
            title: 'ID',
            width: 350,
            sortable: true,
            filterable: true,
            renderer: (row: policiesListItem) => row.id
        },
        {
            accessor: (row: policiesListItem) => row.metadata.lastModifiedTimestamp,
            title: 'Last Modified',
            width: 200,
            sortable: true,
            renderer: (row: policiesListItem) =>
                <Row fullWidth>
                    <Col>
                        <PoliciesStatusIconComponent status={row.metadata.status} />
                    </Col>
                    <Col fullWidth flex={1}>
                        <StatusLine>
                            <span>{POLICY_STATUSES[row.metadata.status as policyPolicyStatus || policyPolicyStatus.SAVED]}</span>
                        </StatusLine>
                        <DateLine>
                            {(row.metadata.status === policyPolicyStatus.DEPLOYING || row.metadata.status === policyPolicyStatus.CONVERTING) &&
                                <StatusText>ELAPSED TIME: <ElapsedTimeComponent time={moment(row.metadata.modifiedTime).valueOf()} /></StatusText>}
                            {row.metadata.status !== policyPolicyStatus.DEPLOYING && row.metadata.status !== policyPolicyStatus.CONVERTING &&
                                <StatusText>{moment(row.metadata.modifiedTime).format('h:mm A, D MMM YYYY')}</StatusText>}
                        </DateLine>
                    </Col>
                </Row>
        },
        {
            accessor: (row: policiesListItem) => row.metadata.labels?.map(label => label.value).join(', '),
            title: 'Labels',
            filterable: true,
            renderer: (row: policiesListItem) =>
                <Stack direction="row" spacing={5}>
                    {row.metadata.labels?.map(
                        label => (<Tag key={label.value} isActionable={false}>{label.value}</Tag>)
                    )}
                </Stack>
        }
    ], [openDetails])

    const rowActions = useCallback(() => [
        {
            items: [
                { value: "edit", icon: "app.edit", label: `Edit Policy` },
                { value: "convert", icon: "app.swap", label: `Convert` },
                { value: "deploy", icon: "app.start", label: `Deploy` },
                { value: "exportjson", icon: "app.externalLink", label: `Export JSON` }
            ],
        },
        {
            items: [
                { value: "delete", icon: "app.delete", label: `Delete` }
            ]
        }
    ], [])

    const handleRowAction = useCallback(({ item, action }: { item: any; action: string }) => {
        switch (action) {
            case "edit":
                item.id && edit(item.id)
                break
            case "deploy":
                item && dispatch(policyDeploySlice.actions.openPolicyDeploy(item))
                break
            case "convert":
                item && dispatch(policyConversionSlice.actions.openPolicyConversion(item));
                break
            case "delete":
                item.id && removeAction([item.id])
                break
            case "exportjson":
                item.id && onExportClick([item.id])
                break
        }
    }, [dispatch, edit, removeAction, onExportClick]);

    const toolbarActions = useCallback(() => <Row>
        <Button disabled={!itemsSelected.length} displayMode='linkInk' onClick={() => onExportClick(itemsSelected)}>
            <Icon icon='app.export' />
            <span>Export</span>
        </Button>
        <Button disabled={!itemsSelected.length} displayMode='linkInk' onClick={onRemoveClick}>
            <Icon icon='app.delete' />
            <span>Delete</span>
        </Button>
    </Row>, [itemsSelected, onExportClick, onRemoveClick])

    const toolbarCustomFilter = useCallback(() => <Button displayMode="ghostInk" onClick={onRefreshClick}>
        <Icon icon="app.refresh" />
        <span>Refresh</span>
    </Button>, [onRefreshClick])

    const policiesGroupActions = useMemo(() => ([{
        items: [
            { value: CreateButtonType.CREATE, label: 'Create New' },
            { value: CreateButtonType.IMPORT, label: 'Import from File' }
        ]
    }]), [])

    const addActionButtonConfig = useMemo(() => ({
        accent: true,
        displayMode: "filled" as "link" | "filled" | "outlined" | "ghost" | "ghostInk" | "linkInk" | "linkSubtle" | undefined,
        size: "medium" as "small" | "medium" | "large" | "mini" | "slim" | undefined,
        text: "Add",
        textPosition: 'left' as "left" | "right" | undefined,
        alignPopup: "right" as "left" | "right" | "center" | "leftMid" | "rightMid"
    }), [])

    const initialConfig = useMemo(() => ({
        sortColumn: columns[2],
        sortDirection: 'descending' as "descending" | "ascending" | undefined
    }), [columns])

    return <Content>

        <Col fullHeight>

            <Row fullWidth>
                <Col flex={1}><Header>Policies</Header></Col>
                <Col>
                    <Select
                        value=''
                        options={policiesGroupActions}
                        onChange={onAddPolicyMenuClick}
                        multiSelect={false}
                        allowSearch={false}
                        allowClearSelection={false}
                        displayMode="button"
                        buttonConfig={addActionButtonConfig}
                    />
                </Col>
            </Row>

            <Row fullWidth flex={1}>
                {!isLoading && dataSource?.length === 0 ? <EmptyPoliciesComponent
                    onAddClick={onAddPolicyClick}
                    onImportClick={openPolicyImport}
                />
                    : <DataGrid
                        isLoading={isLoading}
                        columns={columns}
                        items={dataSource || []}
                        itemIdAccessor='id'
                        showToolbar={true}
                        allowSelectRows={true}
                        allowSelectAll={true}
                        onSelectionChange={handleSelectionChange}
                        toolbarActions={toolbarActions}
                        toolbarCustomFilter={toolbarCustomFilter}
                        rowActions={rowActions}
                        onRowAction={handleRowAction}
                        configInitial={initialConfig}
                    />}
            </Row>
        </Col>

        <DeleteItemsDialog
            title="Delete policies"
            actions={[
                {
                    accent: true,
                    onClick: onRemoveCanceled,
                    displayMode: "outlined",
                    children: "Cancel"
                },
                {
                    accent: true,
                    onClick: onRemoveConfirmed,
                    children: "Delete"
                }
            ]}
            description="Are you sure you want to delete the selected policies?"
            items={dataSource?.filter(item => itemsToDelete.includes(item.id || '')).map(item => item.metadata.name || '') || []}
        />
    </Content >
}