import React, {useEffect} from "react";
import {CircularProgress} from "@mui/material";
import styled from "styled-components";
import { getProviderName } from "./utils";
import { isErrorSelector, isSuccessSelector } from "./selectors";
import { useSelector } from "react-redux";

const ComponentWrapper = styled.div`
  text-align: center;
`

const Title = styled.h2`
  color: #4F73FF;
  margin: 34px 0 14px;
`

const Subtitle = styled.div`
  color: #4F73FF;
  margin-bottom: 40px;
`

const TableWrapper = styled.div`
  display: flex;
  justify-content: center;
  text-align: left;
  font-size: 12px;
  line-height: 18px;
`

const TableRowTitle = styled.td`
  width: 100px;
  font-weight: 700;
`

const TableRowData = styled.td`
  max-width: 215px;
  color: #6C778C;
`

type Props = {
    values: any,
    onNext: Function
    onReset: Function
}

export function InProgressComponent(props: Props) {
    const {values, onNext, onReset} = props

    const {source, target, name} = values

    const isError = useSelector(isErrorSelector)
    const isSuccess = useSelector(isSuccessSelector)

    useEffect(() => {
      if(isError) onReset()
      if(isSuccess) onNext()
    }, [isError, isSuccess])


    return <ComponentWrapper>
        <CircularProgress
            variant="indeterminate"
            disableShrink
            sx={{
                color: '#3183B2'
            }}
            size={85}
            thickness={2}
        />
        <Title>In Progress...</Title>
        <Subtitle>This may take a few minutes.</Subtitle>
        <TableWrapper>
            <table>
              <tbody>
                <tr>
                    <TableRowTitle>Policy Name</TableRowTitle>
                    <TableRowData>{name}</TableRowData>
                </tr>
                <tr>
                    <TableRowTitle>Source Type</TableRowTitle>
                    <TableRowData>{getProviderName(source)}</TableRowData>
                </tr>
                <tr>
                    <TableRowTitle>Target WAF</TableRowTitle>
                    <TableRowData>{getProviderName(target)}</TableRowData>
                </tr>
              </tbody>
            </table>
        </TableWrapper>
    </ComponentWrapper>
}
