import React, { useRef } from "react";
import { Close } from "@mui/icons-material";
import { Stack, TextField, Typography } from "@mui/material";
import Box from '@mui/material/Box';
import styled from "styled-components";

type TagsProps = {
    data: string
    handleDelete: (data: string) => void
}

const Tags = ({ data, handleDelete}: TagsProps) => {
    return (
        <Box
            sx={{
                background: "#E6E9F3",
                height: "20px",
                display: "flex",
                padding: "4px 6px 4px 8px",
                margin: "0 8px 0.4rem 0",
                borderRadius: '4px',
                fontSize: '12px',
                lineHeight: '18px',
                justifyContent: "center",
                alignContent: "center",
                color: "#0F1E57"
            }}
        >
            <Stack direction='row' gap={1} sx={{alignItems: 'center'}}>
                <Typography style={{whiteSpace: 'nowrap'}}>{data}</Typography>
                <Close
                    fontSize='small'
                    sx={{ cursor: "pointer" }}
                    onClick={() => {
                        handleDelete(data);
                    }}
                />
            </Stack>
        </Box>
    );
};

type InputTagsProps = {
    value: Array<string>
    onChange: (value: Array<string>) => void
}

const StyledTextField = styled(TextField)`
  && .MuiInput-root::after {
    border-color: #5e71ce;
  }
`;

export function TagsField({ value, onChange }: InputTagsProps) {
    const tagRef = useRef<HTMLInputElement>();

    const handleDelete = (valueToDelete: string) => {
        const newtags = value.filter((val) => val !== valueToDelete);
        onChange(newtags);
    };
    const onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') {
            e.preventDefault()
            e.stopPropagation()
            if(tagRef.current) {
                const newValue = (tagRef.current?.value || '').trim()
                newValue && onChange([...value, newValue])
                tagRef.current.value = ""
            }
        }
    }
    return (
        <Box sx={{ flexGrow: 1 }}>
            <StyledTextField
                onKeyDown={onKeyDown}
                inputRef={tagRef}
                fullWidth
                variant='standard'
                size='small'
                sx={{ margin: "1rem 0" }}
                margin='none'
                placeholder={value.length < 5 ? "Enter tags" : ""}
                InputProps={{
                    startAdornment: (
                        <Box sx={{ margin: "0 0.2rem 0 0", display: "flex", height: '35px' }}>
                            {value.map((data, index) => {
                                return (
                                    <Tags data={data} handleDelete={handleDelete} key={index} />
                                );
                            })}
                        </Box>
                    ),
                }}
            />
        </Box>
    );
}