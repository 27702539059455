import {createSlice, PayloadAction} from '@reduxjs/toolkit'

const initialState = {
    isLoaded: false
}

type StateType = typeof initialState;

export const startSlice = createSlice({
    name: 'START',
    initialState: initialState as StateType,
    reducers: {
        setIsLoaded: (state: StateType , action: PayloadAction<boolean>) => {
            state.isLoaded = action.payload;
        }
    }
});
