import {createSlice, PayloadAction} from '@reduxjs/toolkit'

const initialState = {
    providerId: '' as string,
}

type StateType = typeof initialState;

export const providerDetailsSlice = createSlice({
    name: 'PROVIDER_DETAILS',
    initialState: initialState as StateType,
    reducers: {
        setProviderId: (state: StateType , action: PayloadAction<string>) => {
            state.providerId = action.payload;
        },  
    }
});
