import {Align, Button, ContentStatus, FormField, Input} from "@emerald/react";
import React, {useCallback, useState} from "react";
import {useAppDispatch} from "../../store";
import {Header, BodyForm, Footer} from "../../components/styled/Drawer";
import {Divider} from "../../components/Divider";
import {Col} from "../../components/Col";
import {Row} from "../../components/Row";
import {createSecretXC} from "./actions";
import {ScrollableContent} from "../../components/PageScrollable";
import styled from "styled-components";

const InstructionText = styled.div`
  font-size: 12px;
  line-height: 16px;
`

const InstructionList = styled.ul`

  padding-inline-start: 20px;
  
  li {
    list-style-type: number;
    margin-right: 0;
  }
`

type Props = {
    onFinish: () => void
}

const INITIAL_STATE = {
    name: '',
    token: ''
}

export const AddSecretComponent = (props: Props) => {

    const {onFinish} = props

    const dispatch = useAppDispatch()
    const [isCreating, setIsCreating] = useState(false)
    const [state, setState] = useState(INITIAL_STATE)

    const {name, token} = state

    const onCreateClick = useCallback(() => {
        setIsCreating(true)
        dispatch(createSecretXC(name, token))
            .then(() => {
                onFinish()
            })
            .finally(() => {
                setIsCreating(false)
            })
    }, [dispatch, onFinish, name, token])

    const onCancelClick = useCallback(() => {
        onFinish()
    }, [onFinish])

    const onNewNameChange = (event: any) => {
        setState({
            ...state,
            name: event.target.value
        })
    }

    const onNewTokenChange = (event: any) => {
        setState({
            ...state,
            token: event.target.value
        })
    }

    return <BodyForm>
        <Header>Add Secret</Header>
        <ScrollableContent>
            <div>
                <FormField required={true} label="Secret Name">
                    <Input value={state.name} onChange={onNewNameChange}/>
                </FormField>
                <FormField required={true} label="API Token">
                    <Input value={state.token} onChange={onNewTokenChange}/>
                </FormField>
                <InstructionText>
                    <p>Instructions to generate API Token:</p>

                    <InstructionList>
                        <li>Go to <b><i>Account {'>'} Account Settings {'>'} Personal Management {'>'} Credentials
                            {'>'} Add Credentials</i></b>.
                        </li>
                        <li>At <b><i>Credential Type</i></b> select <b><i>API Token</i></b></li>
                        <li>Enter a name and an expiration date and click Generate</li>
                    </InstructionList>
                </InstructionText>
            </div>
        </ScrollableContent>
        <Footer>
            <Divider/>
            <Col flex={1} rightDirection>
                <Row spacing={20} style={{marginTop: 10}}>
                    <Align>
                        <Button accent={true} displayMode="outlined" onClick={onCancelClick}>Cancel</Button>
                    </Align>
                    <Align>
                        <Button disabled={!state.name} accent={true} onClick={onCreateClick}>Create</Button>
                    </Align>
                </Row>
            </Col>
        </Footer>
        <ContentStatus
            isVisible={isCreating}
            spinnerSize={"medium"}
            defaultMessage={<span>Creating secret</span>}
            fillOuterContainer={false}
        />
    </BodyForm>
}