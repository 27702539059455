import {RootState} from "../../store";
import {conversionConversionInfo} from "core-lib";
import {createSelector} from "@reduxjs/toolkit";
import {conversionsListItem} from "./types";

export const conversionsListSelector = (state: RootState): Array<conversionConversionInfo> => state.conversionsList.data.conversions || [];
export const conversionListTotalCount = (state: RootState): number =>
    state.conversionsList.data.totalCount ? Number(state.conversionsList.data.totalCount) : 0;
export const conversionsListArraySelector = createSelector(
    [conversionsListSelector],
    (conversionsList) => {
    return conversionsList.map((item: conversionConversionInfo) => ({
        ...item,
        dateTimestamp: item.date ? new Date(item.date).getTime() : 0
    })) as conversionsListItem[]
})
