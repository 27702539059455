import {
    BodyFormFull,
    Header,
    HeaderHeader,
} from "../../../components/styled/Drawer";
import { Divider } from "../../../components/Divider";
import { Align, Button, Icon } from "@emerald/react";
import { Col } from "../../../components/Col";
import { Row } from "../../../components/Row";
import React, { useCallback } from "react";
import { useSelector } from "react-redux";
import {
    commitMessageSelector,
    policyConversionPolicySelector, policyConversionProviderTypeSelectedSelector, reportGroupIdSelector
} from "../selectors";
import styled from "styled-components";
import onboardingIMG from "../../../assets/onboarding.png";
import { LOCATIONS, PATHS } from "../../../navigation";
import { ScrollableContent } from "../../../components/PageScrollable";
import { Link } from "../../../components/styled/Link";
import { PROVIDERS_INFO } from "../../../constants";
import { useNavigate } from "react-router-dom";

const CenterRow = styled(Row)`
  justify-content: center;
  flex-direction: column;
  align-items: center;
`
const ReportText = styled.div`
  line-height: 20px;
  font-size: 14px;
  margin-top: 30px;
  margin-bottom: 70px;
`

const SuccessText = styled.div`
  margin-top: 10px;
  color: #4F73FF;
  font-weight: 700;
  font-size: 24px;
  line-height: 36px;
`

const GrayIcon = styled(Icon)`
  color: #00A980;
`

const ButtonText = styled.div`
  width: 118px;
  text-align: center;
`

const ProgressItemTitle = styled.span`
    font-size: 12px;
    margin-left: 19px;
    line-height: 18px;
    font-weight: bold;
    color: #0F1E57;
    width: 100px;
`

const ProgressItemText = styled.span`
    color: #6C778C;
    font-size: 12px;
    line-height: 18px;
    width: 120px;
`

type Props = {
    onBack: () => void,
    onNext: () => void
}

export const ConversionResultScreen = (props: Props) => {

    const navigate = useNavigate();
    const policy = useSelector(policyConversionPolicySelector)
    const reportGroupId = useSelector(reportGroupIdSelector)
    const commitMessage = useSelector(commitMessageSelector)
    const providerTypeToConvert = useSelector(policyConversionProviderTypeSelectedSelector)

    const onReportClick = useCallback(() => {
        navigate(`${PATHS[LOCATIONS.reports]}#${reportGroupId}`)
    }, [reportGroupId, navigate])

    return <BodyFormFull>
        <Header pr={20} pl={20}>
            <Col centerVertical fullHeight>
                <HeaderHeader>Conversion Report</HeaderHeader>
            </Col>
        </Header>
        <Divider />
        <ScrollableContent mt={78}>
            <div>
                <CenterRow>
                    <Col center>
                        <img src={onboardingIMG} alt='success' />
                        <SuccessText>Success!</SuccessText>
                    </Col>
                    <Col spacing={6} mt={48}>
                        <Row center>
                            <GrayIcon size="xs" icon="app.check" />
                            <ProgressItemTitle>Policy Name</ProgressItemTitle>
                            <ProgressItemText>{policy?.metadata?.name}</ProgressItemText>
                        </Row>
                        <Row center>
                            <GrayIcon size="xs" icon="app.check" />
                            <ProgressItemTitle>Provider Type</ProgressItemTitle>
                            <ProgressItemText>{PROVIDERS_INFO[providerTypeToConvert || '']?.name}</ProgressItemText>
                        </Row>
                    </Col>
                </CenterRow>
                <CenterRow>
                    <ReportText>
                        Conversion report saved as: <Link target='_blank'
                            to={`${PATHS[LOCATIONS.reports]}#${reportGroupId}`}>
                            {commitMessage} <Icon icon='app.externalLink' />
                        </Link>
                    </ReportText>
                </CenterRow>
            </div>
            <Col flex={1} center>
                <Row spacing={20} mt={10}>
                    <Align>
                        <Button accent={true} displayMode="outlined" onClick={props.onNext}>
                            <ButtonText>Policies Overview</ButtonText>
                        </Button>
                    </Align>
                    <Align>
                        <Button accent={true} onClick={onReportClick}>
                            <ButtonText>View Report</ButtonText>
                        </Button>
                    </Align>
                </Row>
            </Col>
        </ScrollableContent>
    </BodyFormFull>
}