import { BodyForm, Header, HeaderText } from "../../../components/styled/Drawer";
import { Divider } from "../../../components/Divider";
import { Align, Button, Icon } from "@emerald/react";
import { Col } from "../../../components/Col";
import { Row } from "../../../components/Row";
import React, { useCallback, useEffect } from "react";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../../../store";
import { CircularProgress } from "@mui/material";
import styled from "styled-components";
import onboardingIMG from "../../../assets/onboarding.png";
import warningIMG from "../../../assets/warning.png";
import { Link } from "../../../components/styled/Link";
import { LOCATIONS, PATHS } from "../../../navigation";
import { ScrollableContent } from "../../../components/PageScrollable";
import { ingestPolicies } from "../actions";
import { providerIngestionCommitMessageSelector, providerIngestionReportGroupIdSelector, providerIngestionResultSelector } from "../selectors";
import { useNavigate } from "react-router-dom";

const GrayIcon = styled(Icon)`
  color: #00A980;
`

const SpinnerRow = styled(Row)`
  padding: 24px;
  justify-content: center;
`
const CenterRow = styled(Row)`
  justify-content: center;
`
const SuccessText = styled.div`
  margin-top: 10px;
  color: #4F73FF;
  font-weight: 700;
  font-size: 24px;
  line-height: 36px;
`
const IncompleteText = styled.div`
  margin-top: 10px;
  color: #FFC400;;
  font-weight: 700;
  font-size: 24px;
  line-height: 36px;
`
const ReportText = styled.div`
  padding: 40px 0;
`

type Props = {
    onBack: () => void,
    onNext: () => void,
}

export const ResultScreen = (props: Props) => {

    const navigate = useNavigate();
    const result = useSelector(providerIngestionResultSelector);
    const commitMessage = useSelector(providerIngestionCommitMessageSelector);
    const reportGroupId = useSelector(providerIngestionReportGroupIdSelector);
    const showCompletedScreen = result.count === result.completed;
    const showCompletionSuccess = result.completed === result.succeeded

    const dispatch = useAppDispatch()

    const onReportClick = useCallback(() => {
        navigate(`${PATHS[LOCATIONS.reports]}#${reportGroupId}`)
    }, [reportGroupId, navigate])

    useEffect(() => {
        dispatch(ingestPolicies())
    }, [dispatch])

    return <BodyForm>
        <Header pr={20} pl={20}>
            <Col centerVertical fullHeight>
                <HeaderText>Ingest Policies</HeaderText>
            </Col>
        </Header>
        <ScrollableContent>
            <Divider mb={90}></Divider>
            {!showCompletedScreen ? <div>
                <SpinnerRow>
                    <Col center>
                        <CircularProgress
                            variant="indeterminate"
                            disableShrink
                            sx={{
                                color: '#3183B2'
                            }}
                            size={85}
                            thickness={2}
                        />
                        <CenterRow><SuccessText>{result.succeeded}/{result.count} Ingested Policies...</SuccessText></CenterRow>
                    </Col>
                </SpinnerRow>
                <CenterRow>
                    <Col spacing={20}>
                        <Row>Connecting to Provider...</Row>
                        <Row>Ingesting Policies...</Row>
                    </Col>
                </CenterRow>
            </div> :
                <>
                    <div>
                        <SpinnerRow>
                            {showCompletionSuccess ? <Col center>
                                <img src={onboardingIMG} alt='success' />
                                <SuccessText>Success!</SuccessText>
                            </Col> : <Col center>
                                <img src={warningIMG} alt='incomplete' />
                                <IncompleteText>Incomplete!</IncompleteText>
                            </Col>}
                        </SpinnerRow>
                        {showCompletionSuccess &&
                            <>
                                <CenterRow>
                                    <Col spacing={20}>
                                        <Row center spacing={13.5}><GrayIcon size="xs" icon="app.check" />Connecting to Provider...</Row>
                                        <Row center spacing={13.5}><GrayIcon size="xs" icon="app.check" />Ingesting Policies...</Row>
                                    </Col>
                                </CenterRow>
                                {<CenterRow>
                                    <ReportText>
                                        Ingest Policy report saved as: <Link
                                            target="_blank"   
                                            to={`${PATHS[LOCATIONS.reports]}#${reportGroupId}`}>
                                            {commitMessage} <Icon icon='app.externalLink' />
                                        </Link>
                                    </ReportText>
                                </CenterRow>}
                            </>}
                    </div>
                    <Col flex={1} center>
                        <Row spacing={20} mt={10}>
                            <Align>
                                <Button accent={true} displayMode="outlined" onClick={props.onNext}>Policies
                                    Overview</Button>
                            </Align>
                            <Align>
                                <Button accent={true} onClick={onReportClick}>View Full Report</Button>
                            </Align>
                        </Row>
                    </Col>
                </>}
        </ScrollableContent>
    </BodyForm>
}