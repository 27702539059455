import React from "react";
import styled from "styled-components";
import emptyGroupImg from "../../assets/emptyProviders.png";
import {Align, Button, Card, Icon, Tooltip} from "@emerald/react";
import {Col} from "../../components/Col";

const ContentCard = styled(Card)`
  justify-content: center;
  text-align: center;
  width: 100%;
`
const TextDiv = styled.div`
  display: inline-block;
  margin-left: 5px;
`
const ButtonDiv = styled.div`
  margin-top: 20px;
`
const Content = styled.div`
  width: 100%;
`

type Props = {
    onButtonClick: () => void
}

export const EmptyProvidersComponent = (props: Props) => {
    return <Content>
        <ContentCard>
            <div><img src={emptyGroupImg} alt='empty providers'/></div>
            <div>
                <h3>No Providers added yet<TextDiv>
                    <Align>
                        <Tooltip
                            target={<Icon icon="app.information"/>}
                            content='Providers are remote instances that provide WAF services, such as NGINX App Protect and BIG-IP AWAF.'
                        />
                    </Align>
                </TextDiv>
                </h3>
            </div>
            <div>First, let’s add your providers before we add policies.</div>
            <ButtonDiv>
                <Col style={{alignItems: 'center'}}>
                    <Align>
                        <Button accent={true} onClick={props.onButtonClick}>Add Provider</Button>
                    </Align>
                </Col>
            </ButtonDiv>
        </ContentCard>
    </Content>
}