import React from "react";
import { Layout } from "@emerald/react";
import styled from "styled-components";
import f5Logo from "../../assets/F5 Logo.png";
import { Row } from "../../components/Row";
import { Col } from "../../components/Col";
import { Accordion } from "../../components/Accordion";
import { versionInfo } from "./versionInfo";


const ContentDiv = styled.div`
  padding: 20px;
  height: 1px;
  min-height: 100%;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  background: #FFFFFF;
`
const HeaderDiv = styled.div`
  height: 38px;
  padding: 10px;
`
const StyledLayout = styled(Layout)`
  min-height: 100vh;
`
const PSHeader = styled.div`
  font-size: 12px;
  line-height: 18px;
  height: 18px;
`
const PathHeader = styled.div`
  font-size: 18px;
`
const LogoWrapper = styled.div`    
    border-right: 1px solid #E6E9F3;
    margin-right: 10px;
    padding-right: 10px;
`
const ReleaseNotesContainer = styled.div`
    justify-content: left;
    width: 80%;
    border: 1px solid #CFD4E2;
    border-radius: 4px;
    overflow-y: scroll;
`

const Notes = styled.ul`
    padding: 27px;
    border-radius: 4px;
    border: 1px solid var(--grayscale-n-100-athens, #F7F8FA);
    background: var(--grayscale-n-0-white, #FFF);

    /* shadow/01 */
    box-shadow: 0px 2px 4px 0px rgba(72, 86, 101, 0.12), 0px 0px 2px 0px rgba(72, 86, 101, 0.24);
`;

const NoteItem = styled.li`
    color: #000;
    font-variant-numeric: lining-nums tabular-nums;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 171.429% */
`;

const VersionInfoContainer = styled.div``;

const VersionInfoTitle = styled.span``;
const VersionInfoDate = styled.span`
    margin-left: 16px;
`;

type VersionInfoProps = {
    title: string;
    date: Date;
}

function VersionInfo({ title, date }: VersionInfoProps) {
    return <VersionInfoContainer>
        <VersionInfoTitle>{title}</VersionInfoTitle>
        <VersionInfoDate>{date.toLocaleDateString()}</VersionInfoDate>
    </VersionInfoContainer>
}

export function ReleaseNotesScreen() {
    const AppbarContent = <div />

    const SidebarHeaderContent = <LogoWrapper>
        <a href='/'><img src={f5Logo} alt='F5 logo' /></a>
    </LogoWrapper>


    const HeaderContent = <HeaderDiv>
        <Row>
            <Col>{SidebarHeaderContent}</Col>
            <Col flex={1}>
                <PSHeader>
                    Policy Supervisor
                </PSHeader>
                <PathHeader>Release Notes</PathHeader>
            </Col>
        </Row>
    </HeaderDiv>

    return <StyledLayout
        edgeMode="inner"
        header={HeaderContent}
        headerActions={AppbarContent}>
        <ContentDiv>
            <ReleaseNotesContainer>
                {
                    versionInfo.map((vi, viIndex) => {
                        return (
                            <Accordion key={viIndex} title={<VersionInfo title={vi.title} date={vi.date} />} initialOpened={viIndex === 0}>
                                <Notes>
                                    {
                                        vi.notes.map((note, noteIndex) => {
                                            return (<NoteItem key={noteIndex}>{note}</NoteItem>)
                                        })
                                    }
                                </Notes>
                            </Accordion>
                        )
                    })
                }
            </ReleaseNotesContainer>
        </ContentDiv>
    </StyledLayout>
}