import React, {useCallback, useMemo} from "react";
import styled from "styled-components";
import {Align, Button, Card, Icon} from "@emerald/react";
import f5Logo from "../../assets/F5 Logo.png";
import {getAuthURL} from "core-lib";
import {Col} from "../../components/Col";
import {Row} from "../../components/Row";
import { Link } from "../../components/styled/Link";
import { LOCATIONS, PATHS } from "../../navigation";
import { WelcomeNotification } from "./WelcomeNotification";

const Content = styled.div`
  padding-top: 200px;
  text-align: center;
`
const CardStyled = styled(Card)`
  text-align: center;
  padding: 65px 80px;
  justify-content: center;
  width: 510px;
`
const AlignCenter = styled(Align)`
  justify-content: center;
`
const LoginButton = styled(Button)`
  width: 100%;
  margin-top: 20px;
  margin-bottom: 20px;

  span {
    justify-content: center;
  }
`
const ConverterLink = styled(Link)`
  margin-top: 20px;
  font-size: 14px;
  color: #3b3e5e !important;
`
const AgreementText = styled.div`
  box-sizing: border-box;
  margin-top: 10px;
  padding: 0 10px;
  width: 510px;
  font-size: 12px;
  line-height: 18px;
  
  a {
    color: #4F73FF;
    text-decoration: none;
  }
`
const InlineIconCenter = styled(Icon)`
  display: inline-block;
  vertical-align: text-top;
  color: #6C778C;
  margin-right: 3px;
`
const InlineIcon = styled(Icon)`
  color: #6C778C;
  margin-bottom: 5px;
`

export const AuthScreen = () => {
    const onButtonClick = useCallback(() => {
        document.location.href = getAuthURL(true)
    }, [])

    const isFirstVisit = useMemo(() => {
      const browserData = localStorage.getItem("visitStatus")
      localStorage.setItem("visitStatus", "visited")

      return browserData !== "visited"
    }, [])

    return <>
      <Content>
          <AlignCenter>
              <Col>
                  <Row>
                      <CardStyled>
                          <div>
                              <img src={f5Logo} alt='F5 logo'/>
                          </div>
                          <h3>Policy Supervisor</h3>
                          <LoginButton accent={true} onClick={onButtonClick}>Sign In with Azure AD</LoginButton>
                          <ConverterLink target='_blank'
                              to={`${PATHS[LOCATIONS.convert]}`}>
                              Conversion Utility <InlineIcon icon='app.externalLink' />
                          </ConverterLink>
                          <div></div>
                      </CardStyled>
                  </Row>
                  <Row fullWidth>
                      <AgreementText>
                          By proceeding with the sign-in, I acknowledge that I have read, understand, and agree to the terms of F5, Inc.
                          <a href="https://www.f5.com/pdf/customer-support/eusa.pdf"
                            target="_blank" rel="noreferrer">End User Services Agreement</a> <InlineIconCenter icon='app.externalLink' size='xs'/>
                          and <a href="https://www.f5.com/company/policies/privacy-notice"
                                target="_blank" rel="noreferrer">F5 Privacy Policy</a> <InlineIconCenter icon='app.externalLink' size='xs'/>
                      </AgreementText>
                  </Row>
              </Col>
          </AlignCenter>
      </Content>
      {isFirstVisit && <WelcomeNotification />}
    </>
}