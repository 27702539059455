import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {workspaceResponse} from "core-lib";

const initialState = {
    data: [] as Array<workspaceResponse>,
}

type StateType = typeof initialState;

export const workspacesSlice = createSlice({
    name: 'WORKSPACES',
    initialState: initialState as StateType,
    reducers: {
        loadWorkspaces: (state: StateType , action: PayloadAction<Array<workspaceResponse>>) => {
            state.data = action.payload;
        }
    }
});
