import React, { useCallback, useMemo, useState } from "react";
import { errorsSelector } from "./selectors";
import { useSelector } from "react-redux";
import { Scroller, TextContainer, Toast } from "@emerald/react";
import { useAppDispatch } from "../../store";
import { removeErrorById } from "./actions";
import styled from "styled-components";

const ShowMoreLink = styled.a`
  color: #4F73FF;
  cursor: pointer;
  border-bottom: 1px dashed #4F73FF;
`
const ErrorsStack = styled.div`
  z-index: 999999;
`
const TitleInsideError = styled.h5`
  margin: 0 0 8px;
  font-size: 14px;
  white-space: pre-wrap;
  word-wrap: anywhere;
`

const TextInsideError = styled.p`
  white-space: pre-wrap;
  word-wrap: anywhere;
`
const ErrorScroller = styled(Scroller)`
  height: 200px !important;
`

const ErrorComponent = (props: { error: { id: number, error: any } }) => {

  const { error } = props
  const code = error.error.body?.statusCode || error.error.status
  const title = error.error.body?.error || error.error.statusText
  const message = error.error.body?.message || error.error.message || ''
  const content = error.error.errorContent || JSON.stringify(error.error, null, 4)

  const dispatch = useAppDispatch()

  const onDismissed = useCallback(() => {
    dispatch(removeErrorById(error.id))
  }, [dispatch, error])

  const [isOpened, setIsOpened] = useState(false)

  const onShowMoreClick = useCallback(() => {
    setIsOpened(true)
  }, [setIsOpened])

  const processedMessage = useMemo(() =>
    message.length > 100 ? `${message.substring(0, 100)} ...` : message
    , [message])

  return <Toast
    key={error.id}
    onDismissed={onDismissed}
    status="error">
    <TitleInsideError>{`Error ${code || ''} - ${title || ''}`}</TitleInsideError>
    <TextInsideError>{processedMessage}</TextInsideError>
    {!isOpened && <ShowMoreLink onClick={onShowMoreClick}>Show content</ShowMoreLink>}
    {isOpened && <ErrorScroller displayMode="inset">
      <TextContainer>
        <TextInsideError>{content}</TextInsideError>
      </TextContainer>
    </ErrorScroller>}
  </Toast>
}

export const ErrorsComponent = () => {

  const errors = useSelector(errorsSelector)

  return <ErrorsStack>
    {errors.map(error => <ErrorComponent key={error.id} error={error} />)}
  </ErrorsStack>
}