import store, {AppDispatch} from "../../store";
import {getWorkspaceIdFromURL, setWorkspaceURL} from "core-lib";
import {startSlice} from "./reducer";
import {fetchWorkspaces} from "../Workspace/actions";
import {workspaceListSelector} from "../Workspace/selectors";

export const loadApp = () => {
    return async (dispatch: AppDispatch, getState: typeof store.getState) => {

        return dispatch(fetchWorkspaces()).then(() => {
            const workspaceId = getWorkspaceIdFromURL();
            if (workspaceId) {
                dispatch(startSlice.actions.setIsLoaded(true));
            } else {
                const workspaces = workspaceListSelector(getState());
                if (workspaces && workspaces.length && workspaces[0].id) {
                    setWorkspaceURL(workspaces[0].id);
                }
            }
        })
    }
}