/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum providerProviderType {
    ONEWAF = 'ONEWAF',
    XC = 'XC',
    NAP = 'NAP',
    AWAF = 'AWAF',
    NONE = 'NONE',
}
