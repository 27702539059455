import React, { useCallback, useMemo } from "react";
import { WorkspaceListComponent } from "../Workspace/WorkspaceListComponent";
import { Alert, Align, Icon, Layout, Navigation, Select } from "@emerald/react";
import { PoliciesListComponent } from "../PoliciesList/PoliciesListComponent";
import { ProvidersListComponent } from "../ProvidersList/ProvidersListComponent";
import { getLogoutURL, getWorkspaceIdFromURL } from "core-lib";
import { StartContainer } from "../StartContainer/StartContainer";

import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { DeploymentsListComponent } from "../DeploymentsList/DeploymentsListComponent";
import { ReportsListComponent } from "../ReportsList/ReportsListComponent";
import styled from "styled-components";
import f5Logo from "../../assets/F5 Logo.png";
import { Row } from "../../components/Row";
import { Col } from "../../components/Col";
import { Error401Component } from "../Errors/Error401Component";
import { InviteScreen } from "../Invite/InviteScreen";
import { ConversionsListComponent } from "../ConversionsList/ConversionsListComponent";
import { LOCATIONS, PATHS } from "../../navigation";
import { useSelector } from "react-redux";
import { userSelector } from "../auth/selectors";
import { Drawers } from "../Drawers/Drawers";
import { ConvertScreen } from "../Convert/ConvertScreen";
import { ReleaseNotesScreen } from "../ReleaseNotes/ReleaseNotesScreen";
import { AnnouncementText } from "../../core/components/AnnouncementHeader/AnnouncementText";

const SidebarDiv = styled.div`
  padding: 10px;
`
const WorkspaceSpaceLine = styled.div`
  padding: 0 0 10px 0;
`
const ContentDiv = styled.div`
  padding: 20px;
  height: 1px;
  min-height: 100%;
  box-sizing: border-box;
`
const HeaderDiv = styled.div`
  padding: 10px;
`
const StyledLayout = styled(Layout)`
  min-height: 100vh;
`
const HeaderIcons = styled(Col)`
  height: 40px;
  justify-content: center;
  padding-right: 10px;
`
const UserIcon = styled(Icon)`
  margin-right: 5px;
`
const PSHeader = styled.div`
  font-size: 12px;
  line-height: 18px;
  height: 18px;
`
const PathHeader = styled.div`
  font-size: 18px;
`

const HeaderElement = styled.div`
    margin-right: 18px;
    &:last-child {
        margin-right: 0px;
    }
`

const SupportMenuItem = styled.div`
    display: flex;
    align-items: center;
`;
const SupportMenuLabel = styled.div`
    margin-right: 0.5em;
`;

const workspaceId = getWorkspaceIdFromURL()

const HEADERS: { [key: string]: any } = {
    [LOCATIONS.policies]: {
        header: 'Overview'
    },
    [LOCATIONS.providers]: {
        header: 'Overview'
    },
    [LOCATIONS.conversions]: {
        header: 'Policy Lifecycle'
    },
    [LOCATIONS.deployments]: {
        header: 'Policy Lifecycle'
    },
    [LOCATIONS.reports]: {
        header: 'Reports'
    },
}



export function DashboardComponent() {
    const navigate = useNavigate()
    const location = useLocation()
    const user = useSelector(userSelector)

    const AppbarContent = <div />

    const locationKey = useMemo(() => Object.keys(PATHS).find((key: string) => {
        return PATHS[key] === location.pathname
    }) || LOCATIONS.providers, [location])

    const sections = useMemo(() => ([
        {
            isActive: locationKey === LOCATIONS.policies || locationKey === LOCATIONS.providers,
            title: "Overview",
            path: PATHS.providers,
            icon: "app.overview",
            items: [
                {
                    title: "Providers",
                    path: PATHS.providers,
                    isActive: locationKey === LOCATIONS.providers,
                },
                {
                    title: "Policies",
                    path: PATHS.policies,
                    isActive: locationKey === LOCATIONS.policies,
                },
            ],
        }, {
            isActive: locationKey === LOCATIONS.conversions || locationKey === LOCATIONS.deployments,
            title: "Policy Lifecycle",
            path: PATHS.conversions,
            icon: "app.integrations",
            items: [
                {
                    title: "Conversions",
                    path: PATHS.conversions,
                    isActive: locationKey === LOCATIONS.providers
                },
                {
                    title: "Deployments",
                    path: PATHS.deployments,
                    isActive: locationKey === LOCATIONS.deployments
                },
            ],
        }, {
            isActive: locationKey === LOCATIONS.reports,
            title: "Reports",
            path: PATHS.reports,
            icon: "app.clipboard"
        }]), [locationKey])

    const handleSectionLink = useCallback(
        (sectionPath: string, evt: any) => {
            evt.preventDefault();
            navigate(sectionPath)
        }, [navigate]);

    const handleItemLink = useCallback(
        ({ path, sectionPath }: { path: string; sectionPath: string }, evt: any) => {
            evt.preventDefault();
            navigate(path)
        }, [navigate]);

    const onUserMenuChange = useCallback(
        (value: any) => {
            switch (value) {
                case 'logout':
                    document.location.href = getLogoutURL()
                    break
            }
        }, []);

    const SidebarContent = <SidebarDiv>
        <Navigation
            onSectionLink={handleSectionLink}
            onItemLink={handleItemLink}
            sections={sections}
            activeItemPath={PATHS[locationKey]}
        />
    </SidebarDiv>

    const SidebarHeaderContent = <HeaderDiv>
        <a href='/'><img src={f5Logo} alt='F5 logo' /></a>
        <h3>Policy Supervisor</h3>
        <WorkspaceSpaceLine>
            <WorkspaceListComponent />
        </WorkspaceSpaceLine>
    </HeaderDiv>

    const userOptions = useMemo(() => ([
        {
            items: [{
                value: 'username',
                label: user?.email || '',
                disabled: true,
                icon: "app.user"
            }]
        },
        {
            items: [{ value: 'logout', label: 'Log Out' }]
        }
    ]), [user])

    const userButtonConfig = useMemo(() => ({
        displayMode: "ghostInk" as "link" | "ghostInk" | "filled" | "outlined" | "ghost" | "linkInk" | "linkSubtle" | undefined,
        size: "small" as "small" | "large" | "medium" | "mini" | "slim" | undefined,
        alignPopup: "right" as "center" | "left" | "right" | "leftMid" | "rightMid",
    }), [])

    const supportActionButtonConfig = useMemo(() => ({
        accent: false,
        displayMode: "ghost" as "link" | "filled" | "outlined" | "ghost" | "ghostInk" | "linkInk" | "linkSubtle" | undefined,
        size: "medium" as "small" | "medium" | "large" | "mini" | "slim" | undefined,
        text: "Support",
        textPosition: 'left' as "left" | "right" | undefined,
        alignPopup: "right" as "left" | "right" | "center" | "leftMid" | "rightMid"
    }), [])

    const supportGroupActions = useMemo(() => ([{
        items: [
            { value: "github", label: "GitHub" },
            { value: "release-notes", label: "Release Notes" }
        ]
    }]), [])

    const openExternalLink = useCallback(
        (value: string | string[] | undefined) => {
            switch (value) {
                case "github": {
                    window.location.href = "https://github.com/f5devcentral/ps-convert";
                    break;
                }
                case "release-notes": {
                    navigate(PATHS.releaseNotes)
                    break;
                }
            }
        }
        , [navigate]);

    const HeaderContent = <HeaderDiv>
        <Row>
            <Col flex={1}>
                <PSHeader>
                    Policy Supervisor
                </PSHeader>
                <PathHeader>{HEADERS[locationKey]?.header}</PathHeader>
            </Col>
            <HeaderIcons>
                <Align>
                    <HeaderElement>
                        <Select
                            icon="help.buoy"
                            value=''
                            options={supportGroupActions}
                            multiSelect={false}
                            allowSearch={false}
                            allowClearSelection={false}
                            displayMode="button"
                            buttonConfig={supportActionButtonConfig}
                            onChange={openExternalLink}
                        />
                    </HeaderElement>
                    <HeaderElement>
                        <UserIcon icon="app.user" />
                        <Select
                            value=''
                            options={userOptions}
                            multiSelect={false}
                            displayMode="button"
                            buttonConfig={userButtonConfig}
                            allowClearSelection={false}
                            allowSelectAll={false}
                            allowSearch={false}
                            onChange={onUserMenuChange}
                        />
                    </HeaderElement>
                </Align>
            </HeaderIcons>
        </Row>
        <AnnouncementText />
    </HeaderDiv>

    const LayoutContent = <StyledLayout
        edgeMode="inner"
        sidebar={SidebarContent}
        sidebarHeader={SidebarHeaderContent}
        header={HeaderContent}
        headerActions={AppbarContent}>
        <>{!workspaceId && <Alert>Select workspace!</Alert>}</>
        <>{workspaceId && <ContentDiv>
            <Routes>
                <Route path='/workspaces/:workspaceId' element={<ProvidersListComponent />} />
                <Route path='/workspaces/:workspaceId/providers' element={<ProvidersListComponent />} />
                <Route path='/workspaces/:workspaceId/policies' element={<PoliciesListComponent />} />
                <Route path='/workspaces/:workspaceId/conversions' element={<ConversionsListComponent />} />
                <Route path='/workspaces/:workspaceId/deployments' element={<DeploymentsListComponent />} />
                <Route path='/workspaces/:workspaceId/reports' element={<ReportsListComponent />} />
            </Routes>
            <Drawers />
        </ContentDiv>}</>
    </StyledLayout>

    const WithStartContainer = <StartContainer>
        {LayoutContent}
    </StartContainer>

    return <>
        <Routes>
            <Route path='/convert/*' element={<ConvertScreen />} />
            <Route path='/invites/:inviteId' element={<InviteScreen />} />
            <Route path='/release-notes' element={<ReleaseNotesScreen />} />
            <Route path='*' element={WithStartContainer} />
        </Routes>
        <Error401Component />
    </>
}
