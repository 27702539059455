import React, { useCallback, useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import { Button, Icon, Spinner } from "@emerald/react";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../../store";
import { conversionsListArraySelector } from "./selectors";
import { downloadConversion, downloadConversionLogs, fetchConversions } from "./actions";
import { commandJobStatus } from "core-lib";
import moment from "moment";
import { Row } from "../../components/Row";
import { Col } from "../../components/Col";
import { Tag } from "../../components/Tag";
import { POLICIES_PROVIDERS_INFO } from "../../constants";
import { conversionsListItem } from "./types";
import { Link } from "../../components/styled/Link";
import { DataGrid } from "../../components/DataGrid";
import { LOCATIONS, PATHS } from "../../navigation";
import { ElapsedTimeComponent } from "../../components/ElapsedTimeComponent";
import { CommonEmptyComponent } from "../../components/CommonEmptyComponent";


const SpinnerIcon = styled(Spinner)`
  vertical-align: baseline;
`
const Header = styled.div`
  font-weight: 700;
  font-size: 24px;
  line-height: 36px;
  margin-bottom: 10px;
`
const Content = styled.div`
  height: 100%
`
const IconIcon = styled(Icon)`
  vertical-align: baseline;
`
const StatusLine = styled.div`
  height: 20px;
`

const DateLine = styled.div`
  color: #9EA7B8;
`

const StatusIcon = styled.span`
  margin-right: 5px;
  vertical-align: middle;
`

const StatusText = styled.div`
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
`

const NameLink = styled(Link)`
line-height: 20px;
height: 20px;
`

type ConversionCommandStatus = commandJobStatus.JOB_SUCCESS | commandJobStatus.JOB_FAILED | commandJobStatus.JOB_PENDING | commandJobStatus.JOB_RUNNING;

const CONVERSION_STATUSES_TEXT = {
    [commandJobStatus.JOB_SUCCESS]: 'Success',
    [commandJobStatus.JOB_FAILED]: 'Failed',
    [commandJobStatus.JOB_PENDING]: 'In Progress',
    [commandJobStatus.JOB_RUNNING]: 'In Progress',
}

const ConversionStatusIconComponent = ({ status }: { status?: string }) => {
    return <StatusIcon>
        {(status === commandJobStatus.JOB_PENDING || status === commandJobStatus.JOB_RUNNING) && <SpinnerIcon size="small" />}
        {status === commandJobStatus.JOB_SUCCESS && <IconIcon size="small" icon="status.success" />}
        {status === commandJobStatus.JOB_FAILED && <IconIcon size="small" icon="status.error" />}
    </StatusIcon>
}

export const ConversionsListComponent = () => {

    const dataSource = useSelector(conversionsListArraySelector);
    const dispatch = useAppDispatch();
    const [isLoading, setIsLoading] = useState(true)

    const refresh = useCallback(() => {
        setIsLoading(true)
        dispatch(fetchConversions())
            .then(() => setIsLoading(false))
    }, [dispatch, setIsLoading])

    useEffect(() => {
        refresh()
    }, [refresh]);

    const onRefreshClick = useCallback(() => {
        refresh()
    }, [refresh])

    const columns: any = useMemo(() => [
        {
            accessor: 'notes',
            title: 'Report Name',
            filterable: true,
            sortable: true,
            width: 400,
            renderer: (row: conversionsListItem) => {
                return <NameLink key={row.groupId} to={{
                    pathname: PATHS[LOCATIONS.reports],
                    hash: `#${row.groupId}`
                }}>{row.notes}</NameLink>
            }
        },
        {
            accessor: 'policyName',
            title: 'Policy',
            filterable: true,
            sortable: true,
        },
        {
            accessor: 'dateTimestamp',
            title: 'Date',
            filterable: true,
            width: 200,
            sortable: true,
            renderer: (row: conversionsListItem) =>
                <Row fullWidth>
                    <Col>
                        <ConversionStatusIconComponent status={row.status} />
                    </Col>
                    <Col fullWidth flex={1}>
                        <StatusLine>
                            <span>{CONVERSION_STATUSES_TEXT[row.status as ConversionCommandStatus || '']}</span>
                        </StatusLine>
                        <DateLine>
                        {(row.status === commandJobStatus.JOB_RUNNING || row.status === commandJobStatus.JOB_PENDING) ?
                            <StatusText>ELAPSED TIME: <ElapsedTimeComponent time={moment(row.dateTimestamp).valueOf()} /></StatusText> :
                            <StatusText>{moment(row.dateTimestamp).format('h:mm A, D MMM YYYY')}</StatusText>}
                        </DateLine>
                    </Col>
                </Row>
        },
        {
            accessor: (row: conversionsListItem) => POLICIES_PROVIDERS_INFO[row.target || '']?.name,
            title: 'Target Provider Type',
            width: 200,
            sortable: true,
            filterable: true,
            renderer: (row: conversionsListItem) => {
                return <Tag key={row.target}
                    isActionable={false}>{row.target && POLICIES_PROVIDERS_INFO[row.target]?.name}</Tag>
            }
        }
    ], [])

    const rowActions = useCallback(() => [
        {
            items: [
                { value: "download", icon: "app.download", label: `Download Policy` },
                { value: "logs", icon: "app.view", label: `Download Logs` }
            ],
        },
    ], [])

    const handleRowAction = useCallback(({ item, action }) => {
        switch (action) {
            case 'download':
                item.id && dispatch(downloadConversion(item.id, `${item.policyName} ${item.target}.json`))
                break
            case 'logs':
                item.id && dispatch(downloadConversionLogs(item.id, `${item.policyName} ${item.target} logs.zip`))
                break
        }
    }, [dispatch, downloadConversionLogs, downloadConversion]);

    const toolbarCustomFilter = useCallback(() => <Button displayMode="ghostInk" onClick={onRefreshClick}>
        <Icon icon="app.refresh" />
        <span>Refresh</span>
    </Button>, [onRefreshClick])

    const initialConfig = useMemo(() => ({
        sortColumn: columns[2],
        sortDirection: 'descending' as "descending" | "ascending" | undefined,
    }), [columns])

    return <Content>

    {dataSource.length === 0 && !isLoading ?
        <CommonEmptyComponent title="No Conversions yet" description="Select a policy to convert" /> :
        <Col fullHeight>

            <Row fullWidth>
                <Col flex={1}><Header>Conversions</Header></Col>
            </Row>

            <Row fullWidth flex={1}>
                <DataGrid
                    isLoading={isLoading}
                    columns={columns}
                    items={dataSource}
                    itemIdAccessor='id'
                    showToolbar={true}
                    allowSelectRows={true}
                    allowSelectAll={true}
                    toolbarCustomFilter={toolbarCustomFilter}
                    rowActions={rowActions}
                    onRowAction={handleRowAction}
                    configInitial={initialConfig}
                />
            </Row>
        </Col>}

    </Content>
}