import {AppDispatch} from "../../store";
import {
    DeploymentsService,
    CommandsService,
    downloadFile
} from "core-lib";
import {errorActionRequest} from "../Errors/actions";
import {deploymentsListSlice} from "./reducer";
import {createErrorObject} from "../Errors/util";
import { PAGINATION_FIRST_PAGE, PAGINATION_HUGE_SIZE } from "../../constants";

export const fetchDeployments = () => {
    return async (dispatch: AppDispatch) => {
        return DeploymentsService.deploymentsListDeployments(
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            PAGINATION_FIRST_PAGE,
            PAGINATION_HUGE_SIZE)
            .then(response => {
                dispatch(deploymentsListSlice.actions.loadDeployments(response));
            })
            .catch(error => {
                errorActionRequest(error);
            })
    }
}

export const downloadDeploymentLogs = (id: string, filename: string) => {
    return async (_dispatch: AppDispatch) => {
        return CommandsService.commandsGetDeployLog(id)
            .then(response => {
                if(response) {
                    downloadFile(JSON.stringify(response), filename)
                } else {
                    throw createErrorObject('Could not download the file', 'The server returned empty result')
                }
            })
            .catch(error => {
                errorActionRequest(error);
            })
    }
}
