import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../../store";

export const policyConversionIsOpenSelector = (state: RootState) => state.policyConversion.policyForConversion !== null
export const policyConversionPolicySelector = (state: RootState) => state.policyConversion.policyForConversion
export const commitMessageSelector = (state: RootState) => state.policyConversion.commitMessage
export const conversionCommandsIdsSelector = (state: RootState) => state.policyConversion.conversionsCommandsIds
export const conversionResultsSelector = (state: RootState) => state.policyConversion.conversionResult
export const policyConversionProviderTypeSelectedSelector = (state: RootState) => state.policyConversion.providerTypeSelected
export const reportGroupIdSelector = (state: RootState) => state.policyConversion.reportGroupId

export const conversionResultSelector = createSelector([conversionResultsSelector, policyConversionProviderTypeSelectedSelector],
    (results, type) => {
        return type ? results[type] || null : null
    })
