import React, {useCallback, useEffect, useState} from "react";
import styled from "styled-components";
import {Align, Button, Card, ContentStatus} from "@emerald/react";
import f5Logo from "../../assets/F5 Logo.png";
import {inviteResponse, setWorkspaceURL} from "core-lib";
import {useAppDispatch} from "../../store";
import {acceptInvite, fetchInvite} from "./actions";
import {useLocation, useNavigate} from "react-router-dom";
import {errorActionRequest} from "../Errors/actions";
import {INVITE_TEXTS} from "./constants";

const Content = styled.div`
  padding-top: 200px;
  text-align: center;
`
const CardStyled = styled(Card)`
  text-align: center;
  padding: 65px 80px;
  justify-content: center;
  width: 500px;
`
const AlignCenter = styled(Align)`
  justify-content: center;
`
const AcceptButton = styled(Button)`
  width: 100%;
  margin-top: 20px;
  
  span {
    justify-content: center;
  }
`

export const InviteScreen = () => {

    const dispatch = useAppDispatch()
    const location = useLocation()
    const navigate = useNavigate()

    const [isLoading, setIsLoading] = useState(true)
    const [invite, setInvite] = useState<inviteResponse | null>(null)

    useEffect(() => {
        const paths = location.pathname.split('/')
        const inViteId = paths[paths.length - 1]
        setIsLoading(true)
        dispatch(fetchInvite(inViteId)).then(response => {
            setInvite(response)
        }).catch(error => {
            if(error.response.status === 404) {
                setInvite(null)
            } else {
                errorActionRequest(error);
            }
        }).finally(() => {
            setIsLoading(false)
        })
    }, [location, dispatch, setIsLoading, setInvite])

    const onAcceptClick = useCallback(() => {
        invite && dispatch(acceptInvite(invite.id || '')).then(_response => {
            invite.workspaceId && setWorkspaceURL(invite.workspaceId)
        })
    }, [invite, dispatch])

    const onBackClick = useCallback(() => {
        navigate('/')
    }, [navigate])

    return <Content>
        <AlignCenter>
            <CardStyled>
                <div>
                    <img src={f5Logo} alt='F5 logo'/>
                </div>
                <h3>{invite ? INVITE_TEXTS.inviteTitle : INVITE_TEXTS.inviteNotFoundTitle}</h3>
                <div>{invite ? INVITE_TEXTS.invite : INVITE_TEXTS.inviteNotFound}</div>
                {invite && <AcceptButton accent={true} onClick={onAcceptClick}>Accept</AcceptButton>}
                <Button displayMode="linkInk" onClick={onBackClick}>Back to my workspaces</Button>
            </CardStyled>
        </AlignCenter>
        <ContentStatus
            isVisible={isLoading}
            spinnerSize={"medium"}
            defaultMessage={<span>Loading</span>}
            fillOuterContainer={false}
        />
    </Content>
}