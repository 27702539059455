import { AppDispatch, RootState } from "../../store";
import {
    commandJobStatus, CommandsService, providerProviderType
} from "core-lib";
import { errorActionRequest } from "../Errors/actions";
import { providerIngestionSlice } from "./reducer";
import { mapAwafResult, mapNapResult } from "./helpers"
import { providerIngestionCommitMessageSelector, providerIngestionPoliciesSelectedListSelector, providerIngestionProfilesBot, providerIngestionProviderSelector, providerIngestionSelectedPolicyProfilesSelector } from "./selectors";
import { CommandStatusCheckManager } from "../../core/commands/CommandStatusCheckManager";

export const fetchIngestionPolicies = () => {
    return async (dispatch: AppDispatch, getState: () => RootState) => {
        const provider = providerIngestionProviderSelector(getState())

        const setEmptyResult = () => dispatch(providerIngestionSlice.actions.loadPolicies([]));

        CommandsService.commandsPostList({
            providerId: provider?.id
        }).then(response => {
            const commandId = response.id;

            if (commandId) {
                dispatch(providerIngestionSlice.actions.addCommandId(commandId))
                CommandStatusCheckManager.getCommandRunner(commandId)
                    .setOnResult((commandId, commandStatus) => {
                        if (commandStatus === commandJobStatus.JOB_SUCCESS) {
                            switch (provider?.type) {
                                case providerProviderType.AWAF:
                                    CommandsService.commandsGetListAwaf(commandId).then(result => {
                                        dispatch(providerIngestionSlice.actions.loadProfilesBot(result.bot?.items?.map((item: any) => ({...item, name: item.fullPath})) || []))
                                        dispatch(providerIngestionSlice.actions.loadProfilesDos(result.dos?.items || []))
                                        dispatch(providerIngestionSlice.actions.loadPolicies(mapAwafResult(result)))
                                    }).catch(error => {
                                        setEmptyResult();
                                        errorActionRequest(error)
                                    })
                                    break;
                                case providerProviderType.NAP:
                                    CommandsService.commandsGetListNap(commandId).then(result => {
                                        dispatch(providerIngestionSlice.actions.loadProfilesDos(result.dos?.dosFileNames?.map((item: string) => ({name: item})) || []))
                                        dispatch(providerIngestionSlice.actions.loadPolicies(mapNapResult(result)))
                                    }).catch(error => {
                                        setEmptyResult();
                                        errorActionRequest(error)
                                    })
                                    break;
                            }
                        }
                    })
                    .setOnError((commandId, error) => {
                        setEmptyResult();
                        errorActionRequest(error)
                    })
            }
        }).catch(error => {
            setEmptyResult();
            errorActionRequest(error);
        })
    }
}

export const ingestPolicies = () => {
    return async (dispatch: AppDispatch, getState: () => RootState) => {
        const provider = providerIngestionProviderSelector(getState());
        const policies = providerIngestionPoliciesSelectedListSelector(getState());
        const commitMessage = providerIngestionCommitMessageSelector(getState());
        const selectedProfiles = providerIngestionSelectedPolicyProfilesSelector(getState());

        const handleError = (policyId: string, error: any) => {
            errorActionRequest(error)
            dispatch(providerIngestionSlice.actions.setPolicyIngestStatus({
                policyId,
                completed: true,
                error: true
            }))
        }

        const handlePostPoliciesError = (error: any) => {
            errorActionRequest(error)
            policies.forEach(({id: policyId}) => {
                dispatch(providerIngestionSlice.actions.setPolicyIngestStatus({
                    policyId,
                    completed: true,
                    error: true
                }))
            })
        }

        policies.forEach(({ id: policyId, name }) => {
            dispatch(providerIngestionSlice.actions.setPolicyIngestStatus({
                policyId,
                completed: false,
                error: false
            }))
        });

        CommandsService.commandsPostIngest({
            providerId: provider?.id,
            elementIds: policies.map((item) => {
                const policyId = item.id
                const body = { policyId }
                const {dosProfile, botProfile} = selectedProfiles[policyId] || {}
                if(dosProfile) {
                    Object.assign(body, {dosName: dosProfile})
                }
                if(botProfile) {
                    Object.assign(body, {botName: botProfile})
                }
                return body
            }),
            notes: commitMessage
        }).then(response => {
            dispatch(providerIngestionSlice.actions.setReportGroupId(response.groupId || ''))
            const commands = response.commands || [];

            commands.forEach(({ commandId }, index) => {
                if (commandId) {
                    const policy = policies[index]
                    dispatch(providerIngestionSlice.actions.addCommandId(commandId))
                    CommandStatusCheckManager.getCommandRunner(commandId)
                        .setOnResult((commandId, commandStatus) => {
                            if (commandStatus === commandJobStatus.JOB_SUCCESS) {
                                CommandsService.commandsPostImportFromIngest({
                                    name: policy.name,
                                    notes: commitMessage,
                                    ingestCommandId: commandId
                                }).then(postImportResponse => {
                                    const postImportCommandId = postImportResponse.conversionCommandId;
                                    if (postImportCommandId) {
                                        dispatch(providerIngestionSlice.actions.addCommandId(postImportCommandId))
                                        CommandStatusCheckManager.getCommandRunner(postImportCommandId)
                                            .setOnResult((commandId, commandStatus) => {
                                                if (commandStatus === commandJobStatus.JOB_SUCCESS) {
                                                    dispatch(providerIngestionSlice.actions.setPolicyIngestStatus({
                                                        policyId: policy.id,
                                                        completed: true,
                                                        error: false
                                                    }))
                                                }
                                            })
                                            .setOnError((commandId, error) => {
                                                handleError(policy.id, error)
                                            })
                                    }
                                }).catch(error => {
                                    handleError(policy.id, error)
                                })
                            }
                        })
                        .setOnError((commandId, error) => {
                            handleError(policy.id, error)
                        })
                    }
                })
        }).catch(error => {
            handlePostPoliciesError(error);
        })
    }
}