import React from "react";
import { Toast } from "@emerald/react";
import styled from "styled-components";
import { LOCATIONS, PATHS } from "../../navigation";
import { Link } from "../../components/styled/Link";

const Actions = styled.div`
    display: flex;
    gap: 20px;
    a {
        color: #4F73FF;
        cursor: pointer;
        border-bottom: 1px dashed #4F73FF;
    }
`

const TextInsideError = styled.p`
    white-space: pre-wrap;
    word-wrap: anywhere;
`

const NotificationWrapper = styled.div`
    z-index: 999999;
`

export const WelcomeNotification = () => { 
  return <NotificationWrapper>
    <Toast
        title="Conversion Utility"
        status="info">
        <TextInsideError>This is a standalone conversion-only feature. No login is required.</TextInsideError>
        <Actions>
            <Link target='_blank' to="#">
                Learn more
            </Link>
            <Link target='_blank' to={`${PATHS[LOCATIONS.convert]}`}>
                Conversion Utility
            </Link>
        </Actions>
    </Toast>
  </NotificationWrapper>
}

