import {
    BodyForm, Footer,
    Header,
    HeaderHeader,
    Legend
} from "../../components/styled/Drawer";
import {Divider} from "../../components/Divider";
import {Row} from "../../components/Row";
import React from "react";
import styled from "styled-components";
import {commandConversionReportResponse} from "core-lib";
import {POLICIES_PROVIDERS_INFO} from "../../constants";
import {ConversionReportNewComponent} from "../../core/components/Conversion/ConversionReportNewComponent";
import {Col} from "../../components/Col";
import {Align, Button} from "@emerald/react";
import {Link} from "react-router-dom";
import {LOCATIONS, PATHS} from "../../navigation";
import {ScrollableContent} from "../../components/PageScrollable";
import { useGetConversionReportErrorData } from "../../core/components/Conversion/util/useGetConversionReportErrorData";
import { ConversionReportErrorComponent } from "../../core/components/Conversion/ConversionReportErrorComponent";

const GrayText = styled.span`
  color: #6C778C;
`
const BoldText = styled.span`
  font-weight: bold;
`
const ConversionSavedText = styled.span`
  font-size: 14px;
  line-height: 20px;
`

type Props = {
    reportId: string,
    reportName: string,
    policyName: string,
    conversionResult: commandConversionReportResponse,
    onGoToOverviewClick: () => void,
    onEditClick: () => void
}

export const ImportPolicyResultComponent = (props: Props) => {

    const { warnings, errors} = useGetConversionReportErrorData(!props.conversionResult ? [] : [props.conversionResult])

    return <BodyForm>
        <Header>
            <HeaderHeader><GrayText>Policy: </GrayText><BoldText>{props.policyName}</BoldText></HeaderHeader>
        </Header>
        <ScrollableContent>
            <Divider mb={20}></Divider>
            <Row mb={20}>
                <HeaderHeader><BoldText>Conversion Completed</BoldText></HeaderHeader>
            </Row>
            <Legend>Source Provider Type:&nbsp;
                {props.conversionResult.ConversionSummary?.Source ? POLICIES_PROVIDERS_INFO[props.conversionResult.ConversionSummary?.Source].name : ''}
            </Legend>
            <ConversionReportErrorComponent ml={13} mr={26} mb={44} errors={errors} type="ERROR" />
            <ConversionReportNewComponent warnings={warnings[0]} report={props.conversionResult}/>
            <Row mt={20} mb={20} centerHorizontal>
                <ConversionSavedText>
                    Conversion saved:&nbsp;
                    <Link
                    to={{
                        pathname: PATHS[LOCATIONS.reports],
                        hash: `#${props.reportId}`
                    }}>{props.reportName}</Link>
                </ConversionSavedText>
            </Row>
        </ScrollableContent>
        <Footer>
            <Divider/>
            <Col flex={1} rightDirection>
                <Row spacing={20} mt={10}>
                    <Align>
                        <Button accent={true} displayMode="outlined" onClick={props.onGoToOverviewClick}>Go
                            to Overview</Button>
                    </Align>
                    <Align>
                        <Button accent={true} onClick={props.onEditClick}>Edit Policy</Button>
                    </Align>
                </Row>
            </Col>
        </Footer>
    </BodyForm>
}