import {AnyAction, configureStore, ThunkDispatch} from '@reduxjs/toolkit'
import { useDispatch } from 'react-redux';
import {workspacesSlice} from "./features/Workspace/reducer";
import {providersListSlice} from "./features/ProvidersList/reducer";
import {policiesListSlice} from "./features/PoliciesList/reducer";
import {startSlice} from "./features/StartContainer/reducer";
import {errorSlice} from "./features/Errors/reducer";
import {authSlice} from "./features/auth/reducer";
import {addProviderSlice} from "./features/ProviderEdit/reducer";
import {policyDeploySlice} from "./features/PolicyDeploy/reducer";
import {conversionsListSlice} from "./features/ConversionsList/reducer";
import {deploymentsListSlice} from "./features/DeploymentsList/reducer";
import {reportsListSlice} from "./features/ReportsList/reducer";
import { providerIngestionSlice } from './features/ProviderIngestion/reducer';
import { providerDetailsSlice } from './features/ProviderDetails/reducer';
import { reportDetailsSlice } from './features/ReportDetails/reducer';
import { policyEditSlice } from './features/PolicyEdit/reducer';
import { policyDetailsSlice } from './features/PolicyDetails/reducer';
import { policyConversionSlice } from './features/PolicyConversion/reducer';
import { policyImportSlice } from './features/PolicyImport/reducer';
import { conversionSlice } from './features/Convert/reducer';

const store = configureStore({
    reducer: {
        start: startSlice.reducer,
        error: errorSlice.reducer,
        auth: authSlice.reducer,
        manualConversion: conversionSlice.reducer,
        workspace: workspacesSlice.reducer,
        providersList: providersListSlice.reducer,
        providerDetails: providerDetailsSlice.reducer,
        addProvider: addProviderSlice.reducer,
        policiesList: policiesListSlice.reducer,
        policyDetails: policyDetailsSlice.reducer,
        policyDeploy: policyDeploySlice.reducer,
        policyConversion: policyConversionSlice.reducer,
        policyEdit: policyEditSlice.reducer,
        policyImport: policyImportSlice.reducer,
        providerIngestion: providerIngestionSlice.reducer,
        conversionsList: conversionsListSlice.reducer,
        deploymentsList: deploymentsListSlice.reducer,
        reportsList: reportsListSlice.reducer,
        reportDetails: reportDetailsSlice.reducer
    },
})

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = ThunkDispatch<RootState, any, AnyAction>;
export const useAppDispatch = () => useDispatch<AppDispatch>()

export default store
