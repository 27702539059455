import {commandJobStatus, CommandsService} from "core-lib";
import { errorActionRequest } from "../../features/Errors/actions";
import { createErrorObject } from "../../features/Errors/util";

export class CommandStatusCheck {

    static stopAll = (checkers: CommandStatusCheck[]) => {
        checkers.forEach(checker => checker.stop())
    }
    static getChecker = (commandId: string, checkDelay?: number, maxErrorsCount?: number) => {
        return new CommandStatusCheck(commandId, checkDelay, maxErrorsCount)
    }

    private commandId: string
    private status: commandJobStatus = commandJobStatus.JOB_PENDING
    private stopped = false
    private isError = false
    private checkDelay = 1000
    private maxErrorsCount = 3
    private currentErrorsCount = 0

    private onResultListener: undefined | ((commandId: string, status: commandJobStatus) => void)
    private onErrorListener: undefined | ((commandId: string, error: unknown) => void)

    constructor(commandId: string, checkDelay?: number, maxErrorsCount?: number) {
        this.commandId = commandId;
        if(typeof checkDelay !== 'undefined') this.checkDelay = checkDelay;
        if(typeof maxErrorsCount !== 'undefined') this.maxErrorsCount = maxErrorsCount;
    }

    readonly setOnResult = (callback: (commandId: string, status: commandJobStatus) => void) => {
        this.onResultListener = callback
        return this
    }

    readonly setOnError = (callback: (commandId: string, error?: unknown) => void) => {
        this.onErrorListener = callback
        return this
    }

    protected onResult = (commandId: string, status: commandJobStatus) => {
        this.onResultListener && this.onResultListener(commandId, status)
    }

    protected onError = (commandId: string, error: unknown) => {
        this.onErrorListener && this.onErrorListener(commandId, error)
    }

    readonly stop = () => {
        this.stopped = true
    }

    private runAsync = async (onFinally?: () => void) => {

        while (
            !this.stopped &&
            !this.isError &&
            this.status !== commandJobStatus.JOB_SUCCESS &&
            this.status !== commandJobStatus.JOB_FAILED &&
            this.status !== commandJobStatus.JOB_CANCELED) {

            try {

                const result = await CommandsService.commandsGetStatus(this.commandId)

                this.currentErrorsCount = 0

                this.status = result.status || commandJobStatus.JOB_PENDING

                if (this.status === commandJobStatus.JOB_SUCCESS || this.status === commandJobStatus.JOB_FAILED || this.status === commandJobStatus.JOB_CANCELED) {
                    onFinally && onFinally()
                    if(this.status === commandJobStatus.JOB_FAILED && result.error) {
                        errorActionRequest(createErrorObject('Job failed', '', result.error))
                    }
                    this.onResult(this.commandId, this.status)
                }

                await new Promise<void>((resolve) => {
                    setTimeout(() => {
                        resolve()
                    }, this.checkDelay)
                })

            } catch(e) {

                this.currentErrorsCount++

                if(this.currentErrorsCount === this.maxErrorsCount) {
                    this.isError = true
                    onFinally && onFinally()
                    this.onError(this.commandId, e)
                }

            }

        }

    }

    public run = (onFinally?: () => void) => {
        this.runAsync(onFinally).catch(e => {
            console.error('CommandStatusCheck run Error', e)
        })
        return this;
    }

}