import { RootState } from "../../store";
import { createSelector } from "@reduxjs/toolkit";
import { PROVIDERS_INFO } from "../../constants";
import {
    commandFindEndpointsAWAFResponse,
    commandFindEndpointsXCResponse,
    providerResponse,
    providerProviderType,
    commandFindEndpointsNAPResponse
} from "core-lib";
import { getFullEndpointId } from "./helpers";

export const policyDeployIsOpenSelector = (state: RootState) => state.policyDeploy.policyForDeploy !== null
export const policyDeployProvidersSelector = (state: RootState) => state.policyDeploy.providers
export const policyDeployIsProvidersLoadingSelector = (state: RootState) => state.policyDeploy.providers === null
export const policyDeployProvidersForDeploySelector = (state: RootState) => state.policyDeploy.providersForDeploy
export const policyDeployPolicySelector = (state: RootState) => state.policyDeploy.policyForDeploy
export const policyDeployEndpointsSelector = (state: RootState) => state.policyDeploy.endpoints
export const commitMessageSelector = (state: RootState) => state.policyDeploy.commitMessage
export const conversionCommandsIdsSelector = (state: RootState) => state.policyDeploy.conversionsCommandsIds
export const conversionResultsSelector = (state: RootState) => state.policyDeploy.conversionResult
export const findEndpointsCommandsIdsSelector = (state: RootState) => state.policyDeploy.findEndpointsCommandsIds
export const findEndpointsResultsSelector = (state: RootState) => state.policyDeploy.findEndpointsResult
export const deployCommandsIdsSelector = (state: RootState) => state.policyDeploy.deploymentsCommandsIds
export const deployResultsSelector = (state: RootState) => state.policyDeploy.deploymentResult
export const policyDeployProviderTypesSelectedSelector = (state: RootState) => state.policyDeploy.providerTypesSelected
export const reportGroupIdSelector = (state: RootState) => state.policyDeploy.reportGroupId

export const providersOptionsSelector = createSelector(
    [policyDeployProvidersSelector],
    (providers) => {
        return Object.values(PROVIDERS_INFO).reduce((result, current) => {
            const providersByType = providers?.filter(provider => provider.type === current.key) || []
            return providersByType.length ? [
                ...result,
                {
                    group: current.name,
                    items: providersByType.map(provider => ({ value: provider.id || '', label: provider.name || '' }))
                }] : result
        }, [] as Array<{ group: string, items: Array<{ value: string, label: string }> }>)
    }
)

export const policyDeployProvidersForDeploySelectedTypesSelector = createSelector([
    policyDeployProvidersForDeploySelector,
    policyDeployProviderTypesSelectedSelector
], (providersForDeploy, typesSelected) => {
    return providersForDeploy.filter(provider => provider.type && typesSelected.indexOf(provider.type) > -1)
})

export const policyDeployCProvidersByTypeSelector = createSelector([
    policyDeployProvidersForDeploySelector,
    (state: RootState, type: providerProviderType) => type
], (providersForDeploy, providerType) => {
    return providersForDeploy.filter(provider => provider.type === providerType)
})
export const policyDeployProvidersTypeSelector = createSelector([
    policyDeployProvidersForDeploySelector
], (providersForDeploy): Array<providerProviderType> => {
    const arr = Array.from(new Set(providersForDeploy.map(provider => provider.type)))
    return arr.filter((type): type is providerProviderType => !!type)
})

const getEndpointOptionsForProviderAWAF = (
    provider: providerResponse,
    findEndpointsResults: { [providerId: string]: commandFindEndpointsAWAFResponse | commandFindEndpointsXCResponse | commandFindEndpointsNAPResponse }
) => {
    if (provider.id && provider.type === providerProviderType.AWAF) {
        const endpointsResult = findEndpointsResults[provider.id] as commandFindEndpointsAWAFResponse
        if (endpointsResult && endpointsResult.partitions) {
            return endpointsResult.partitions.reduce((options, current) => {
                if (current.name && current.virtualServer) {
                    return [
                        ...options,
                        {
                            group: current.name,
                            items: current.virtualServer.map(endpointName => ({
                                value: getFullEndpointId(current.name, endpointName),
                                label: endpointName
                            }))
                        }
                    ]
                }
                return options
            }, [] as { group: string, items: { value: string, label: string }[] }[])
        }
    }
    return undefined
}

const getEndpointOptionsForProviderXC = (
    provider: providerResponse,
    findEndpointsResults: { [providerId: string]: commandFindEndpointsAWAFResponse | commandFindEndpointsXCResponse | commandFindEndpointsNAPResponse }
) => {
    if (provider.id && provider.type === providerProviderType.XC) {
        const endpointsResult = findEndpointsResults[provider.id] as commandFindEndpointsXCResponse
        if (endpointsResult && endpointsResult.namespaces) {
            return endpointsResult.namespaces.reduce((options, current) => {
                if (current.name && current.loadBalancers) {
                    return [
                        ...options,
                        {
                            group: current.name,
                            items: current.loadBalancers.map(endpointName => ({
                                value: getFullEndpointId(current.name, endpointName),
                                label: endpointName
                            }))
                        }
                    ]
                }
                return options
            }, [] as { group: string, items: { value: string, label: string }[] }[])
        }
    }
    return undefined
}

const getEndpointOptionsForProviderNAP = (
    provider: providerResponse,
    findEndpointsResults: { [providerId: string]: commandFindEndpointsAWAFResponse | commandFindEndpointsXCResponse | commandFindEndpointsNAPResponse }
) => {
    if (provider.id && provider.type === providerProviderType.NAP) {
        const endpointsResult = findEndpointsResults[provider.id] as commandFindEndpointsNAPResponse
        if (endpointsResult && endpointsResult.servers) {
            return {
                items: endpointsResult.servers.map((option) => ({
                    value: option,
                    label: option
                }))
            }
        }
    }
    return undefined
}

export const policyDeployEndpointsOptionsAWAFSelector = createSelector([
    findEndpointsResultsSelector,
    (state: RootState) => policyDeployCProvidersByTypeSelector(state, providerProviderType.AWAF)
], (findEndpointsResults, providers) => {
    return providers.reduce((optionsMapByProviders, provider) => {
        if (provider.id) {
            return {
                ...optionsMapByProviders,
                [provider.id]: getEndpointOptionsForProviderAWAF(provider, findEndpointsResults)
            }
        }
        return optionsMapByProviders
    }, {} as { [providerId: string]: ({ group: string, items: { value: string, label: string }[] }[] | undefined) })
})

export const policyDeployEndpointsOptionsXCSelector = createSelector([
    findEndpointsResultsSelector,
    (state: RootState) => policyDeployCProvidersByTypeSelector(state, providerProviderType.XC)
], (findEndpointsResults, providers) => {
    return providers.reduce((optionsMapByProviders, provider) => {
        if (provider.id) {
            return {
                ...optionsMapByProviders,
                [provider.id]: getEndpointOptionsForProviderXC(provider, findEndpointsResults)
            }
        }
        return optionsMapByProviders
    }, {} as { [providerId: string]: ({ group: string, items: { value: string, label: string }[] }[] | undefined) })
})

export const policyDeployEndpointsOptionsNAPSelector = createSelector([
    findEndpointsResultsSelector,
    (state: RootState) => policyDeployCProvidersByTypeSelector(state, providerProviderType.NAP)
], (findEndpointsResults, providers) => {
    return providers.reduce((optionsMapByProviders, provider) => {
        if (provider.id) {
            return {
                ...optionsMapByProviders,
                [provider.id]: getEndpointOptionsForProviderNAP(provider, findEndpointsResults)
            }
        }
        return optionsMapByProviders
    }, {} as { [providerId: string]: ({ items: { value: string, label: string }[] } | undefined) })
})