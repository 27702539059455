import React, {useRef, useCallback, useMemo} from "react";
import {FormField, Button, Select} from "@emerald/react";
import styled from "styled-components";
import {FileInput, FileInputHandle} from "../../components/FileInput";
import { ConvertFormData, ManualConversionProviders } from "./utils";

const SubmitWrapper = styled.div`
    display: flex;
    justify-content: center;
    margin-top: 50px;
`

const SubmitButton = styled(Button)`
    width: 175px;
    span {
        justify-content: center;
    }
`

const StyledFormField = styled(FormField)`
    margin-top: 40px;
`

const DescriptionText = styled.div`
    text-align: center;
    font-size: 12px;
    line-height: 18px;
    color: #6C778C;
    margin-top: 16px;
`

const sourceOptions = [{
    items: Object.keys(ManualConversionProviders).filter((key) => ["AWAF", "NAP"].includes(key)).map((key) => ({ label: ManualConversionProviders[key].name, value: key }))
}]

const allTargetItems = Object.keys(ManualConversionProviders).map((key) => ({ label: ManualConversionProviders[key].name, value: key }))

type Props = {
    values: ConvertFormData,
    setValues: Function,
    onNext: Function
}

export function ConvertComponent(props: Props) {
    const {onNext, values, setValues} = props
    const fileInputRef = useRef<FileInputHandle>(null);

    const isButtonDisabled = useMemo(() => {
        const {source, target, name} = values
        return source === "" || target === "" || name === ""
    }, [values])

    const handleSubmit = () => {
        onNext()
    }

    const targetItems = useMemo(() => {
        return allTargetItems.filter(item => item.value !== values.source)
    }, [values.source])

    const setSource = useCallback((value: any) => {
        setValues((values: ConvertFormData) => ({
            ...values,
            source: value,
        }))
    }, [setValues])

    const setTarget = useCallback((value: any) => {
        setValues((values: ConvertFormData) => ({
            ...values,
            target: value,
        }))
    }, [setValues])

    const setFile = useCallback((file: File) => {
        setValues((values: ConvertFormData) => ({...values, fileData: '', name: ''}))
        const reader = new FileReader()
        reader.onload = async (e) => {
            const text = (e.target?.result)
            setValues((values: ConvertFormData) => ({
                ...values,
                fileData: text?.toString() || '',
                name: file?.name || ''
            }))
        }
        reader.readAsText(file as Blob)
    }, [setValues])

    return <div>
        <StyledFormField required={true} label="Source type">
            <Select options={sourceOptions}
                onChange={setSource}
                allowClearSelection={false}
                allowSelectAll={false}
                allowSearch={false}
            />
        </StyledFormField>
        <StyledFormField required={true} label="Import (JSON or XML format)">
            <FileInput ref={fileInputRef} onChange={setFile} accept=".json,.xml" />
        </StyledFormField>
        <StyledFormField required={true} label="Target WAF">
            <Select options={[{items: targetItems}]}
                onChange={setTarget}
                allowClearSelection={false}
                allowSelectAll={false}
                allowSearch={false}
            />
        </StyledFormField>
        <SubmitWrapper>
            <SubmitButton size="large" onClick={handleSubmit} disabled={isButtonDisabled}>Convert</SubmitButton>
        </SubmitWrapper>
        <DescriptionText>
            Files will include policy file(s) and the conversion reports
        </DescriptionText>
    </div>
}
