import React from "react";
import { createPortal } from 'react-dom';
import { Col } from "./Col";
import { Button, Dialog as EmeraldDialog, DialogTypes, ButtonTypes, Align } from "@emerald/react";
import { Row } from "./Row";
import { DeepPartial } from "@reduxjs/toolkit";

type Props = {
    open?: boolean;
    children: React.ReactNode;
    title?: string;
    actions?: ButtonTypes.PropsConsumable[]
    themeOverride?: DeepPartial<DialogTypes.Theme>
}

export const Dialog = ({ open, children, actions = [], ...dialogProps }: Props) => {
    if(!open) {
        return null;
    }

    const dialog = <EmeraldDialog
        {...dialogProps}
        open
        actionsFooter={<Col flex={1} rightDirection>
            <Row spacing={20} style={{ marginRight: 20 }}>
                {actions.map(({children: buttonChildren, ...buttonProps}, index) =>
                <Align key={index}>
                    <Button {...buttonProps}>{buttonChildren}</Button>
                </Align>)}
            </Row>
        </Col>}
    >
        {children}
    </EmeraldDialog>

    return createPortal(
        dialog,
        document.body
      );
}