import React, {useCallback, useState} from "react";
import {ImportPolicyForm} from "./ImportPolicyForm";
import {
    commandConversionReportResponse,
    commandImportFromFileRequest
} from "core-lib";
import {ConversionProcessComponent} from "../../core/components/Conversion/ConversionProcessComponent";
import {ImportPolicyResultComponent} from "./ImportPolicyResultComponent";
import {useAppDispatch} from "../../store";
import {importPolicy} from "../PoliciesList/actions";
import {Drawer100} from "../../components/styled/Drawer";
import {ImportPolicyErrorComponent} from "./ImportPolicyErrorComponent";
import { policiesListSlice } from "../PoliciesList/reducer";
import { policyEditSlice } from "../PolicyEdit/reducer";
import { useSelector } from "react-redux";
import { policyImportOpenSelector } from "./selectors";
import { policyImportSlice } from "./reducer";

export const ImportPolicyComponent = () => {

    const open = useSelector(policyImportOpenSelector);
    const dispatch = useAppDispatch()

    const [formValues, setFormValues] = useState<commandImportFromFileRequest | null>(null)
    const [conversionResult, setConversionResult] = useState<{policyId: string; report: commandConversionReportResponse, reportId: string, reportName: string} | null>(null)
    const [error, setError] = useState<any | null>(null)
    const isConversionReport = !!conversionResult

    const resetForm = useCallback(() => {
        setFormValues(null)
        setConversionResult(null)
        setError(null)
    }, [setFormValues, setConversionResult, setError])

    const onClose = useCallback(() => {
        dispatch(policyImportSlice.actions.close())
    }, [dispatch])

    const onFormSuccess = useCallback((values: commandImportFromFileRequest) => {
        setFormValues(values)
        dispatch(importPolicy(values)).then(result => {
            dispatch(policiesListSlice.actions.resetPolicies())
            setConversionResult(result)
        }).catch(errorResponse => {
            setError(errorResponse.response || errorResponse)
        })
    }, [dispatch, setFormValues, setConversionResult, setError])

    const onPolicyEditClick = useCallback(() => {
        conversionResult?.policyId && dispatch(policyEditSlice.actions.setPolicyId(conversionResult.policyId))
    }, [conversionResult, dispatch])

    return <Drawer100 open={open} {...(isConversionReport ? { width: '950px' } : null)} hideActionsRow onAfterClose={resetForm}>
        {!error && !formValues && <ImportPolicyForm onSuccess={onFormSuccess} onCloseClick={onClose} />}
        {!error && formValues && !conversionResult &&
            <ConversionProcessComponent policy={formValues} />}
        {!error && formValues && conversionResult &&
            <ImportPolicyResultComponent
                policyName={formValues.name || ''}
                conversionResult={conversionResult.report}
                reportId={conversionResult.reportId}
                reportName={conversionResult.reportName}
                onEditClick={onPolicyEditClick}
                onGoToOverviewClick={onClose}
            />}
        {!!error && <ImportPolicyErrorComponent
            onTryAgainClick={resetForm}
            onGoToOverviewClick={onClose}
            message={error.data?.message || ''}
        />}
    </Drawer100>
}