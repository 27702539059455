import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {policyPolicyInfo} from "core-lib";

const initialState = {
    data: null as Array<policyPolicyInfo> | null,
    itemsToDelete: [] as Array<string>
}

type StateType = typeof initialState;

export const policiesListSlice = createSlice({
    name: 'POLICIES_LIST',
    initialState: initialState as StateType,
    reducers: {
        setPolicies: (state: StateType , action: PayloadAction<Array<policyPolicyInfo> | null>) => {
            state.data = action.payload
        },
        setItemsToDelete: (state: StateType , action: PayloadAction<Array<string>>) => {
            state.itemsToDelete = action.payload
        },
        resetPolicies: (state: StateType) => {
            state.data = null
            state.itemsToDelete = []
        }
    }
});
