import styled from "styled-components";

interface Props {
    mb?: number
    mt?: number
}

export const Divider = styled.div<Props>`
  flex-shrink: 0;
  border-width: 0;
  border-style: solid;
  border-color: rgba(0, 0, 0, 0.12);
  border-bottom-width: thin;
  margin-bottom: ${props => props.mb}px;
  margin-top: ${props => props.mt}px;
`