import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Row } from "../../../components/Row";

const AnnouncementDiv = styled.div`
    background-color: #fffbcc; 
    border: 1px solid #ffdd00; 
    padding: 10px;
    text-align: center;
    color: #333;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); 
    margin-bottom: 10px; 
    margin-top: 10px;
    width: 100%;
    text-align: center;
`;

const fetchAnnouncement = (): Promise<string> => {
    return fetch("/api/v1/announcement")
        .then(x => {
            console.log("status", x);

            if (x.status !== 200) {
                return ""
            }

            return x.text();
        })
        .then(txt => {
            return txt.trim();
        })
}


export function AnnouncementText() {
    const [announcementText, setAnnouncementText] = useState("");

    useEffect(() => {
        fetchAnnouncement()
            .then(r => {
                setAnnouncementText(r)
            })
    }, []);

    return announcementText.length > 0
        ? (<Row>
            <AnnouncementDiv>{announcementText}</AnnouncementDiv>
        </Row>)
        : <div />
}