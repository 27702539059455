import {Align, Button, ContentStatus, FormField, Icon, Input} from "@emerald/react";
import React, {useCallback, useEffect, useState} from "react";
import {permissionUserRole} from "core-lib";
import {createToken, getAgentLink} from "./actions";
import {useAppDispatch} from "../../store";
import {Header, BodyForm, Footer} from "../../components/styled/Drawer";
import {Divider} from "../../components/Divider";
import {Col} from "../../components/Col";
import {Row} from "../../components/Row";
import {ScrollableContent} from "../../components/PageScrollable";
import styled from "styled-components";
import {CircularProgress} from "@mui/material";

const InstructionText = styled.div`
  font-size: 12px;
  line-height: 16px;

  a {
    color: #4F73FF;
    text-decoration: none;
  }
`

const InlineIconCenter = styled(Icon)`
  display: inline-block;
  vertical-align: text-top;
  margin: 0 5px;
  color: #6C778C;
`

const ProgressInlineCenter = styled(CircularProgress)`
  display: inline-block;
  vertical-align: text-top;
  margin: 0 5px;
  color: #6C778C;
`

const InstructionList = styled.ul`

  padding-inline-start: 20px;
  
  li {
    list-style-type: number;
    margin-right: 0;
  }
`

type Props = {
    onFinish: () => void
}

export const AddAgentComponent = (props: Props) => {

    const dispatch = useAppDispatch()
    const [token, setToken] = useState('')
    const [isTokenLoading, setIsTokenLoading] = useState(false)
    const [agentLink, setAgentLink] = useState('https://gitlab.policysupervisor.io/wafps/agent-install/-/packages')
    const [agentLinkIsLoading, setAgentLinkIsLoading] = useState(false)
    const {onFinish} = props

    const getToken = useCallback(() => {
        setIsTokenLoading(true)
        dispatch(createToken('token-name', permissionUserRole.ADMIN))
            .then(tokenData => {
                if (tokenData.value) {
                    setToken(tokenData.value)
                }
            })
            .finally(() => {
                setIsTokenLoading(false)
            })
    }, [dispatch, setToken])

    useEffect(() => {
        getToken()
        setAgentLinkIsLoading(true)
        getAgentLink().then(agentLink => {
            agentLink && setAgentLink(agentLink)
        }).finally(() => {
            setAgentLinkIsLoading(false)
        })
    }, [getToken, setAgentLink])

    const onDoneClick = useCallback(() => {
        setToken('')
        onFinish()
    }, [onFinish, setToken])

    return <BodyForm>
        <Header>Add Agent</Header>
        <ScrollableContent>
            <div>
                <FormField required={true} label="Token">
                    <Input value={token}/>
                </FormField>
                <InstructionText>
                    <p>Instructions to Add Agent:</p>

                    <InstructionList>
                        <li>Go to <a href={agentLink} target="_blank" rel="noreferrer">agent-install</a>
                            {agentLinkIsLoading && <ProgressInlineCenter
                                variant="indeterminate"
                                disableShrink
                                size={12}
                                thickness={2}
                            />}
                            {!agentLinkIsLoading && <InlineIconCenter icon='app.externalLink' size='xs'/>}
                        </li>
                        <li>Prerequisites: Linux, docker installed</li>
                        <li>Download the binary called <b>agent-installer</b></li>
                        <li>Copy this binary, give it execution rights: <b>chmod +x ./agent-installer</b></li>
                        <li>Run it with: <b>sudo ./agent-installer</b></li>
                    </InstructionList>
                </InstructionText>
            </div>
        </ScrollableContent>
        <Footer>
            <Divider/>
            <Col flex={1} rightDirection>
                <Row spacing={20} style={{marginTop: 10}}>
                    <Align>
                        <Button accent={true} displayMode="outlined" onClick={onDoneClick}>Done</Button>
                    </Align>
                </Row>
            </Col>
        </Footer>
        <ContentStatus
            isVisible={isTokenLoading}
            spinnerSize={"medium"}
            defaultMessage={<span>Loading token</span>}
            fillOuterContainer={false}
        />
    </BodyForm>
}