import {AppDispatch} from "../../store";
import {AggregateService} from "core-lib";
import {errorActionRequest} from "../Errors/actions";
import {reportsListSlice} from "./reducer";
import { PAGINATION_FIRST_PAGE, PAGINATION_HUGE_SIZE } from "../../constants";

export const fetchReports = () => {
    return async (dispatch: AppDispatch) => {
        return AggregateService.aggregateAggregateListReports(
            undefined,
            undefined,
            undefined,
            undefined,
            PAGINATION_FIRST_PAGE,
            PAGINATION_HUGE_SIZE)
            .then(response => {
                dispatch(reportsListSlice.actions.loadReports(response));
            })
            .catch(error => {
                errorActionRequest(error);
            })
    }
}