/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum commandJobStatus {
    JOB_CREATED = 'JOB_CREATED',
    JOB_PENDING = 'JOB_PENDING',
    JOB_RUNNING = 'JOB_RUNNING',
    JOB_FAILED = 'JOB_FAILED',
    JOB_SUCCESS = 'JOB_SUCCESS',
    JOB_CANCELED = 'JOB_CANCELED',
    JOB_SKIPPED = 'JOB_SKIPPED',
    NONE = 'NONE',
}
