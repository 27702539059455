import {createSlice, PayloadAction} from '@reduxjs/toolkit'

const initialState = {
    policyId: null as string | null,
}

type StateType = typeof initialState;

export const policyEditSlice = createSlice({
    name: 'POLICY_EDIT',
    initialState: initialState as StateType,
    reducers: {
        setPolicyId: (state: StateType , action: PayloadAction<string | null>) => {
            state.policyId = action.payload;
        },  
    }
});
