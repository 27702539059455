/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { deploymentListDeploymentResponse } from '../models/deploymentListDeploymentResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class DeploymentsService {

    /**
     * @param filterStatus
     * @param filterPolicyId
     * @param filterPolicyName
     * @param filterProviderId
     * @param filterProviderName
     * @param filterCommandId
     * @param filterAttachmentsCount
     * @param orderDate
     * @param orderStatus
     * @param orderPolicyName
     * @param orderProviderName
     * @param orderAttachmentsCount
     * @param orderCreatedAt
     * @param orderUpdatedAt
     * @param page
     * @param size
     * @returns deploymentListDeploymentResponse A successful response.
     * @throws ApiError
     */
    public static deploymentsListDeployments(
        filterStatus?: Array<string>,
        filterPolicyId?: Array<string>,
        filterPolicyName?: Array<string>,
        filterProviderId?: Array<string>,
        filterProviderName?: Array<string>,
        filterCommandId?: Array<string>,
        filterAttachmentsCount?: Array<number>,
        orderDate: 'DESCENDING' | 'ASCENDING' = 'DESCENDING',
        orderStatus: 'DESCENDING' | 'ASCENDING' = 'DESCENDING',
        orderPolicyName: 'DESCENDING' | 'ASCENDING' = 'DESCENDING',
        orderProviderName: 'DESCENDING' | 'ASCENDING' = 'DESCENDING',
        orderAttachmentsCount: 'DESCENDING' | 'ASCENDING' = 'DESCENDING',
        orderCreatedAt: 'DESCENDING' | 'ASCENDING' = 'DESCENDING',
        orderUpdatedAt: 'DESCENDING' | 'ASCENDING' = 'DESCENDING',
        page?: number,
        size?: number,
    ): CancelablePromise<deploymentListDeploymentResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/deployments',
            query: {
                'filter.status': filterStatus,
                'filter.policyId': filterPolicyId,
                'filter.policyName': filterPolicyName,
                'filter.providerId': filterProviderId,
                'filter.providerName': filterProviderName,
                'filter.commandId': filterCommandId,
                'filter.attachmentsCount': filterAttachmentsCount,
                'order.date': orderDate,
                'order.status': orderStatus,
                'order.policyName': orderPolicyName,
                'order.providerName': orderProviderName,
                'order.attachmentsCount': orderAttachmentsCount,
                'order.createdAt': orderCreatedAt,
                'order.updatedAt': orderUpdatedAt,
                'page': page,
                'size': size,
            },
            errors: {
                400: `Invalid argument`,
                401: `Returned when the user does not have permission to access the resource.`,
                404: `Resource was not found.`,
                500: `Internal server error`,
            },
        });
    }

}
