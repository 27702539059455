import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import Editor, { Monaco } from "@monaco-editor/react";
import styled from "styled-components";
import { Divider } from "../../components/Divider";
import { Row } from "../../components/Row";
import { Align, Button } from "@emerald/react";
import { Col } from "../../components/Col";
import { policyPolicy } from "core-lib";
import { errorActionRequest } from "../Errors/actions";
import { createErrorObject } from "../Errors/util";
import { API_URI } from "../../constants";
import POLICY_SCHEMA from "core-lib/src/schema/policy.json";
import NAME_VALIDATION_SCHEMA from "./name-validation.json";
import { Dialog } from "../../components/Dialog";
import { Position, languages } from 'monaco-editor/esm/vs/editor/editor.api'
import type monaco from 'monaco-editor';
import { findObjectBoundaryFromPosition } from "./helpers";
import { ReferencesData, ReferenceMatch } from "./types";
import moment from "moment";
import { fetchReferenceDataByType } from "./actions";
import { useAppDispatch } from "../../store";

const BodyForm = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 20px;
  height: 100%
`
const Header = styled.div`
  min-height: 58px;
`
const Footer = styled.div`
  margin-top: auto;
  min-height: 72px;
`
const Content = styled.div`
  flex: 1;
`

const ConfirmationDialogText = styled.span`
font-size: 14px;
`

const SCHEMA_PATH = API_URI + '/policy.json'
const MODEL_PATH = API_URI + '/policyModel.json'

function filterOutNullValues(key: any, value: any) {
    if (value === null) {
        return undefined;
    }
    return value;
}

export const EditPolicyContentComponent = (props: {
    isNewPolicy: boolean,
    policy: policyPolicy | null,
    referencesData: ReferencesData,
    onClose: () => void,
    onBack: () => void,
    onSave: (policy: policyPolicy, shouldClose?: boolean) => void,
}) => {

    const { isNewPolicy, policy, referencesData, onSave, onClose, onBack } = props
    const [cancelConfirmationOpen, setCancelConfirmationOpen] = useState(false);
    const [markers, setMarkers] = useState<monaco.editor.IMarker[]>([]);

    const defaultValue = useMemo(() => {
        return policy ? JSON.stringify(policy, filterOutNullValues, '    ') : '{}'
    }, [policy])

    useEffect(() => {
        if (editorRef?.current && policy) {
            editorRef.current.getModel()?.setValue(JSON.stringify(policy, filterOutNullValues, '    '))
            setTimeout(function () {
                editorRef?.current?.getAction('editor.action.formatDocument')?.run();
            }, 300);
        }
    }, [policy])

    const dispatch = useAppDispatch()
    const editorRef = useRef<monaco.editor.IStandaloneCodeEditor | null>(null);
    const completionProviderRef = useRef<monaco.IDisposable | null>(null);
    const onReferenceSelectedRef = useRef<monaco.IDisposable | null>(null);
    const referencesDocumentMatches = useRef<ReferenceMatch[]>([]);
    const referenceDocumentMatch = useRef<ReferenceMatch | null>(null);

    const onSaveButtonClick = useCallback((shouldClose?: boolean) => {
        if (editorRef?.current) {
            if(markers.length > 0) {           
                const marker = markers[0]
                editorRef.current?.revealLine(marker.startLineNumber);
                const errorMessage = `errorLine: ${marker.startLineNumber}\nerrorColumn: ${marker.startColumn}\nerror: ${marker.message}`
                errorActionRequest(createErrorObject('There are errors in JSON', 'Fix the following error to continue', errorMessage))
                return;
            }
            let policyEdited
            try {
                policyEdited = JSON.parse(editorRef.current.getValue())
            } catch (e) {
                errorActionRequest(createErrorObject('Could not parse JSON', 'Your JSON is not correct'))
            }

            if (policyEdited) {
                onSave(policyEdited as policyPolicy, shouldClose)
            }
        }
    }, [editorRef, onSave, markers])

    const onCancelButtonClick = useCallback(() => {
        if (!isNewPolicy && editorRef?.current?.getValue() !== defaultValue) {
            setCancelConfirmationOpen(true);
        } else {
            onClose();
        }
    }, [onClose, defaultValue, setCancelConfirmationOpen, isNewPolicy])

    const handleEditorDidMount = useCallback((editor: monaco.editor.IStandaloneCodeEditor, monaco: Monaco) => {

        editor.onDidChangeCursorPosition(e => {
            const positionsToCheck = referencesDocumentMatches.current || []
            let match: ReferenceMatch | null = null;

            positionsToCheck.forEach((item) => {
                if (item.range.containsPosition(e.position) &&
                    e.position.isBefore(item.range.getEndPosition()) &&
                    item.range.getStartPosition().isBefore(e.position)) {
                    match = item;
                }
            })

            referenceDocumentMatch.current = null;
            if (match) {
                const text = editor.getModel()?.getValueInRange({
                    startLineNumber: (match as ReferenceMatch).range.startLineNumber,
                    startColumn: (match as ReferenceMatch).range.startColumn,
                    endLineNumber: e.position.lineNumber,
                    endColumn: e.position.column,
                }) || '';
                const matchFirstBracket = new RegExp(/^{(?![\s\S]*({|"))/gm)
                const matchIdField = new RegExp(/"id"\s*:\s*"[^"]*$/gm)
                
                if(!!text.match(matchFirstBracket) || text.match(matchIdField)) {
                    referenceDocumentMatch.current = match;
                    setTimeout(function () {
                        editor.getAction('editor.action.triggerSuggest')?.run();
                    }, 20);
                }
            }
        });

        const constructReferencePositions = () => {
            let newMatches: any[] = [];
            const model = editor.getModel();
            if (model) {
                Object.keys(referencesData).forEach((key) => {
                    const matches = model.findMatches('"' + key + '"\\s*:\\s*\\{', true, true, true, null, false);

                    if (matches) {
                        matches.forEach((item) => {
                            const { isObject, range } = findObjectBoundaryFromPosition(model, new Position(item.range.endLineNumber, item.range.endColumn))

                            if (isObject) {
                                newMatches.push({
                                    type: key,
                                    range: range
                                });
                            }
                        })
                    }
                })
            }

            referencesDocumentMatches.current = newMatches
        }

        editor.onDidChangeModelContent(constructReferencePositions)

        editorRef.current = editor;

        setTimeout(function () {
            editor.getAction('editor.action.formatDocument')?.run();
            constructReferencePositions()
        }, 300);
    }, [referencesData])

    const handleBeforeMount = (monaco: Monaco) => {
        monaco.languages.json.jsonDefaults.setDiagnosticsOptions({
            validate: true,
            schemas: [
                {
                    uri: SCHEMA_PATH,
                    fileMatch: ['*'],
                    schema: POLICY_SCHEMA
                },
                {   
                    uri: "nameValidation.json",
                    fileMatch: ['*'],
                    schema: NAME_VALIDATION_SCHEMA
                }
            ],
            schemaValidation: 'error'
        });

        onReferenceSelectedRef.current = monaco.editor.registerCommand("onReferenceSelected", (_, ...args) => {
            const [selectedItemId, referenceType] = args;

            dispatch(fetchReferenceDataByType(selectedItemId, referenceType)).then((data) => {
                const selectedItemString = JSON.stringify(data);

                if (editorRef?.current && selectedItemString && referenceDocumentMatch.current) {
                    const rangeToReplace = referenceDocumentMatch.current.range
    
                    editorRef.current.executeEdits(undefined, [{
                        range: rangeToReplace,
                        text: selectedItemString,
                        forceMoveMarkers: true
                    }])
    
                    const position = new Position(rangeToReplace.startLineNumber, rangeToReplace.startColumn + 1);
                    const model = editorRef.current.getModel();
    
                    if (model) {
                        const { isObject, range } = findObjectBoundaryFromPosition(model, position);
    
    
                        if (isObject) {
                            editorRef?.current?.getAction('editor.action.formatDocument')?.run();
                            editorRef.current.setSelections([{
                                selectionStartLineNumber: range.startLineNumber,
                                selectionStartColumn: range.startColumn,
                                positionLineNumber: range.endLineNumber,
                                positionColumn: range.endColumn
                            }])
                        }
                    }
                }
            })
        })

        completionProviderRef.current = monaco.languages.registerCompletionItemProvider("json", {
            provideCompletionItems: (model: monaco.editor.ITextModel, position: monaco.Position) => {
                if (!referenceDocumentMatch.current) {
                    return { suggestions: [] };
                }

                const proposalsMatch = referencesData[referenceDocumentMatch.current?.type];
                const word = model.getWordUntilPosition(position);
                const wordRange = {
                    startLineNumber: position.lineNumber,
                    endLineNumber: position.lineNumber,
                    startColumn: word.startColumn,
                    endColumn: word.endColumn,
                };

               
                if (referenceDocumentMatch.current && proposalsMatch) {
                    return {
                        suggestions:
                            [{
                                label: `Select one from following ${referenceDocumentMatch.current.type} items ...`,
                                kind: languages.CompletionItemKind.Constant,
                                insertText: '',
                                range: wordRange,
                                sortText: "  ",
                                command: {
                                    title: "",
                                    id: "editor.action.triggerSuggest"
                                },
                            },
                            ...proposalsMatch.map((item, index) => ({
                                label: `${item?.metadata?.name} - ${moment(item?.metadata?.modifiedTime).format('h:mm A, D MMM YYYY')} - ${item?.id}`,
                                kind: languages.CompletionItemKind.Reference,
                                insertText: '',
                                sortText: moment(item?.metadata?.modifiedTime).unix().toString(2).replace(/[0-1]/g, (v) => (v === "1" ? "0" : "1")),
                                command: {
                                    title: "",
                                    id: "onReferenceSelected",
                                    arguments: [item.id, referenceDocumentMatch.current?.type]
                                },
                                range: wordRange
                            }))]
                    };
                }
            }
        })
    }

    const handleValidation = useCallback((markers: monaco.editor.IMarker[]) => {
        setMarkers(markers)
    }, [])

    useEffect(() => {
        return () => {
            if (completionProviderRef.current) {
                completionProviderRef.current.dispose();
            }
            if (onReferenceSelectedRef.current) {
                onReferenceSelectedRef.current.dispose();
            }
        }
    }, [])


    return <>
        <BodyForm>
            <Header>
                {isNewPolicy ? 'Policy Creation' : 'Policy editing'}
            </Header>
            <Content>
                <Editor
                    theme="vs-dark"
                    height="100%"
                    defaultLanguage="json"
                    defaultValue={defaultValue}
                    path={MODEL_PATH}
                    onMount={handleEditorDidMount}
                    beforeMount={handleBeforeMount}
                    onValidate={handleValidation}
                />
            </Content>
            <Footer>
                <Divider />
                <Row flex={1} style={{ marginTop: 10 }}>
                    <Col flex={1}>
                        <Align>
                            <Button accent={true} displayMode="outlined" onClick={onCancelButtonClick}>Cancel & Exit</Button>
                        </Align>
                    </Col>
                    <Col flex={1} rightDirection>
                        <Row flex={1} spacing={20}>
                            <Align>
                                {isNewPolicy ? <Button accent={true} displayMode="outlined" onClick={onBack}>Back</Button> : <Button accent={true} displayMode="outlined" onClick={() => onSaveButtonClick(false)}>Save</Button>}
                            </Align>
                            <Align>
                                <Button accent={true} onClick={() => onSaveButtonClick()}>Save & Exit</Button>
                            </Align>
                        </Row>
                    </Col>
                </Row>
            </Footer>
        </BodyForm>
        <Dialog
            title="Cancel & Exit"
            open={cancelConfirmationOpen}
            actions={[
                {
                    accent: true,
                    onClick: () => {
                        setCancelConfirmationOpen(false)
                    },
                    displayMode: "outlined",
                    children: "Back"
                },
                {
                    accent: true,
                    onClick: () => {
                        onClose()
                        setCancelConfirmationOpen(false)
                    },
                    children: "Yes, exit without saving"
                }
            ]}
        >
            <ConfirmationDialogText>You have unsaved changes. Continue without saving?</ConfirmationDialogText>
        </Dialog>
    </>
}