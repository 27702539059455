import { OpenAPI } from '../types-generated'

class OpenApiParams {
  private pathnameValue: string

  get pathname() {
    if (typeof this.pathnameValue === 'undefined') {
      console.error(
        'Pathname is not defined! Set it using setOpenAPIPathname(pathname: string)'
      )
    }
    return this.pathnameValue
  }

  setOpenAPIPathname(pathname: string) {
    this.pathnameValue = pathname
  }
}

const OPENAPI_PARAMS = new OpenApiParams()

export const setOpenAPIPathname = (pathname: string) => {
  OPENAPI_PARAMS.setOpenAPIPathname(pathname)
}

export const getAuthURL = (savePath = false): string => {
  const currentPath = document.location.pathname
  return `${OpenAPI.BASE}${OPENAPI_PARAMS.pathname}/authenticate${
    savePath ? `?state=api;${currentPath}` : ''
  }`
}

export const getLogoutURL = (): string => {
  return `${OpenAPI.BASE}${OPENAPI_PARAMS.pathname}/logout`
}

export const getConversionLogUrl = (commandId: string) => {
  return `${OpenAPI.BASE}${OPENAPI_PARAMS.pathname}/commands/convert/${commandId}/report`
}

export const getDeploymentLogUrl = (commandId: string) => {
  return `${OpenAPI.BASE}${OPENAPI_PARAMS.pathname}/commands/deploy/${commandId}/log`
}
