import {providerProviderType} from "core-lib";

type ProvidersInfoType = {
    [key: string]: {key: providerProviderType, name: string}
}

export const ManualConversionProviders: ProvidersInfoType = {
    [providerProviderType.AWAF]: {key: providerProviderType.AWAF, name: 'BIG-IP Advanced WAF'},
    [providerProviderType.XC]: {key: providerProviderType.XC, name: 'F5 Distributed Cloud'},
    [providerProviderType.NAP]: {key: providerProviderType.NAP, name: 'NGINX App Protect WAF'},
}

export const getProviderName = (id: string) => {
    return ManualConversionProviders[id]?.name || "N/A"
}

export type ConvertFormData = {
    name: string,
    source: string,
    fileData: string,
    target: string
}