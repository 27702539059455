import { RootState } from "../../store";
import { createSelector } from "@reduxjs/toolkit";

export const providerIngestionIsOpenSelector = (state: RootState) => state.providerIngestion.providerForIngestion !== null
export const providerIngestionProviderSelector = (state: RootState) => state.providerIngestion.providerForIngestion
export const providerIngestionPoliciesListSelector = (state: RootState) => state.providerIngestion.policies
export const providerIngestionSelectedPoliciesSelector = (state: RootState) => state.providerIngestion.selectedPolicyIds
export const providerIngestionSelectedPolicyProfilesSelector = (state: RootState) => state.providerIngestion.selectedPolicyProfileIds
export const providerIngestionCommitMessageSelector = (state: RootState) => state.providerIngestion.commitMessage
export const providerIngestionStatusSelector = (state: RootState) => state.providerIngestion.ingestionStatus
export const providerIngestionCommandIdsSelector = (state: RootState) => state.providerIngestion.commandIds
export const providerIngestionReportGroupIdSelector = (state: RootState) => state.providerIngestion.reportGroupId
export const providerIngestionProfilesBot = (state: RootState) => state.providerIngestion.profilesBot
export const providerIngestionProfilesDos = (state: RootState) => state.providerIngestion.profilesDos

export const providerIngestionPoliciesSelectedListSelector = createSelector(
    [providerIngestionPoliciesListSelector, providerIngestionSelectedPoliciesSelector],
    (list, selectedIds) => {
        return list?.filter((item) => selectedIds?.includes(item.id)) || [];
    })


export const providerIngestionResultSelector = createSelector(
    [providerIngestionStatusSelector, providerIngestionSelectedPoliciesSelector],
    (statuses, selectedIds) => {
        return {
            count: selectedIds.length,
            completed: Object.keys(statuses).reduce<number>((count, key) => {
                const { completed } = statuses[key];
                if (completed) {
                    count++;
                }
                return count;
            }, 0),
            succeeded: Object.keys(statuses).reduce<number>((count, key) => {
                const { completed, error } = statuses[key];
                if (completed && !error) {
                    count++;
                }
                return count;
            }, 0)
        }
    })