import {AppDispatch, RootState} from "../../store";
import {errorActionRequest} from "../Errors/actions";
import {policyDeploySlice} from "./reducer";
import {
    commandDeployAWAFRequestItem, commandDeployXCRequestItem,
    commandJobStatus,
    CommandsService,
    providerResponse,
    ProvidersService,
    providerProviderType,
    commandDeployNapRequestItem
} from "core-lib";
import {CommandStatusCheckManager} from "../../core/commands/CommandStatusCheckManager";
import {conversionCommandsIdsSelector, deployCommandsIdsSelector, findEndpointsCommandsIdsSelector} from "./selectors";
import {PAGINATION_FIRST_PAGE, PAGINATION_HUGE_SIZE} from "../../constants";

export const fetchDeploymentProviders = () => {
    return async (dispatch: AppDispatch) => {
        return ProvidersService.providersListProviders(
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            ["CONNECTED"],
            PAGINATION_FIRST_PAGE,
            PAGINATION_HUGE_SIZE)
            .then(response => {
                dispatch(policyDeploySlice.actions.setProviders(response.providers || []));
            })
            .catch(error => {
                errorActionRequest(error);
            })
    }
}

export const convertPolicy = (policyId: string, providerTypes: providerProviderType[], notes: string) => {
    return async (dispatch: AppDispatch, getState: () => RootState) => {

        const setErrorResult = (type: providerProviderType) => {
            dispatch(policyDeploySlice.actions.addConversionResult({
                type: type,
                result: false
            }))
        }

        CommandStatusCheckManager.stopCommands(Object.values(conversionCommandsIdsSelector(getState())))
        dispatch(policyDeploySlice.actions.resetConversion())
        dispatch(policyDeploySlice.actions.setReportGroupId(''))

        return CommandsService.commandsPostDeployConvert({
            policyId,
            targets: providerTypes,
            notes: notes
        }).then(response => {

            dispatch(policyDeploySlice.actions.setReportGroupId(response.groupId || ''))

            response.conversions?.forEach(conversionPostResponse => {

                const {target, commandId} = conversionPostResponse

                

                if(target && commandId) {
                    dispatch(policyDeploySlice.actions.addConversionCommandId({
                        type: target,
                        commandId: commandId
                    }));

                    CommandStatusCheckManager.getCommandRunner(commandId)
                        .setOnResult((commandId, commandStatus) => {
                            if(commandStatus === commandJobStatus.JOB_SUCCESS) {
                                CommandsService.commandsGetConvertReport(commandId).then((result) => {
                                    dispatch(policyDeploySlice.actions.addConversionResult({
                                        type: target,
                                        result: result
                                    }))
                                }).catch(error => {
                                    errorActionRequest(error)
                                })
                            } else {
                                setErrorResult(target)
                            }
                        })
                        .setOnError((commandId, e) => {
                            setErrorResult(target)
                            errorActionRequest(e)
                        })
                }
            })

            return response.conversions?.map(conversionPostResponse => conversionPostResponse.commandId) || []
        }).catch(error => {
            providerTypes.forEach(type => setErrorResult(type))
            errorActionRequest(error);
            throw error
        })
    }
}

export const findEndpoints = (provider: providerResponse) => {
    return async (dispatch: AppDispatch) => {

        const providerId = provider.id
        const endpointsProviderType = provider.type

        const setEmptyResult = () => {
            if(providerId) {
                if (endpointsProviderType === providerProviderType.AWAF) {
                    dispatch(policyDeploySlice.actions.addFindEndpointsResult({
                        providerId: providerId,
                        result: {partitions: []}
                    }))
                } else if (endpointsProviderType === providerProviderType.XC) {
                    dispatch(policyDeploySlice.actions.addFindEndpointsResult({
                        providerId: providerId,
                        result: {namespaces: []}
                    }))
                }
                else if (endpointsProviderType === providerProviderType.NAP) {
                    dispatch(policyDeploySlice.actions.addFindEndpointsResult({
                        providerId: providerId,
                        result: {servers: []}
                    }))
                }
            }
        }

        return CommandsService.commandsPostFindEndpoints({
            providerId: providerId
        }).then(response => {

            const commandId = response.id

            if(commandId && endpointsProviderType && providerId) {
                dispatch(policyDeploySlice.actions.addFindEndpointsCommandId({
                    providerId: providerId,
                    commandId: commandId
                }));

                CommandStatusCheckManager.getCommandRunner(commandId)
                    .setOnResult((commandId, commandStatus) => {
                        if(commandStatus === commandJobStatus.JOB_SUCCESS) {
                            if (endpointsProviderType === providerProviderType.AWAF) {
                                CommandsService.commandsGetFindEndpointsAwaf(commandId).then((result) => {
                                    dispatch(policyDeploySlice.actions.addFindEndpointsResult({
                                        providerId: providerId,
                                        result: result
                                    }))
                                }).catch(error => {
                                    errorActionRequest(error)
                                })
                            } else if (endpointsProviderType === providerProviderType.XC) {
                                CommandsService.commandsGetFindEndpointsXc(commandId).then((result) => {
                                    dispatch(policyDeploySlice.actions.addFindEndpointsResult({
                                        providerId: providerId,
                                        result: result
                                    }))
                                }).catch(error => {
                                    errorActionRequest(error)
                                })
                            } else if (endpointsProviderType === providerProviderType.NAP) {
                                CommandsService.commandsGetFindEndpointsNap(commandId).then((result) => {
                                    dispatch(policyDeploySlice.actions.addFindEndpointsResult({
                                        providerId: providerId,
                                        result: result
                                    }))
                                }).catch(error => {
                                    errorActionRequest(error)
                                })
                            }
                        } else {
                            setEmptyResult()
                        }
                    })
                    .setOnError((commandId, e) => {
                        setEmptyResult()
                        errorActionRequest(e)
                    })
            }
            return response.id
        }).catch(error => {
            setEmptyResult()
            errorActionRequest(error);
            throw error
        })
    }
}

export const deployPolicyAWAF = (conversionCommandId: string, endpointsByProvider: {[providerId: string]: {[groupId: string]: Array<string>}}) => {
    return async (dispatch: AppDispatch) => {

        const requestProviders = Object.keys(endpointsByProvider).reduce((result, providerId) => {
            const partitions = Object.keys(endpointsByProvider[providerId]).map(partition => ({
                partition: partition,
                endpoints: endpointsByProvider[providerId][partition]
            }))

            return [...result, {
                providerId: providerId,
                partitions: partitions
            }]

        }, [] as Array<commandDeployAWAFRequestItem>)

        const setErrors = () => {
            requestProviders.forEach(partitionProvider => {
                const partitions = (partitionProvider.partitions?.length || 0) > 0 ? partitionProvider.partitions : [{ partition: partitionProvider.providerId}] 

                partitions?.forEach(partitionEndpoints => {
                    if(partitionProvider.providerId && partitionEndpoints.partition) {
                        dispatch(policyDeploySlice.actions.addDeploymentResult({
                            providerId: partitionProvider.providerId,
                            groupId: partitionEndpoints.partition,
                            result: false
                        }))
                    }
                })
            })
        }

        return CommandsService.commandsPostDeployAwaf({
            conversionCommandId: conversionCommandId,
            providers: requestProviders
        }).then(response => {

            response.providerCommands?.forEach(command => {
                const providerId = command.providerId

                command.partitions?.forEach(partitionCommand => {
                    const {partition, commandId} = partitionCommand

                    if(commandId && providerId) {
                        dispatch(policyDeploySlice.actions.addDeploymentCommandId({
                            providerId: providerId,
                            groupId: partition || providerId,
                            commandId: commandId
                        }));

                        CommandStatusCheckManager.getCommandRunner(commandId)
                            .setOnResult((commandId, commandStatus) => {
                                dispatch(policyDeploySlice.actions.addDeploymentResult({
                                    providerId: providerId,
                                    groupId: partition || providerId,
                                    result: commandStatus === commandJobStatus.JOB_SUCCESS
                                }))
                            })
                            .setOnError((commandId, e) => {
                                dispatch(policyDeploySlice.actions.addDeploymentResult({
                                    providerId: providerId,
                                    groupId: partition || providerId,
                                    result: false
                                }))
                                errorActionRequest(e)
                            })
                    }
                })
            })

            return response.providerCommands

        }).catch(error => {
            setErrors()
            errorActionRequest(error);
            throw error
        })
    }
}

export const deployPolicyXC = (conversionCommandId: string, endpointsByProvider: {[providerId: string]: {[groupId: string]: Array<string>}}) => {
    return async (dispatch: AppDispatch) => {

        const requestProviders = Object.keys(endpointsByProvider).reduce((result, providerId) => {
            const namespaces = Object.keys(endpointsByProvider[providerId]).map(namespace => ({
                namespace: namespace,
                loadBalancers: endpointsByProvider[providerId][namespace]
            }))

            return [...result, {
                providerId: providerId,
                namespaces: namespaces
            }]

        }, [] as Array<commandDeployXCRequestItem>)

        const setErrors = () => {
            requestProviders.forEach(namespaceProvider => {
                namespaceProvider.namespaces?.forEach(namespaceEndpoints => {
                    if(namespaceProvider.providerId && namespaceEndpoints.namespace) {
                        dispatch(policyDeploySlice.actions.addDeploymentResult({
                            providerId: namespaceProvider.providerId,
                            groupId: namespaceEndpoints.namespace,
                            result: false
                        }))
                    }
                })
            })
        }

        return CommandsService.commandsPostDeployXc({
            conversionCommandId: conversionCommandId,
            providers: requestProviders
        }).then(response => {

            response.providerCommands?.forEach(command => {
                const providerId = command.providerId

                command.namespaces?.forEach(namespaceCommand => {
                    const {namespace, commandId} = namespaceCommand

                    if(commandId && providerId && namespace) {
                        dispatch(policyDeploySlice.actions.addDeploymentCommandId({
                            providerId: providerId,
                            groupId: namespace,
                            commandId: commandId
                        }));

                        CommandStatusCheckManager.getCommandRunner(commandId)
                            .setOnResult((commandId, commandStatus) => {
                                dispatch(policyDeploySlice.actions.addDeploymentResult({
                                    providerId: providerId,
                                    groupId: namespace,
                                    result: commandStatus === commandJobStatus.JOB_SUCCESS
                                }))
                            })
                            .setOnError((commandId, e) => {
                                dispatch(policyDeploySlice.actions.addDeploymentResult({
                                    providerId: providerId,
                                    groupId: namespace,
                                    result: false
                                }))
                                errorActionRequest(e)
                            })
                    }
                })
            })

            return response.providerCommands

        }).catch(error => {
            setErrors()
            errorActionRequest(error);
            throw error
        })
    }
}

export const deployPolicyNAP = (conversionCommandId: string, endpointsByProvider: {[providerId: string]: {[groupId: string]: Array<string>}}) => {
    return async (dispatch: AppDispatch) => {

        const requestProviders = Object.keys(endpointsByProvider).reduce((result, providerId) => {
            return [...result, {
                providerId: providerId,
                servers: endpointsByProvider[providerId][providerId] || []
            }]
        }, [] as Array<commandDeployNapRequestItem>)

       const setErrors = () => {
            requestProviders.forEach(namespaceProvider => {
                    if(namespaceProvider.providerId) {
                        dispatch(policyDeploySlice.actions.addDeploymentResult({
                            providerId: namespaceProvider.providerId,
                            groupId: namespaceProvider.providerId,
                            result: false
                        }))
                    }
            })
        }


        return CommandsService.commandsPostDeployNap({
            conversionCommandId: conversionCommandId,
            providers: requestProviders
        }).then(response => {
            response.providerCommands?.forEach((command, index) => {
                const {providerId, commandId} = command;

                    if(commandId && providerId) {
                        dispatch(policyDeploySlice.actions.addDeploymentCommandId({
                            providerId: providerId,
                            groupId: providerId,
                            commandId: commandId
                        }));

                        CommandStatusCheckManager.getCommandRunner(commandId)
                            .setOnResult((commandId, commandStatus) => {
                                dispatch(policyDeploySlice.actions.addDeploymentResult({
                                    providerId: providerId,
                                    groupId : providerId,
                                    result: commandStatus === commandJobStatus.JOB_SUCCESS
                                }))
                            })
                            .setOnError((commandId, e) => {
                                dispatch(policyDeploySlice.actions.addDeploymentResult({
                                    providerId: providerId,
                                    groupId: providerId,
                                    result: false
                                }))
                                errorActionRequest(e)
                            })
                    }
                
            })

            return response.providerCommands

        }).catch(error => {
            setErrors()
            errorActionRequest(error);
            throw error
        })
    }
}

export const cleanAllDeploymentsScreenCommands = () => {
    return async (dispatch: AppDispatch, getState: () => RootState) => {
        const conversionCommandsIds = conversionCommandsIdsSelector(getState())
        const findEndpointsCommandsIds = findEndpointsCommandsIdsSelector(getState())
        const deployCommandsIds = deployCommandsIdsSelector(getState())

        CommandStatusCheckManager.stopCommands(Object.values(conversionCommandsIds))
        CommandStatusCheckManager.stopCommands(Object.values(findEndpointsCommandsIds))
        Object.values(deployCommandsIds).forEach((commandByGroup) => {
            CommandStatusCheckManager.stopCommands(Object.values(commandByGroup))
        })
    }
}
