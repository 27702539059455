import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {
    providerResponse,
    policyPolicyInfo,
    providerProviderType,
    commandConversionReportResponse,
    commandFindEndpointsAWAFResponse,
    commandFindEndpointsXCResponse,
    commandFindEndpointsNAPResponse
} from "core-lib";

const initialState = {
    policyForDeploy: null as policyPolicyInfo | null,
    providers: null as Array<providerResponse> | null,
    commitMessage: '',
    providersForDeploy: [] as Array<providerResponse>,
    providerTypesSelected: [] as Array<providerProviderType>,
    reportGroupId: '',

    conversionsCommandsIds: {} as {[type: string]: string},
    conversionResult: {} as {[type: string]: commandConversionReportResponse | false},

    findEndpointsCommandsIds: {} as {[providerId: string]: string},
    findEndpointsResult: {} as {[providerId: string]: commandFindEndpointsAWAFResponse | commandFindEndpointsXCResponse | commandFindEndpointsNAPResponse},

    deploymentsCommandsIds: {} as {[providerId: string]: {[groupName: string]: string}},
    deploymentResult: {} as {[providerId: string]: {[groupName: string]: boolean | undefined}},

    endpoints: {} as {[providerId: string]: Array<string>}
}

type StateType = typeof initialState;

export const policyDeploySlice = createSlice({
    name: 'POLICY_DEPLOY',
    initialState: initialState as StateType,
    reducers: {
        openPolicyDeploy: (state: StateType , action: PayloadAction<policyPolicyInfo | null>) => {
            state.policyForDeploy = action.payload;
        },
        setCommitMessage: (state: StateType , action: PayloadAction<string>) => {
            state.commitMessage = action.payload;
        },
        setProvidersForDeploy: (state: StateType , action: PayloadAction<Array<providerResponse>>) => {
            state.providersForDeploy = action.payload;
        },
        setProviders: (state: StateType , action: PayloadAction<Array<providerResponse> | null>) => {
            state.providers = action.payload;
        },
        setProviderTypesSelected: (state: StateType , action: PayloadAction<Array<providerProviderType>>) => {
            state.providerTypesSelected = action.payload;
        },
        addConversionCommandId: (state: StateType, action: PayloadAction<{type: providerProviderType, commandId: string}>) => {
            state.conversionsCommandsIds = {
                ...state.conversionsCommandsIds,
                [action.payload.type]: action.payload.commandId
            }
        },
        resetConversion: (state: StateType) => {
            state.conversionsCommandsIds = {}
            state.conversionResult = {}
        },
        addConversionResult: (state: StateType , action: PayloadAction<{type: providerProviderType, result: commandConversionReportResponse | false}>) => {
            state.conversionResult = {
                ...state.conversionResult,
                [action.payload.type]: action.payload.result
            }
        },
        addFindEndpointsCommandId: (state: StateType, action: PayloadAction<{providerId: string, commandId: string}>) => {
            state.findEndpointsCommandsIds = {
                ...state.findEndpointsCommandsIds,
                [action.payload.providerId]: action.payload.commandId
            }
        },
        addFindEndpointsResult: (
            state: StateType ,
            action: PayloadAction<{providerId: string, result: commandFindEndpointsAWAFResponse | commandFindEndpointsXCResponse | commandFindEndpointsNAPResponse}>
        ) => {
            state.findEndpointsResult = {
                ...state.findEndpointsResult,
                [action.payload.providerId]: action.payload.result
            }
        },
        setEndpoints: (state: StateType , action: PayloadAction<{providerId: string, endpoints: Array<string>}>) => {
            state.endpoints = {
                ...state.endpoints,
                [action.payload.providerId]: action.payload.endpoints
            }
        },
        addDeploymentCommandId: (state: StateType, action: PayloadAction<{providerId: string, groupId: string, commandId: string}>) => {
            state.deploymentsCommandsIds = {
                ...state.deploymentsCommandsIds,
                [action.payload.providerId]: {
                    ...(state.deploymentsCommandsIds[action.payload.providerId] ? state.deploymentsCommandsIds[action.payload.providerId] : {}),
                    [action.payload.groupId]: action.payload.commandId
                }
            }
        },
        addDeploymentResult: (state: StateType , action: PayloadAction<{providerId: string, groupId: string, result: boolean}>) => {
            state.deploymentResult = {
                ...state.deploymentResult,
                [action.payload.providerId]: {
                    ...(state.deploymentResult[action.payload.providerId] ? state.deploymentResult[action.payload.providerId] : {}),
                    [action.payload.groupId]: action.payload.result
                }
            }
        },
        setReportGroupId: (state: StateType , action: PayloadAction<string>) => {
            state.reportGroupId = action.payload
        },
        clean: (state: StateType , _action: PayloadAction<void>) => {
            state.providers = null
            state.policyForDeploy = null
            state.providersForDeploy = []
            state.endpoints = {}
            state.commitMessage = ''
            state.conversionsCommandsIds = {}
            state.conversionResult = {}
            state.findEndpointsCommandsIds = {}
            state.findEndpointsResult = {}
            state.deploymentsCommandsIds = {}
            state.deploymentResult = {}
            state.providerTypesSelected = []
            state.reportGroupId = ''
        }
    }
});
