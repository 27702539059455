import React, {useState} from "react";
import {Button, Icon} from "@emerald/react";
import styled from "styled-components";
import onboardingIcon from '../../assets/onboarding-blue.png'
import { Dialog } from "../../components/Dialog";
import checkmark from '../../assets/checkmark.svg'
import { getProviderName } from "./utils";
import { useAppDispatch } from "../../store";
import { downloadConvertedFile } from "./actions";

const ComponentWrapper = styled.div`
  text-align: center;
`

const Title = styled.h2`
  color: #4F73FF;
  margin-bottom: 50px;
`

const TableWrapper = styled.div`
  display: flex;
  justify-content: center;
  text-align: left;
  font-size: 12px;
  line-height: 18px;
  margin-bottom: 30px
`

const TableRowCheckmark = styled.td`

`

const TableRowTitle = styled.td`
  width: 100px;
  font-weight: 700;
`

const TableRowData = styled.td`
  max-width: 215px;
  color: #6C778C;
`

const DownloadButton = styled(Button)`
  width: 175px;
  span {
    display: flex;
    justify-content: center;
    gap: 3px;
  }
`

const ConvertLink = styled(Button)`
  margin-top: 20px;

  span {
    justify-content: center;
  }
`

const DescriptionWrapper = styled.div`
  color: #6C778C;
  font-size: 12px;
  margin-top: 10px;
`

const ConfirmModal = styled(Dialog)`
  width: 544px;
`

type Props = {
    values: any,
    onReset: Function,
}

export function SuccessComponent(props: Props) {
    const {onReset, values} = props

    const {source, target, name} = values || {}

    const [downloaded, setDownloaded] = useState(false)
    const [confirmModal, setConfirmModal] = useState(false)

    const dispatch = useAppDispatch()

    const handleDownload = () => {
      setDownloaded(true)
      dispatch(downloadConvertedFile(name))
    }

    const handleConvertClick = () => {
      // Display warning dialog if no download occured
      if(!downloaded)
        setConfirmModal(true)
      else
        onReset()
    }

    return <ComponentWrapper>
        <img src={onboardingIcon} alt="success-icon" />
        <Title>Success!</Title>
        <TableWrapper>
            <table>
              <tbody>
                <tr>
                    <TableRowCheckmark>
                      <img src={checkmark} />
                    </TableRowCheckmark>
                    <TableRowTitle>Policy Name</TableRowTitle>
                    <TableRowData>{name}</TableRowData>
                </tr>
                <tr>
                    <TableRowCheckmark>
                      <img src={checkmark} />
                    </TableRowCheckmark>
                    <TableRowTitle>Source Type</TableRowTitle>
                    <TableRowData>{getProviderName(source)}</TableRowData>
                </tr>
                <tr>
                    <TableRowCheckmark>
                      <img src={checkmark} />
                    </TableRowCheckmark>
                    <TableRowTitle>Target WAF</TableRowTitle>
                    <TableRowData>{getProviderName(target)}</TableRowData>
                </tr>
              </tbody>
            </table>
        </TableWrapper>
        <DownloadButton onClick={handleDownload}>
          <span>Download</span>
          <Icon icon="app.download" />
        </DownloadButton>
        <DescriptionWrapper>
          Files will include policy file(s) and the conversion reports
        </DescriptionWrapper>
        <ConvertLink onClick={handleConvertClick} size="small" accent displayMode="link">
          Convert another policy
        </ConvertLink>
        <ConfirmModal 
          title="Download file?" 
          open={confirmModal}
          actions={[
            {
              displayMode:"outlined",
                onClick: () => onReset(),
                children: "Continue without download"
            },
            {
              onClick: () => {
                handleDownload()
                onReset()
              },
              children: "Download and continue"
            }
        ]}>
          You have not downloaded the converted file. How would like to proceed?
        </ConfirmModal>
    </ComponentWrapper>
}
