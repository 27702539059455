import {AppDispatch} from "../../store";
import {UsersService} from "core-lib";
import {errorActionRequest} from "../Errors/actions";
import {authSlice} from "./reducer";

export const fetchUser = () => {
    return async (dispatch: AppDispatch) => {
        return UsersService.usersGetUser()
            .then(response => {
                dispatch(authSlice.actions.setUser(response))
            })
    }
}