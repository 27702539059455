import styled from "styled-components";

interface Props {
    height?: number
    width?: number
    fullHeight?: boolean
    fullWidth?: boolean
    flex?: number
    bottomDirection?: boolean
    centerHorizontal?: boolean
    spacing?: number
    center?: boolean
    mt?: number
    mb?: number
}

export const Row = styled.div<Props>`
  display: flex;
  flex-direction: row;
  height: ${props => props.fullHeight ? '100%' : props.height ? props.height+'px' : null};
  width: ${props => props.fullWidth ? '100%' : props.width ? props.width+'px' : null};
  flex: ${props => props.flex};
  gap: ${props => props.spacing ? `${props.spacing}px` : null};
  margin-top: ${props => props.mt ? `${props.mt}px` : null};
  margin-bottom: ${props => props.mb ? `${props.mb}px` : null};
  align-items: ${props => props.center ? 'center' : props.bottomDirection ? 'flex-end' : null};
  justify-content: ${props => props.centerHorizontal ? 'center' : null};
`