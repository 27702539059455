import React, { useCallback, useMemo, useState } from "react";
import styled from "styled-components";
import { Col } from "./Col";
import { Button, Icon } from "@emerald/react";
import { Row } from "./Row";

const Header = styled.div`
  width: 100%;
  padding: 0 25px;
  background: #F7F8FA;
  box-sizing: border-box;
  border-bottom: 1px solid #EAEDEF;
`
const HeaderTitle = styled.div`
font-weight: bold;
font-size: 12px;
line-height: 47px;
color: #181B1F;
text-transform: uppercase;
height: 47px;
`

const BodyContent = styled.div`
  padding-top: 10px;
  padding-left: 25px;
  padding-right 25px;
  margin-bottom: 10px;
  box-sizing: border-box;
  width: 100%;
`

const Body = styled.div`
  width: 100%;
  overflow-y: hidden;
`

type Props = {
    opened?: boolean;
    initialOpened?: boolean;
    children: React.ReactNode;
    title: string | React.ReactNode;
    onToggle?: (opened: boolean) => void;
}

export const Accordion = (props: Props) => {

    const { opened: openedProp, onToggle } = props
    const [isOpened, setIsOpened] = useState(props.initialOpened || false)

    const isPanelOpened = useMemo(() => {
        return openedProp || isOpened
    }, [openedProp, isOpened])

    const onToggleClick = useCallback(() => {
        if (typeof openedProp !== 'undefined') {
            onToggle && onToggle(!openedProp)
        } else {
            setIsOpened(!isOpened)
        }
    }, [openedProp, isOpened, onToggle, setIsOpened])

    const bodyStyle = useMemo(() => {
        return {
            height: isPanelOpened ? 'auto' : 0
        }
    }, [isPanelOpened])

    return <div>
        <Header>
            <Row>
                <Col flex={1}>
                    <Col fullHeight centerVertical>
                        <HeaderTitle>{props.title}</HeaderTitle>
                    </Col>
                </Col>
                <Col rightDirection centerVertical>
                    <Button size='small' displayMode="linkInk" onClick={onToggleClick}>
                        <Icon icon={isPanelOpened ? 'app.chevronUp' : 'app.chevronDown'} size='xs' />
                    </Button>
                </Col>
            </Row>
        </Header>
        <Body style={bodyStyle}><BodyContent>{props.children}</BodyContent></Body>
    </div>
}