import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {
    policyPolicyInfo,
    providerProviderType,
    commandConversionReportResponse,
} from "core-lib";

const initialState = {
    policyForConversion: null as policyPolicyInfo | null,
    commitMessage: '',
    providerTypeSelected: null as providerProviderType | null,
    reportGroupId: '',

    conversionsCommandsIds: {} as {[type: string]: string},
    conversionResult: {} as {[type: string]: commandConversionReportResponse | false},
}

type StateType = typeof initialState;

export const policyConversionSlice = createSlice({
    name: 'POLICY_CONVERSION',
    initialState: initialState as StateType,
    reducers: {
        openPolicyConversion: (state: StateType , action: PayloadAction<policyPolicyInfo | null>) => {
            state.policyForConversion = action.payload;
        },
        setCommitMessage: (state: StateType , action: PayloadAction<string>) => {
            state.commitMessage = action.payload;
        },
        setProviderTypeSelected: (state: StateType , action: PayloadAction<providerProviderType>) => {
            state.providerTypeSelected = action.payload;
        },
        addConversionCommandId: (state: StateType, action: PayloadAction<{type: providerProviderType, commandId: string}>) => {
            state.conversionsCommandsIds = {
                ...state.conversionsCommandsIds,
                [action.payload.type]: action.payload.commandId
            }
        },
        resetConversion: (state: StateType) => {
            state.conversionsCommandsIds = {}
            state.conversionResult = {}
        },
        addConversionResult: (state: StateType , action: PayloadAction<{type: providerProviderType, result: commandConversionReportResponse | false}>) => {
            state.conversionResult = {
                ...state.conversionResult,
                [action.payload.type]: action.payload.result
            }
        },
        setReportGroupId: (state: StateType , action: PayloadAction<string>) => {
            state.reportGroupId = action.payload
        },
        clean: (state: StateType , _action: PayloadAction<void>) => {
            state.policyForConversion = null
            state.commitMessage = ''
            state.conversionsCommandsIds = {}
            state.conversionResult = {}
            state.providerTypeSelected = null
            state.reportGroupId = ''
        }
    }
});
