import React from "react";
import styled from "styled-components";
import {Col} from "../../../components/Col";
import warningIMG from "../../../assets/warning.png";

const IncompleteText = styled.div`
  margin-top: 10px;
  color: #FFC400;
  font-weight: 700;
  font-size: 24px;
  line-height: 36px;
`

export const ConversionFailedComponent = () => {
    return <div>
        <Col center fullWidth style={{marginTop: 20}}>
            <img src={warningIMG} alt='incomplete'/>
            <IncompleteText>Conversion Failed!</IncompleteText>
        </Col>
    </div>
}