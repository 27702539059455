import { Row } from "../../components/Row";
import { Col } from "../../components/Col";
import { Icon } from "@emerald/react";
import { Divider } from "../../components/Divider";
import React, { useCallback, useMemo, useState } from "react";
import styled from "styled-components";
import {
    aggregate_reportConversionReportResponseItem,
    aggregate_reportDeploymentReportResponseItem,
    aggregate_reportReport,
    aggregate_reportReportPolicy,
    providerProviderType, reportReportType
} from "core-lib";
import { POLICIES_PROVIDERS_INFO, REPORT_TYPES_NAMES } from "../../constants";
import moment from "moment";
import { Tag } from "../../components/Tag";
import { Stack } from "../../components/Stack";
import { CollapsiblePanel } from "../../components/CollapsiblePanel";
import { ConversionReportGroupedComponent } from "../../core/components/Conversion/ConversionReportGroupedComponent";
import { Link } from "../../components/styled/Link";
import { LOCATIONS, PATHS } from "../../navigation";

const SectionHeader = styled(Row)`
  color: #181B1F;
  background: #F7F8FA;
  font-weight: 600;
  font-size: 12px;
  line-height: 22px;
  align-items: center;
  padding: 13px 0 13px 25px;
`
const PropTitle = styled(Col)`
  padding: 11px 0 11px 25px;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #6C778C;
`
const PropValue = styled(Col)`
  padding: 11px 0 11px 25px;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #111922;
`
const DeploymentsDiv = styled(Col)`
  padding: 8px 20px;
`
const PanelHeaderHeader = styled.div`
  font-weight: 700;
  font-size: 12px;
  line-height: 18px;
  color: #0F1E57;
  margin-bottom: 5px;
`
const PanelHeaderValue = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #6C778C;
`
const PanelBody = styled.div`
  padding-top: 10px;
  width: 100%;
`
const ColumnsValues = styled.div`
  position: relative;
`
const ColumnValue = styled.div`
  float: left;
  width: 50%;
`
const CollapseAllSpan = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #0F1E57;
  cursor: pointer;
  padding: 5px 0 8px 0;
`
const ErrorText = styled.span`
  margin-left: 10px;
  font-size: 12px;
  line-height: 18px;
`
const ErrorIcon = styled(Icon)`
  vertical-align: top;
`

export const DeploymentPanel = ({ deployment, opened, onToggle }: {
    deployment: aggregate_reportDeploymentReportResponseItem,
    opened: boolean,
    onToggle: (deploymentId: string, opened: boolean) => void
}) => {

    const onTogglePanel = useCallback((isOpened: boolean) => {
        deployment.commandId && onToggle(deployment.commandId, isOpened)
    }, [deployment, onToggle])

    const isError = deployment.status === 'ERROR'
    const borderColor = isError ? '#FFC400' : undefined

    const { endpoints, endpointsTitle } = useMemo(() => {
        switch (deployment.providerType) {
            case providerProviderType.XC:
                return { endpoints: deployment.loadBalancers, endpointsTitle: 'Load Balancers' }
            case providerProviderType.NAP:
                return { endpoints: deployment.servers, endpointsTitle: 'Servers' }
            default:
                return { endpoints: deployment.endpoints, endpointsTitle: 'Endpoints' }
        }
    }, [deployment])

    return <CollapsiblePanel borderColor={borderColor} opened={opened} onToggle={onTogglePanel} holdRightSpace header={<Row>
        <Col flex={1}>
            <PanelHeaderHeader>Provider</PanelHeaderHeader>
            <PanelHeaderValue>{deployment.providerName}</PanelHeaderValue>
        </Col>
        <Col flex={1}>
            <PanelHeaderHeader>Attached</PanelHeaderHeader>
            <PanelHeaderValue>{deployment.attachmentsCount}</PanelHeaderValue>
        </Col>
    </Row>}>
        {isError && <PanelBody>
            <PanelHeaderHeader>
                <ErrorIcon icon='status.warning' /><ErrorText>Deployment Failed</ErrorText>
            </PanelHeaderHeader>
        </PanelBody>}
        {!isError && <PanelBody>
            <PanelHeaderHeader>{endpointsTitle}</PanelHeaderHeader>
            <ColumnsValues>
                {!!endpoints && endpoints.map((endpoint: string) => <ColumnValue key={endpoint}>
                    <PanelHeaderValue>{endpoint}</PanelHeaderValue>
                </ColumnValue>)}
            </ColumnsValues>
        </PanelBody>}
    </CollapsiblePanel>
}

export const ConversionPanel = ({ conversion, opened, onToggle }: {
    conversion: aggregate_reportConversionReportResponseItem,
    opened: boolean,
    onToggle: (deploymentId: string, opened: boolean) => void
}) => {

    const onTogglePanel = useCallback((isOpened: boolean) => {
        conversion.commandId && onToggle(conversion.commandId, isOpened)
    }, [conversion, onToggle])

    const isError = !!conversion.message
    const borderColor = isError ? '#FFC400' : undefined

    return <CollapsiblePanel borderColor={borderColor} opened={opened} onToggle={onTogglePanel} header={<Col>
        <Row><PanelHeaderHeader>Target Provider Type</PanelHeaderHeader></Row>
        <Row><PanelHeaderValue>
            {conversion.targetPolicyType ? POLICIES_PROVIDERS_INFO[conversion.targetPolicyType].name : '-'}
        </PanelHeaderValue></Row>
    </Col>}>
        <PanelBody>
            {isError && <>
                <PanelHeaderHeader>
                    <ErrorIcon icon='status.warning' /><ErrorText>Conversion Failed</ErrorText>
                </PanelHeaderHeader>
                <PanelHeaderValue>{conversion.message}</PanelHeaderValue>
            </>}
            {!isError && !!conversion.report && <ConversionReportGroupedComponent report={conversion.report} />}
        </PanelBody>
    </CollapsiblePanel>
}

type Props = {
    report: aggregate_reportReport,
}

export const ReportDetailsOldView = ({ report }: Props) => {

    const [openedDeployments, setOpenedDeployments] = useState<{ [deploymentId: string]: boolean }>({})
    const [openedConversions, setOpenedConversions] = useState<{ [conversionId: string]: boolean }>({})

    const onDeploymentPanelToggle = useCallback((deploymentId: string, isOpened: boolean) => {
        setOpenedDeployments(prevState => ({
            ...prevState,
            [deploymentId]: isOpened
        }));
    }, [setOpenedDeployments])

    const onCollapseAllDeploymentsClick = useCallback(() => {
        setOpenedDeployments({});
    }, [setOpenedDeployments])

    const onConversionPanelToggle = useCallback((conversionId: string, isOpened: boolean) => {
        setOpenedConversions(prevState => ({
            ...prevState,
            [conversionId]: isOpened
        }));
    }, [setOpenedConversions])

    const onCollapseAllConversionsClick = useCallback(() => {
        setOpenedConversions({});
    }, [setOpenedConversions])

    return <>
        <SectionHeader>GENERAL PROPERTIES</SectionHeader>
        <Row>
            <PropTitle flex={1}>Report Name</PropTitle>
            <PropValue flex={1}>{report?.name ? report?.name : '-'}</PropValue>
        </Row>
        <Row>
            <PropTitle flex={1}>Type</PropTitle>
            <PropValue flex={1}>{report?.type ? REPORT_TYPES_NAMES[report?.type as reportReportType] : '-'}</PropValue>
        </Row>
        <Row>
            <PropTitle flex={1}>Date</PropTitle>
            <PropValue flex={1}>{report?.lastModified ? moment(report.lastModified).format('D MMM YYYY') : '-'}</PropValue>
        </Row>
        <Row>
            <PropTitle flex={1}>Target Provider Type</PropTitle>
            <PropValue flex={1}><Stack direction="row" spacing={5}>
                {report?.providerType ? report?.providerType?.map(
                    (providerType: string) => (
                        <Tag key={providerType} isActionable={false} size='short'>{POLICIES_PROVIDERS_INFO[providerType].name}</Tag>
                    )
                ) : '-'}
            </Stack></PropValue>
        </Row>
        {report?.policies && report.policies.map((reportItem: aggregate_reportReportPolicy) => (
            <div key={reportItem.policyId}>
                <Divider mt={20} mb={20} />
                <Row mb={20}>
                    <PropTitle flex={1}><PanelHeaderHeader>Policy</PanelHeaderHeader></PropTitle>
                    <PropValue flex={1}>{reportItem.policyName ? <Link to={{
                        pathname: PATHS[LOCATIONS.policies],
                        hash: `#${reportItem.policyId}`
                    }}>{reportItem.policyName}</Link> : '-'}</PropValue>
                </Row>
                {!!reportItem?.deploymentReports && reportItem?.deploymentReports.length > 0 && <>
                    <SectionHeader>DEPLOYMENT</SectionHeader>
                    <DeploymentsDiv>
                        <Row>
                            <Col rightDirection fullWidth>
                                <CollapseAllSpan onClick={onCollapseAllDeploymentsClick}>Collapse all</CollapseAllSpan>
                            </Col>
                        </Row>
                        <Stack direction="column" spacing={8}>
                            {reportItem?.deploymentReports.map(deployment =>
                                <DeploymentPanel
                                    key={deployment.commandId}
                                    deployment={deployment}
                                    opened={deployment.commandId ? openedDeployments[deployment.commandId] || false : false}
                                    onToggle={onDeploymentPanelToggle}
                                />)
                            }
                        </Stack>
                    </DeploymentsDiv>
                </>}
                <SectionHeader>CONVERSION</SectionHeader>
                {!!reportItem?.conversionReports && [reportItem?.conversionReports].length > 0 && <>
                    <DeploymentsDiv>
                        <Row>
                            <Col rightDirection fullWidth>
                                <CollapseAllSpan onClick={onCollapseAllConversionsClick}>Collapse all</CollapseAllSpan>
                            </Col>
                        </Row>
                        <Stack direction="column" spacing={8}>
                            {reportItem?.conversionReports.map(conversion => <ConversionPanel
                                key={conversion.commandId}
                                conversion={conversion}
                                opened={conversion.commandId ? openedConversions[conversion.commandId] || false : false}
                                onToggle={onConversionPanelToggle}
                            />)}
                        </Stack>
                    </DeploymentsDiv>
                </>}
            </div>))}
    </>
}