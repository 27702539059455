import React from "react";
import {Row} from "../../../components/Row";
import styled from "styled-components";
import {Col} from "../../../components/Col";
import {
    commandConversionLog,
    commandConversionReportResponse,
    conversionLogItemStatus
} from "core-lib";
import {Dot} from "../../../components/Dot";

const ResultCountCol = styled(Col)`
  padding: 0 30px 15px 10px;
`
const ResultTypeLabel = styled.div`
  font-size: 12px;
`
const ResultTypeNumber = styled.div<{color: string}>`
  font-size: 24px;
  line-height: 36px;
  font-weight: bold;
  color: ${props => props.color};
`
const ResultsHeader = styled(Col)`
  font-size: 10px;
  line-height: 16px;
  letter-spacing: 0.5px;
  background-color: #F7F8FA;
  padding: 9px 10px;
`
const ResultLine = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  vertical-align: center;
  word-wrap: anywhere;
  white-space: pre-wrap;
`
const ResultRowStyled = styled(Row)`
  margin-top: 10px;
`

export const ConversionReportComponent = (props: {
    report: commandConversionReportResponse,
}) => {
    return <>
        <Row style={{marginTop: 20}} fullWidth>
            <ResultCountCol flex={1}>
                <ResultTypeLabel>Full Support</ResultTypeLabel>
                <ResultTypeNumber color='#11BD8D'>{props.report.ConversionSummary?.FullConvert}</ResultTypeNumber>
            </ResultCountCol>
            <ResultCountCol flex={1}>
                <ResultTypeLabel>Partial Support</ResultTypeLabel>
                <ResultTypeNumber color='#E69B08'>{props.report.ConversionSummary?.PartialConvert}</ResultTypeNumber>
            </ResultCountCol>
            <ResultCountCol flex={1}>
                <ResultTypeLabel>No Support</ResultTypeLabel>
                <ResultTypeNumber color='#9EA7B8'>{props.report.ConversionSummary?.NoneConvert}</ResultTypeNumber>
            </ResultCountCol>
        </Row>
        <Row fullWidth>
            <ResultsHeader flex={1}>ITEM</ResultsHeader>
            <ResultsHeader flex={1}>DESCRIPTION</ResultsHeader>
        </Row>
        {props.report.ConversionLogs?.map(
            logItem => <ResultRow key={logItem.Name} logItem={logItem}/>
        )}
    </>
}

const ResultRow = (props: {
    logItem: commandConversionLog,
}) => {

    const {logItem} = props
    const color = logItem.Status === conversionLogItemStatus.NONE ? '#9EA7B8' :
        (logItem.Status === conversionLogItemStatus.PARTIAL ? '#E69B08' : '#11BD8D')

    return <ResultRowStyled spacing={5}>
        <Col flex={1}>
            <Row>
                <Col>
                    <ResultLine>
                        <Dot size={10} color={color} style={{marginLeft: 10, marginRight: 5}}/>
                    </ResultLine>
                </Col>
                <Col flex={1}>
                    <ResultLine>
                            {logItem.Name}
                    </ResultLine>
                </Col>
            </Row>
        </Col>
        <Col flex={1}><ResultLine>{logItem.Description}</ResultLine></Col>
    </ResultRowStyled>
}