import {
    BodyForm,
    Header,
    HeaderHeader,
    HeaderSubText,
    Legend
} from "../../../components/styled/Drawer";
import {Divider} from "../../../components/Divider";
import {Align, Button, Icon} from "@emerald/react";
import {Col} from "../../../components/Col";
import {Row} from "../../../components/Row";
import React from "react";
import {useSelector} from "react-redux";
import {
    commitMessageSelector,
    policyDeployPolicySelector, reportGroupIdSelector
} from "../selectors";
import styled from "styled-components";
import { Link } from "../../../components/styled/Link";
import onboardingIMG from "../../../assets/onboarding.png";
import {LOCATIONS, PATHS} from "../../../navigation";
import {ScrollableContent} from "../../../components/PageScrollable";

const CenterRow = styled(Row)`
  justify-content: center;
`
const ReportText = styled.div`
  padding: 40px 0;
`

const SuccessText = styled.div`
  color: #3183B2;
  font-weight: 700;
  font-size: 24px;
  line-height: 36px;
`

type Props = {
    onBack: () => void,
    onNext: () => void
}

export const ConversionResultScreen = (props: Props) => {

    const policy = useSelector(policyDeployPolicySelector)
    const reportGroupId = useSelector(reportGroupIdSelector)
    const commitMessage = useSelector(commitMessageSelector)

    return <BodyForm>
        <Header>
            <HeaderHeader>Conversion Report</HeaderHeader>
            <HeaderSubText>Brief descriptoin letting the user know they can continue working while conversion is
                continuing in the background</HeaderSubText>
        </Header>
        <ScrollableContent>
            <Divider mb={17}></Divider>
            <Legend>Policy: {policy?.metadata?.name}</Legend>
            <div>
                <Col center mt={40} mb={40}>
                    <div><img src={onboardingIMG} alt='success'/></div>
                    <div><SuccessText>Conversion successed!</SuccessText></div>
                </Col>
                <CenterRow>
                    <ReportText>
                        Conversion(s) saved as: <Link target="_blank"
                                                        to={`${PATHS[LOCATIONS.reports]}#${reportGroupId}`}>
                        {commitMessage} <Icon icon='app.externalLink'/>
                    </Link>
                    </ReportText>
                </CenterRow>
            </div>
            <Col flex={1} center>
                <Row spacing={20} style={{marginTop: 10}}>
                    <Align>
                        <Button accent={true} onClick={props.onNext}>Continue Deployment</Button>
                    </Align>
                </Row>
            </Col>
        </ScrollableContent>
    </BodyForm>
}