import {
    BodyForm, Footer,
    Header,
    HeaderHeader,
    Legend
} from "../../components/styled/Drawer";
import {Divider} from "../../components/Divider";
import {Row} from "../../components/Row";
import React from "react";
import styled from "styled-components";
import {Col} from "../../components/Col";
import {Align, Button} from "@emerald/react";
import {ScrollableContent} from "../../components/PageScrollable";
import {ConversionFailedComponent} from "../../core/components/Conversion/ConversionFailedComponent";

const BoldText = styled.span`
  font-weight: bold;
`

type Props = {
    onGoToOverviewClick: () => void,
    onTryAgainClick: () => void,
    message: string
}

export const ImportPolicyErrorComponent = (props: Props) => {

    return <BodyForm>
        <Header>
            <HeaderHeader><BoldText>Conversion Failed</BoldText></HeaderHeader>
        </Header>
        <ScrollableContent>
            <Divider mb={20}></Divider>
            <Row mb={20}>
                <Legend>{props.message}</Legend>
            </Row>
            <ConversionFailedComponent />
        </ScrollableContent>
        <Footer>
            <Divider/>
            <Col flex={1} rightDirection>
                <Row spacing={20} style={{marginTop: 10}}>
                    <Align>
                        <Button accent={true} displayMode="outlined" onClick={props.onGoToOverviewClick}>Go
                            to Overview</Button>
                    </Align>
                    <Align>
                        <Button accent={true} onClick={props.onTryAgainClick}>Try other</Button>
                    </Align>
                </Row>
            </Col>
        </Footer>
    </BodyForm>
}