import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {deploymentDeploymentInfo, deploymentListDeploymentResponse} from "core-lib";

const initialState = {
    data: {
        page: {index: 0, size: 0},
        totalCount: '0',
        deployments: []
    } as deploymentListDeploymentResponse
}

type StateType = typeof initialState;

export const deploymentsListSlice = createSlice({
    name: 'DEPLOYMENTS_LIST',
    initialState: initialState as StateType,
    reducers: {
        loadDeployments: (state: StateType , action: PayloadAction<deploymentListDeploymentResponse>) => {
            state.data = action.payload;
        },
        addDeployment: (state: StateType , action: PayloadAction<deploymentDeploymentInfo>) => {
            if(state.data.deployments) {
                state.data.deployments.push(action.payload)
            } else {
                state.data.deployments = [action.payload]
            }
        },
        removeDeployment: (state: StateType , action: PayloadAction<string>) => {
            if(state.data.deployments) {
                state.data.deployments = state.data.deployments.filter(deployment => deployment.id !== action.payload)
            }
        }
    }
});
