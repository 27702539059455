import React, {useEffect, useRef, useState} from 'react'
import {getElapsedTimeString} from "core-lib";

export function ElapsedTimeComponent({time} : {time: number}) {

    const [componentElapsedTime, setComponentElapsedTime] = useState(time)
    const interval = useRef<ReturnType<typeof setInterval> | null>(null);

    useEffect(() => {
        interval.current && clearInterval(interval.current)
        interval.current = setInterval(() => {
            setComponentElapsedTime(Date.now() - time)
        }, 1000)

        return () => {
            interval.current && clearInterval(interval.current)
        }
    }, [time]);

    return <span>{getElapsedTimeString(componentElapsedTime)}</span>
}
