import { Row } from "../../components/Row";
import { Col } from "../../components/Col";
import React, { useCallback, useMemo, useState } from "react";
import styled from "styled-components";
import { useAppDispatch } from "../../store";
import {
    aggregate_reportConversionReportResponseItem,
    aggregate_reportDeploymentReportResponseItem,
    aggregate_reportReport,
    aggregate_reportReportPolicy,
    commandConversionReportResponse,
    providerProviderType, reportReportType
} from "core-lib";
import { downloadConversionLogs } from "../ConversionsList/actions";
import { POLICIES_PROVIDERS_INFO, PROVIDERS_INFO, REPORT_TYPES_NAMES } from "../../constants";
import moment from "moment";
import { CollapsiblePanel } from "../../components/CollapsiblePanel";
import { ConversionReportNewComponent } from "../../core/components/Conversion/ConversionReportNewComponent";
import { Link } from "../../components/styled/Link";
import { LOCATIONS, PATHS } from "../../navigation";
import { conversionReportError, useGetConversionReportErrorData } from "../../core/components/Conversion/util/useGetConversionReportErrorData";
import { ConversionReportErrorComponent } from "../../core/components/Conversion/ConversionReportErrorComponent";

const SectionHeader = styled(Row)`
  color: #181B1F;
  background: #F7F8FA;
  font-weight: 600;
  font-size: 12px;
  line-height: 22px;
  align-items: center;
  padding: 13px 0 13px 25px;
`
const PropTitle = styled(Col)`
  padding: 11px 0;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #6C778C;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  display: block;
`
const PropValue = styled(Col)`
  padding: 11px 0;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #111922;
`

const DeploymentPropValue = styled(Col)`
  padding: 11px 0;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #6C778C;
  word-break: break-word;
  display: block;
`

const DeploymentPropTitle = styled(Col)`
  padding: 11px 0;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  color: #0F1E57;
`

const PanelHeaderHeader = styled.div`
  font-weight: 700;
  font-size: 12px;
  line-height: 18px;
  color: #0F1E57;
  margin-top: 5px;
  margin-left: 6px;
`

const PanelBody = styled.div`
  padding-top: 10px;
  width: 100%;
`

const PolicyContainer = styled.span`
  background: #F7F8FA;
  border-radius: 4px 0px 0px 4px;
  height: 32px;
  line-height: 32px;
  font-size: 12px;
  padding: 0 10px;
`

const ProviderContainer = styled.span`
  background: #E6E9F3;
  border-radius: 0px 4px 4px 0px;
  height: 32px;
  line-height: 32px;
  font-size: 12px;
  padding: 0 10px;
`

const ConversionsDiv = styled(Col)`
padding: 22px 11px;
`

const TextContainer = styled.div`
max-width: 250px;
overflow: hidden;
text-overflow: ellipsis;
white-space: nowrap;
`

export const ConversionPanel = ({ reportName, policyName, conversion, opened, onToggle, warnings }: {
    warnings: conversionReportError[]
    conversion: aggregate_reportConversionReportResponseItem,
    opened: boolean,
    policyName: string,
    reportName: string,
    onToggle: (deploymentId: string, opened: boolean) => void
}) => {

    const dispatch = useAppDispatch()
    const onTogglePanel = useCallback((isOpened: boolean) => {
        conversion.commandId && onToggle(conversion.commandId, isOpened)
    }, [conversion, onToggle])

    const onExport = useCallback(() => {
            dispatch(downloadConversionLogs(conversion?.commandId || '', `${reportName} ${policyName} ${conversion.targetPolicyType} conversion.zip`))
        }, [conversion, dispatch, reportName, downloadConversionLogs, policyName])

    return <CollapsiblePanel holdRightSpace={false} opened={opened} alwaysShowBody onExport={onExport} onToggle={onTogglePanel} header={<Col>
        <Row><PanelHeaderHeader>
            {conversion.targetPolicyType ? POLICIES_PROVIDERS_INFO[conversion.targetPolicyType].name : '-'}
        </PanelHeaderHeader></Row>
    </Col>}>
        <PanelBody>
            <ConversionReportNewComponent warnings={warnings} report={conversion.report} hideContentSection={!opened} />
        </PanelBody>
    </CollapsiblePanel>
}

type Props = {
    report: aggregate_reportReport,
}

export const ReportDetailsNewView = ({ report }: Props) => {
    const [openedConversions, setOpenedConversions] = useState<{ [conversionId: string]: boolean }>({})

    const onConversionPanelToggle = useCallback((conversionId: string, isOpened: boolean) => {
        setOpenedConversions(prevState => ({
            ...prevState,
            [conversionId]: isOpened
        }));
    }, [setOpenedConversions])

    const getDeploymentAttachementDetail = (deployment: aggregate_reportDeploymentReportResponseItem) => {
        switch (deployment.providerType) {
            case providerProviderType.XC:
                return deployment.loadBalancers
            case providerProviderType.NAP:
                return deployment.servers
            default:
                return deployment.endpoints
        }
    }

    const conversionReports: { commandId: string | undefined, report: commandConversionReportResponse | undefined}[] = useMemo(() => (report?.policies || []).flatMap(
        (conversionReportItem) => (conversionReportItem?.conversionReports || []).flatMap(
            (reportItem) => ({ commandId: reportItem.commandId, report: reportItem.report })))
        , [report])

    const { warnings, errors } = useGetConversionReportErrorData(conversionReports.map(item => item.report))

    return <>
        <ConversionReportErrorComponent ml={25} mr={18} mb={44} errors={errors} type="ERROR" />
        {report?.policies && report.policies.map((reportItem: aggregate_reportReportPolicy) => {
            const sourceProviderType = reportItem?.conversionReports?.[0]?.report?.ConversionSummary?.Source

            return (
                <div key={reportItem.policyId}>
                    <Col mb={13}>
                        <SectionHeader mb={13}>GENERAL PROPERTIES</SectionHeader>
                        <Row spacing={36}>
                            <PropTitle ml={25} width={200}>Report Name</PropTitle>
                            <PropValue flex={1}><TextContainer>{report?.name ? report?.name : '-'}</TextContainer></PropValue>
                        </Row>
                        <Row spacing={36}>
                            <PropTitle ml={25} width={200}>Type</PropTitle>
                            <PropValue flex={1}>{report?.type ? REPORT_TYPES_NAMES[report?.type as reportReportType] : '-'}</PropValue>
                        </Row>
                        <Row spacing={36}>
                            <PropTitle ml={25} width={200}>Date</PropTitle>
                            <PropValue flex={1}>{report?.lastModified ? moment(report.lastModified).format('D MMM YYYY') : '-'}</PropValue>
                        </Row>
                        <Row spacing={36}>
                            <PropTitle ml={25} width={200}>Policy / Source Provider</PropTitle>
                            <Row>
                                <PolicyContainer><Link to={{
                                    pathname: PATHS[LOCATIONS.policies],
                                    hash: `#${reportItem.policyId}`
                                }}><TextContainer>{reportItem.policyName}</TextContainer></Link></PolicyContainer>
                                <ProviderContainer>{POLICIES_PROVIDERS_INFO[sourceProviderType || '']?.name}</ProviderContainer>
                            </Row>
                        </Row>
                    </Col>
                    {!!reportItem?.deploymentReports && reportItem?.deploymentReports.length > 0 &&
                        <Col mb={13}>
                            <SectionHeader mb={13}>DEPLOYMENT</SectionHeader>
                            <Row spacing={36}>
                                <DeploymentPropTitle ml={25} width={200}>Provider/Type</DeploymentPropTitle>
                                <DeploymentPropTitle width={200}>Attachment</DeploymentPropTitle>
                                <DeploymentPropTitle width={200}>Attachment Detail</DeploymentPropTitle>
                            </Row>
                            {reportItem?.deploymentReports.map(deployment =>
                                <Row key={deployment.commandId} spacing={36}>
                                    <Col ml={25} width={200}>
                                        <DeploymentPropValue>{deployment.providerName} / {PROVIDERS_INFO[deployment.providerType || '']?.name}</DeploymentPropValue>
                                    </Col>
                                    <DeploymentPropValue width={200}>{deployment.attachmentsCount}</DeploymentPropValue>
                                    <Col flex={1}>
                                        <DeploymentPropValue>{(getDeploymentAttachementDetail(deployment) || []).join(", ")}</DeploymentPropValue>
                                    </Col>
                                </Row>
                            )}
                        </Col>}
                    <SectionHeader>CONVERSION</SectionHeader>
                    {!!reportItem?.conversionReports && [reportItem?.conversionReports].length > 0 && <>
                        <ConversionsDiv spacing={27}>
                            {reportItem?.conversionReports.map(conversion => {
                            const warningsIndex = conversionReports.findIndex((item) => item.commandId === conversion.commandId)
                            const conversionWarnings = warnings[warningsIndex] || []
                            
                            return <ConversionPanel
                                key={conversion.commandId}
                                conversion={conversion}
                                policyName={reportItem?.policyName || ''}
                                reportName={report?.name || ''}
                                warnings={conversionWarnings}
                                opened={conversion.commandId ? openedConversions[conversion.commandId] || false : false}
                                onToggle={onConversionPanelToggle}
                            />
                            })}
                        </ConversionsDiv>
                    </>}
                </div >)
        }
        )}
    </>
}