/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { apiHttpBody } from '../models/apiHttpBody';
import type { cdpBaseAppSecPolicyResponse } from '../models/cdpBaseAppSecPolicyResponse';
import type { cdpLoggingCustomizationResponse } from '../models/cdpLoggingCustomizationResponse';
import type { cdpOverlayProtocolsResponse } from '../models/cdpOverlayProtocolsResponse';
import type { cdpSignatureSetsResponse } from '../models/cdpSignatureSetsResponse';
import type { cdpTrustListsResponse } from '../models/cdpTrustListsResponse';
import type { policyBaseAppSecPolicyList } from '../models/policyBaseAppSecPolicyList';
import type { policyListResponse } from '../models/policyListResponse';
import type { policyLoggingCustomizationList } from '../models/policyLoggingCustomizationList';
import type { policyOverlayProtocolsList } from '../models/policyOverlayProtocolsList';
import type { policyPolicy } from '../models/policyPolicy';
import type { policyPolicyList } from '../models/policyPolicyList';
import type { policySignatureSetsList } from '../models/policySignatureSetsList';
import type { policyTrustListsList } from '../models/policyTrustListsList';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class PoliciesService {

    /**
     * @returns policyBaseAppSecPolicyList A successful response.
     * @throws ApiError
     */
    public static policiesGetBasePolicy(): CancelablePromise<policyBaseAppSecPolicyList> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/base/policies',
            errors: {
                400: `Invalid argument`,
                401: `Returned when the user does not have permission to access the resource.`,
                404: `Resource was not found.`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * @returns policyPolicyList A successful response.
     * @throws ApiError
     */
    public static policiesGetBasePolicyExport(): CancelablePromise<policyPolicyList> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/base/policies/export',
            errors: {
                400: `Invalid argument`,
                401: `Returned when the user does not have permission to access the resource.`,
                404: `Resource was not found.`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * @returns policyLoggingCustomizationList A successful response.
     * @throws ApiError
     */
    public static policiesGetBaseLoggingCustomization(): CancelablePromise<policyLoggingCustomizationList> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/base/policies/loggingCustomization',
            errors: {
                400: `Invalid argument`,
                401: `Returned when the user does not have permission to access the resource.`,
                404: `Resource was not found.`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * @returns policyOverlayProtocolsList A successful response.
     * @throws ApiError
     */
    public static policiesGetBaseOverlayProtocols(): CancelablePromise<policyOverlayProtocolsList> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/base/policies/overlayProtocols',
            errors: {
                400: `Invalid argument`,
                401: `Returned when the user does not have permission to access the resource.`,
                404: `Resource was not found.`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * @returns policySignatureSetsList A successful response.
     * @throws ApiError
     */
    public static policiesGetBaseSignatureSets(): CancelablePromise<policySignatureSetsList> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/base/policies/signatureSets',
            errors: {
                400: `Invalid argument`,
                401: `Returned when the user does not have permission to access the resource.`,
                404: `Resource was not found.`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * @returns policyTrustListsList A successful response.
     * @throws ApiError
     */
    public static policiesGetBaseTrustLists(): CancelablePromise<policyTrustListsList> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/base/policies/trustLists',
            errors: {
                400: `Invalid argument`,
                401: `Returned when the user does not have permission to access the resource.`,
                404: `Resource was not found.`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Logging Customization
     * @param orderName
     * @param orderStatus
     * @param orderCreatedAt
     * @param orderUpdatedAt
     * @param filterName
     * @param filterStatus
     * @param filterLabel
     * @param page
     * @param size
     * @returns policyListResponse A successful response.
     * @throws ApiError
     */
    public static policiesListLoggingCustomization(
        orderName: 'DESCENDING' | 'ASCENDING' = 'DESCENDING',
        orderStatus: 'DESCENDING' | 'ASCENDING' = 'DESCENDING',
        orderCreatedAt: 'DESCENDING' | 'ASCENDING' = 'DESCENDING',
        orderUpdatedAt: 'DESCENDING' | 'ASCENDING' = 'DESCENDING',
        filterName?: Array<string>,
        filterStatus?: Array<string>,
        filterLabel?: Array<string>,
        page?: number,
        size?: number,
    ): CancelablePromise<policyListResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/loggingCustomization',
            query: {
                'order.name': orderName,
                'order.status': orderStatus,
                'order.createdAt': orderCreatedAt,
                'order.updatedAt': orderUpdatedAt,
                'filter.name': filterName,
                'filter.status': filterStatus,
                'filter.label': filterLabel,
                'page': page,
                'size': size,
            },
            errors: {
                400: `Invalid argument`,
                401: `Returned when the user does not have permission to access the resource.`,
                404: `Resource was not found.`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * @param body
     * @returns cdpLoggingCustomizationResponse A successful response.
     * @throws ApiError
     */
    public static policiesCreateLoggingCustomization(
        body: cdpLoggingCustomizationResponse,
    ): CancelablePromise<cdpLoggingCustomizationResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/loggingCustomization',
            body: body,
            errors: {
                400: `Invalid argument`,
                401: `Returned when the user does not have permission to access the resource.`,
                404: `Resource was not found.`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * @param body
     * @returns cdpLoggingCustomizationResponse A successful response.
     * @throws ApiError
     */
    public static policiesUpdateLoggingCustomization(
        body: cdpLoggingCustomizationResponse,
    ): CancelablePromise<cdpLoggingCustomizationResponse> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/v1/loggingCustomization',
            body: body,
            errors: {
                400: `Invalid argument`,
                401: `Returned when the user does not have permission to access the resource.`,
                404: `Resource was not found.`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * @param id
     * @returns cdpLoggingCustomizationResponse A successful response.
     * @throws ApiError
     */
    public static policiesGetLoggingCustomization(
        id: string,
    ): CancelablePromise<cdpLoggingCustomizationResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/loggingCustomization/{id}',
            path: {
                'id': id,
            },
            errors: {
                400: `Invalid argument`,
                401: `Returned when the user does not have permission to access the resource.`,
                404: `Resource was not found.`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * @param id
     * @param force
     * @returns any A successful response.
     * @throws ApiError
     */
    public static policiesDeleteLoggingCustomization(
        id: string,
        force?: boolean,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/v1/loggingCustomization/{id}',
            path: {
                'id': id,
            },
            query: {
                'force': force,
            },
            errors: {
                400: `Invalid argument`,
                401: `Returned when the user does not have permission to access the resource.`,
                404: `Resource was not found.`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * @param orderName
     * @param orderStatus
     * @param orderCreatedAt
     * @param orderUpdatedAt
     * @param filterName
     * @param filterStatus
     * @param filterLabel
     * @param page
     * @param size
     * @returns policyListResponse A successful response.
     * @throws ApiError
     */
    public static policiesListOverlayProtocols(
        orderName: 'DESCENDING' | 'ASCENDING' = 'DESCENDING',
        orderStatus: 'DESCENDING' | 'ASCENDING' = 'DESCENDING',
        orderCreatedAt: 'DESCENDING' | 'ASCENDING' = 'DESCENDING',
        orderUpdatedAt: 'DESCENDING' | 'ASCENDING' = 'DESCENDING',
        filterName?: Array<string>,
        filterStatus?: Array<string>,
        filterLabel?: Array<string>,
        page?: number,
        size?: number,
    ): CancelablePromise<policyListResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/overlayProtocols',
            query: {
                'order.name': orderName,
                'order.status': orderStatus,
                'order.createdAt': orderCreatedAt,
                'order.updatedAt': orderUpdatedAt,
                'filter.name': filterName,
                'filter.status': filterStatus,
                'filter.label': filterLabel,
                'page': page,
                'size': size,
            },
            errors: {
                400: `Invalid argument`,
                401: `Returned when the user does not have permission to access the resource.`,
                404: `Resource was not found.`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * @param body
     * @returns cdpOverlayProtocolsResponse A successful response.
     * @throws ApiError
     */
    public static policiesCreateOverlayProtocols(
        body: cdpOverlayProtocolsResponse,
    ): CancelablePromise<cdpOverlayProtocolsResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/overlayProtocols',
            body: body,
            errors: {
                400: `Invalid argument`,
                401: `Returned when the user does not have permission to access the resource.`,
                404: `Resource was not found.`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * @param body
     * @returns cdpOverlayProtocolsResponse A successful response.
     * @throws ApiError
     */
    public static policiesUpdateOverlayProtocols(
        body: cdpOverlayProtocolsResponse,
    ): CancelablePromise<cdpOverlayProtocolsResponse> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/v1/overlayProtocols',
            body: body,
            errors: {
                400: `Invalid argument`,
                401: `Returned when the user does not have permission to access the resource.`,
                404: `Resource was not found.`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * @param id
     * @returns cdpOverlayProtocolsResponse A successful response.
     * @throws ApiError
     */
    public static policiesGetOverlayProtocols(
        id: string,
    ): CancelablePromise<cdpOverlayProtocolsResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/overlayProtocols/{id}',
            path: {
                'id': id,
            },
            errors: {
                400: `Invalid argument`,
                401: `Returned when the user does not have permission to access the resource.`,
                404: `Resource was not found.`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * @param id
     * @param force
     * @returns any A successful response.
     * @throws ApiError
     */
    public static policiesDeleteOverlayProtocols(
        id: string,
        force?: boolean,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/v1/overlayProtocols/{id}',
            path: {
                'id': id,
            },
            query: {
                'force': force,
            },
            errors: {
                400: `Invalid argument`,
                401: `Returned when the user does not have permission to access the resource.`,
                404: `Resource was not found.`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * @param orderName
     * @param orderStatus
     * @param orderCreatedAt
     * @param orderUpdatedAt
     * @param filterName
     * @param filterStatus
     * @param filterLabel
     * @param page
     * @param size
     * @returns policyListResponse A successful response.
     * @throws ApiError
     */
    public static policiesListPolicies(
        orderName: 'DESCENDING' | 'ASCENDING' = 'DESCENDING',
        orderStatus: 'DESCENDING' | 'ASCENDING' = 'DESCENDING',
        orderCreatedAt: 'DESCENDING' | 'ASCENDING' = 'DESCENDING',
        orderUpdatedAt: 'DESCENDING' | 'ASCENDING' = 'DESCENDING',
        filterName?: Array<string>,
        filterStatus?: Array<string>,
        filterLabel?: Array<string>,
        page?: number,
        size?: number,
    ): CancelablePromise<policyListResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/policies',
            query: {
                'order.name': orderName,
                'order.status': orderStatus,
                'order.createdAt': orderCreatedAt,
                'order.updatedAt': orderUpdatedAt,
                'filter.name': filterName,
                'filter.status': filterStatus,
                'filter.label': filterLabel,
                'page': page,
                'size': size,
            },
            errors: {
                400: `Invalid argument`,
                401: `Returned when the user does not have permission to access the resource.`,
                404: `Resource was not found.`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * @param body
     * @returns cdpBaseAppSecPolicyResponse A successful response.
     * @throws ApiError
     */
    public static policiesCreatePolicy(
        body: cdpBaseAppSecPolicyResponse,
    ): CancelablePromise<cdpBaseAppSecPolicyResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/policies',
            body: body,
            errors: {
                400: `Invalid argument`,
                401: `Returned when the user does not have permission to access the resource.`,
                404: `Resource was not found.`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * @param body
     * @returns cdpBaseAppSecPolicyResponse A successful response.
     * @throws ApiError
     */
    public static policiesUpdatePolicy(
        body: cdpBaseAppSecPolicyResponse,
    ): CancelablePromise<cdpBaseAppSecPolicyResponse> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/v1/policies',
            body: body,
            errors: {
                400: `Invalid argument`,
                401: `Returned when the user does not have permission to access the resource.`,
                404: `Resource was not found.`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * @param id
     * @returns policyPolicy A successful response.
     * @throws ApiError
     */
    public static policiesExportPolicy(
        id: string,
    ): CancelablePromise<policyPolicy> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/policies/export/{id}',
            path: {
                'id': id,
            },
            errors: {
                400: `Invalid argument`,
                401: `Returned when the user does not have permission to access the resource.`,
                404: `Resource was not found.`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * @param body
     * @returns policyPolicy A successful response.
     * @throws ApiError
     */
    public static policiesImportPolicy(
        body: policyPolicy,
    ): CancelablePromise<policyPolicy> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/policies/import',
            body: body,
            errors: {
                400: `Invalid argument`,
                401: `Returned when the user does not have permission to access the resource.`,
                404: `Resource was not found.`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * @param id
     * @returns cdpBaseAppSecPolicyResponse A successful response.
     * @throws ApiError
     */
    public static policiesGetPolicy(
        id: string,
    ): CancelablePromise<cdpBaseAppSecPolicyResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/policies/{id}',
            path: {
                'id': id,
            },
            errors: {
                400: `Invalid argument`,
                401: `Returned when the user does not have permission to access the resource.`,
                404: `Resource was not found.`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * @param id
     * @param force
     * @returns any A successful response.
     * @throws ApiError
     */
    public static policiesDeletePolicy(
        id: string,
        force?: boolean,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/v1/policies/{id}',
            path: {
                'id': id,
            },
            query: {
                'force': force,
            },
            errors: {
                400: `Invalid argument`,
                401: `Returned when the user does not have permission to access the resource.`,
                404: `Resource was not found.`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * @param body
     * @returns cdpBaseAppSecPolicyResponse A successful response.
     * @throws ApiError
     */
    public static policiesCreatePreConvertedPolicy(
        body: cdpBaseAppSecPolicyResponse,
    ): CancelablePromise<cdpBaseAppSecPolicyResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/pre',
            body: body,
            errors: {
                400: `Invalid argument`,
                401: `Returned when the user does not have permission to access the resource.`,
                404: `Resource was not found.`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * PreConverted Data
     * @param id
     * @param target
     * @param ref
     * @returns apiHttpBody A successful response.
     * @throws ApiError
     */
    public static policiesGetPreConvertedData(
        id: string,
        target?: string,
        ref?: string,
    ): CancelablePromise<apiHttpBody> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/preConvertedData/{id}',
            path: {
                'id': id,
            },
            query: {
                'target': target,
                'ref': ref,
            },
            errors: {
                400: `Invalid argument`,
                401: `Returned when the user does not have permission to access the resource.`,
                404: `Resource was not found.`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * @param orderName
     * @param orderStatus
     * @param orderCreatedAt
     * @param orderUpdatedAt
     * @param filterName
     * @param filterStatus
     * @param filterLabel
     * @param page
     * @param size
     * @returns policyListResponse A successful response.
     * @throws ApiError
     */
    public static policiesListSignatureSets(
        orderName: 'DESCENDING' | 'ASCENDING' = 'DESCENDING',
        orderStatus: 'DESCENDING' | 'ASCENDING' = 'DESCENDING',
        orderCreatedAt: 'DESCENDING' | 'ASCENDING' = 'DESCENDING',
        orderUpdatedAt: 'DESCENDING' | 'ASCENDING' = 'DESCENDING',
        filterName?: Array<string>,
        filterStatus?: Array<string>,
        filterLabel?: Array<string>,
        page?: number,
        size?: number,
    ): CancelablePromise<policyListResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/signatureSets',
            query: {
                'order.name': orderName,
                'order.status': orderStatus,
                'order.createdAt': orderCreatedAt,
                'order.updatedAt': orderUpdatedAt,
                'filter.name': filterName,
                'filter.status': filterStatus,
                'filter.label': filterLabel,
                'page': page,
                'size': size,
            },
            errors: {
                400: `Invalid argument`,
                401: `Returned when the user does not have permission to access the resource.`,
                404: `Resource was not found.`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * @param body
     * @returns cdpSignatureSetsResponse A successful response.
     * @throws ApiError
     */
    public static policiesCreateSignatureSets(
        body: cdpSignatureSetsResponse,
    ): CancelablePromise<cdpSignatureSetsResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/signatureSets',
            body: body,
            errors: {
                400: `Invalid argument`,
                401: `Returned when the user does not have permission to access the resource.`,
                404: `Resource was not found.`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * @param body
     * @returns cdpSignatureSetsResponse A successful response.
     * @throws ApiError
     */
    public static policiesUpdateSignatureSets(
        body: cdpSignatureSetsResponse,
    ): CancelablePromise<cdpSignatureSetsResponse> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/v1/signatureSets',
            body: body,
            errors: {
                400: `Invalid argument`,
                401: `Returned when the user does not have permission to access the resource.`,
                404: `Resource was not found.`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * @param id
     * @returns cdpSignatureSetsResponse A successful response.
     * @throws ApiError
     */
    public static policiesGetSignatureSets(
        id: string,
    ): CancelablePromise<cdpSignatureSetsResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/signatureSets/{id}',
            path: {
                'id': id,
            },
            errors: {
                400: `Invalid argument`,
                401: `Returned when the user does not have permission to access the resource.`,
                404: `Resource was not found.`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * @param id
     * @param force
     * @returns any A successful response.
     * @throws ApiError
     */
    public static policiesDeleteSignatureSets(
        id: string,
        force?: boolean,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/v1/signatureSets/{id}',
            path: {
                'id': id,
            },
            query: {
                'force': force,
            },
            errors: {
                400: `Invalid argument`,
                401: `Returned when the user does not have permission to access the resource.`,
                404: `Resource was not found.`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * @param orderName
     * @param orderStatus
     * @param orderCreatedAt
     * @param orderUpdatedAt
     * @param filterName
     * @param filterStatus
     * @param filterLabel
     * @param page
     * @param size
     * @returns policyListResponse A successful response.
     * @throws ApiError
     */
    public static policiesListTrustLists(
        orderName: 'DESCENDING' | 'ASCENDING' = 'DESCENDING',
        orderStatus: 'DESCENDING' | 'ASCENDING' = 'DESCENDING',
        orderCreatedAt: 'DESCENDING' | 'ASCENDING' = 'DESCENDING',
        orderUpdatedAt: 'DESCENDING' | 'ASCENDING' = 'DESCENDING',
        filterName?: Array<string>,
        filterStatus?: Array<string>,
        filterLabel?: Array<string>,
        page?: number,
        size?: number,
    ): CancelablePromise<policyListResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/trustLists',
            query: {
                'order.name': orderName,
                'order.status': orderStatus,
                'order.createdAt': orderCreatedAt,
                'order.updatedAt': orderUpdatedAt,
                'filter.name': filterName,
                'filter.status': filterStatus,
                'filter.label': filterLabel,
                'page': page,
                'size': size,
            },
            errors: {
                400: `Invalid argument`,
                401: `Returned when the user does not have permission to access the resource.`,
                404: `Resource was not found.`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * @param body
     * @returns cdpTrustListsResponse A successful response.
     * @throws ApiError
     */
    public static policiesCreateTrustLists(
        body: cdpTrustListsResponse,
    ): CancelablePromise<cdpTrustListsResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/trustLists',
            body: body,
            errors: {
                400: `Invalid argument`,
                401: `Returned when the user does not have permission to access the resource.`,
                404: `Resource was not found.`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * @param body
     * @returns cdpTrustListsResponse A successful response.
     * @throws ApiError
     */
    public static policiesUpdateTrustLists(
        body: cdpTrustListsResponse,
    ): CancelablePromise<cdpTrustListsResponse> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/v1/trustLists',
            body: body,
            errors: {
                400: `Invalid argument`,
                401: `Returned when the user does not have permission to access the resource.`,
                404: `Resource was not found.`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * @param id
     * @returns cdpTrustListsResponse A successful response.
     * @throws ApiError
     */
    public static policiesGetTrustLists(
        id: string,
    ): CancelablePromise<cdpTrustListsResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/trustLists/{id}',
            path: {
                'id': id,
            },
            errors: {
                400: `Invalid argument`,
                401: `Returned when the user does not have permission to access the resource.`,
                404: `Resource was not found.`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * @param id
     * @param force
     * @returns any A successful response.
     * @throws ApiError
     */
    public static policiesDeleteTrustLists(
        id: string,
        force?: boolean,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/v1/trustLists/{id}',
            path: {
                'id': id,
            },
            query: {
                'force': force,
            },
            errors: {
                400: `Invalid argument`,
                401: `Returned when the user does not have permission to access the resource.`,
                404: `Resource was not found.`,
                500: `Internal server error`,
            },
        });
    }

}
