import {createSlice, PayloadAction} from '@reduxjs/toolkit'

const initialState = {
    response: null as string | null,
    isLoading: false,
    isError: false,
    isSuccess: false,
}

type StateType = typeof initialState;

export const conversionSlice = createSlice({
    name: 'CONVERT',
    initialState: initialState as StateType,
    reducers: {
        setIsLoading: (state: StateType, action: PayloadAction<boolean>) => {
            state.isLoading = action.payload
        },
        setIsError: (state: StateType, action: PayloadAction<boolean>) => {
            state.isError = action.payload
        },
        setIsSuccess: (state: StateType, action: PayloadAction<boolean>) => {
            state.isSuccess = action.payload
        },
        setResponse: (state: StateType, action: PayloadAction<string | null>) => {
            state.response = action.payload
        },
        resetState: (state: StateType) => {
            state.response = initialState.response
            state.isLoading = initialState.isLoading
            state.isError = initialState.isError
            state.isSuccess = initialState.isSuccess
        },
    }
});
