import React, { useCallback, useState } from "react";
import { useSelector } from "react-redux";
import { providerIngestionIsOpenSelector } from "./selectors";
import { useAppDispatch } from "../../store";
import { cleanAllIngestionScreenCommands, providerIngestionSlice } from "./reducer";
import { Drawer100 } from "../../components/styled/Drawer";
import { SelectPoliciesScreen } from "./stages/SelectPoliciesScreen";
import { ResultScreen } from "./stages/ResultScreen";
import { IngestionSummaryScreen } from "./stages/IngestionSummaryScreen";
import { PolicySettingsScreen } from "./stages/PolicySettingsScreen";
import { LOCATIONS, PATHS } from "../../navigation";

export enum STAGES {
    SELECT_POLICIES = 'SELECT_POLICIES',
    POLICY_SETTINGS = 'POLICY_SETTINGS',
    SUMMARY = 'SUMMARY',
    RESULT = 'RESULT'
}

const STAGES_AVAILABLE = [
    STAGES.SELECT_POLICIES,
    STAGES.POLICY_SETTINGS,
    STAGES.SUMMARY,
    STAGES.RESULT
]

export const ProviderIngestionDrawerComponent = () => {

    const isOpen = useSelector(providerIngestionIsOpenSelector)
    const dispatch = useAppDispatch()
    const [stage, setStage] = useState(STAGES_AVAILABLE[0])
    const [prevStage, setPrevStage] = useState(STAGES_AVAILABLE[0])

    const handleNextStage = () => {
        const nextStage = STAGES_AVAILABLE[STAGES_AVAILABLE.indexOf(stage) + 1]
        if (!nextStage) {
            onFinish()
        }
        setPrevStage(stage)
        setStage(nextStage)
    }
    const handlePrevStage = () => {
        const nextStage = STAGES_AVAILABLE[STAGES_AVAILABLE.indexOf(stage) - 1]
        if (!nextStage) {
            onDrawerClose()
        }
        setPrevStage(stage)
        setStage(nextStage)
    }

    const onDrawerClose = useCallback(() => {
        setStage(STAGES_AVAILABLE[0])
        // Comment out for mock flow (2 lines)
        dispatch(cleanAllIngestionScreenCommands())
        dispatch(providerIngestionSlice.actions.clean())
    }, [setStage, dispatch])

    const onFinish = useCallback(() => {
        onDrawerClose();
        window.location.href = PATHS[LOCATIONS.policies]
    }, [onDrawerClose])

    const drawerStyle = () => {
        switch(stage){
            case STAGES.SELECT_POLICIES:
                return {width: '75vw'};
            case STAGES.POLICY_SETTINGS:
                return {width: '50vw'};   
            case STAGES.SUMMARY:
                return {width: '50vw'};                
            case STAGES.RESULT:
                return null;            
        }
        return null
    }

    return <Drawer100 {...drawerStyle()} themeOverride={{ spacing: { horizontal: 0, vertical: 0 } }} open={isOpen} hideActionsRow hideBreadcrumbs onAfterClose={onDrawerClose}>
        {stage === STAGES.SELECT_POLICIES && <SelectPoliciesScreen onBack={handlePrevStage} onNext={handleNextStage} />}
        {stage === STAGES.POLICY_SETTINGS && <PolicySettingsScreen onBack={handlePrevStage} onNext={handleNextStage} prevStage={prevStage} />}
        {stage === STAGES.SUMMARY && <IngestionSummaryScreen onBack={handlePrevStage} onNext={handleNextStage} />}
        {stage === STAGES.RESULT && <ResultScreen onBack={handlePrevStage} onNext={handleNextStage} />}
    </Drawer100>
}