import { AppDispatch } from "../../store";
import { errorActionRequest } from "../Errors/actions";
import { PoliciesService, policyPolicy, cdpSignatureSetsResponse, cdpTrustListsResponse, cdpLoggingCustomizationResponse, cdpOverlayProtocolsResponse } from "core-lib";
import { ReferenceKeys } from "./types";
import { PAGINATION_FIRST_PAGE, PAGINATION_HUGE_SIZE } from "../../constants";

export const fetchPolicy = (id: string) => {
    return async (_dispatch: AppDispatch) => {
        return PoliciesService.policiesExportPolicy(id)
            .catch(error => {
                errorActionRequest(error);
            })
    }
}

export const fetchBasePolicies = () => {
    return async (_dispatch: AppDispatch) => {
        return PoliciesService.policiesGetBasePolicyExport()
            .catch(error => {
                errorActionRequest(error);
            })
    }
}

export const fetchReferencesData = () => {
    return async (_dispatch: AppDispatch) => {
        return Promise.all([
            PoliciesService.policiesListTrustLists(
                undefined,
                undefined,
                undefined,
                undefined,
                undefined,
                undefined,
                undefined,
                PAGINATION_FIRST_PAGE,
                PAGINATION_HUGE_SIZE)
                .catch(error => {
                    errorActionRequest(error);
                }),
            PoliciesService.policiesListSignatureSets(
                undefined,
                undefined,
                undefined,
                undefined,
                undefined,
                undefined,
                undefined,
                PAGINATION_FIRST_PAGE,
                PAGINATION_HUGE_SIZE)
                .catch(error => {
                    errorActionRequest(error);
                }),
            PoliciesService.policiesListOverlayProtocols(
                undefined,
                undefined,
                undefined,
                undefined,
                undefined,
                undefined,
                undefined,
                PAGINATION_FIRST_PAGE,
                PAGINATION_HUGE_SIZE)
                .catch(error => {
                    errorActionRequest(error);
                }),
            PoliciesService.policiesListLoggingCustomization(
                undefined,
                undefined,
                undefined,
                undefined,
                undefined,
                undefined,
                undefined,
                PAGINATION_FIRST_PAGE,
                PAGINATION_HUGE_SIZE)
                .catch(error => {
                    errorActionRequest(error);
                })
        ])
    }
}

export const fetchReferenceDataByType = (id: string, type: ReferenceKeys) => {
    return async (_dispatch: AppDispatch) : Promise<cdpSignatureSetsResponse | cdpTrustListsResponse | cdpLoggingCustomizationResponse | cdpOverlayProtocolsResponse | void> => {
        switch (type) {
            case "TrustLists":
                return PoliciesService.policiesGetTrustLists(id).catch(error => {
                    errorActionRequest(error);
                })
            case "LoggingCustomization":
                return PoliciesService.policiesGetLoggingCustomization(id).catch(error => {
                    errorActionRequest(error);
                })
            case "OverlayProtocols":
                return PoliciesService.policiesGetOverlayProtocols(id).catch(error => {
                    errorActionRequest(error);
                })
            case "SignatureSet":
                return PoliciesService.policiesGetSignatureSets(id).catch(error => {
                    errorActionRequest(error);
                })
        }
    }
}

export const updatePolicy = (policy: policyPolicy) => {
    return async (_dispatch: AppDispatch) => {
        return PoliciesService.policiesImportPolicy(policy)
            .catch(error => {
                errorActionRequest(error);
            })
    }
}

export const createPolicy = (policy: policyPolicy) => {
    return async (_dispatch: AppDispatch) => {
        const { id, ...policyToSend } = policy
        return PoliciesService.policiesImportPolicy(policyToSend)
            .catch(error => {
                errorActionRequest(error);
            })
    }
}