export enum deploymentStatus {
  Deployed = 'Deployed',
  Deploying = 'Deploying'
}

export enum conversionLogItemStatus {
  FULL = 'FULL',
  NONE = 'NONE',
  PARTIAL = 'PARTIAL'
}

export enum policyPolicyStatus {
  SAVED = 'SAVED',
  ATTACHED = 'ATTACHED',
  DEPLOYING = 'DEPLOYING',
  DEPLOYED = 'DEPLOYED',
  CONVERTING = 'CONVERTING',
  CONVERTED = 'CONVERTED',
  CONVERT_FAILED = 'CONVERT_FAILED',
  DEPLOY_FAILED = 'DEPLOY_FAILED'
}
