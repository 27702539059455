import React, { useCallback, useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import { Button, Icon } from "@emerald/react";
import { useSelector } from "react-redux";
import { deploymentsListArraySelector } from "./selectors";
import { useAppDispatch } from "../../store";
import { fetchDeployments, downloadDeploymentLogs } from "./actions";
import { Row } from "../../components/Row";
import { Col } from "../../components/Col";
import moment from "moment";
import { Link } from "../../components/styled/Link";
import { deploymentsListItem } from "./types";
import { DataGrid } from "../../components/DataGrid";
import { LOCATIONS, PATHS } from "../../navigation";
import { CommonEmptyComponent } from "../../components/CommonEmptyComponent";

const Header = styled.div`
  font-weight: 700;
  font-size: 24px;
  line-height: 36px;
  margin-bottom: 10px;
`
const Content = styled.div`
  height: 100%
`

const IconIcon = styled(Icon)`
  vertical-align: baseline;
`

const StatusIcon = styled.span`
  margin-right: 5px;
  vertical-align: middle;
`

const StatusLine = styled.div`
  height: 20px;
`

const StatusText = styled.div`
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
`

const DateLine = styled.div`
  color: #9EA7B8;
`

const NameLink = styled(Link)`
line-height: 20px;
height: 20px;
`

const DEPLOYMENT_STATUSES = {
    ATTACHED: 'ATTACHED',
    FAILED: 'FAILED',
}

const DEPLOYMENT_STATUSES_TEXT = {
    [DEPLOYMENT_STATUSES.ATTACHED]: 'Attached',
    [DEPLOYMENT_STATUSES.FAILED]: 'Failed',
}

const DeploymentStatusIconComponent = ({ status }: { status?: string }) => {
    return <StatusIcon>
        {status === DEPLOYMENT_STATUSES.ATTACHED && <IconIcon size="small" icon="status.success" />}
        {status === DEPLOYMENT_STATUSES.FAILED && <IconIcon size="small" icon="status.error" />}
    </StatusIcon>
}

export const DeploymentsListComponent = () => {

    const dataSource = useSelector(deploymentsListArraySelector);
    const dispatch = useAppDispatch();
    const [isLoading, setIsLoading] = useState(true)

    const refresh = useCallback(() => {
        setIsLoading(true)
        dispatch(fetchDeployments())
            .finally(() => setIsLoading(false))
    }, [dispatch, setIsLoading])

    useEffect(() => {
        refresh()
    }, [refresh]);

    const onRefreshClick = useCallback(() => {
        refresh()
    }, [refresh])

    const columns: any = useMemo(() => [
        {
            accessor: 'notes',
            title: 'Report Name',
            filterable: true,
            sortable: true,
            width: 400,
            renderer: (row: deploymentsListItem) => {
                return <NameLink to={{
                    pathname: PATHS[LOCATIONS.reports],
                    hash: `#${row.groupId}`
                }}>{row.notes}</NameLink>
            }
        },
        {
            accessor: 'policyName',
            title: 'Policy',
            filterable: true,
            sortable: true,
        },
        {
            accessor: 'dateTimestamp',
            title: 'Deployment Date',
            width: 200,
            filterable: true,
            sortable: true,
            renderer: (row: deploymentsListItem) =>
                <Row fullWidth>
                    <Col>
                        <DeploymentStatusIconComponent status={row.status} />
                    </Col>
                    <Col fullWidth flex={1}>
                        <StatusLine>
                            <span>{DEPLOYMENT_STATUSES_TEXT[row.status || '']}</span>
                        </StatusLine>
                        <DateLine>
                            <StatusText>{moment(row.dateTimestamp).format('h:mm A, D MMM YYYY')}</StatusText>
                        </DateLine>
                    </Col>
                </Row>
        },
        {
            accessor: 'providerName',
            title: 'Provider',
            sortable: true,
            filterable: true,
            renderer: (row: deploymentsListItem) => {
                return <Link to={{
                    pathname: PATHS[LOCATIONS.providers],
                    hash: `#${row.providerId}`
                }}>{row.providerName}</Link>
            }
        },
        {
            accessor: 'attachmentsCount',
            title: 'Attachment',
            width: 150,
            sortable: true,
            filterable: true,
            renderer: (row: deploymentsListItem) => row.attachmentsCount || '-'
        }
    ], [])

    const toolbarCustomFilter = useCallback(
        () => <Button displayMode="ghostInk" onClick={onRefreshClick}>
            <Icon icon="app.refresh" />
            <span>Refresh</span>
        </Button>, [onRefreshClick])

    const initialConfig = useMemo(() => ({
        sortColumn: columns[2],
        sortDirection: 'descending' as "descending" | "ascending" | undefined,
    }), [columns])

    const rowActions = useCallback(() => [
        {
            items: [
                { value: "logs", icon: "app.view", label: `Download Logs` }
            ],
        },
    ], [])

    const handleRowAction = useCallback(({ item, action }) => {
        switch (action) {
            case 'logs':
                item.commandId && dispatch(downloadDeploymentLogs(item.commandId, `${item.policyName} ${item.providerName}.json`))
                break
        }
    }, [dispatch]);

    return <Content>
        {dataSource.length === 0 && !isLoading  ?
            <CommonEmptyComponent title="No Deployments yet" description="Select a policy to deploy" /> :
            <Col fullHeight>

                <Row fullWidth>
                    <Col flex={1}><Header>Deployments</Header></Col>
                </Row>

                <Row fullWidth flex={1}>
                    <DataGrid
                        isLoading={isLoading}
                        columns={columns}
                        items={dataSource}
                        itemIdAccessor='id'
                        showToolbar={true}
                        allowSelectRows={true}
                        allowSelectAll={true}
                        toolbarCustomFilter={toolbarCustomFilter}
                        rowActions={rowActions}
                        configInitial={initialConfig}
                        onRowAction={handleRowAction}
                    />
                </Row>
            </Col>}
    </Content>
}