import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Button, ContentStatus, Drawer, Icon } from "@emerald/react";
import { BodyFormFull, Header, HeaderText } from "../../components/styled/Drawer";
import { Row } from "../../components/Row";
import { Col } from "../../components/Col";
import { ScrollableContent } from "../../components/PageScrollable";
import {
    commandFindEndpointsAWAFResponse,
    commandFindEndpointsXCResponse,
    commandFindEndpointsNAPResponse,
    providerResponse,
    providerProviderType,
    deploymentListDeploymentResponse,
    commandJobStatus
} from "core-lib";
import { useAppDispatch } from "../../store";
import { findEndpoints, getDeployments, getProvider } from "./actions";
import { Divider } from "../../components/Divider";
import { InfoAWAF } from "./InfoAWAF";
import { InfoXC } from "./InfoXC";
import { InfoNAP } from "./InfoNAP";
import { FullHeight } from "../../components/styled";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { providerIdSelector } from "./selectors";
import { providerDetailsSlice } from "./reducer";
import { providerIngestionSlice } from "../ProviderIngestion/reducer";

const CloseIcon = styled(Icon)`
  color: #6C778C;
  font-size: 8px !important;
  height: 8px !important;
  line-height: 8px !important;
`

const ButtonIcon = styled(Icon)`
  font-size: 16px !important;
  height: 16px !important;
  line-height: 16px !important;
  color: #9EA7B8
`

export const ProviderDetailsComponent = () => {
    const dispatch = useAppDispatch()
    const providerId = useSelector(providerIdSelector)

    const [provider, setProvider] = useState<providerResponse | null>(null)
    const [deployments, setDeployments] = useState<deploymentListDeploymentResponse | null>(null)
    const [endpoints, setEndpoints] = useState<commandFindEndpointsAWAFResponse | commandFindEndpointsXCResponse | commandFindEndpointsNAPResponse | null>(null)

    const [endpointsError, setEndpointsError] = useState<string>('')

    const setEndpointsErrorMessage = useCallback((error = '') => {
        let message = '';

        if (error) {
            switch (error) {
                case commandJobStatus.JOB_CANCELED:
                    message = 'Fetching endpoints was canceled. Could not connect to the agent.'
                    break;
                default:
                    message = 'Error fetching endpoints.'
            }
        }

        setEndpointsError(message)
    }, [setEndpointsError])

    const refresh = useCallback(() => {
        if (providerId) {
            setProvider(null)
            setDeployments(null)
            setEndpoints(null)
            setEndpointsErrorMessage();

            dispatch(getProvider(providerId)).then(provider => {
                setProvider(provider)
                dispatch(findEndpoints(provider)).then(endpoints => {
                    setEndpoints(endpoints)
                }).catch(error => {
                    setEndpointsErrorMessage(error)
                })
            })
            dispatch(getDeployments(providerId)).then(deployments => {
                deployments && setDeployments(deployments)
            })
        }
    }, [dispatch, providerId, setProvider, setEndpoints, setEndpointsErrorMessage])

    const isLoading = useMemo(() => deployments === null || provider === null, [provider, deployments])

    useEffect(() => {
        refresh()
    }, [refresh])

    const onClose = useCallback(() => {
        dispatch(providerDetailsSlice.actions.setProviderId(''))
    }, [dispatch])

    const actions = [
        { value: "ingest", icon: "app.swap", label: `Ingest Policies`, disabled: provider?.type === providerProviderType.XC },
        { value: "refresh", icon: "app.refresh", label: `Refresh` },
    ]

    const handleAction = useCallback((action: string) => {
        switch (action) {
            case "refresh":
                refresh()
                break
            case "ingest":
                provider && dispatch(providerIngestionSlice.actions.openProviderIngestion(provider))
                break
        }
    }, [dispatch, refresh, provider]);


    return <Drawer hideOverlay hideActionsRow themeOverride={{ spacing: { horizontal: 0, vertical: 0 } }} open={!!providerId} onAfterClose={onClose} hideBreadcrumbs>
        {!isLoading && <FullHeight>
            <BodyFormFull>
                <Header pr={20} pl={20}>
                    <Row fullHeight>
                        <Col centerVertical flex={1} fullHeight>
                            <HeaderText>Provider Details</HeaderText>
                        </Col>
                        <Col centerVertical rightDirection>
                            <Button size='small' displayMode="linkInk" onClick={onClose}><CloseIcon icon='app.close' /></Button>
                        </Col>
                    </Row>
                </Header>
                <Divider />
                <Col fullWidth rightDirection mt={18} mb={18}>
                    <Row>
                        {actions.map(({ icon, label, value, disabled }) => <Button key={label} displayMode="ghostInk" disabled={!!disabled} onClick={() => handleAction(value)}><ButtonIcon size="small" icon={icon} /><span>{label}</span></Button>)}
                    </Row>
                </Col>
                <ScrollableContent>
                    {!!provider && provider.type === providerProviderType.AWAF && <InfoAWAF
                        provider={provider}
                        deployments={deployments}
                        endpoints={endpoints as commandFindEndpointsAWAFResponse | null}
                        endpointsError={endpointsError} />
                    }
                    {!!provider && provider.type === providerProviderType.XC && <InfoXC
                        provider={provider}
                        deployments={deployments}
                        endpoints={endpoints as commandFindEndpointsXCResponse | null}
                        endpointsError={endpointsError} />
                    }
                    {!!provider && provider.type === providerProviderType.NAP && <InfoNAP
                        provider={provider}
                        deployments={deployments}
                        endpoints={endpoints as commandFindEndpointsNAPResponse | null}
                        endpointsError={endpointsError} />
                    }
                </ScrollableContent>
            </BodyFormFull>
        </FullHeight>}
        <ContentStatus
            isVisible={isLoading}
            spinnerSize={"medium"}
            defaultMessage={<span>Loading provider</span>}
            fillOuterContainer={false}
        />
    </Drawer>
}
