import styled from "styled-components";
import { Link as RouterLink } from "react-router-dom";


export const Link = styled(RouterLink)`
  color: #4F73FF !important;
  cursor: pointer;
  text-decoration: none !important;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`