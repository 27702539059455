import React from "react";
import {PROVIDERS_INFO} from "../../constants";
import styled from "styled-components";
import {Row} from "../../components/Row";
import {Col} from "../../components/Col";
import {commandFindEndpointsNAPResponse, deploymentListDeploymentResponse, providerResponse} from "core-lib";
import {LOCATIONS, PATHS} from "../../navigation";
import { Link } from "../../components/styled/Link";
import {CircularProgress} from "@mui/material";
import { Icon } from "@emerald/react";
import { Accordion } from "../../components/Accordion";

const PanelContentBody = styled(Col)`
flex: 1;
font-weight: 400;
font-size: 12px;
line-height: 18px;
`

const PanelContentLabel = styled(Col)`
font-weight: 400;
font-size: 12px;
line-height: 18px;
color: #6C778C;
width: 235px;
`

const TextContainer = styled.div`
max-width: 250px;
overflow: hidden;
text-overflow: ellipsis;
white-space: nowrap;
`

const LoadingLabel = () => {
    return <Row>
        <Col>
            <CircularProgress
                variant="indeterminate"
                disableShrink
                sx={{
                    color: '#0F1E57'
                }}
                size={16}
                thickness={2}
            />
        </Col>
        <Col ml={5}>Loading...</Col>
    </Row>
}

const ErrorLabel = (props: {message?: string}) => {
    return <Row>
        <Col>
            <Icon icon='status.error' />
        </Col>
        <Col ml={5}>{props.message ? props.message : 'Error'}</Col>
    </Row>
}

export const InfoNAP = (props: {
    provider: providerResponse | null,
    deployments: deploymentListDeploymentResponse | null,
    endpoints: commandFindEndpointsNAPResponse | null,
    endpointsError: string
}) => {

    const {provider, deployments, endpoints, endpointsError} = props

    return  <div>
    <Accordion title="General properties" initialOpened>
        <Col spacing={20}>
            <Row>
                <PanelContentLabel>Provider Type</PanelContentLabel>
                <PanelContentBody>{PROVIDERS_INFO[provider?.type || '']?.name}</PanelContentBody>
            </Row>
            <Row>
                <PanelContentLabel>SSH Server and Port</PanelContentLabel>
                <PanelContentBody>{provider?.url}</PanelContentBody>
            </Row>
        </Col>
    </Accordion>
    <Accordion title="Servers on Provider" initialOpened>
        <Col spacing={20}>
            <Row>
                <PanelContentLabel>Servers {endpoints && `(${endpoints?.servers?.length || 0})`}</PanelContentLabel>
                {!endpointsError && <PanelContentBody>{endpoints ? endpoints?.servers?.map((name) => <div key={name}>{name}</div>) : <LoadingLabel />}</PanelContentBody>}
                {!!endpointsError && <PanelContentBody><ErrorLabel message={endpointsError} /></PanelContentBody>}
            </Row>
        </Col>
    </Accordion>
    <Accordion title="Total Deployments" initialOpened>
        <Col spacing={20}>
            <Row>
                <PanelContentLabel>Deployments ({deployments?.deployments?.length || 0})</PanelContentLabel>
                <PanelContentLabel>Deployment Reports </PanelContentLabel>
            </Row>
            <Row>
                <PanelContentLabel />
                <PanelContentBody>{deployments?.deployments?.map(({ id, groupId, notes }) => <Link key={id} to={{
                    pathname: PATHS[LOCATIONS.reports],
                    hash: `#${groupId}`
                }}><TextContainer>{notes}</TextContainer></Link>)}</PanelContentBody>
            </Row>
        </Col>
    </Accordion>
    </div>
}
