import React, {useMemo} from "react";
import {
    commandConversionLog,
    commandConversionReportResponse,
    conversionLogItemStatus,
    providerProviderType
} from "core-lib";
import {ConversionReportComponent} from "./ConversionReportComponent";

const LEVEL_SEPARATOR = '.'
const GROUP_LEVELS_COUNT = {
    [providerProviderType.ONEWAF]: 3,
    [providerProviderType.AWAF]: 2,
    [providerProviderType.XC]: 3,
    [providerProviderType.NAP]: 2,
    [providerProviderType.NONE]: 0
}
const DESCRIPTIONS_SEPARATOR = '\n'
const STATUS_SORT_PRIORITY = {
    [conversionLogItemStatus.FULL]: 0,
    [conversionLogItemStatus.PARTIAL]: 1,
    [conversionLogItemStatus.NONE]: 2
}

type groupItem = {
    key: string
    children: commandConversionLog[],
    status: conversionLogItemStatus,
    descriptions: string[]
}

export const ConversionReportGroupedComponent = (props: {
    report: commandConversionReportResponse,
}) => {

    const {report} = props

    const reportGrouped = useMemo(() => {
        if (report.ConversionLogs && report.ConversionSummary && report.ConversionSummary.Source) {

            const groups = report.ConversionLogs.reduce((result, logItem) => {

                const levels = logItem.Name?.split(LEVEL_SEPARATOR).filter(Boolean) || []

                const key = report.ConversionSummary?.Source ?
                    levels.slice(0, GROUP_LEVELS_COUNT[report.ConversionSummary.Source as providerProviderType]).join(LEVEL_SEPARATOR) : ''

                if (!result[key]) {

                    return {
                        ...result,
                        [key]: {
                            key: key,
                            children: [logItem],
                            status: logItem.Status || conversionLogItemStatus.FULL,
                            descriptions: [logItem.Description]
                        } as groupItem
                    }

                } else {

                    const status: conversionLogItemStatus = result[key].status === conversionLogItemStatus.PARTIAL ? result[key].status :
                        (result[key].status === logItem.Status ? result[key].status : conversionLogItemStatus.PARTIAL)

                    return {
                        ...result,
                        [key]: {
                            key: key,
                            children: [...result[key].children, logItem],
                            status: status,
                            descriptions: [...result[key].descriptions, logItem.Description || '']
                        } as groupItem
                    }

                }

            }, {} as { [key: string]: groupItem })

            const logsGrouped = Object.values(groups).map(groupItem => ({
                Name: groupItem.key,
                Status: groupItem.status,
                Description: Array.from(new Set(groupItem.descriptions)).filter(Boolean).join(DESCRIPTIONS_SEPARATOR)
            } as commandConversionLog)).sort((item1, item2) => {
                if(item1.Status && item2.Status) {
                    const compareStatus = STATUS_SORT_PRIORITY[item1.Status as conversionLogItemStatus] -
                        STATUS_SORT_PRIORITY[item2.Status as conversionLogItemStatus]
                    return compareStatus ? compareStatus : ((item1.Name || '') < (item2.Name || '') ? -1 : 1)
                }
                return 0
            })

            const counts = logsGrouped.reduce((result, current) => ({
                FullConvert: result.FullConvert + (current.Status === conversionLogItemStatus.FULL ? 1 : 0),
                PartialConvert: result.PartialConvert + (current.Status === conversionLogItemStatus.PARTIAL ? 1 : 0),
                NoneConvert: result.NoneConvert + (current.Status === conversionLogItemStatus.NONE ? 1 : 0)
            }), {
                FullConvert: 0,
                PartialConvert: 0,
                NoneConvert: 0
            })

            return {
                ConversionSummary: {
                    ...report.ConversionSummary,
                    ...counts
                },
                ConversionLogs: logsGrouped
            }
        }

        return report

    }, [report])

    return <ConversionReportComponent report={reportGrouped}/>
}