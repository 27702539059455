import React, {forwardRef, useImperativeHandle, useRef, useState} from "react";
import {Row} from "./Row";
import { Col } from "./Col";
import {Button, Icon, Input} from "@emerald/react";
import styled from "styled-components";

const LineButton = styled(Button)`
    vertical-align: middle;
`
const HiddenInput = styled.input`
  display: none;
`

export type FileInputHandle = {
    resetInput: () => void;
};

type Props = {
    onChange: (file: File) => void,
    accept?: string
};

export const FileInput = forwardRef<FileInputHandle, Props>((props, ref) => {
    useImperativeHandle(ref, () => {
        return {
          resetInput: () => setFile(null)
        };
    }, []);

    const hiddenFileInput = useRef<HTMLInputElement>(null);
    const [file, setFile] = useState<File | null>(null)

    const handleClick = () => {
        hiddenFileInput.current?.click();
    }

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const selectedFile = event.target?.files?.[0]
        selectedFile && setFile(selectedFile)
        selectedFile && props.onChange(selectedFile)
    }

    return <>
            <HiddenInput ref={hiddenFileInput} onChange={handleChange} type="file" accept={props.accept} />
            <Row spacing={10}>
                <Col flex={1} fullHeight>
                    <Input value={file?.name || ''}></Input>
                </Col>
                <Col height={48} centerVertical>
                    <LineButton onClick={handleClick} displayMode="outlined">
                        <Icon icon='app.upload' />
                        <span>Upload</span>
                    </LineButton>
                </Col>
            </Row>
        </>
})