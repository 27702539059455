export const downloadFile = (content: string, filename: string, type: string = 'text/plain') => {
  const blob = new Blob([content], {
    type
  })

  const href = URL.createObjectURL(blob)

  const link = document.createElement('a')
  link.href = href
  link.setAttribute('download', filename)
  document.body.appendChild(link)
  link.click()

  document.body.removeChild(link)
  URL.revokeObjectURL(href)
}
