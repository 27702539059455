import {
    commandFindEndpointsAWAFResponse,
    commandFindEndpointsXCResponse,
    commandFindEndpointsNAPResponse,
    commandJobStatus, CommandsService, DeploymentsService,
    providerResponse, ProvidersService,
    providerProviderType
} from "core-lib";
import { AppDispatch } from "../../store";
import { errorActionRequest } from "../Errors/actions";
import { CommandStatusCheck } from "../../core/commands/CommandStatusCheck";
import { PAGINATION_FIRST_PAGE, PAGINATION_HUGE_SIZE } from "../../constants";
import { providersListSlice } from "../ProvidersList/reducer";

export const getProvider = (providerId: string) => {
    return async (_dispatch: AppDispatch) => {
        return ProvidersService.providersGetProvider(providerId)
            .catch((error) => {
                errorActionRequest(error)
                throw error
            })
    }
}

export const getDeployments = (providerId: string) => {
    return async (_dispatch: AppDispatch) => {
        return DeploymentsService.deploymentsListDeployments(
            undefined,
            undefined,
            undefined,
            [providerId],
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            PAGINATION_FIRST_PAGE,
            PAGINATION_HUGE_SIZE)
            .catch(error => {
                errorActionRequest(error);
            })
    }
}

export const findEndpoints = (provider: providerResponse) => {
    return async (dispatch: AppDispatch) => {

        const providerId = provider.id
        const endpointsProviderType = provider.type

        return new Promise<commandFindEndpointsAWAFResponse | commandFindEndpointsXCResponse | commandFindEndpointsNAPResponse>((resolve, reject) => {

            return CommandsService.commandsPostFindEndpoints({
                providerId: providerId
            }).then(response => {

                const commandId = response.id

                if (commandId && endpointsProviderType) {
                    CommandStatusCheck.getChecker(commandId).run(() => dispatch(providersListSlice.actions.resetProviders()))
                        .setOnResult((commandId, commandStatus) => {
                            if (commandStatus === commandJobStatus.JOB_SUCCESS) {
                                if (endpointsProviderType === providerProviderType.AWAF) {
                                    CommandsService.commandsGetFindEndpointsAwaf(commandId).then((result) => {
                                        resolve(result)
                                    }).catch(error => {
                                        errorActionRequest(error)
                                    })
                                } else if (endpointsProviderType === providerProviderType.XC) {
                                    CommandsService.commandsGetFindEndpointsXc(commandId).then((result) => {
                                        resolve(result)
                                    }).catch(error => {
                                        errorActionRequest(error)
                                    })
                                } else if (endpointsProviderType === providerProviderType.NAP) {
                                    CommandsService.commandsGetFindEndpointsNap(commandId).then((result) => {
                                        resolve(result)
                                    }).catch(error => {
                                        errorActionRequest(error)
                                    })
                                }
                            } else {
                                reject(commandStatus)
                            }
                        })
                        .setOnError((commandId, e) => {
                            reject(e)
                            errorActionRequest(e)
                        })
                }
                return response.id
            }).catch(error => {
                reject(error)
                errorActionRequest(error);
            })
        })

    }
}