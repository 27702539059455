import React, { useEffect } from "react";
import {
    BodyFormFull,
    Footer,
    Header,
    HeaderHeader,
    Legend
} from "../../../components/styled/Drawer";
import { Divider } from "../../../components/Divider";
import { Align, Button } from "@emerald/react";
import { Col } from "../../../components/Col";
import { Row } from "../../../components/Row";
import { useSelector } from "react-redux";
import {
    commitMessageSelector,
    conversionResultSelector,
    policyConversionPolicySelector,
    policyConversionProviderTypeSelectedSelector
} from "../selectors";
import { CircularProgress } from "@mui/material";
import styled from "styled-components";
import { GraySpan } from "../../../components/styled";
import { useAppDispatch } from "../../../store";
import { convertPolicy } from "../actions";
import { ScrollableContent } from "../../../components/PageScrollable";
import { PROVIDERS_INFO } from "../../../constants";
import { ConversionReportNewComponent } from "../../../core/components/Conversion/ConversionReportNewComponent";
import { ConversionFailedComponent } from "../../../core/components/Conversion/ConversionFailedComponent";
import { useGetConversionReportErrorData } from "../../../core/components/Conversion/util/useGetConversionReportErrorData";
import { ConversionReportErrorComponent } from "../../../core/components/Conversion/ConversionReportErrorComponent";

const SpinnerRow = styled(Row)`
  padding: 40px;
  justify-content: center;
`
const CenterRow = styled(Row)`
  justify-content: center;
  flex-direction: column;
  align-items: center;
`

const InnerContent = styled.div`
    padding: 0 20px;
`

const ProvidersLabel = styled.div`
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 45px;
`

const ProgressTitle = styled(Row)`
    font-size: 24px;
    line-height: 36px;
    font-weight: bold;
    color: #4F73FF
`

const ProgressSubtitle = styled(Row)`
    color: #4F73FF
`

const ProgressItemTitle = styled.span`
    font-size: 12px;
    line-height: 18px;
    font-weight: bold;
    color: #0F1E57;
    width: 100px;
`

const ProgressItemText = styled.span`
    color: #6C778C;
    font-size: 12px;
    line-height: 18px;
    width: 120px;
`

type Props = {
    onBack: () => void,
    onNext: () => void
}

export const ConversionSummaryScreen = (props: Props) => {

    const dispatch = useAppDispatch()

    const policy = useSelector(policyConversionPolicySelector)
    const conversionResult = useSelector(conversionResultSelector)
    const providerTypeToConvert = useSelector(policyConversionProviderTypeSelectedSelector)
    const isProgress = conversionResult === null
    const commitMessage = useSelector(commitMessageSelector)

    const { warnings, errors } = useGetConversionReportErrorData(isProgress ? [] : [conversionResult])

    useEffect(() => {
        if (policy?.id && providerTypeToConvert) {
            dispatch(convertPolicy(policy.id, providerTypeToConvert, commitMessage))
        }
    }, [dispatch, policy, providerTypeToConvert, commitMessage])


    return <BodyFormFull>
        <Header pr={20} pl={20}>
            <Col centerVertical fullHeight>
                <HeaderHeader>{isProgress ? 'Conversion' : 'Conversion Summary'}</HeaderHeader>
            </Col>
        </Header>
        <Divider />
        <ScrollableContent mt={38} ml={20} mr={20}>
            <Legend><GraySpan>Policy: </GraySpan>{policy?.metadata?.name}</Legend>
            {isProgress ? <div>
                <SpinnerRow>
                    <CircularProgress
                        variant="indeterminate"
                        disableShrink
                        sx={{
                            color: '#3183B2'
                        }}
                        size={85}
                        thickness={2}
                    />
                </SpinnerRow>
                <CenterRow>
                    <ProgressTitle>In Progress...</ProgressTitle>
                    <ProgressSubtitle mt={10} mb={34}>This may take a few minutes.</ProgressSubtitle>
                    <Row>
                        <ProgressItemTitle>Policy Name</ProgressItemTitle>
                        <ProgressItemText>{policy?.metadata?.name}</ProgressItemText>
                    </Row>
                    <Row mt={6}>
                        <ProgressItemTitle>Provider Type</ProgressItemTitle>
                        <ProgressItemText>{PROVIDERS_INFO[providerTypeToConvert || '']?.name}</ProgressItemText>
                    </Row>
                </CenterRow>
            </div> : (
                <InnerContent>
                    <ProvidersLabel>Target Provider Type: {PROVIDERS_INFO[providerTypeToConvert || '']?.name}</ProvidersLabel>
                    {conversionResult &&
                        <>
                            <ConversionReportErrorComponent ml={13} mr={26} mb={44} errors={errors} type="ERROR" />
                            <ConversionReportNewComponent warnings={warnings[0]} report={conversionResult} />
                        </>}
                    {!conversionResult && <ConversionFailedComponent />}
                </InnerContent>)}
        </ScrollableContent>
        {!isProgress && <Footer>
            <Divider />
            <Col flex={1} rightDirection mr={20}>
                <Row spacing={20} style={{ marginTop: 10 }}>
                    <Align>
                        <Button accent={true} displayMode="outlined" onClick={props.onBack}>Back</Button>
                    </Align>
                    <Align>
                        <Button disabled={!conversionResult} accent={true} onClick={props.onNext}>
                            Continue</Button>
                    </Align>
                </Row>
            </Col>
        </Footer>}
    </BodyFormFull>
}