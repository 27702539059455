/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { apiHttpBody } from '../models/apiHttpBody';
import type { commandBigipListResult } from '../models/commandBigipListResult';
import type { commandConversionReportResponse } from '../models/commandConversionReportResponse';
import type { commandDeployAWAFRequest } from '../models/commandDeployAWAFRequest';
import type { commandDeployNapRequest } from '../models/commandDeployNapRequest';
import type { commandDeployXCRequest } from '../models/commandDeployXCRequest';
import type { commandFindEndpointsAWAFResponse } from '../models/commandFindEndpointsAWAFResponse';
import type { commandFindEndpointsNAPResponse } from '../models/commandFindEndpointsNAPResponse';
import type { commandFindEndpointsXCResponse } from '../models/commandFindEndpointsXCResponse';
import type { commandImportFromFileDataResponse } from '../models/commandImportFromFileDataResponse';
import type { commandImportFromFileRequest } from '../models/commandImportFromFileRequest';
import type { commandImportFromFileResponse } from '../models/commandImportFromFileResponse';
import type { commandImportFromIngestRequest } from '../models/commandImportFromIngestRequest';
import type { commandNapListResult } from '../models/commandNapListResult';
import type { commandPolicyConvertRequest } from '../models/commandPolicyConvertRequest';
import type { commandPolicyIngestRequest } from '../models/commandPolicyIngestRequest';
import type { commandPolicyIngestResponse } from '../models/commandPolicyIngestResponse';
import type { commandPostDeployConvertRequest } from '../models/commandPostDeployConvertRequest';
import type { commandPostDeployConvertResponse } from '../models/commandPostDeployConvertResponse';
import type { commandProviderAWAFCommandResponse } from '../models/commandProviderAWAFCommandResponse';
import type { commandProviderNapCommandResponse } from '../models/commandProviderNapCommandResponse';
import type { commandProviderRequest } from '../models/commandProviderRequest';
import type { commandProviderXCCommandResponse } from '../models/commandProviderXCCommandResponse';
import type { commandResponse } from '../models/commandResponse';
import type { commandStatusResponse } from '../models/commandStatusResponse';
import type { commonIdRequest } from '../models/commonIdRequest';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class CommandsService {

    /**
     * @param body
     * @returns commandResponse A successful response.
     * @throws ApiError
     */
    public static commandsPostConvert(
        body: commandPolicyConvertRequest,
    ): CancelablePromise<commandResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/commands/convert',
            body: body,
            errors: {
                400: `Invalid argument`,
                401: `Returned when the user does not have permission to access the resource.`,
                404: `Resource was not found.`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * @param body
     * @returns commandResponse A successful response.
     * @throws ApiError
     */
    public static commandsPostIngestConvert(
        body: commonIdRequest,
    ): CancelablePromise<commandResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/commands/convert-ingestion',
            body: body,
            errors: {
                400: `Invalid argument`,
                401: `Returned when the user does not have permission to access the resource.`,
                404: `Resource was not found.`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * @param id
     * @returns apiHttpBody A successful response.
     * @throws ApiError
     */
    public static commandsGetConvertComments(
        id: string,
    ): CancelablePromise<apiHttpBody> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/commands/convert/{id}/comments',
            path: {
                'id': id,
            },
            errors: {
                400: `Invalid argument`,
                401: `Returned when the user does not have permission to access the resource.`,
                404: `Resource was not found.`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * @param id
     * @returns commandConversionReportResponse A successful response.
     * @throws ApiError
     */
    public static commandsGetConvertReport(
        id: string,
    ): CancelablePromise<commandConversionReportResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/commands/convert/{id}/report',
            path: {
                'id': id,
            },
            errors: {
                400: `Invalid argument`,
                401: `Returned when the user does not have permission to access the resource.`,
                404: `Resource was not found.`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * @param id
     * @returns apiHttpBody A successful response.
     * @throws ApiError
     */
    public static commandsGetConvertReportZip(
        id: string,
    ): CancelablePromise<apiHttpBody> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/commands/convert/{id}/reportZip',
            path: {
                'id': id,
            },
            errors: {
                400: `Invalid argument`,
                401: `Returned when the user does not have permission to access the resource.`,
                404: `Resource was not found.`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * @param body
     * @returns commandPostDeployConvertResponse A successful response.
     * @throws ApiError
     */
    public static commandsPostDeployConvert(
        body: commandPostDeployConvertRequest,
    ): CancelablePromise<commandPostDeployConvertResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/commands/deploy-convert',
            body: body,
            errors: {
                400: `Invalid argument`,
                401: `Returned when the user does not have permission to access the resource.`,
                404: `Resource was not found.`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * @param body
     * @returns commandProviderAWAFCommandResponse A successful response.
     * @throws ApiError
     */
    public static commandsPostDeployAwaf(
        body: commandDeployAWAFRequest,
    ): CancelablePromise<commandProviderAWAFCommandResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/commands/deploy/awaf',
            body: body,
            errors: {
                400: `Invalid argument`,
                401: `Returned when the user does not have permission to access the resource.`,
                404: `Resource was not found.`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * @param body
     * @returns commandProviderNapCommandResponse A successful response.
     * @throws ApiError
     */
    public static commandsPostDeployNap(
        body: commandDeployNapRequest,
    ): CancelablePromise<commandProviderNapCommandResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/commands/deploy/nap',
            body: body,
            errors: {
                400: `Invalid argument`,
                401: `Returned when the user does not have permission to access the resource.`,
                404: `Resource was not found.`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * @param body
     * @returns commandProviderXCCommandResponse A successful response.
     * @throws ApiError
     */
    public static commandsPostDeployXc(
        body: commandDeployXCRequest,
    ): CancelablePromise<commandProviderXCCommandResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/commands/deploy/xc',
            body: body,
            errors: {
                400: `Invalid argument`,
                401: `Returned when the user does not have permission to access the resource.`,
                404: `Resource was not found.`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * @param id
     * @returns apiHttpBody A successful response.
     * @throws ApiError
     */
    public static commandsGetDeployLog(
        id: string,
    ): CancelablePromise<apiHttpBody> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/commands/deploy/{id}/log',
            path: {
                'id': id,
            },
            errors: {
                400: `Invalid argument`,
                401: `Returned when the user does not have permission to access the resource.`,
                404: `Resource was not found.`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * @param body
     * @returns commandResponse A successful response.
     * @throws ApiError
     */
    public static commandsPostFindEndpoints(
        body: commandProviderRequest,
    ): CancelablePromise<commandResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/commands/find-endpoints',
            body: body,
            errors: {
                400: `Invalid argument`,
                401: `Returned when the user does not have permission to access the resource.`,
                404: `Resource was not found.`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * @param id
     * @returns commandFindEndpointsAWAFResponse A successful response.
     * @throws ApiError
     */
    public static commandsGetFindEndpointsAwaf(
        id: string,
    ): CancelablePromise<commandFindEndpointsAWAFResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/commands/find-endpoints/{id}/awaf',
            path: {
                'id': id,
            },
            errors: {
                400: `Invalid argument`,
                401: `Returned when the user does not have permission to access the resource.`,
                404: `Resource was not found.`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * @param id
     * @returns commandFindEndpointsNAPResponse A successful response.
     * @throws ApiError
     */
    public static commandsGetFindEndpointsNap(
        id: string,
    ): CancelablePromise<commandFindEndpointsNAPResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/commands/find-endpoints/{id}/nap',
            path: {
                'id': id,
            },
            errors: {
                400: `Invalid argument`,
                401: `Returned when the user does not have permission to access the resource.`,
                404: `Resource was not found.`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * @param id
     * @returns commandFindEndpointsXCResponse A successful response.
     * @throws ApiError
     */
    public static commandsGetFindEndpointsXc(
        id: string,
    ): CancelablePromise<commandFindEndpointsXCResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/commands/find-endpoints/{id}/xc',
            path: {
                'id': id,
            },
            errors: {
                400: `Invalid argument`,
                401: `Returned when the user does not have permission to access the resource.`,
                404: `Resource was not found.`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * @param body
     * @returns commandImportFromFileResponse A successful response.
     * @throws ApiError
     */
    public static commandsPostImportFromFile(
        body: commandImportFromFileRequest,
    ): CancelablePromise<commandImportFromFileResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/commands/import-from-file',
            body: body,
            errors: {
                400: `Invalid argument`,
                401: `Returned when the user does not have permission to access the resource.`,
                404: `Resource was not found.`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * @param id
     * @returns commandImportFromFileDataResponse A successful response.
     * @throws ApiError
     */
    public static commandsGetImportFromFileData(
        id: string,
    ): CancelablePromise<commandImportFromFileDataResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/commands/import-from-file/{id}/data',
            path: {
                'id': id,
            },
            errors: {
                400: `Invalid argument`,
                401: `Returned when the user does not have permission to access the resource.`,
                404: `Resource was not found.`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * @param body
     * @returns commandImportFromFileResponse A successful response.
     * @throws ApiError
     */
    public static commandsPostImportFromIngest(
        body: commandImportFromIngestRequest,
    ): CancelablePromise<commandImportFromFileResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/commands/import-from-ingest',
            body: body,
            errors: {
                400: `Invalid argument`,
                401: `Returned when the user does not have permission to access the resource.`,
                404: `Resource was not found.`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * @param body
     * @returns commandPolicyIngestResponse A successful response.
     * @throws ApiError
     */
    public static commandsPostIngest(
        body: commandPolicyIngestRequest,
    ): CancelablePromise<commandPolicyIngestResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/commands/ingest',
            body: body,
            errors: {
                400: `Invalid argument`,
                401: `Returned when the user does not have permission to access the resource.`,
                404: `Resource was not found.`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * @param id
     * @returns apiHttpBody A successful response.
     * @throws ApiError
     */
    public static commandsGetIngestedPolicy(
        id: string,
    ): CancelablePromise<apiHttpBody> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/commands/ingest/{id}/data',
            path: {
                'id': id,
            },
            errors: {
                400: `Invalid argument`,
                401: `Returned when the user does not have permission to access the resource.`,
                404: `Resource was not found.`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * @param id
     * @returns apiHttpBody A successful response.
     * @throws ApiError
     */
    public static commandsGetIngestLog(
        id: string,
    ): CancelablePromise<apiHttpBody> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/commands/ingest/{id}/log',
            path: {
                'id': id,
            },
            errors: {
                400: `Invalid argument`,
                401: `Returned when the user does not have permission to access the resource.`,
                404: `Resource was not found.`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * @param body
     * @returns commandResponse A successful response.
     * @throws ApiError
     */
    public static commandsPostList(
        body: commandProviderRequest,
    ): CancelablePromise<commandResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/commands/list',
            body: body,
            errors: {
                400: `Invalid argument`,
                401: `Returned when the user does not have permission to access the resource.`,
                404: `Resource was not found.`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * @param id
     * @returns commandBigipListResult A successful response.
     * @throws ApiError
     */
    public static commandsGetListAwaf(
        id: string,
    ): CancelablePromise<commandBigipListResult> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/commands/list/{id}/awaf',
            path: {
                'id': id,
            },
            errors: {
                400: `Invalid argument`,
                401: `Returned when the user does not have permission to access the resource.`,
                404: `Resource was not found.`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * @param id
     * @returns apiHttpBody A successful response.
     * @throws ApiError
     */
    public static commandsGetListLog(
        id: string,
    ): CancelablePromise<apiHttpBody> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/commands/list/{id}/log',
            path: {
                'id': id,
            },
            errors: {
                400: `Invalid argument`,
                401: `Returned when the user does not have permission to access the resource.`,
                404: `Resource was not found.`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * @param id
     * @returns commandNapListResult A successful response.
     * @throws ApiError
     */
    public static commandsGetListNap(
        id: string,
    ): CancelablePromise<commandNapListResult> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/commands/list/{id}/nap',
            path: {
                'id': id,
            },
            errors: {
                400: `Invalid argument`,
                401: `Returned when the user does not have permission to access the resource.`,
                404: `Resource was not found.`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * @param id
     * @returns commandStatusResponse A successful response.
     * @throws ApiError
     */
    public static commandsGetStatus(
        id: string,
    ): CancelablePromise<commandStatusResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/commands/{id}',
            path: {
                'id': id,
            },
            errors: {
                400: `Invalid argument`,
                401: `Returned when the user does not have permission to access the resource.`,
                404: `Resource was not found.`,
                500: `Internal server error`,
            },
        });
    }

}
