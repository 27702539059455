import styled from "styled-components";
import {Drawer} from "@emerald/react";

type PaddingProps = {
  pl?: number,
  pt?: number,
  pb?: number,
  pr?: number
}

export const Drawer100 = styled(Drawer)`
  height: 100%;

  > div > div {
    height: 100%;
  }
`

export const BodyForm = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 20px;
  height: 100%
`
export const BodyFormFull = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%
`
export const PaddingHor = styled.div`
  padding: 0 20px;
  height: 100%;
  width: 100%;
`

export const SuperSmallSubText = styled.span`
  font-size: 8px;
  color: #6C778C;
`
export const HeaderHeader = styled.div`
  font-size: 18px;
  line-height: 26px;
  font-weight: bold;
`
export const HeaderText = styled.div`
  font-size: 18px;
  line-height: 26px;
`
export const HeaderSubText = styled.div`
  font-size: 14px;
  line-height: 20px;
`
export const Content = styled.div`
  flex: 1;
  position: relative;
`

export const ScrollerContentFixed = styled.div`
  width: 99%;
`

export const Legend = styled.div`
  margin-bottom: 20px;
`

export const Header = styled.div<PaddingProps>`
  min-height: 58px;
  padding-top: ${props => props.pt ? `${props.pt}px` : null};
  padding-bottom: ${props => props.pb ? `${props.pb}px` : null};
  padding-left: ${props => props.pl ? `${props.pl}px` : null};
  padding-right: ${props => props.pr ? `${props.pr}px` : null};
`

export const Footer = styled.div<PaddingProps>`
  margin-top: auto;
  min-height: 72px;
  padding-top: ${props => props.pt ? `${props.pt}px` : null};
  padding-bottom: ${props => props.pb ? `${props.pb}px` : null};
  padding-left: ${props => props.pl ? `${props.pl}px` : null};
  padding-right: ${props => props.pr ? `${props.pr}px` : null};
`