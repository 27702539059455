import React from "react";
import styled from "styled-components";
import emptyGroupImg from "../assets/emptyProviders.png";
import {Align, Button, Card} from "@emerald/react";
import {Col} from "./Col";
import { useNavigate } from "react-router-dom";
import { LOCATIONS, PATHS } from "../navigation";

const ContentCard = styled(Card)`
  justify-content: center;
  text-align: center;
  width: 100%;
`
const ButtonDiv = styled.div`
  margin-top: 20px;
`
const Content = styled.div`
  width: 100%;
`

type Props = {
    title?: string;
    description?: string;
};

export const CommonEmptyComponent = ({ title, description}: Props) => {
    const navigate = useNavigate()
    const onViewPoliciesClick = () => navigate(`${PATHS[LOCATIONS.policies]}`);

    return <Content>
        <ContentCard>
            <div><img src={emptyGroupImg} alt='empty'/></div>
            <div>
                <h3>{title}</h3>
            </div>
            <div>{description}</div>
            <ButtonDiv>
                <Col style={{alignItems: 'center'}}>
                    <Align>
                        <Button onClick={onViewPoliciesClick}>View Policies</Button>
                    </Align>
                </Col>
            </ButtonDiv>
        </ContentCard>
    </Content>
}