import React, {useCallback, useEffect, useMemo} from "react";
import {Select, Button, Icon} from "@emerald/react";
import {useSelector} from "react-redux";
import {addProviderAgentsSelector, addProviderIsLoadingAgentsSelector} from "./selectors";
import {useAppDispatch} from "../../store";
import {fetchAgents} from "./actions";
import {Label} from "../../components/Label";
import {providerProviderType} from "core-lib";
import { Row } from "../../components/Row";
import { Col } from "../../components/Col";
import {CircularProgress} from "@mui/material";

type AgentSelectProps = {
    providerType: providerProviderType,
    value?: string,
    onChange: (value: string) => void,
    onAddAgentClick: () => void
}

const agentTip = 'Agents run within your production network and work as a conduit between the Policy Supervisor ' +
    'service and your Provider Systems.  Agents must be running, installed by you and correctly configured before ' +
    'they appear on this list.'

export const AgentSelect = (props: AgentSelectProps) => {

    const dispatch = useAppDispatch()
    const agents = useSelector(addProviderAgentsSelector)
    const isAgentsLoading = useSelector(addProviderIsLoadingAgentsSelector)

    const {onChange, onAddAgentClick} = props;

    useEffect(() => {
        dispatch(fetchAgents())
    }, [dispatch])

    const onRefreshClick = useCallback(() => {
        dispatch(fetchAgents())
    }, [dispatch])

    const onAddClick = useCallback(() => {
        onAddAgentClick()
    }, [onAddAgentClick])

    const onChangeSelect = useCallback((agentId: any) => {
        onChange(agentId)
    }, [onChange])

    const options = useMemo(() => ([{
        items: agents.map(agent => ({
            value: agent.id || '',
            label: agent.name || ''
        }))
    }]), [agents])

    return <div>
        <Label required tip={agentTip}>Select Agent</Label>
        <Row fullWidth spacing={10}>
            <Col flex={1}>
                <Select
                    value={props.value}
                    options={options}
                    onChange={onChangeSelect}
                    allowClearSelection={false}
                    allowSelectAll={false}
                    allowSearch={true}
                />
            </Col>
            <Col centerVertical center width={32}>
                {!isAgentsLoading && <Button displayMode="ghostInk" onClick={onRefreshClick}>
                    <Icon icon="app.refresh" />
                </Button>}
                {isAgentsLoading && <CircularProgress
                    variant="indeterminate"
                    disableShrink
                    sx={{
                        color: '#0F1E57'
                    }}
                    size={16}
                    thickness={2}
                />}
            </Col>
        </Row>
        <Button displayMode='link' size='slim' onClick={onAddClick}>
            <Icon icon="app.plus"/>
            <span>Add new agent</span>
        </Button>
    </div>
}