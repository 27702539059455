import React, { useCallback, useMemo, useState } from "react";
import { Col } from "../../../components/Col";
import { DissmisibleMessage } from "../../../components/DissmisibleMessage";
import { conversionReportError } from "./util/useGetConversionReportErrorData";

type Props = {
    errors: conversionReportError[];
    type: "WARNING" | "ERROR";
    mt?: number;
    mb?: number;
    ml?: number;
    mr?: number;
}

export const ConversionReportErrorComponent = ({ type, errors, mt, mr, ml, mb }: Props) => {

    const [closedErrors, setClosedErrors] = useState<boolean[]>([])

    const onErrorClose = useCallback((index: number) => {
        setClosedErrors(prevState => {
            const newState = [...prevState]
            newState[index] = true
            return newState
        })
    }, [setClosedErrors])

    const showErrors = useMemo(() =>
        closedErrors.filter(error => !!error).length < errors.length
        , [closedErrors, errors])

    return showErrors ? <Col spacing={16} mt={mt} ml={ml} mr={mr} mb={mb}>
        {errors.map((error, index) => <DissmisibleMessage key={index} type={type} title={error.title} text={error.text} open={!closedErrors[index]} onClose={() => onErrorClose(index)} />)}
    </Col> : null
}