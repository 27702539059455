import {AppDispatch, RootState} from "../../store";
import {apiHttpBody, conversionConversionPolicy, ConversionsService, downloadFile} from "core-lib";
import {conversionSlice} from "./reducer";
import { ConvertFormData } from "./utils";
import {createErrorObject} from "../Errors/util";
import {errorActionRequest} from "../Errors/actions";

export const convert = (values: ConvertFormData) => {    
    return async (dispatch: AppDispatch) => {
        const {source, target, fileData} = values || {}

        let conversionFunction = (body: conversionConversionPolicy) => new Promise<apiHttpBody>(() => {})

        if(source === "AWAF" && target === "NAP")
            conversionFunction = ConversionsService.conversionsManualConversionAwaFtoNap
        else if(source === "AWAF" && target === "XC")
            conversionFunction = ConversionsService.conversionsManualConversionAwaFtoXc
        else if(source === "NAP" && target === "AWAF")
            conversionFunction = ConversionsService.conversionsManualConversionNaPtoAwaf
        else if(source === "NAP" && target === "XC")
            conversionFunction = ConversionsService.conversionsManualConversionNaPtoXc

            
        dispatch(conversionSlice.actions.setIsLoading(true))
        return conversionFunction({policy: fileData})
            .then(response => {
                if(response) {
                    dispatch(conversionSlice.actions.setIsSuccess(true))
                    dispatch(conversionSlice.actions.setResponse(response as string || ''))
                } else {
                    throw createErrorObject('Could not convert the file', 'The server returned empty result')
                }
            })
            .catch(err => {
                dispatch(conversionSlice.actions.setIsError(true))
                errorActionRequest(err);
            }).finally(() => {
                dispatch(conversionSlice.actions.setIsLoading(false))
            })
    }
}

export const downloadConvertedFile = (name: string) => {
    return async (dispatch: AppDispatch, getState: () => RootState) => {
        const fileData = getState().manualConversion.response
        const fileName = `${name.substring(0, name.lastIndexOf("."))}-converted.zip`;
        downloadFile(fileData || '', fileName, "application/octet-stream")
    }
}

export const reset = () => {
    return async (dispatch: AppDispatch) => {
        dispatch(conversionSlice.actions.resetState())
    }
}