import {
    BodyForm,
    Footer,
    Header,
    HeaderHeader,
    HeaderSubText,
    Legend
} from "../../../components/styled/Drawer";
import {Divider} from "../../../components/Divider";
import {Align, Button} from "@emerald/react";
import {Col} from "../../../components/Col";
import {Row} from "../../../components/Row";
import React from "react";
import {useSelector} from "react-redux";
import {
    policyDeployCProvidersByTypeSelector,
    policyDeployEndpointsSelector,
    policyDeployPolicySelector,
    policyDeployProviderTypesSelectedSelector
} from "../selectors";
import {PROVIDERS_INFO} from "../../../constants";
import {providerProviderType} from "core-lib";
import {RootState} from "../../../store";
import styled from "styled-components";
import {getEndpointFromFullId} from "../helpers";
import {ScrollableContent} from "../../../components/PageScrollable";

const ListTitleRow = styled(Row)`
  margin: 20px 0;
  color: #6C778C;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
`
const ListRow = styled(Row)`
  margin: 0 0 20px 0;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
`
const TypeBlock = styled.div`
  margin-bottom: 40px;
`
const TypeTitle = styled.div`
  color: #0B1640;
  margin: 0 0 5px 0;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
`

const EndpointsListAWAF = () => {

    const providersByType = useSelector((state: RootState) => policyDeployCProvidersByTypeSelector(state, providerProviderType.AWAF))
    const endpoints = useSelector(policyDeployEndpointsSelector)

    return <TypeBlock>
        <TypeTitle>{PROVIDERS_INFO[providerProviderType.AWAF].name}</TypeTitle>
        <Divider/>
        <ListTitleRow>
            <Col flex={1}>Instances</Col>
            <Col flex={1}>Partitions / Virtual Servers</Col>
        </ListTitleRow>
        {providersByType.map(provider => provider.id ? <ListRow key={provider.id}>
            <Col flex={1}>{provider.name}</Col>
            <Col flex={1}>{endpoints[provider.id] ? endpoints[provider.id].map(fullEndpointId => {
                const [partition, endpointName] = getEndpointFromFullId(fullEndpointId)
                return <div key={fullEndpointId}>{partition}&nbsp;/&nbsp;{endpointName}</div>
            }) : '-'}</Col>
        </ListRow> : null)}
    </TypeBlock>
}

const EndpointsListXC = () => {

    const providersByType = useSelector((state: RootState) => policyDeployCProvidersByTypeSelector(state, providerProviderType.XC))
    const endpoints = useSelector(policyDeployEndpointsSelector)

    return <TypeBlock>
        <TypeTitle>{PROVIDERS_INFO[providerProviderType.XC].name}</TypeTitle>
        <Divider/>
        <ListTitleRow>
            <Col flex={1}>Tenants</Col>
            <Col flex={1}>Namespace / Load Balancers</Col>
        </ListTitleRow>
        {providersByType.map(provider => provider.id ? <ListRow key={provider.id}>
            <Col flex={1}>{provider.name}</Col>
            <Col flex={1}>{endpoints[provider.id] ? endpoints[provider.id].map(fullEndpointId => {
                const [namespace, endpointName] = getEndpointFromFullId(fullEndpointId)
                return <div key={fullEndpointId}>{namespace}&nbsp;/&nbsp;{endpointName}</div>
            }) : '-'}</Col>
        </ListRow> : null)}
    </TypeBlock>
}

const EndpointsListNAP = () => {
    
    const providersByType = useSelector((state: RootState) => policyDeployCProvidersByTypeSelector(state, providerProviderType.NAP))
    const endpoints = useSelector(policyDeployEndpointsSelector)

    return <TypeBlock>
        <TypeTitle>{PROVIDERS_INFO[providerProviderType.NAP].name}</TypeTitle>
        <Divider/>
        <ListTitleRow>
            <Col flex={1}>Tenants</Col>
            <Col flex={1}>Servers</Col>
        </ListTitleRow>
        {providersByType.map(provider => provider.id ? <ListRow key={provider.id}>
            <Col flex={1}>{provider.name}</Col>
            <Col flex={1}>{endpoints[provider.id] ? endpoints[provider.id].map(name => {
                return <div key={name}>{name}</div>
            }) : '-'}</Col>
        </ListRow> : null)}
    </TypeBlock>
}


type Props = {
    onBack: () => void,
    onNext: () => void
}

export const EndpointsStaticScreen = (props: Props) => {

    const policy = useSelector(policyDeployPolicySelector)
    const providerTypesToDeploySelected = useSelector(policyDeployProviderTypesSelectedSelector)

    return <BodyForm>
        <Header>
            <HeaderHeader>Deployment Attachments for Selected Providers</HeaderHeader>
            <HeaderSubText>Review deployment and attachment configurations</HeaderSubText>
        </Header>
        <ScrollableContent>
            <Divider mb={17}></Divider>
            <Legend>Policy: {policy?.metadata?.name}</Legend>
            {providerTypesToDeploySelected.map(type => <div key={type}>
                {type === providerProviderType.AWAF && <EndpointsListAWAF/>}
                {type === providerProviderType.XC && <EndpointsListXC/>}
                {type === providerProviderType.NAP && <EndpointsListNAP/>}
            </div>)}
        </ScrollableContent>
        <Footer>
            <Divider/>
            <Col flex={1} rightDirection>
                <Row spacing={20} style={{marginTop: 10}}>
                    <Align>
                        <Button accent={true} displayMode="outlined" onClick={props.onBack}>Back</Button>
                    </Align>
                    <Align>
                        <Button accent={true} onClick={props.onNext}>Deploy</Button>
                    </Align>
                </Row>
            </Col>
        </Footer>
    </BodyForm>
}