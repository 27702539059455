import React, {useCallback, useEffect, useState} from "react";
import {useAppDispatch} from "../../store";
import {createProvider, testConnection, updateProvider} from "./actions";
import styled from "styled-components";
import {providerResponse, providerProviderType} from "core-lib";
import {Row} from "../../components/Row";
import {Col} from "../../components/Col";
import {Align, Button, Icon} from "@emerald/react";
import {Stack} from "../../components/Stack";
import {Divider} from "../../components/Divider";
import {CircularProgress} from "@mui/material";
import {useSelector} from "react-redux";
import {
    addProviderAgentsSelector,
    addProviderSecretsSelector,
    closeConnectionTextSelector
} from "./selectors";
import onboardingIMG from "../../assets/onboarding.png"
import {GraySpan} from "../../components/styled";
import {Tag} from "../../components/Tag";
import {BodyForm, Header, HeaderText} from "../../components/styled/Drawer";
import warningIMG from "../../assets/warning.png";
import {ScrollableContent} from "../../components/PageScrollable";
import { providersListSlice } from "../ProvidersList/reducer";
import { policyDeploySlice } from "../PolicyDeploy/reducer";
import { providerIngestionSlice } from "../ProviderIngestion/reducer";

const DetailLabel = styled.div`
  font-weight: bold;
  padding: 8px 0;
`
const DetailRow = styled(Row)`
  margin-top: 15px;
  padding: 0 10px;
`
const SpinnerRow = styled(Row)`
  padding: 40px;
  justify-content: center;
`
const TestRow = styled(Row)`
  font-size: 18px;
  line-height: 26px;
  padding: 8px 0;
  color: #3183B2;
  justify-content: center;

  &.success {
    color: #3D1754;
  }
`
const TestText = styled.div`
  margin-top: 8px;
`
const SuccessText = styled.div`
  color: #3183B2;
  font-weight: 700;
  font-size: 24px;
  line-height: 36px;
`
const IncompleteText = styled.div`
  margin-top: 10px;
  color: #FFC400;;
  font-weight: 700;
  font-size: 24px;
  line-height: 36px;
`
const IconGreen = styled(Icon)`
  color: #00A980;
`

type TestScreenProps = {
    provider: providerResponse,
    onClose: () => void,
    onAddAnotherClick: () => void,
    onEditButtonClick: (provider: providerResponse) => void
}

export const TestConnectionScreen = (props: TestScreenProps) => {

    const dispatch = useAppDispatch()
    const agents = useSelector(addProviderAgentsSelector)
    const secrets = useSelector(addProviderSecretsSelector)
    const closeTestConnectionText = useSelector(closeConnectionTextSelector)

    const [isProviderUpdating, setIsProviderUpdating] = useState(false)
    const [providerUpdateResult, setProviderUpdateResult] = useState<boolean | undefined>(undefined)
    const [isConnectionTesting, setIsConnectionTesting] = useState(false)
    const [testConnectionResult, setTestConnectionResult] = useState<boolean | undefined>(undefined)

    const [providerUpdated, setProviderUpdated] = useState<providerResponse | null>(null)

    const agent = agents.find(item => item.id === props.provider.agentId)
    const secret = secrets.find(item => item.id === props.provider.secretId)

    const {provider, onEditButtonClick, onClose, onAddAnotherClick} = props;

    const getUpdateAction = useCallback(() => {
        return provider.id ? updateProvider(provider.id, provider) : createProvider(provider)
    }, [provider])

    const onSuccess = useCallback(() => {
        dispatch(providersListSlice.actions.resetProviders());
        dispatch(policyDeploySlice.actions.setProviders(null));
    }, [dispatch])

    const onIngestConfiguration = useCallback(() => {
        dispatch(providerIngestionSlice.actions.openProviderIngestion(providerUpdated));
        onClose();
    }, [dispatch, onClose, providerUpdated])

    const onConnectionTested = useCallback(() => {
        dispatch(providersListSlice.actions.resetProviders());
    }, [dispatch])

    const startTestConnection = useCallback((provider: providerResponse) => {
        setIsConnectionTesting(true)
        setTestConnectionResult(undefined)
        dispatch(testConnection(provider))
            .then((result) => {
                setTestConnectionResult(result)
            })
            .catch(() => {
                setTestConnectionResult(false)
            })
            .finally(() => {
                setIsConnectionTesting(false)
                onConnectionTested()
            })
    }, [dispatch, setIsConnectionTesting, setTestConnectionResult, onConnectionTested])

    useEffect(() => {

        setProviderUpdated(null)

        setProviderUpdateResult(undefined)
        setTestConnectionResult(undefined)
        setIsConnectionTesting(false)
        setIsProviderUpdating(true)

        dispatch(getUpdateAction())
            .then((response) => {
                const provider = response as providerResponse
                setProviderUpdated(provider)
                setProviderUpdateResult(true)
                onSuccess()
                startTestConnection(provider)
            })
            .catch((_e) => {
                setProviderUpdateResult(false)
            })
            .finally(() => {
                setIsProviderUpdating(false)
            })
    }, [
        dispatch,
        getUpdateAction,
        provider,
        onSuccess,
        setIsProviderUpdating,
        setProviderUpdateResult,
        setIsConnectionTesting,
        setTestConnectionResult,
        setProviderUpdated,
        startTestConnection
    ])

    const onEditProviderClick = useCallback(() => {
        onEditButtonClick(providerUpdated || provider)
    }, [providerUpdated, provider, onEditButtonClick])

    const isProcessing = isProviderUpdating || isConnectionTesting
    const showResult = typeof providerUpdateResult !== 'undefined' &&
        (!providerUpdateResult || typeof testConnectionResult !== 'undefined')

    return <BodyForm>
        <Header>
            <HeaderText>Test Connection</HeaderText>
        </Header>
        <ScrollableContent>
            <Divider/>
            <DetailRow>
                <Col flex={1}>
                    <DetailLabel>Provider Type</DetailLabel>
                    <div><GraySpan>{provider.type}</GraySpan></div>
                </Col>
                <Col flex={1}>
                    <DetailLabel>Provider Name</DetailLabel>
                    <div><GraySpan>{provider.name}</GraySpan></div>
                </Col>
            </DetailRow>
            <DetailRow>
                <Col flex={1}>
                    <DetailLabel>Provider URI</DetailLabel>
                    <div>{provider.url}</div>
                </Col>
                <Col flex={1}>
                    <DetailLabel>Agent</DetailLabel>
                    <div>{agent?.name}</div>
                </Col>
            </DetailRow>
            <DetailRow>
                <Col flex={1}>
                    <DetailLabel>Labels</DetailLabel>
                    <div><Stack direction="row" spacing={5}>
                        {provider.labels?.map(
                            (label: string) => (<Tag key={label} isActionable={false}>{label}</Tag>)
                        )}
                    </Stack></div>
                </Col>
                <Col flex={1}>
                    <DetailLabel>Secret Used</DetailLabel>
                    <div>{secret?.name}</div>
                </Col>
            </DetailRow>
            <SpinnerRow>
                {isProcessing && <Col fullWidth>
                    <Row fullWidth centerHorizontal>
                        <CircularProgress
                            variant="indeterminate"
                            disableShrink
                            sx={{
                                color: '#3183B2'
                            }}
                            size={85}
                            thickness={2}
                        />
                    </Row>
                    <TestRow fullWidth centerHorizontal mt={10}>
                        <Col>
                            <TestText>Connecting to agent...</TestText>
                            <TestText>Connecting to Provider...</TestText>
                            <TestText>Sending secrets...</TestText>
                            <TestText>Retrieving Provider data...</TestText>
                        </Col>
                    </TestRow>
                </Col>}
                {showResult && <Col fullWidth>
                    <Row fullWidth>
                        {providerUpdateResult && testConnectionResult && <Col fullWidth center>
                            <Row><img src={onboardingIMG} alt='success'/></Row>
                            <Row><SuccessText>Success!</SuccessText></Row>
                        </Col>}
                        {(!providerUpdateResult || !testConnectionResult) && <Col fullWidth center>
                            <img src={warningIMG} alt='incomplete'/>
                            <IncompleteText>Incomplete!</IncompleteText>
                        </Col>}
                    </Row>
                    <TestRow fullWidth centerHorizontal>
                        {!providerUpdateResult && <Col>
                            <TestText>Updating provider info <IconGreen icon="status.error"/></TestText>
                            <TestText>Connecting to agent</TestText>
                            <TestText>Retrieving Secret Info</TestText>
                            <TestText>Connection testing</TestText>
                        </Col>}
                        {providerUpdateResult && !testConnectionResult && <Col>
                            <TestText><IconGreen icon="app.check"/> Connected to agent</TestText>
                            <TestText><IconGreen icon="app.check"/> Retrieved Secret Info</TestText>
                            <TestText><IconGreen icon="status.error"/> Connection testing</TestText>
                        </Col>}
                        {providerUpdateResult && testConnectionResult && <Col>
                            <TestText><IconGreen icon="app.check"/> Connected to agent</TestText>
                            <TestText><IconGreen icon="app.check"/> Retrieved Secret Info</TestText>
                            <TestText><IconGreen icon="app.check"/> Connection tested</TestText>
                        </Col>}
                    </TestRow>
                </Col>}
            </SpinnerRow>
            {showResult && providerUpdateResult && testConnectionResult && <div>
                <Row fullWidth centerHorizontal spacing={10} mt={10}>
                    <Align>
                        <Button onClick={onIngestConfiguration} disabled={provider.type === providerProviderType.XC} accent={true} displayMode="outlined" >Ingest
                            Configuration</Button>
                    </Align>
                    <Align>
                        <Button accent={true} onClick={onAddAnotherClick}>Add another Provider</Button>
                    </Align>
                </Row>
                <Row fullWidth centerHorizontal mt={10}>
                    <Button displayMode="linkInk" onClick={onClose}>
                        {closeTestConnectionText || 'Go to overview'}
                    </Button>
                </Row>
            </div>}
            {showResult && (!providerUpdateResult || !testConnectionResult) && <div>
                <Row fullWidth centerHorizontal spacing={10} mt={10}>
                    <Align>
                        <Button displayMode="outlined" onClick={onEditProviderClick}>Back</Button>
                    </Align>
                    <Align>
                        <Button displayMode="linkInk" onClick={onClose}>
                            {closeTestConnectionText || 'Go to overview'}
                        </Button>
                    </Align>
                </Row>
            </div>}
        </ScrollableContent>
    </BodyForm>
}