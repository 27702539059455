import React, {useCallback, useState} from "react";
import {useSelector} from "react-redux";
import {conversionResultSelector, policyConversionIsOpenSelector} from "./selectors";
import {useAppDispatch} from "../../store";
import {policyConversionSlice} from "./reducer";
import {Drawer100} from "../../components/styled/Drawer";
import {SelectProviderScreen} from "./stages/SelectProviderScreen";
import {ConversionSummaryScreen} from "./stages/ConversionSummaryScreen";
import {ConversionResultScreen} from "./stages/ConversionResultScreen";
import {cleanAllDeploymentsScreenCommands} from "./actions";
import { policyDetailsSlice } from "../PolicyDetails/reducer";
import { policiesListSlice } from "../PoliciesList/reducer";

enum STAGES {
    SELECT_PROVIDERS = 'SELECT_PROVIDERS',
    SUMMARY = 'SUMMARY',
    RESULT = 'RESULT'
}

const STAGES_AVAILABLE = [
    STAGES.SELECT_PROVIDERS,
    STAGES.SUMMARY,
    STAGES.RESULT,
]

export const PolicyConversionDrawerComponent = () => {

    const isOpen = useSelector(policyConversionIsOpenSelector)
    const dispatch = useAppDispatch()
    const [stage, setStage] = useState(STAGES_AVAILABLE[0])
    const conversionResult = useSelector(conversionResultSelector)
    const isConversionReport = conversionResult !== null && stage === STAGES.SUMMARY

    const setNextStage = () => {
        const nextStage = STAGES_AVAILABLE[STAGES_AVAILABLE.indexOf(stage) + 1]
        if (!nextStage) {
            return onFinish()
        }
        setStage(nextStage)
    }
    const setPrewStage = () => {
        const nextStage = STAGES_AVAILABLE[STAGES_AVAILABLE.indexOf(stage) - 1]
        if (!nextStage) {
            return onDrawerClose()
        }
        setStage(nextStage)
    }

    const onDrawerClose = useCallback(() => {
        setStage(STAGES_AVAILABLE[0])
        dispatch(cleanAllDeploymentsScreenCommands())
        dispatch(policyConversionSlice.actions.clean())
    }, [setStage, dispatch])

    const onFinish = useCallback(() => {
        dispatch(policiesListSlice.actions.resetPolicies())
        dispatch(policyDetailsSlice.actions.setPolicyId(null))
        onDrawerClose();
    }, [onDrawerClose, dispatch])


    return <>
        <Drawer100 open={isOpen} hideActionsRow hideBreadcrumbs {...(isConversionReport ? { width: '950px' } : null)} themeOverride={{ spacing: { horizontal: 0, vertical: 0 }}} onAfterClose={onDrawerClose}>
            {stage === STAGES.SELECT_PROVIDERS && <SelectProviderScreen onBack={setPrewStage} onNext={setNextStage} />}
            {stage === STAGES.SUMMARY && <ConversionSummaryScreen onBack={setPrewStage} onNext={setNextStage}/>}
            {stage === STAGES.RESULT && <ConversionResultScreen onBack={setPrewStage} onNext={setNextStage}/>}
        </Drawer100>
    </>
}