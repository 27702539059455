/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { apiHttpBody } from '../models/apiHttpBody';
import type { conversionConversionPolicy } from '../models/conversionConversionPolicy';
import type { conversionListConversionResponse } from '../models/conversionListConversionResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ConversionsService {

    /**
     * @param body
     * @returns apiHttpBody A successful response.
     * @throws ApiError
     */
    public static conversionsManualConversionAwaFtoNap(
        body: conversionConversionPolicy,
    ): CancelablePromise<apiHttpBody> {
        return __request({...OpenAPI, BASE: "https://api.conversion.policysupervisor.io"}, {
            method: 'POST',
            url: '/api/v1/conversion/AWAF/NAP',
            body: body,
            errors: {
                400: `Invalid argument`,
                401: `Returned when the user does not have permission to access the resource.`,
                404: `Resource was not found.`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * @param body
     * @returns apiHttpBody A successful response.
     * @throws ApiError
     */
    public static conversionsManualConversionAwaFtoXc(
        body: conversionConversionPolicy,
    ): CancelablePromise<apiHttpBody> {
        return __request({...OpenAPI, BASE: "https://api.conversion.policysupervisor.io"}, {
            method: 'POST',
            url: '/api/v1/conversion/AWAF/XC',
            body: body,
            errors: {
                400: `Invalid argument`,
                401: `Returned when the user does not have permission to access the resource.`,
                404: `Resource was not found.`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * @param body
     * @returns apiHttpBody A successful response.
     * @throws ApiError
     */
    public static conversionsManualConversionNaPtoAwaf(
        body: conversionConversionPolicy,
    ): CancelablePromise<apiHttpBody> {
        return __request({...OpenAPI, BASE: "https://api.conversion.policysupervisor.io"}, {
            method: 'POST',
            url: '/api/v1/conversion/NAP/AWAF',
            body: body,
            errors: {
                400: `Invalid argument`,
                401: `Returned when the user does not have permission to access the resource.`,
                404: `Resource was not found.`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * @param body
     * @returns apiHttpBody A successful response.
     * @throws ApiError
     */
    public static conversionsManualConversionNaPtoXc(
        body: conversionConversionPolicy,
    ): CancelablePromise<apiHttpBody> {
        return __request({...OpenAPI, BASE: "https://api.conversion.policysupervisor.io"}, {
            method: 'POST',
            url: '/api/v1/conversion/NAP/XC',
            body: body,
            errors: {
                400: `Invalid argument`,
                401: `Returned when the user does not have permission to access the resource.`,
                404: `Resource was not found.`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * @param orderDate
     * @param orderPolicyName
     * @param orderSource
     * @param orderTarget
     * @param orderCreatedAt
     * @param orderUpdatedAt
     * @param filterPolicyName
     * @param filterSource
     * @param filterTarget
     * @param filterPolicyId
     * @param page
     * @param size
     * @returns conversionListConversionResponse A successful response.
     * @throws ApiError
     */
    public static conversionsListConversions(
        orderDate: 'DESCENDING' | 'ASCENDING' = 'DESCENDING',
        orderPolicyName: 'DESCENDING' | 'ASCENDING' = 'DESCENDING',
        orderSource: 'DESCENDING' | 'ASCENDING' = 'DESCENDING',
        orderTarget: 'DESCENDING' | 'ASCENDING' = 'DESCENDING',
        orderCreatedAt: 'DESCENDING' | 'ASCENDING' = 'DESCENDING',
        orderUpdatedAt: 'DESCENDING' | 'ASCENDING' = 'DESCENDING',
        filterPolicyName?: Array<string>,
        filterSource?: Array<string>,
        filterTarget?: Array<string>,
        filterPolicyId?: Array<string>,
        page?: number,
        size?: number,
    ): CancelablePromise<conversionListConversionResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/conversions',
            query: {
                'order.date': orderDate,
                'order.policyName': orderPolicyName,
                'order.source': orderSource,
                'order.target': orderTarget,
                'order.createdAt': orderCreatedAt,
                'order.updatedAt': orderUpdatedAt,
                'filter.policyName': filterPolicyName,
                'filter.source': filterSource,
                'filter.target': filterTarget,
                'filter.policyId': filterPolicyId,
                'page': page,
                'size': size,
            },
            errors: {
                400: `Invalid argument`,
                401: `Returned when the user does not have permission to access the resource.`,
                404: `Resource was not found.`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * @param id
     * @returns apiHttpBody A successful response.
     * @throws ApiError
     */
    public static conversionsDownloadConvertedPolicy(
        id: string,
    ): CancelablePromise<apiHttpBody> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/conversions/{id}/converted-policy',
            path: {
                'id': id,
            },
            errors: {
                400: `Invalid argument`,
                401: `Returned when the user does not have permission to access the resource.`,
                404: `Resource was not found.`,
                500: `Internal server error`,
            },
        });
    }

}
