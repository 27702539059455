import React, { useState } from "react";
import { Card, Layout, Icon } from "@emerald/react";
import styled from "styled-components";
import f5Logo from "../../assets/F5 Logo.png";
import { Row } from "../../components/Row";
import { Col } from "../../components/Col";
import { ConvertComponent } from "./ConvertComponent";
import { InProgressComponent } from "./InProgressComponent";
import { SuccessComponent } from "./SuccessComponent";
import { useAppDispatch } from "../../store";
import { convert, reset } from "./actions";
import { ConvertFormData } from "./utils";

const ContentDiv = styled.div`
  padding: 20px;
  height: 1px;
  min-height: 100%;
  box-sizing: border-box;
  display: flex;
    justify-content: center;
`
const DescriptionText = styled.div`
    font-size: 14px;
    font-weight: 300;
    line-height: 20px;
    letter-spacing: -0.25px;
    color: #0F1E57;
    a {
        color: #4F73FF;
        text-decoration: none;
    }
`
const HeaderDiv = styled.div`
height: 38px;
  padding: 10px;
`
const StyledLayout = styled(Layout)`
  min-height: 100vh;
`
const PSHeader = styled.div`
  font-size: 12px;
  line-height: 18px;
  height: 18px;
`
const PathHeader = styled.div`
  font-size: 18px;
`
const LogoWrapper = styled.div`    
    border-right: 1px solid #E6E9F3;
    margin-right: 10px;
    padding-right: 10px;
`
const CardTitle = styled.h3`
    margin-bottom: 45px;
`
const ContentWrapper = styled.div`
    width: 580px;
`
const CardStyled = styled(Card)`
  padding: 0 29px 29px;
  width: 100%;
`
const ContentHeader = styled.div`
    margin-bottom: 24px;
`
const InlineIconCenter = styled(Icon)`
    display: inline-block;
    vertical-align: text-top;
    color: #6C778C;
    margin-right: 3px;
`

type StageType = {
    [key: string]: { id: string, component: Function }
}

const stages: StageType = {
    convert: {
        id: "convert",
        component: ConvertComponent
    },
    in_progress: {
        id: "in_progress",
        component: InProgressComponent
    },
    success: {
        id: "success",
        component: SuccessComponent
    },
}

const initialValues = {
    name: "",
    source: "",
    target: "",
    fileData: "",
}

export function ConvertScreen() {
    const [flowStage, setFlowStage] = useState<string>(stages.convert.id)
    const [values, setValues] = useState<ConvertFormData>(initialValues)

    const dispatch = useAppDispatch()

    const handleConvert = () => {
        dispatch(convert(values))
    }

    const handleNext = () => {
        setFlowStage(prev => {
            switch (prev) {
                case stages.convert.id:
                    handleConvert()
                    return stages.in_progress.id
                case stages.in_progress.id:
                    return stages.success.id
                case stages.success.id:
                    return stages.convert.id
            }
            return stages.convert.id
        })
    }

    const handleReset = () => {
        setFlowStage(stages.convert.id)
        setValues(initialValues)
        dispatch(reset())
    }

    const AppbarContent = <div />

    const SidebarHeaderContent = <LogoWrapper>
        <a href='/'><img src={f5Logo} alt='F5 logo' /></a>
    </LogoWrapper>

    const HeaderContent = <HeaderDiv>
        <Row>
            <Col>{SidebarHeaderContent}</Col>
            <Col flex={1}>
                <PSHeader>
                    Policy Supervisor
                </PSHeader>
                <PathHeader>Conversion Utility</PathHeader>
            </Col>
        </Row>
    </HeaderDiv>

    const StageComponent = stages[flowStage].component

    return <StyledLayout
        edgeMode="inner"
        header={HeaderContent}
        headerActions={AppbarContent}>
        <ContentDiv>
            <ContentWrapper>
                <ContentHeader>
                    <h2>Policy Supervisor Conversion Utility</h2>
                    <DescriptionText>
                        This is a conversion-only feature. All fields are required. Please remember to download the converted file at the end. Additional instructions are available to
                        &nbsp;<a href="https://github.com/f5devcentral/ps-convert" target="_blank" rel="noreferrer">Learn More</a>&nbsp;&nbsp;
                        <InlineIconCenter icon='app.externalLink' size='xs' />
                    </DescriptionText>
                </ContentHeader>
                <CardStyled>
                    <CardTitle>Conversion Only</CardTitle>
                    <StageComponent onNext={handleNext} onReset={handleReset} values={values} setValues={setValues} />
                </CardStyled>
            </ContentWrapper>
        </ContentDiv>
    </StyledLayout>
}
