import {getWorkspaceIdFromURL} from "core-lib";

const workspaceId = getWorkspaceIdFromURL()

export enum LOCATIONS {
    policies = 'policies',
    providers = 'providers',
    conversions = 'conversions',
    deployments = 'deployments',
    reports = 'reports',
    convert = 'convert',
    releaseNotes = 'releaseNotes'
}

export const PATHS: { [key: string]: string } = {
    [LOCATIONS.policies]: `/workspaces/${workspaceId}/policies`,
    [LOCATIONS.providers]: `/workspaces/${workspaceId}/providers`,
    [LOCATIONS.conversions]: `/workspaces/${workspaceId}/conversions`,
    [LOCATIONS.deployments]: `/workspaces/${workspaceId}/deployments`,
    [LOCATIONS.reports]: `/workspaces/${workspaceId}/reports`,
    [LOCATIONS.convert]: `/convert`,
    [LOCATIONS.releaseNotes]: `/release-notes`,
}