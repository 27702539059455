/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { inviteCreateRequest } from '../models/inviteCreateRequest';
import type { inviteListResponse } from '../models/inviteListResponse';
import type { inviteResponse } from '../models/inviteResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class InvitesService {

    /**
     * @param filterUserEmail
     * @param orderUserEmail
     * @param orderCreatedAt
     * @param page
     * @param size
     * @returns inviteListResponse A successful response.
     * @throws ApiError
     */
    public static invitesListInvites(
        filterUserEmail?: Array<string>,
        orderUserEmail: 'DESCENDING' | 'ASCENDING' = 'DESCENDING',
        orderCreatedAt: 'DESCENDING' | 'ASCENDING' = 'DESCENDING',
        page?: number,
        size?: number,
    ): CancelablePromise<inviteListResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/invites',
            query: {
                'filter.userEmail': filterUserEmail,
                'order.userEmail': orderUserEmail,
                'order.createdAt': orderCreatedAt,
                'page': page,
                'size': size,
            },
            errors: {
                400: `Invalid argument`,
                401: `Returned when the user does not have permission to access the resource.`,
                404: `Resource was not found.`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * @param body
     * @returns inviteResponse A successful response.
     * @throws ApiError
     */
    public static invitesCreateInvite(
        body: inviteCreateRequest,
    ): CancelablePromise<inviteResponse> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/v1/invites',
            body: body,
            errors: {
                400: `Invalid argument`,
                401: `Returned when the user does not have permission to access the resource.`,
                404: `Resource was not found.`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * @param id
     * @returns inviteResponse A successful response.
     * @throws ApiError
     */
    public static invitesGetInvite(
        id: string,
    ): CancelablePromise<inviteResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/invites/{id}',
            path: {
                'id': id,
            },
            errors: {
                400: `Invalid argument`,
                401: `Returned when the user does not have permission to access the resource.`,
                404: `Resource was not found.`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * @param id
     * @param force
     * @returns any A successful response.
     * @throws ApiError
     */
    public static invitesDeleteInvite(
        id: string,
        force?: boolean,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/v1/invites/{id}',
            path: {
                'id': id,
            },
            query: {
                'force': force,
            },
            errors: {
                400: `Invalid argument`,
                401: `Returned when the user does not have permission to access the resource.`,
                404: `Resource was not found.`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * @param id
     * @returns any A successful response.
     * @throws ApiError
     */
    public static invitesAcceptInvite(
        id: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/invites/{id}',
            path: {
                'id': id,
            },
            errors: {
                400: `Invalid argument`,
                401: `Returned when the user does not have permission to access the resource.`,
                404: `Resource was not found.`,
                500: `Internal server error`,
            },
        });
    }

}
