import React, { useCallback, useMemo, useState } from "react";
import { policyPolicy, cdpResponseMetadata, policyPolicyList } from "core-lib";
import { Align, FormField, Input, Button, Select } from "@emerald/react";
import { Divider } from "../../components/Divider";
import { Row } from "../../components/Row";
import { Col } from "../../components/Col";
import { BodyForm, Header, Legend, Footer, HeaderText } from "../../components/styled/Drawer";
import { TagsField } from "../../components/TagsField";
import { ScrollableContent } from "../../components/PageScrollable";
import { INITIAL_POLICY } from "./EditPolicyComponent";

const MAX_POLICY_NAME_LENGTH = 62;

export const EditPolicyForm = (props: {
    policyId: string | null,
    policy: policyPolicy | null,
    basePolicies: policyPolicyList | null,
    onCloseClick: () => void,
    onSuccess: (values: policyPolicy) => void
}) => {

    const { onSuccess, policy, basePolicies, policyId } = props

    const [values, setValues] = useState<cdpResponseMetadata>(props.policy?.metadata || {
        name: '',
        labels: []
    })
    const [nameError, setNameError] = useState<string>('');
    const [templateId, setTemplateId] = useState<string>('');

    const templateOptions = useMemo(() =>
    ([{
        items: basePolicies?.Policies?.map((item) => ({
            value: item.id || '',
            label: item.metadata?.name || ''
        })) || []
    }])
        , [basePolicies])

    const selectedTemplate = useMemo(() =>
        basePolicies?.Policies?.find((item) => item.id === templateId) || null
        , [basePolicies, templateId])

    const validateName = (value: string) => {
        setNameError('');

        if (!/^\S+$/.test(value)) {
            setNameError('Policy Name cannot include whitespace.');
        } else if (value.length > MAX_POLICY_NAME_LENGTH) {
            setNameError(`Policy Name cannot have more than ${MAX_POLICY_NAME_LENGTH} characters.`);
        }
    }

    const setName = useCallback((event: any) => {
        const name = event.target.value;
        validateName(name);

        setValues(values => ({
            ...values,
            name: name
        }))
    }, [setValues])
    const setLabels = useCallback((selectedTags: string[]) => {
        setValues(values => ({
            ...values,
            labels: selectedTags.map(labelStr => ({ key: labelStr, value: labelStr }))
        }))
    }, [setValues])

    const labels = values.labels
    const labelsValues = useMemo<string[]>(() => {
        return labels ? labels.map(label => label.value).filter(labelValue => !!labelValue) as string[] : []
    }, [labels])

    const onSubmit = useCallback(() => {

        if (!policyId) {
            onSuccess({
                ...(selectedTemplate ? selectedTemplate : INITIAL_POLICY),
                metadata: {
                    ...selectedTemplate?.metadata,
                    name: values.name,
                    labels: values.labels
                }
            })
        } else {
            onSuccess({
                ...policy,
                metadata:
                {
                    ...(policy?.metadata ? policy.metadata : {}),
                    name: values.name,
                    labels: values.labels
                },
            })
        }
    }, [onSuccess, policy, values, selectedTemplate, policyId])

    return <BodyForm>
        <Header>
            <HeaderText>{policyId ? 'Edit policy' : 'Create a new policy'}</HeaderText>
        </Header>
        <ScrollableContent>
            <Legend>Create a new policy.</Legend>
            <FormField required={true} hasError={!!nameError} errorMessage={nameError} label="Policy Name">
                <Input value={values.name} onChange={setName} />
            </FormField>
            <FormField label="Labels">
                <TagsField value={labelsValues} onChange={setLabels} />
            </FormField>
            {!policyId && <FormField label="Template">
                <Select options={templateOptions}
                    placeholder={"Select a template to apply ..."}
                    onChange={(value: any) => setTemplateId(value)}
                    allowClearSelection={true}
                    allowSelectAll={false}
                    allowSearch={true}
                />
            </FormField>}
        </ScrollableContent>
        <Footer>
            <Divider />
            <Col flex={1} rightDirection>
                <Row spacing={20} style={{ marginTop: 10 }}>
                    <Align>
                        <Button accent={true} displayMode="outlined" onClick={props.onCloseClick}>Cancel</Button>
                    </Align>
                    <Align>
                        <Button accent={true} disabled={!!nameError} onClick={onSubmit}>Continue</Button>
                    </Align>
                </Row>
            </Col>
        </Footer>
    </BodyForm>
}