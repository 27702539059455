import {RootState} from "../../store";
import {deploymentDeploymentInfo} from "core-lib";
import {createSelector} from "@reduxjs/toolkit";
import {deploymentsListItem} from "./types";

export const deploymentsListSelector = (state: RootState): Array<deploymentDeploymentInfo> => state.deploymentsList.data.deployments || [];
export const deploymentsListTotalCount = (state: RootState): number =>
    state.deploymentsList.data.totalCount ? Number(state.deploymentsList.data.totalCount) : 0;
export const deploymentsListArraySelector = createSelector(
    [deploymentsListSelector],
    (deploymentsList) => {
        return deploymentsList.map((item: deploymentDeploymentInfo) => ({
            ...item,
            dateTimestamp: item.date ? new Date(item.date).getTime() : 0
        })) as deploymentsListItem[]
    })
