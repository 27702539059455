/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { aggregate_reportReport } from '../models/aggregate_reportReport';
import type { aggregate_reportReportList } from '../models/aggregate_reportReportList';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class AggregateService {

    /**
     * @param filterPolicyId
     * @param filterProviderId
     * @param orderCreatedAt
     * @param orderUpdatedAt
     * @param page
     * @param size
     * @returns aggregate_reportReportList A successful response.
     * @throws ApiError
     */
    public static aggregateAggregateListReports(
        filterPolicyId?: Array<string>,
        filterProviderId?: Array<string>,
        orderCreatedAt: 'DESCENDING' | 'ASCENDING' = 'DESCENDING',
        orderUpdatedAt: 'DESCENDING' | 'ASCENDING' = 'DESCENDING',
        page?: number,
        size?: number,
    ): CancelablePromise<aggregate_reportReportList> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/aggregate/reports',
            query: {
                'filter.policyId': filterPolicyId,
                'filter.providerId': filterProviderId,
                'order.createdAt': orderCreatedAt,
                'order.updatedAt': orderUpdatedAt,
                'page': page,
                'size': size,
            },
            errors: {
                400: `Invalid argument`,
                401: `Returned when the user does not have permission to access the resource.`,
                404: `Resource was not found.`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * @param id
     * @returns aggregate_reportReport A successful response.
     * @throws ApiError
     */
    public static aggregateAggregateGetReport(
        id: string,
    ): CancelablePromise<aggregate_reportReport> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/aggregate/reports/{id}',
            path: {
                'id': id,
            },
            errors: {
                400: `Invalid argument`,
                401: `Returned when the user does not have permission to access the resource.`,
                404: `Resource was not found.`,
                500: `Internal server error`,
            },
        });
    }

}
