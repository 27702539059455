export type PsVersionInfo = {
    title: string;
    date: Date;
    notes: string[]
}

export const versionInfo: PsVersionInfo[] = [
    {
        title: "v0.18.15",
        date: new Date("2024-03-12"),
        notes: [            
            "Fix: Content profile headers AWAF -> CDP -> XC correct convert",
            "Fix: Error handling when no LB found for provider",            
        ]
    },
    {
        title: "v0.18.14",
        date: new Date("2023-01-25"),
        notes: [            
            "Fix: Multiple combination content profiles for the same URL is supported (AWAF -> OneWAF -> XC)",            
        ]
    },
    {
        title: "v0.18.13",
        date: new Date("2023-01-20"),
        notes: [            
            "Feature: Convert to XC leaves exculede signatures when switching to medium-high accuracy",
            "Fix: Content Profiles support (OneWAF and XC)",
        ]
    },
    {
        title: "v0.18.12",
        date: new Date("2023-01-08"),
        notes: [            
            "Feature: Support for old BigIP XML files. Ref: K75434850",
        ]
    },
    {
        title: "v0.18.11",
        date: new Date("2023-12-21"),
        notes: [
            "Feature: Disallowed urls support in CDP as a separate TrustLists key: DisallowUrlListCustomizations",
            "Feature: Disallow URLs are dployed to XC as a separate Service Policy",
        ]
    },
    {
        title: "v0.18.10",
        date: new Date("2023-12-08"),
        notes: [
            "Fix: Deploy converted trusted clients to XC load balancer",
            "Fix: Use disable_suppression by default for XC firewall",
            "Fix: Rename service policy from allowed_headers to mandatory_headers",
        ]
    },
    {
        title: "v0.18.9",
        date: new Date("2023-11-30"),
        notes: [
            "Fix: Add 100-103, 226 to default status codes",
            "Fix: Increase Max Signature Overrides to 150",
            "Fix: Signature staging period is 7 days by default",
            "Feature: Allow IPs moved from Service Policy to Load Balancer's trusted clients",
            "Feature: Content profiles are supported for URLs",
            "Architecture: Extract proto definitions to external library",
        ]
    },
    {
        title: "v0.18.8",
        date: new Date("2023-11-16"),
        notes: [
            "Fix: sensitive parameters in convert to XC",
            "Fix: threat-campaigns structure in convert to XC",
            "Fix: signatures staging in convert to XC",
        ]
    },
    {
        title: "v0.18.7",
        date: new Date("2023-11-13"),
        notes: [
            "Add default allow HTTP Response Codes",
        ]
    },
    {
        title: "v0.18.6",
        date: new Date("2023-11-10"),
        notes: [
            "Support for EXACT match to separate regext and values match",
            "Convert to XC only when MatchAction avaiable",
            "Negative Security policy overrides moved from Service Policy to WAF Exception Rules",
        ]
    },
    {
        title: "v0.18.5",
        date: new Date("2023-11-08"),
        notes: [
            "Remove not supported blocks for signature overrides",
        ]
    },
    {
        title: "v0.18.4",
        date: new Date("2023-11-08"),
        notes: [
            "WAF Exclusion rules bugfixes",
        ]
    },
    {
        title: "v0.18.3",
        date: new Date("2023-11-08"),
        notes: [
            "XC Conversion: Add only required http-headers to XC",
            "XC Conversion: Any signature overrides without URL goes to LB",
            "XC Conversion: Group service policy rules by path",
            "XC Conversion: Fix empty SA policy creation",
            "XC Conversion: Fix missing NegativeSecurityOverrides for Parameters",            
        ]
    },
    {
        title: "v0.18.2",
        date: new Date("2023-11-07"),
        notes: [
            "XC Conversion: Specific URLS go to service policy.",
            "XC Conversion: Global parameters, cookies, headers go to LB exclusion rules. Merged to one rule.",
            "XC Conversion: Make sure remove query_parameters, cookies, heders, etc from Service Policies.",
            "XC Conversion: Http headers - check for allow. Add check_present flag.",            
        ]
    },
    {
        title: "v0.18.1",
        date: new Date("2023-11-06"),
        notes: [            
            "Postprocessing of XC and CDP policies to correspond to correct items grouping",
            "Postprocessing to multiply signature override items for each signature",
            "Update load balancer related code to support WAF Exclusion Rules",
            "Convert xml uses additional isolated partition",
            "Support for multiple Service Policies",
            "Global Parameters (no URL specified) and URL signature overrides go to WAF exclusion rules",
            "Parameters, headers and cookies with URL specified go to service policy",
            "If there are more than 50 global signature overrides, they are not created. Instead, High-Medium Signature Selection By Accuracy mode is set",
            "Service policies are created in specific order. Starting with Method policies",
            "Headers, cookies which has wildcards are skipped",
            "Fixed Disabled Violations conversion (CDP to XC)",
            "Fixed rules decoupling for signatures and parameters/cookies/urls",
            "Context and Corresponding context name are filled for local parameters/urls/cookies for both: Service Policies and WAF Exclusion Rules",
            "Removed first empty service policy creation",
        ]
    },
]
