import {createSlice, PayloadAction} from '@reduxjs/toolkit'

const initialState = {
    error401: false,
    errors: [] as {id: number, error: any}[],
    currentErrorId: 0
}

type StateType = typeof initialState;

export const errorSlice = createSlice({
    name: 'ERRORS',
    initialState: initialState as StateType,
    reducers: {
        set401Error: (state: StateType , action: PayloadAction<boolean>) => {
            state.error401 = action.payload;
        },
        addError: (state: StateType , action: PayloadAction<any>) => {
            state.errors.push({id: state.currentErrorId++, error: action.payload})
        },
        removeErrorById: (state: StateType , action: PayloadAction<number>) => {
            const errorIndex = state.errors.findIndex(item => item.id === action.payload)
            if(errorIndex > -1) {
                state.errors.splice(errorIndex, 1)
            }
        }
    }
});
