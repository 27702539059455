import {AppDispatch} from "../../store";
import {InvitesService} from "core-lib";
import {errorActionRequest} from "../Errors/actions";

export const fetchInvite = (inviteId: string) => {
    return async (_dispatch: AppDispatch) => {
        return InvitesService.invitesGetInvite(inviteId)
    }
}

export const acceptInvite = (inviteId: string) => {
    return async (_dispatch: AppDispatch) => {
        return InvitesService.invitesAcceptInvite(inviteId)
            .catch(error => {
                errorActionRequest(error);
                throw error
            })
    }
}