import {AppDispatch} from "../../store";
import {ConversionsService, CommandsService, downloadFile, PoliciesService} from "core-lib";
import {errorActionRequest} from "../Errors/actions";
import {conversionsListSlice} from "./reducer";
import {createErrorObject} from "../Errors/util";
import { PAGINATION_FIRST_PAGE, PAGINATION_HUGE_SIZE } from "../../constants";

export const fetchConversions = () => {
    return async (dispatch: AppDispatch) => {
        return ConversionsService.conversionsListConversions(
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            PAGINATION_FIRST_PAGE,
            PAGINATION_HUGE_SIZE)
            .then(response => {
                dispatch(conversionsListSlice.actions.loadConversions(response));
            })
            .catch(error => {
                errorActionRequest(error);
            })
    }
}

export const downloadConversion = (id: string, filename: string) => {
    return async (_dispatch: AppDispatch) => {
        return ConversionsService.conversionsDownloadConvertedPolicy(id)
            .then(response => {
                if(response) {
                    downloadFile(JSON.stringify(response), filename)
                } else {
                    throw createErrorObject('Could not download the file', 'The server returned empty result')
                }
            })
            .catch(error => {
                errorActionRequest(error);
            })
    }
}

export const downloadConversionLogs = (id: string, filename: string) => {
    return async (_dispatch: AppDispatch) => {
        return CommandsService.commandsGetConvertReportZip(id)
            .then(response => {
                if(response) {
                    downloadFile(response as string, filename, "octet/stream")
                } else {
                    throw createErrorObject('Could not download the file', 'The server returned empty result')
                }
            })
            .catch(error => {
                errorActionRequest(error);
            })
    }
}

export const downloadPolicy = (id: string, filename: string) => {
    return async (_dispatch: AppDispatch) => {
        return PoliciesService.policiesExportPolicy(id)
            .then(response => {
                if(response) {
                    downloadFile(JSON.stringify(response), filename)
                } else {
                    throw createErrorObject('Could not download the file', 'The server returned empty result')
                }
            })
            .catch(error => {
                errorActionRequest(error);
            })
    }
}