import { AppDispatch, RootState } from "../../store";
import { errorActionRequest } from "../Errors/actions";
import { policyConversionSlice } from "./reducer";
import {
    commandResponse,
    commandJobStatus,
    CommandsService,
    providerProviderType,
} from "core-lib";
import { CommandStatusCheckManager } from "../../core/commands/CommandStatusCheckManager";
import { conversionCommandsIdsSelector } from "./selectors";

export const convertPolicy = (policyId: string, providerType: providerProviderType, notes: string) => {
    return async (dispatch: AppDispatch, getState: () => RootState) => {

        const setErrorResult = (type: providerProviderType) => {
            dispatch(policyConversionSlice.actions.addConversionResult({
                type: type,
                result: false
            }))
        }

        CommandStatusCheckManager.stopCommands(Object.values(conversionCommandsIdsSelector(getState())))
        dispatch(policyConversionSlice.actions.resetConversion())
        dispatch(policyConversionSlice.actions.setReportGroupId(''))
        
        const target = providerType;

        return CommandsService.commandsPostConvert({
            policyId,
            target,
            notes
        }).then((response: commandResponse) => {

            const commandId = response.id;        

            if (target && commandId) {
                dispatch(policyConversionSlice.actions.setReportGroupId(commandId || ''))

                dispatch(policyConversionSlice.actions.addConversionCommandId({
                    type: target,
                    commandId: commandId
                }));

                CommandStatusCheckManager.getCommandRunner(commandId)
                    .setOnResult((commandId, commandStatus) => {
                        if (commandStatus === commandJobStatus.JOB_SUCCESS) {
                            CommandsService.commandsGetConvertReport(commandId).then((result) => {
                                dispatch(policyConversionSlice.actions.addConversionResult({
                                    type: target,
                                    result: result
                                }))
                            }).catch(error => {
                                errorActionRequest(error)
                            })
                        } else {
                            setErrorResult(target)
                        }
                    })
                    .setOnError((_, e) => {
                        setErrorResult(target)
                        errorActionRequest(e)
                    })
            }
        }).catch(error => {
            setErrorResult(providerType)
            errorActionRequest(error);
            throw error
        })
    }
}

export const cleanAllDeploymentsScreenCommands = () => {
    return async (dispatch: AppDispatch, getState: () => RootState) => {
        const conversionCommandsIds = conversionCommandsIdsSelector(getState())

        CommandStatusCheckManager.stopCommands(Object.values(conversionCommandsIds))
    }
}
