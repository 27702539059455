import {
    BodyForm,
    Footer,
    Header,
    HeaderHeader,
    HeaderSubText,
    Legend
} from "../../../components/styled/Drawer";
import { Align, Button, ContentStatus, FormField, Icon, Select, TextArea } from "@emerald/react";
import { Divider } from "../../../components/Divider";
import { Col } from "../../../components/Col";
import { Row } from "../../../components/Row";
import React, { useCallback, useEffect } from "react";
import { useSelector } from "react-redux";
import {
    commitMessageSelector,
    policyDeployIsProvidersLoadingSelector,
    policyDeployPolicySelector,
    policyDeployProvidersForDeploySelector,
    policyDeployProvidersSelector, providersOptionsSelector
} from "../selectors";
import { useAppDispatch } from "../../../store";
import { policyDeploySlice } from "../reducer";
import { fetchDeploymentProviders } from "../actions";
import { ScrollableContent } from "../../../components/PageScrollable";

type Props = {
    onBack: () => void,
    onNext: () => void,
    onAddProviderClick: () => void
}

export const SelectProvidersScreen = (props: Props) => {

    const dispatch = useAppDispatch()
    const policy = useSelector(policyDeployPolicySelector)
    const providersForDeploy = useSelector(policyDeployProvidersForDeploySelector)
    const providers = useSelector(policyDeployProvidersSelector)
    const providersLoading = useSelector(policyDeployIsProvidersLoadingSelector)
    const options = useSelector(providersOptionsSelector)
    const commitMessage = useSelector(commitMessageSelector)
    const optionsSelected = providersForDeploy.map(provider => provider.id || '')

    useEffect(() => {
        if (providersLoading) {       
            dispatch(fetchDeploymentProviders())
        }
    }, [dispatch, providersLoading])

    const onCommitMessageChamge = useCallback((event: any) => {
        dispatch(policyDeploySlice.actions.setCommitMessage(event.target.value))
    }, [dispatch])

    const setProvidersForDeploy = useCallback((_value: string | string[] | undefined, selectedValues: string[] | undefined) => {
        const ids = selectedValues || []
        const providersToSet = providers?.filter(provider => ids.indexOf(provider.id || '') > -1) || []
        dispatch(policyDeploySlice.actions.setProvidersForDeploy(providersToSet))
    }, [dispatch, providers])

    return <BodyForm>
        <Header>
            <HeaderHeader>Deployment</HeaderHeader>
            <HeaderSubText>First, select your provider and proceed to conversion</HeaderSubText>
        </Header>
        <ScrollableContent>
            <Divider mb={17}></Divider>
            <Legend>Policy: {policy?.metadata?.name}</Legend>
            <FormField label="Provider" required>
                <Select options={options}
                    value={optionsSelected}
                    onChange={setProvidersForDeploy}
                    allowClearSelection={true}
                    allowSelectAll={true}
                    multiSelect={true}
                />
                <Button displayMode='link' size='slim' onClick={props.onAddProviderClick}>
                    <Icon icon="app.plus" />
                    <span>Add provider</span>
                </Button>
            </FormField>
            <FormField label="Deployment Note / Summary" required>
                <TextArea
                    value={commitMessage}
                    onChange={onCommitMessageChamge}
                    placeholder='Commit message for each deployment' />
            </FormField>
        </ScrollableContent>
        <Footer>
            <Divider />
            <Col flex={1} rightDirection>
                <Row spacing={20} style={{ marginTop: 10 }}>
                    <Align>
                        <Button accent={true} displayMode="outlined" onClick={props.onBack}>Cancel</Button>
                    </Align>
                    <Align>
                        <Button
                            disabled={!providersForDeploy.length || !commitMessage}
                            accent={true}
                            onClick={props.onNext}>
                            Conversion Summary
                        </Button>
                    </Align>
                </Row>
            </Col>
        </Footer>
        <ContentStatus
            isVisible={providersLoading}
            spinnerSize={"medium"}
            defaultMessage={<span>Loading providers</span>}
            fillOuterContainer={false}
        />
    </BodyForm>
}