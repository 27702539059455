import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useAppDispatch } from "../../store";
import { fetchProviders, removeProviders } from "./actions";
import { useSelector } from "react-redux";
import { providersListWithGroupsSelector, ProviderWithGroups } from "./selectors";
import { Button, Icon, Spinner } from "@emerald/react";
import styled from "styled-components";
import { EmptyProvidersComponent } from "./EmptyProvidersComponent";
import { Stack } from "../../components/Stack";
import { Tag } from "../../components/Tag";
import { PROVIDERS_INFO } from "../../constants";
import { providerResponse, providerProviderStatus, providerProviderType } from "core-lib";
import { Row } from "../../components/Row";
import { Col } from "../../components/Col";
import { useLocation, useNavigate } from "react-router-dom";
import { LOCATIONS, PATHS } from "../../navigation";
import { providerIngestionSlice } from "../ProviderIngestion/reducer";
import { providerDetailsSlice } from "../ProviderDetails/reducer";
import { providersListSlice } from "./reducer";
import { addProviderSlice } from "../ProviderEdit/reducer";
import { DataGrid } from "../../components/DataGrid";
import { DeleteItemsDialog } from "../../components/DeleteItemsDialog";

const Header = styled.div`
  font-weight: 700;
  font-size: 24px;
  line-height: 36px;
  margin-bottom: 10px;
`
const Content = styled.div`
  position: relative;
  height: 100%
`
const OneRowSpan = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`
const TypeImg = styled.img`
  width: 16px;
  height: 16px;
  margin-right: 10px;
  vertical-align: middle;
`
const NameSpan = styled.div`
  color: #4F73FF;
  cursor: pointer;
  vertical-align: middle;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  line-height: 20px;
  height: 20px;
`
const VerticalSpan = styled.span`
  vertical-align: middle;
`
const IconIcon = styled(Icon)`
  vertical-align: baseline;
`
const StatusIcon = styled.span`
  margin-right: 5px;
  vertical-align: middle;
`
const SpinnerIcon = styled(Spinner)`
  vertical-align: baseline;
`

const DataGridContainer = styled.div`
width: 100%
`

const PROVIDER_STATUSES = {
    [providerProviderStatus.PENDING]: 'Pending',
    [providerProviderStatus.CONNECTED]: 'Connected',
    [providerProviderStatus.NOT_CONNECTED]: 'Not connected'
}

const accessorWithGroup = (row: ProviderWithGroups, value: string | number | undefined): string => {
    if (row.id) {
        const typeRowIndex = Object.keys(PROVIDERS_INFO).indexOf(row.id || '')
        const itemTypeRowIndex = Object.keys(PROVIDERS_INFO).indexOf(row.type || '')
        const typeIndex = typeRowIndex < 0 ? Object.keys(PROVIDERS_INFO).indexOf(row.type || '') : typeRowIndex
        const indexStr = `${typeIndex}`.padStart(3, '0')
        const typeIndexStr = typeRowIndex < 0 ? '0'.padStart(3, '0') : `${itemTypeRowIndex}`.padStart(3, '0')
        return `${indexStr}${typeIndexStr} ${typeof value !== 'undefined' ? value : ''}`
    }
    return ''
}

const ProviderNameCell = (props: { row: ProviderWithGroups, onClick: (providerId: string | null) => void }) => {
    const { row, onClick } = props;

    const onProviderNameClick = useCallback(() => {
        onClick(row.provider?.id || null)
    }, [onClick, row])

    return <OneRowSpan>
        {row.isGroup && row.id ? <TypeImg src={PROVIDERS_INFO[row.id].icon} alt={row.name} /> : null}
        {row.isGroup ? <VerticalSpan>{row.name}</VerticalSpan> :
            <NameSpan onClick={onProviderNameClick}>{row.name}</NameSpan>}
    </OneRowSpan>
}

function ProviderStatusIconComponent(props: { status?: providerProviderStatus }) {
    return <StatusIcon>
        {props.status === providerProviderStatus.PENDING && <SpinnerIcon size="small" />}
        {props.status === providerProviderStatus.CONNECTED && <IconIcon size="small" icon="status.success" />}
        {props.status === providerProviderStatus.NOT_CONNECTED && <IconIcon size="small" icon="status.error" />}
    </StatusIcon>
}

export function ProvidersListComponent() {

    const location = useLocation()
    const initialProviderId = location.hash ? unescape(location.hash.substr(1)) : ''

    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const dataSource = useSelector(providersListWithGroupsSelector)
    const [deleting, setDeleting] = useState<boolean>(false)
    const [expandedItemIds, setExpandedItemIds] = useState<providerProviderType[]>([])
    const [itemsToDelete, setItemsToDelete] = useState<string[]>([])
    const isLoading = dataSource === null || deleting;
    const ref = useRef<HTMLDivElement>(null);

    const setProviderInfoId = useCallback((id?: string | null) => {
        dispatch(providerDetailsSlice.actions.setProviderId(id || ''))
    }, [dispatch])

    const dataSourceVisible = useMemo(() => dataSource?.filter(item => item.isGroup || (item.type && expandedItemIds.indexOf(item.type) > -1)) || [], [dataSource, expandedItemIds])
    const summaryItemCount = ((dataSource?.length || 0) - Object.values(PROVIDERS_INFO).length) || 0

    const refresh = useCallback(() => {
        dispatch(providersListSlice.actions.resetProviders());
    }, [dispatch])

    const removeAction = useCallback((ids: string[]) => {
        setItemsToDelete(ids)
    }, [setItemsToDelete])
    const onRemoveConfirmed = useCallback(() => {
        if (itemsToDelete) {
            setDeleting(true);
            dispatch(removeProviders(itemsToDelete))
        }
    }, [dispatch, itemsToDelete])
    const onRemoveCanceled = useCallback(() => {
        setItemsToDelete([])
    }, [setItemsToDelete])

    const setIngestionProviderInfo = useCallback((provider: providerResponse) => {
        dispatch(providerIngestionSlice.actions.openProviderIngestion(provider))
    }, [dispatch])

    useEffect(() => {
        if (initialProviderId) {
            setProviderInfoId(initialProviderId)
        }
    }, [initialProviderId, setProviderInfoId])

    useEffect(() => {
        if (dataSource === null) {
            dispatch(fetchProviders()).then(() => {
                setDeleting(false);
                setItemsToDelete([])
            });
        }
    }, [dispatch, dataSource]);

    const onAddProviderClick = useCallback(() => {
        dispatch(addProviderSlice.actions.setEditProviderData({ providerId: '' }))
    }, [dispatch])

    const columns: any = useMemo(() => [
        {
            accessor: (row: ProviderWithGroups) => accessorWithGroup(row, row.name),
            title: 'Providers',
            width: 400,
            filterable: true,
            filterMatch: (row: providerResponse, filter: string) => {
                return Object.keys(PROVIDERS_INFO).indexOf(row.id || '') > -1
                    || !!row.name?.match(new RegExp(filter, 'i'))
            },
            renderer: (row: ProviderWithGroups) => {
                return <ProviderNameCell row={row} onClick={setProviderInfoId} />
            }
        },
        {
            accessor: (row: ProviderWithGroups) => accessorWithGroup(row, 'Connected'),
            title: 'Status',
            width: 200,
            renderer: (row: ProviderWithGroups) => {
                if (row.isGroup) {
                    return '-'
                }
                return <OneRowSpan>
                    <ProviderStatusIconComponent status={row.status} />
                    <VerticalSpan>{row.status ? PROVIDER_STATUSES[row.status as providerProviderStatus] : '-'}</VerticalSpan>
                </OneRowSpan>
            }
        },
        {
            accessor: (row: ProviderWithGroups) => accessorWithGroup(row, row.deployments || 0),
            title: 'Deployments',
            width: 150,
            renderer: (row: ProviderWithGroups) => row.deployments || '-'
        },
        {
            accessor: (row: ProviderWithGroups) => accessorWithGroup(row, row.agentName),
            title: 'Agent',
            width: 250,
            renderer: (row: ProviderWithGroups) => row.agentName || '-'
        },
        {
            accessor: (row: ProviderWithGroups) => accessorWithGroup(row, row.labels?.join(', ')),
            title: 'Labels',
            filterable: true,
            renderer: (row: ProviderWithGroups) => {
                if (row.isGroup) {
                    return '-'
                }
                return <Stack direction="row" spacing={5}>
                    {row.labels?.map(
                        (label: string) => (<Tag key={label} isActionable={false}>{label}</Tag>)
                    )}
                </Stack>
            }
        }
    ], [setProviderInfoId])

    const rowActions = useCallback(({ item }) => {
        if (item.isGroup) {
            return []
        }
        return [
            {
                items: [
                    { value: "edit", icon: "app.edit", label: `Edit Provider` },
                    { value: "ingestPolicies", icon: "app.swap", label: `Ingest Policies`, disabled: item?.provider?.type === providerProviderType.XC },
                    { value: "deployments", icon: "app.start", label: `Deployments` },
                    { value: "viewDetails", icon: "app.import", label: `View Details` }
                ],
            },
            {
                items: [
                    { value: "delete", icon: "app.delete", label: `Delete` }
                ]
            }
        ]
    }, [])

    const handleRowAction = useCallback(({ item, action }) => {
        switch (action) {
            case 'edit':
                dispatch(addProviderSlice.actions.setEditProviderData({ providerId: item.provider?.id || '' }))
                break
            case "delete":
                removeAction([item.id || ''])
                break
            case "viewDetails":
                setProviderInfoId(item.provider?.id)
                break
            case "deployments":
                navigate(`${PATHS[LOCATIONS.deployments]}#${item.name}`)
                break
            case "ingestPolicies":
                setIngestionProviderInfo(item.provider as providerResponse)
                break

        }
    }, [setProviderInfoId, setIngestionProviderInfo, removeAction, navigate, dispatch]);

    const toolbarCustomFilter = useCallback(() => (<Button displayMode="ghostInk" onClick={refresh}>
        <Icon icon="app.refresh" />
        <span>Refresh</span>
    </Button>), [refresh])

    const hierarchyLevel = useCallback(({ item }) =>
        item ? (item.isGroup ? 0 : 1) : 0, []);

    const isItemExpandable = useCallback(({ item }: { item: any | undefined }) =>
        item ? item.isGroup : false, []);

    const handleRowCollapse = useCallback(({ itemId }: { itemId: string | number }) => {
        setExpandedItemIds(expandedItemIds.filter(type => type !== itemId) as providerProviderType[])
    }, [setExpandedItemIds, expandedItemIds]);
    const handleRowExpand = useCallback(({ itemId }: { itemId: string | number }) => {
        setExpandedItemIds([...expandedItemIds, itemId] as providerProviderType[])
    }, [setExpandedItemIds, expandedItemIds]);

    useEffect(() => {
        setTimeout(() => {
            if (ref.current) {
                const results = ref.current.querySelectorAll('div[data-selector^="DataGridRow.Styled.Hierarchy"]')
                results.forEach((item) => {
                    const row = item.parentElement?.parentElement
                    if (row && item.hasChildNodes()) {
                        const actions = row.querySelector('span[data-selector^="DataGridRow.Styled.ActionCell"]')
                        if (actions) {
                            actions.remove()
                        }
                    }
                })
            }
        })
    }, [dataSourceVisible])

    return <Content>

        <Col fullHeight>

            <Row fullWidth>
                <Col flex={1}><Header>Providers</Header></Col>
                <Col><Button accent={true} onClick={onAddProviderClick}>Add provider</Button></Col>
            </Row>

            <Row fullWidth flex={1}>
                {!isLoading && summaryItemCount === 0 ?
                    <EmptyProvidersComponent onButtonClick={onAddProviderClick} /> :
                    <DataGridContainer ref={ref}>
                        <DataGrid
                            isLoading={isLoading}
                            columns={columns}
                            items={dataSourceVisible}
                            itemIdAccessor='id'
                            showToolbar={true}
                            allowSelectAll={true}
                            toolbarCustomFilter={toolbarCustomFilter}
                            rowActions={rowActions}
                            onRowAction={handleRowAction}
                            allowExpandableRows={true}
                            expandableWidthMode="scrollWithRows"
                            expandableShowPinnedShadow={true}
                            itemHierarchyLevel={hierarchyLevel}
                            isItemExpandable={isItemExpandable}
                            onRowCollapse={handleRowCollapse}
                            onRowExpand={handleRowExpand}
                            virtualize={false}
                            summaryItemCount={summaryItemCount}
                        />
                    </DataGridContainer>
                }
            </Row>

        </Col>

        <DeleteItemsDialog
            title="Delete providers"
            actions={[
                {
                    accent: true,
                    onClick: onRemoveCanceled,
                    displayMode: "outlined",
                    children: "Cancel"
                },
                {
                    accent: true,
                    onClick: onRemoveConfirmed,
                    children: "Delete"
                }
            ]}
            description="Are you sure you want to delete the selected providers?"
            items={dataSource?.filter(item => itemsToDelete.includes(item.id || '')).map(item => item.name || '') || []}
        />

    </Content>
}