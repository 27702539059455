import {
    BodyFormFull,
    Footer,
    Header,
    HeaderHeader,
} from "../../../components/styled/Drawer";
import { Align, Button, FormField, Input, Select, TextArea } from "@emerald/react";
import { Divider } from "../../../components/Divider";
import { Col } from "../../../components/Col";
import { Row } from "../../../components/Row";
import React, { useCallback, useMemo } from "react";
import { useSelector } from "react-redux";
import {
    commitMessageSelector,
    policyConversionPolicySelector,
    policyConversionProviderTypeSelectedSelector
} from "../selectors";
import { useAppDispatch } from "../../../store";
import { policyConversionSlice } from "../reducer";
import { ScrollableContent } from "../../../components/PageScrollable";
import { PROVIDERS_INFO } from "../../../constants";
import { providerProviderType } from "core-lib";
import { GraySpan } from "../../../components/styled";
import styled from "styled-components";

const Label = styled.div`
font-size: 14px;
line-height: 20px;
`

const HelpMessage = styled(GraySpan)`
font-size: 12px;
line-height: 18px;
`

const LetterCount = styled.div`
font-size: 12px;
line-height: 16px;
font-weight: bold;
`

type Props = {
    onBack: () => void,
    onNext: () => void,
}

const MAX_COMMIT_MESSAGE_LENGTH = 50;

export const SelectProviderScreen = (props: Props) => {

    const dispatch = useAppDispatch()
    const policy = useSelector(policyConversionPolicySelector)
    const selectedValue = useSelector(policyConversionProviderTypeSelectedSelector)
    const commitMessage = useSelector(commitMessageSelector)

    const options = useMemo(() => ([{
        items: Object.keys(PROVIDERS_INFO).map((key) => ({ label: PROVIDERS_INFO[key].name, value: key }))
    }]), [])

    const setCommitMessage = useCallback((e: any) => {
        const value = e.target.value;
        if (value.length <= MAX_COMMIT_MESSAGE_LENGTH) {
            dispatch(policyConversionSlice.actions.setCommitMessage(value))
        }
    }, [dispatch])

    const setProvidersForConversion = useCallback((value: string | string[] | undefined) => {
        const type = (value || null) as providerProviderType
        dispatch(policyConversionSlice.actions.setProviderTypeSelected(type))
    }, [dispatch])

    return <BodyFormFull>
        <Header pr={20} pl={20}>
            <Col centerVertical fullHeight>
                <HeaderHeader>Conversion</HeaderHeader>
            </Col>
        </Header>
        <ScrollableContent mt={38} mr={20} ml={20}>
            <FormField label="Policy Name *">
                <Input themeOverride={{ color: { states: { disabled: { border: "#F7F8FA", fill: "white" } } } }} readOnly value={policy?.metadata?.name} />
            </FormField>
            <Row>
                <Col fullWidth><Label>Provider Type *</Label></Col>
                <Col fullWidth rightDirection><HelpMessage>Required</HelpMessage></Col>
            </Row>
            <Select options={options}
                value={selectedValue || undefined}
                onChange={setProvidersForConversion}
                allowClearSelection={false}
                allowSelectAll={false}
                multiSelect={false}
            />
            <Row mt={24}>
                <Col fullWidth><Label>Conversion Name / Summary *</Label></Col>
                <Col fullWidth rightDirection><HelpMessage>Required</HelpMessage></Col>
            </Row>
            <TextArea
                value={commitMessage}
                onChange={setCommitMessage}
                placeholder='Commit message for Conversion' />
            <Row>
                <Col fullWidth rightDirection><LetterCount>{commitMessage.length}/{MAX_COMMIT_MESSAGE_LENGTH}</LetterCount></Col>
            </Row>
        </ScrollableContent>
        <Footer>
            <Divider />
            <Col flex={1} rightDirection mr={20}>
                <Row spacing={20} mt={10}>
                    <Align>
                        <Button accent={true} displayMode="outlined" onClick={props.onBack}>Cancel</Button>
                    </Align>
                    <Align>
                        <Button
                            disabled={!selectedValue || !commitMessage}
                            accent={true}
                            onClick={props.onNext}>
                            Continue
                        </Button>
                    </Align>
                </Row>
            </Col>
        </Footer>
    </BodyFormFull>
}