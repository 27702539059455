import styled from "styled-components";

interface Props {
    height?: number
    width?: number
    percentWidth?: number
    flex?: number
    rightDirection?: boolean
    center?: boolean
    centerVertical?: boolean
    spacing?: number
    fullHeight?: boolean
    fullWidth?: boolean
    mt?: number
    mb?: number
    ml?: number
    mr?: number
}

export const Col = styled.div<Props>`
  display: flex;
  flex-direction: column;
  height: ${props => props.fullHeight ? '100%' : props.height ? props.height+'px' : null};
  width: ${props => props.fullWidth ? '100%' : props.width ? props.width+'px' : null};
  flex: ${props => props.flex};
  align-items: ${props => props.center ? 'center' : props.rightDirection ? 'flex-end' : null};
  justify-content: ${props => props.centerVertical ? 'center' : null};
  gap: ${props => props.spacing ? `${props.spacing}px` : null};
  margin-top: ${props => props.mt ? `${props.mt}px` : null};
  margin-bottom: ${props => props.mb ? `${props.mb}px` : null};
  margin-left: ${props => props.ml ? `${props.ml}px` : null};
  margin-right: ${props => props.mr ? `${props.mr}px` : null};
`