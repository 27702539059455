import React from 'react';
import { Provider } from "react-redux";
import store from "./store";
import { DashboardComponent } from "./features/Dashboard/DashboardComponent";
import { EmeraldReact } from "@emerald/react";
import { configure } from "@emerald/react";
import { Product } from "@f5/design-system-themes";
import { F5 } from "@f5/design-system-icons";
import { AuthLock } from "./features/auth/AuthLock";
import { ErrorsComponent } from "./features/Errors/ErrorsComponent";
import { getWorkspaceIdFromURL, OpenAPI, setOpenAPIPathname } from "core-lib";
import { API_URI } from "./constants";
import { PSIcons } from './assets/ControlIcons/control-icons.lookup';

const uri = new URL(API_URI)

OpenAPI.BASE = uri.origin
OpenAPI.WITH_CREDENTIALS = true
OpenAPI.HEADERS = {
    'X-Workspace-Key': getWorkspaceIdFromURL()
}
setOpenAPIPathname(uri.pathname)
console.log('OpenAPI.BASE', OpenAPI.BASE)

configure({
    iconLookup: (name) => {
        const psIcon = PSIcons.lookup(name);
        return (psIcon ?? F5.lookup(name)) as any
    }
});

const LightTheme = Product.F5_BIGIPCM_light;
//const DarkTheme = Product.F5_BIGIPCM_dark;

EmeraldReact.ThemeProvider.registerTheme("custom", LightTheme)

function App() {
    return (
        <Provider store={store}>
            <div className="App">
                <EmeraldReact.ThemeProvider theme="custom">
                    <AuthLock>
                        <DashboardComponent />
                    </AuthLock>
                    <ErrorsComponent />
                </EmeraldReact.ThemeProvider>
            </div>
        </Provider>
    );
}

export default App;
