import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {agentAgent, secretResponse} from "core-lib";

type EditProviderData =  {
    providerId: string | null,
    closeConnectionText?: string
}

const initialState = {
    data: { providerId: null } as EditProviderData,
    agents: [] as Array<agentAgent>,
    secrets: [] as Array<secretResponse>,
    isLoadingAgents: false,
    isLoadingSecrets: false
}

type StateType = typeof initialState;

export const addProviderSlice = createSlice({
    name: 'ADD_PROVIDER',
    initialState: initialState as StateType,
    reducers: {
        setEditProviderData: (state: StateType , action: PayloadAction<EditProviderData>) => {
            state.data = action.payload;
        },
        setIsLoadingAgents: (state: StateType , action: PayloadAction<boolean>) => {
            state.isLoadingAgents = action.payload
        },
        setIsLoadingSecrets: (state: StateType , action: PayloadAction<boolean>) => {
            state.isLoadingSecrets = action.payload
        },
        loadAgents: (state: StateType , action: PayloadAction<Array<agentAgent>>) => {
            state.agents = action.payload
        },
        loadSecrets: (state: StateType , action: PayloadAction<Array<secretResponse>>) => {
            state.secrets = action.payload
        },
        close: (state: StateType) => {
            state.data = { providerId: null };
        },
    }
});
