import React, { useCallback, useEffect, useMemo, useState } from "react";
import { fetchPolicy, fetchDeployments, fetchConversions } from "./actions";
import { useAppDispatch } from "../../store";
import {
    conversionListConversionResponse,
    deploymentListDeploymentResponse,
    policyPolicy,
} from "core-lib";
import styled from "styled-components";
import { Button, ContentStatus, Drawer, Icon } from "@emerald/react";
import { BodyFormFull, Header, HeaderText } from "../../components/styled/Drawer";
import { ScrollableContent } from "../../components/PageScrollable";
import { Row } from "../../components/Row";
import { Col } from "../../components/Col";
import { Accordion } from "../../components/Accordion";
import { LOCATIONS, PATHS } from "../../navigation";
import { Link } from "../../components/styled/Link";
import { Divider } from "../../components/Divider";
import { useSelector } from "react-redux";
import { policyIdSelector, shouldRefreshSelector } from "./selectors";
import { policyDetailsSlice } from "./reducer";
import { downloadPolicy } from "../PoliciesList/actions";
import { policiesListSlice } from "../PoliciesList/reducer";
import { policyEditSlice } from "../PolicyEdit/reducer";
import { policyDeploySlice } from "../PolicyDeploy/reducer";
import { policyConversionSlice } from "../PolicyConversion/reducer";
import { POLICIES_PROVIDERS_INFO } from "../../constants";

const FullHeight = styled.div`
  height: 100%;
`

const CloseIcon = styled(Icon)`
  color: #6C778C;
  font-size: 8px !important;
  height: 8px !important;
  line-height: 8px !important;
`

const ButtonIcon = styled(Icon)`
  font-size: 16px !important;
  height: 16px !important;
  line-height: 16px !important;
  color: #9EA7B8
`

const PanelContentBody = styled(Col)`
flex: 1;
font-weight: 400;
font-size: 12px;
line-height: 18px; 
`

const PanelContentLabel = styled(Col)`
font-weight: 400;
font-size: 12px;
line-height: 18px;
color: #6C778C;
width: 235px;
`

const TextContainer = styled.div`
max-width: 150px;
overflow: hidden;
text-overflow: ellipsis;
white-space: nowrap;
`

export const PolicyDetailsComponent = () => {
    const dispatch = useAppDispatch()
    const policyId = useSelector(policyIdSelector);
    const shouldRefresh = useSelector(shouldRefreshSelector);

    const [policyDetails, setPolicyDetails] = useState<policyPolicy | null>(null)
    const [deployments, setDeployments] = useState<deploymentListDeploymentResponse | null>(null)
    const [conversions, setConversions] = useState<conversionListConversionResponse | null>(null)

    const onCloseClick = useCallback(() => dispatch(policyDetailsSlice.actions.setPolicyId(null)), [dispatch])

    const rowActions = useMemo(() => [
        { value: "edit", icon: "app.edit", label: `Edit` },
        { value: "convert", icon: "app.swap", label: `Convert` },
        { value: "deploy", icon: "app.start", label: `Deploy` },
        { value: "exportjson", icon: "app.export", label: `Export` },
        { value: "delete", icon: "app.delete", label: `Delete` }
    ], [])

    const handleRowAction = useCallback((action: string) => {
        switch (action) {
            case "edit":
                policyDetails?.id && dispatch(policyEditSlice.actions.setPolicyId(policyDetails.id));
                break
            case "deploy":
                policyDetails && dispatch(policyDeploySlice.actions.openPolicyDeploy(policyDetails));
                break
            case "convert":
                policyDetails && dispatch(policyConversionSlice.actions.openPolicyConversion(policyDetails));
                break
            case "delete":
                policyDetails?.id && dispatch(policiesListSlice.actions.setItemsToDelete([policyDetails.id]));
                break
            case "exportjson":
                policyDetails?.id && dispatch(downloadPolicy(policyDetails.id, `${policyDetails?.metadata?.name} CDP.json`))
                break
        }
    }, [dispatch, policyDetails]);

    useEffect(() => {
        setDeployments(null)
        setConversions(null)
        setPolicyDetails(null)

        if (policyId !== null || shouldRefresh) {
            if (policyId !== null) {
                dispatch(fetchPolicy(policyId)).then((policyDetails) => {
                    policyDetails && setPolicyDetails(policyDetails)
                })
                dispatch(fetchDeployments(policyId)).then((deployments) => {
                    deployments && setDeployments(deployments)
                })
                dispatch(fetchConversions(policyId)).then((conversions) => {
                    conversions && setConversions(conversions)
                })
                dispatch(policyDetailsSlice.actions.setShouldRefresh(false))
            }
        }
    }, [dispatch, policyId, shouldRefresh])

    const isLoading = useMemo(() => deployments === null || conversions === null || policyDetails === null, [conversions, deployments, policyDetails])

    return <Drawer hideOverlay hideActionsRow themeOverride={{ spacing: { horizontal: 0, vertical: 0 } }} open={policyId !== null} hideBreadcrumbs>
        {!isLoading && <FullHeight>
            <BodyFormFull>
                <Header pr={20} pl={20}>
                    <Row fullHeight>
                        <Col centerVertical flex={1} fullHeight>
                            <HeaderText>Policy Details</HeaderText>
                        </Col>
                        <Col centerVertical rightDirection>
                            <Button size='small' displayMode="linkInk" onClick={onCloseClick}><CloseIcon icon='app.close' /></Button>
                        </Col>
                    </Row>
                </Header>
                <Divider />
                <Col fullWidth rightDirection mt={18} mb={18}>
                    <Row>
                        {rowActions.map(({ icon, label, value }) => <Button key={label} displayMode="ghostInk" onClick={() => policyDetails && handleRowAction(value)}><ButtonIcon size="small" icon={icon} /><span>{label}</span></Button>)}
                    </Row>
                </Col>
                <ScrollableContent>
                    <Accordion title="General properties" initialOpened>
                        <Col spacing={20}>
                            <Row>
                                <PanelContentLabel>Policy name</PanelContentLabel>
                                <PanelContentBody><TextContainer>{policyDetails?.metadata?.name}</TextContainer></PanelContentBody>
                            </Row>
                        </Col>
                    </Accordion>
                    <Accordion title="Conversions" initialOpened>
                        <Col spacing={20}>
                            <Row>
                                <PanelContentLabel>Conversions ({conversions?.conversions?.length || 0})</PanelContentLabel>
                                <PanelContentLabel>Conversion Reports</PanelContentLabel>
                            </Row>
                            <Row>
                                <PanelContentLabel />
                                <PanelContentBody>{conversions?.conversions?.map(({ id, groupId, notes, target }) =>
                                    <Link key={id} to={{
                                        pathname: PATHS[LOCATIONS.reports],
                                        hash: `#${groupId}`
                                    }}><Row><TextContainer>{notes}</TextContainer><div>, {POLICIES_PROVIDERS_INFO[target || ''].name}</div></Row></Link>
                                )}</PanelContentBody>
                            </Row>
                        </Col>
                    </Accordion>
                    <Accordion title="Deployments" initialOpened>
                        <Col spacing={20}>
                            <Row>
                                <PanelContentLabel>Endpoints ({deployments?.deployments?.length || 0})</PanelContentLabel>
                                <PanelContentLabel>Deployment Reports </PanelContentLabel>
                            </Row>
                            <Row>
                                <PanelContentLabel />
                                <PanelContentBody>{deployments?.deployments?.map(({ id, groupId, notes, providerName }) => <Link key={id} to={{
                                    pathname: PATHS[LOCATIONS.reports],
                                    hash: `#${groupId}`
                                }}><Row><TextContainer>{notes}</TextContainer><div>, {providerName}</div></Row></Link>)}</PanelContentBody>
                            </Row>
                        </Col>
                    </Accordion>
                </ScrollableContent>
            </BodyFormFull>
        </FullHeight>}
        <ContentStatus
            isVisible={isLoading}
            spinnerSize={"medium"}
            defaultMessage={<span>Loading policy</span>}
            fillOuterContainer={false}
        />
    </Drawer>
}