import React, { useEffect } from "react";
import {
    BodyForm,
    Footer,
    Header,
    HeaderHeader,
    HeaderSubText,
    Legend
} from "../../../components/styled/Drawer";
import { Divider } from "../../../components/Divider";
import { Align, Button } from "@emerald/react";
import { Col } from "../../../components/Col";
import { Row } from "../../../components/Row";
import { useSelector } from "react-redux";
import {
    commitMessageSelector,
    conversionResultsSelector, findEndpointsCommandsIdsSelector,
    policyDeployPolicySelector, policyDeployProvidersForDeploySelector,
    policyDeployProvidersTypeSelector, policyDeployProviderTypesSelectedSelector
} from "../selectors";
import { CircularProgress } from "@mui/material";
import styled from "styled-components";
import { GraySpan } from "../../../components/styled";
import { useAppDispatch } from "../../../store";
import { convertPolicy, findEndpoints } from "../actions";
import { ConversionSummaryItem } from "./ConversionSummaryItem";
import { providerProviderType } from "core-lib";
import { policyDeploySlice } from "../reducer";
import { ScrollableContent } from "../../../components/PageScrollable";

const SpinnerRow = styled(Row)`
  padding: 40px;
  justify-content: center;
`
const CenterRow = styled(Row)`
  justify-content: center;
`

type Props = {
    onBack: () => void,
    onNext: () => void
}

export const ConversionSummaryScreen = (props: Props) => {

    const dispatch = useAppDispatch()

    const policy = useSelector(policyDeployPolicySelector)
    const conversionResults = useSelector(conversionResultsSelector)
    const findEndpointsCommandsIds = useSelector(findEndpointsCommandsIdsSelector)
    const providerTypesToDeploy = useSelector(policyDeployProvidersTypeSelector)
    const providerTypesSelected = useSelector(policyDeployProviderTypesSelectedSelector)
    const providersToDeploy = useSelector(policyDeployProvidersForDeploySelector)
    const convertedCount = providerTypesToDeploy.reduce((value, current) => {
        return value + ((conversionResults[current || ''] || conversionResults[current || ''] === false) ? 1 : 0);
    }, 0)
    const totalToDeploy = providerTypesToDeploy.length
    const isProgress = totalToDeploy !== convertedCount
    const commitMessage = useSelector(commitMessageSelector)

    useEffect(() => {
        dispatch(
            policyDeploySlice.actions.setProviderTypesSelected(
                [...providerTypesToDeploy.filter(type => {
                    const result = conversionResults[type];
                    return !!result && result?.ConversionSummary?.ErrorConvert === 0
                })]
            )
        )
    }, [dispatch, providerTypesToDeploy, conversionResults])

    useEffect(() => {
        if (policy && policy.id) {
            dispatch(convertPolicy(policy.id, providerTypesToDeploy, commitMessage))
        }
    }, [dispatch, policy, providerTypesToDeploy, commitMessage])

    useEffect(() => {
        providersToDeploy.forEach(provider => {
            if (provider.id && !findEndpointsCommandsIds[provider.id]) {
                dispatch(findEndpoints(provider))
            }
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, providersToDeploy])

    const onCheckboxChange = (type: providerProviderType, isChecked: boolean) => {
        const newSelectedTypes = isChecked ? [...providerTypesSelected, type] :
            providerTypesSelected.filter(typeItem => typeItem !== type)
        dispatch(policyDeploySlice.actions.setProviderTypesSelected(newSelectedTypes))
    }

    return <BodyForm>
        <Header>
            <HeaderHeader>Conversion Summary</HeaderHeader>
            {isProgress && <HeaderSubText>In progress...</HeaderSubText>}
            {!isProgress && <HeaderSubText>Review the results, make your selection to save the conversion and
                proceed</HeaderSubText>}
        </Header>
        <ScrollableContent>
            <Divider mb={17}></Divider>
            <Legend><GraySpan>Policy:</GraySpan> {policy?.metadata?.name}</Legend>
            {isProgress && <div>
                <SpinnerRow>
                    <CircularProgress
                        variant="indeterminate"
                        disableShrink
                        sx={{
                            color: '#3183B2'
                        }}
                        size={85}
                        thickness={2}
                    />
                </SpinnerRow>
                <CenterRow><GraySpan>Providers</GraySpan>&nbsp;{convertedCount}/{totalToDeploy}</CenterRow>
            </div>}
            {!isProgress && <div>
                {providerTypesToDeploy.map(providerType => (<ConversionSummaryItem
                    key={providerType}
                    providerType={providerType}
                    checked={providerTypesSelected.indexOf(providerType) > -1}
                    onCheckboxChange={onCheckboxChange}
                />))}
            </div>}
        </ScrollableContent>
        {!isProgress && <Footer>
            <Divider />
            <Col flex={1} rightDirection>
                <Row spacing={20} style={{ marginTop: 10 }}>
                    <Align>
                        <Button accent={true} displayMode="outlined" onClick={props.onBack}>Back</Button>
                    </Align>
                    <Align>
                        <Button disabled={!providerTypesSelected.length} accent={true} onClick={props.onNext}>Save &
                            Continue</Button>
                    </Align>
                </Row>
            </Col>
        </Footer>}
    </BodyForm>
}