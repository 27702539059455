import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {providerResponse} from "core-lib";

const initialState = {
    data: null as Array<providerResponse> | null
}

type StateType = typeof initialState;

export const providersListSlice = createSlice({
    name: 'PROVIDERS_LIST',
    initialState: initialState as StateType,
    reducers: {
        setProviders: (state: StateType , action: PayloadAction<Array<providerResponse> | null>) => {
            state.data = action.payload
        },
        resetProviders: (state: StateType) => {
            state.data = null
        }
    }
});
