import React, { useCallback, useEffect, useMemo, useState } from "react";
import { EditPolicyForm } from "./EditPolicyForm";
import { EditPolicyContentComponent } from "./EditPolicyContentComponent";
import { createPolicy, fetchPolicy, fetchBasePolicies, updatePolicy, fetchReferencesData } from "./actions";
import { useAppDispatch } from "../../store";
import {
    policyPolicy,
    policyPolicyList,
} from "core-lib";
import styled from "styled-components";
import { ContentStatus, Drawer } from "@emerald/react";
import { policiesListSlice } from "../PoliciesList/reducer";
import { useSelector } from "react-redux";
import { policyEditSlice } from "./reducer";
import { policyIdSelector } from "./selectors";
import { policyDetailsSlice } from "../PolicyDetails/reducer";
import { ReferencesData } from "./types";

const FullHeight = styled.div`
  height: 100%;
`

export const INITIAL_POLICY = { specification: {} };

export const EditPolicyComponent = () => {
    const dispatch = useAppDispatch()

    const policyId = useSelector(policyIdSelector);

    const [editContentOpen, setEditContentOpen] = useState(false)
    const [isSaving, setIsSaving] = useState(false)
    const [fullPolicy, setFullPolicy] = useState<policyPolicy | null>(null)
    const [basePolicies, setBasePolicies] = useState<policyPolicyList | null>(null);
    const [referencesData, setReferencesData] = useState<ReferencesData | null>(null);

    const isNewPolicy = policyId === '';

    const isLoading = useMemo(() =>
        fullPolicy === null || (basePolicies === null && isNewPolicy) ||
        (editContentOpen && referencesData === null)
        , [fullPolicy, basePolicies, isNewPolicy, editContentOpen, referencesData])

    const onClose = useCallback(() => dispatch(policyEditSlice.actions.setPolicyId(null)), [dispatch])

    const reloadReferencesData = useCallback(() => {
        setReferencesData(null);
        dispatch(fetchReferencesData()).then((data) => {
            const [trustListsResponse, signatureSetsResponse, overlayProtocolosResponse, loggingCustomizationResponse] = data;
            data && setReferencesData({
                TrustLists: trustListsResponse?.policyMetadata || [],
                SignatureSet: signatureSetsResponse?.policyMetadata || [],
                OverlayProtocols: overlayProtocolosResponse?.policyMetadata || [],
                LoggingCustomization: loggingCustomizationResponse?.policyMetadata || []
            })
        })
    }, [dispatch])   
    
    useEffect(() => {
        if (policyId !== null) {
            setFullPolicy(null)
            setEditContentOpen(!isNewPolicy)
            if (!isNewPolicy) {
                dispatch(fetchPolicy(policyId)).then((policy) => {
                    policy && setFullPolicy(policy)
                })   
            } else {
                dispatch(fetchBasePolicies()).then((policies) => {
                    policies && setBasePolicies(policies)
                })
                setFullPolicy(INITIAL_POLICY)
            }
            reloadReferencesData();
        }
    }, [dispatch, setFullPolicy, policyId, isNewPolicy, reloadReferencesData])

    const onFormSuccess = useCallback((policyEdited: policyPolicy) => {
        setFullPolicy(policyEdited)
        setEditContentOpen(true)
    }, [setEditContentOpen])

    const onBackForm = useCallback(() => {
        setEditContentOpen(false)
    }, [setEditContentOpen])

    const onAfterCloseDrawer = useCallback(() => {
        setEditContentOpen(false)
    }, [setEditContentOpen])

    const onSave = useCallback((policyEdited: policyPolicy, shouldClose: boolean = true) => {
        if (!isSaving) {
            setIsSaving(true)

            const action = !isNewPolicy && policyId ? updatePolicy({
                id: policyId,
                metadata: policyEdited.metadata,
                specification: policyEdited.specification
            }) : createPolicy({
                metadata: policyEdited.metadata,
                specification: policyEdited.specification
            });

            dispatch(action).then((policy) => {
                dispatch(policiesListSlice.actions.resetPolicies())
                dispatch(policyDetailsSlice.actions.setShouldRefresh(true))
                reloadReferencesData();
                if (shouldClose) {
                    onClose()
                } else if (policy) {
                    setFullPolicy(policy);
                }
            }).finally(() => {
                setIsSaving(false)
            })
        }
    }, [dispatch, setIsSaving, isSaving, isNewPolicy, policyId, onClose, reloadReferencesData])

    return <Drawer open={policyId !== null} onAfterClose={onAfterCloseDrawer} hideActionsRow hideBreadcrumbs
        {...(editContentOpen ? { width: '90vw' } : null)}>
        {!isLoading && <FullHeight>
            <FullHeight style={{ display: !editContentOpen ? 'block' : 'none' }}>
                <EditPolicyForm
                    policyId={policyId}
                    policy={fullPolicy}
                    basePolicies={basePolicies}
                    onCloseClick={onClose}
                    onSuccess={onFormSuccess}
                />
            </FullHeight>
            <FullHeight style={{ display: editContentOpen ? 'block' : 'none' }}>
                {referencesData && <EditPolicyContentComponent
                    isNewPolicy={isNewPolicy}
                    referencesData={referencesData}
                    policy={fullPolicy}
                    onBack={onBackForm}
                    onClose={onClose}
                    onSave={onSave}
                />}
            </FullHeight>
        </FullHeight>}
        <ContentStatus
            isVisible={isSaving}
            spinnerSize={"medium"}
            defaultMessage={<span>Saving</span>}
            fillOuterContainer={false}
        />
        <ContentStatus
            isVisible={isLoading}
            spinnerSize={"medium"}
            defaultMessage={<span>Loading policy</span>}
            fillOuterContainer={false}
        />
    </Drawer>
}