import React, {useState} from "react";
import {useSelector} from "react-redux";
import {policyDeployProviderTypesSelectedSelector} from "../selectors";
import {EndpointsSelectTypeAWAF} from "./EndpointsSelectTypeAWAF";
import {providerProviderType} from "core-lib";
import {EndpointsSelectTypeXC} from "./EndpointsSelectTypeXC";
import {EndpointsSelectTypeNAP} from "./EndpointsSelectTypeNAP";

type Props = {
    onBack: () => void,
    onNext: () => void
}

export const EndpointsSelectScreen = (props: Props) => {

    const providerTypesSelected = useSelector(policyDeployProviderTypesSelectedSelector);
    const [currentType, setProviderType] = useState(providerTypesSelected[0])

    const onBack = () => {
        const nextStage = providerTypesSelected[providerTypesSelected.indexOf(currentType) - 1]
        if(!nextStage) {
            return props.onBack()
        }
        setProviderType(nextStage)
    }
    const onNext = () => {
        const nextStage = providerTypesSelected[providerTypesSelected.indexOf(currentType) + 1]
        if(!nextStage) {
            return props.onNext()
        }
        setProviderType(nextStage)
    }

    return <>
        {currentType === providerProviderType.AWAF && <EndpointsSelectTypeAWAF onBack={onBack} onNext={onNext} />}
        {currentType === providerProviderType.XC && <EndpointsSelectTypeXC onBack={onBack} onNext={onNext} />}
        {currentType === providerProviderType.NAP && <EndpointsSelectTypeNAP onBack={onBack} onNext={onNext} />}
    </>
}