import {
    BodyFormFull,
    Footer,
    Header,
    HeaderText,
} from "../../../components/styled/Drawer";
import { Align, Button, Tag } from "@emerald/react";
import { Divider } from "../../../components/Divider";
import { Col } from "../../../components/Col";
import { Row } from "../../../components/Row";
import React, { useCallback, useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../../../store";
import { ScrollableContent } from "../../../components/PageScrollable";
import { fetchIngestionPolicies } from "../actions";
import { providerIngestionPoliciesListSelector, providerIngestionProviderSelector, providerIngestionSelectedPoliciesSelector } from "../selectors";
import { ingestionPoliciesListItem } from "../types";
import { CircularProgress, Stack } from "@mui/material";
import { providerIngestionSlice } from "../reducer";
import iconWarning from "../../../assets/icon-status-warning.svg";
import styled from "styled-components";
import { PROVIDERS_INFO } from "../../../constants";
import { providerProviderType } from "core-lib";
import { LOCATIONS, PATHS } from "../../../navigation";
import { Link } from "react-router-dom";
import { DataGrid } from "../../../components/DataGrid";
import moment from "moment";

const LoadingContainer = styled.div`
    width: 100%
`

const ProvidersLink = styled(Link)`
    color: #4F73FF;
    cursor: pointer;
    text-decoration: none;
`

const LoadingHeader = styled.div`
    margin-top: 20px;
    font-weight: 700;
    font-size: 18px;
    line-height: 26px;
`

const WarningHeader = styled(LoadingHeader)`
    margin-top: 10px;
`

const LoadingDescription = styled.div`
    margin-top: 8px;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
`

const StyledDataGrid = styled(DataGrid)`
    height: calc(100vh - 250px);

    & .os-scrollbar-vertical {
        opacity: 1 !important;
        visibility: visible !important;
        pointer-events: auto !important;
        width: 4px !important;
    }

    & .os-scrollbar-track:before {
        width: 4px !important;
    }

    & .os-scrollbar-handle:hover:before, & .os-scrollbar-handle:before {
        background: rgba(15,30,87,0.35) !important;
    }
`

const StyledTag = styled(Tag)`
    white-space: nowrap;
`

type Props = {
    onBack: () => void,
    onNext: () => void,
}


export const SelectPoliciesScreen = (props: Props) => {

    const provider = useSelector(providerIngestionProviderSelector);
    const dataSource = useSelector(providerIngestionPoliciesListSelector);
    const selectedPolicyIds = useSelector(providerIngestionSelectedPoliciesSelector);
    const dispatch = useAppDispatch();

    useEffect(() => {
        if (dataSource === null) {
            // Comment out for mock flow
            dispatch(fetchIngestionPolicies())
        }
    }, [dispatch, dataSource])


    const handleSelectionChange = useCallback(
        (_changed: any[], selected: any[]) => {
            dispatch(providerIngestionSlice.actions.selectPolicyIds(selected))
        }, [dispatch]
    );

    const columns: any = useMemo(() => {
        switch (provider?.type) {
            case providerProviderType.AWAF:
                return [
                    {
                        accessor: (row: ingestionPoliciesListItem) => row.name,
                        title: 'Policy name',
                        sortable: true,
                        filterable: true,
                        renderer: (row: ingestionPoliciesListItem) => row.name
                    },
                    {
                        accessor: (row: ingestionPoliciesListItem) => row.servers?.join(', '),
                        title: 'Virtual Servers',
                        sortable: true,
                        filterable: true,
                        renderer: (row: ingestionPoliciesListItem) =>
                            <Stack direction="row" alignItems="center" spacing={1}>
                                {row.servers?.map(
                                    server => (<StyledTag key={server} isActionable={false}>{server}</StyledTag>)
                                )}
                            </Stack>
                    },
                    {
                        accessor: (row: ingestionPoliciesListItem) => row.partition,
                        title: 'Partition',
                        sortable: true,
                        filterable: true,
                        renderer: (row: ingestionPoliciesListItem) => row.partition || '-'
                    },
                    {
                        accessor: (row: ingestionPoliciesListItem) => row.lastModified,
                        title: 'Last Modified',
                        sortable: true,
                        filterable: true,
                        renderer: (row: ingestionPoliciesListItem) => moment(row.lastModified).format('h:mm A, D MMM YYYY')
                    },
                ]
            case providerProviderType.NAP:
                return [
                    {
                        accessor: (row: ingestionPoliciesListItem) => row.name,
                        title: 'Policy name',
                        sortable: true,
                        filterable: true,
                        renderer: (row: ingestionPoliciesListItem) => row.name
                    },
                    {
                        accessor: (row: ingestionPoliciesListItem) => row.lastModified,
                        title: 'Last Modified',
                        sortable: true,
                        filterable: true,
                        renderer: (row: ingestionPoliciesListItem) => moment(row.lastModified).format('h:mm A, D MMM YYYY')
                    },
                ]
        }
    }, [provider?.type]);

    const initialConfig = useMemo(() => ({
        sortColumn: columns[columns.length - 1],
        sortDirection: 'descending' as "descending" | "ascending" | undefined,
    }), [columns])

    const Grid = (dataSource?.length || 0) > 0 ? StyledDataGrid : DataGrid

    return <BodyFormFull>
        <Header pr={20} pl={20}>
            <Col centerVertical fullHeight>
                <HeaderText>Ingest Policies</HeaderText>
            </Col>
        </Header>
        <Divider />
        <ScrollableContent mt={47} ml={48} mr={20}>
                <Col fullHeight>
                    <Row fullWidth>
                        <HeaderText>{PROVIDERS_INFO[provider?.type || '']?.name}</HeaderText>
                    </Row>
                    <Divider mb={27}></Divider>
                    <Row fullWidth flex={1}>
                        <Grid
                            themeOverride={{ color: { header: { fill: "transparent", ink: "#111922" } } }}
                            emptyContent={{
                                title: '',
                                action: () =>
                                    dataSource === null ?
                                        <LoadingContainer>
                                            <CircularProgress
                                                variant="indeterminate"
                                                disableShrink
                                                sx={{
                                                    color: '#3183B2'
                                                }}
                                                size={56}
                                                thickness={2}
                                            />
                                            <LoadingHeader>Connecting to Provider...</LoadingHeader>
                                            <LoadingDescription>Retrieving Policies (this may take a few minutes)</LoadingDescription>
                                        </LoadingContainer> :
                                        <LoadingContainer>
                                            <img src={iconWarning} alt='error' />
                                            <WarningHeader>Provider is not connected.</WarningHeader>
                                            <LoadingDescription>Agent is offline. To reconnect, go to <ProvidersLink to={PATHS[LOCATIONS.providers]} onClick={props.onBack}>Providers</ProvidersLink></LoadingDescription>
                                        </LoadingContainer>
                            }}
                            columns={columns}
                            items={dataSource || []}
                            itemIdAccessor='id'
                            showToolbar={true}
                            allowSelectRows={true}
                            allowSelectAll={true}
                            onSelectionChange={handleSelectionChange}
                            showToolbarFilter={true}
                            configInitial={initialConfig}
                            configFullList={{
                                selectedItemIds: selectedPolicyIds || [],
                            }}
                        />
                    </Row>
                </Col>
        </ScrollableContent>
        <Footer>
            <Divider />
            <Col flex={1} rightDirection mr={20}>
                <Row spacing={20} mt={10}>
                    <Align>
                        <Button accent={true} displayMode="outlined" onClick={props.onBack}>Cancel</Button>
                    </Align>
                    <Align>
                        <Button disabled={selectedPolicyIds.length === 0} accent={true} onClick={props.onNext}>Continue</Button>
                    </Align>
                </Row>
            </Col>
        </Footer>
    </BodyFormFull>
}