import React, { useCallback, useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import { Button, Icon } from "@emerald/react";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../../store";
import { reportsListTotalCount, reportsListArraySelector } from "./selectors";
import { fetchReports } from "./actions";
import moment from "moment";
import { Row } from "../../components/Row";
import { Col } from "../../components/Col";
import { useLocation } from "react-router-dom";
import { reportsListItem } from "./types";
import { REPORT_TYPES_NAMES } from "../../constants";
import { reportDetailsSlice } from "../ReportDetails/reducer";
import { DataGrid } from "../../components/DataGrid";
import { CommonEmptyComponent } from "../../components/CommonEmptyComponent";

const Content = styled.div`
  height: 100%
`
const NameSpan = styled.div`
  color: #4F73FF;
  cursor: pointer;
  vertical-align: middle;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  line-height: 16px;
`

export const ReportsListComponent = () => {

    const location = useLocation()
    const intialReportId = location.hash ? unescape(location.hash.substr(1)) : ''

    const dataSource = useSelector(reportsListArraySelector);
    const totalCount = useSelector(reportsListTotalCount);
    const dispatch = useAppDispatch();
    const [isLoading, setIsLoading] = useState(true)
    const [, setItemsSelected] = useState([] as string[])

    const refresh = useCallback(() => {
        setItemsSelected([])
        setIsLoading(true)
        dispatch(fetchReports())
            .then(() => setIsLoading(false))
    }, [dispatch, setIsLoading])

    useEffect(() => {
        refresh()
    }, [refresh]);

    const onRefreshClick = useCallback(() => {
        refresh()
    }, [refresh])

    const handleSelectionChange = useCallback(
        (_changed: any[], selected: any[]) => {
            setItemsSelected(selected)
        }, []
    );

    const onNameClick = useCallback((reportId: string) => {
        dispatch(reportDetailsSlice.actions.setReportId(reportId))
    }, [dispatch])

    useEffect(() => {
        if (intialReportId) {
            onNameClick(intialReportId)
        }
    }, [onNameClick, intialReportId])

    const columns: any = useMemo(() => [
        {
            accessor: 'name',
            title: 'Report name',
            filterable: true,
            sortable: true,
            width: 400,
            renderer: (row: reportsListItem) => {
                return <NameSpan onClick={() => onNameClick(row.commandId || '')}>{row.name}</NameSpan>
            }
        },
        {
            accessor: (row: reportsListItem) => row.policies?.map(policy => policy.policyName).join(', '),
            title: 'Policies',
            filterable: true,
            sortable: true,
            renderer: (row: reportsListItem) => row.policies?.map(policy => policy.policyName).join(', '),
        },
        {
            accessor: 'dateTimestamp',
            title: 'Date',
            width: 200,
            sortable: true,
            renderer: (row: reportsListItem) => {
                return moment(row.lastModifiedTimestamp).format('h:mm A, D MMM YYYY')
            }
        },
        {
            accessor: (row: reportsListItem) => {
                return row.type ? REPORT_TYPES_NAMES[row.type] : '-'
            },
            width: 150,
            title: 'Type',
            filterable: true,
            sortable: true,
            renderer: (row: reportsListItem) => {
                return row.type ? REPORT_TYPES_NAMES[row.type] : '-'
            }
        }
    ], [onNameClick])

    const initialConfig = useMemo(() => ({
        sortColumn: columns[2],
        sortDirection: 'descending' as "descending" | "ascending" | undefined,
    }), [columns])

    const toolbarCustomFilter = useCallback(() => <Button displayMode="ghostInk" onClick={onRefreshClick}>
        <Icon icon="app.refresh" />
        <span>Refresh</span>
    </Button>, [onRefreshClick])

    return <Content>

        {dataSource.length === 0 && !isLoading ?
            <CommonEmptyComponent title="No Reports yet" description="Select a policy to convert or deploy" /> :
            <Col fullHeight>

                <Row fullWidth flex={1}>
                    <DataGrid
                        isLoading={isLoading}
                        columns={columns}
                        items={dataSource}
                        itemIdAccessor='commandId'
                        showToolbar={true}
                        allowSelectRows={true}
                        allowSelectAll={true}
                        onSelectionChange={handleSelectionChange}
                        toolbarCustomFilter={toolbarCustomFilter}
                        summaryItemCount={totalCount}
                        configInitial={initialConfig}
                    />
                </Row>
            </Col>}
    </Content>
}