import {
    BodyForm,
    Footer,
    Header,
    HeaderHeader,
    HeaderSubText,
    Legend
} from "../../../components/styled/Drawer";
import {Divider} from "../../../components/Divider";
import {Align, Button, FormField, Select} from "@emerald/react";
import {Col} from "../../../components/Col";
import {Row} from "../../../components/Row";
import React from "react";
import {useSelector} from "react-redux";
import {
    policyDeployCProvidersByTypeSelector,
    policyDeployEndpointsOptionsAWAFSelector,
    policyDeployEndpointsSelector,
    policyDeployPolicySelector
} from "../selectors";
import {RootState, useAppDispatch} from "../../../store";
import {providerProviderType} from "core-lib";
import {policyDeploySlice} from "../reducer";
import {PROVIDERS_INFO} from "../../../constants";
import styled from "styled-components";
import {ScrollableContent} from "../../../components/PageScrollable";

const SelectContent = styled.div`
  padding-left: 25px;
`

type Props = {
    onBack: () => void,
    onNext: () => void
}

export const EndpointsSelectTypeAWAF = (props: Props) => {

    const dispatch = useAppDispatch()
    const policy = useSelector(policyDeployPolicySelector)
    const providersByType = useSelector((state: RootState) => policyDeployCProvidersByTypeSelector(state, providerProviderType.AWAF))
    const endpoints = useSelector(policyDeployEndpointsSelector)

    const endpointsAWAFOptions = useSelector(policyDeployEndpointsOptionsAWAFSelector)

    const onChangeEndpoints = (providerId: string) => (value: any, checked: any) => {
        dispatch(policyDeploySlice.actions.setEndpoints({providerId: providerId, endpoints: checked}))
    }

    return <BodyForm>
        <Header>
            <HeaderHeader>Endpoint Attachment: {PROVIDERS_INFO[providerProviderType.AWAF].name}</HeaderHeader>
            <HeaderSubText>Select the partition and corresponding virtual servers to attach the policy
                to</HeaderSubText>
        </Header>
        <ScrollableContent>
            <Divider mb={17}></Divider>
            <Legend>Policy: {policy?.metadata?.name}</Legend>
            <SelectContent>
                <h3>Virtual Servers</h3>
                {providersByType.map(provider => {
                    return provider.id ? <div key={provider.id}>
                        <FormField label={provider.name}>
                            <Select options={endpointsAWAFOptions[provider.id]}
                                    value={endpoints[provider.id]}
                                    onChange={onChangeEndpoints(provider.id)}
                                    allowClearSelection={true}
                                    allowSelectAll={true}
                                    multiSelect={true}
                                    placeholder={endpointsAWAFOptions[provider.id] ? 'Select Virtual Servers' : 'Loading...'}
                            />
                        </FormField>
                    </div> : null
                })}
            </SelectContent>
        </ScrollableContent>
        <Footer>
            <Divider/>
            <Col flex={1} rightDirection>
                <Row spacing={20} style={{marginTop: 10}}>
                    <Align>
                        <Button accent={true} displayMode="outlined" onClick={props.onBack}>Back</Button>
                    </Align>
                    <Align>
                        <Button accent={true} onClick={props.onNext}>Next</Button>
                    </Align>
                </Row>
            </Col>
        </Footer>
    </BodyForm>
}