import { commandBigipListResult, commandNapListResult, commandBigipPolicyListItem, commandNapPolicyList } from "core-lib";
import { ingestionPoliciesListItem } from "./types";

export const mapAwafResult = (result: commandBigipListResult) : ingestionPoliciesListItem[] => result?.policies?.items?.map((item: commandBigipPolicyListItem) => ({
    id: item.id || '',
    name: item.name || '',
    partition: item.partition || '',
    servers: item.virtualServers || [],
    lastModified: item.versionDatetime || '',
})) || []


export const mapNapResult = (result: commandNapListResult) : ingestionPoliciesListItem[] => result?.policies?.policyNames?.map((item: string, index: number) => ({
    id: item || '',
    name: item || '',
    lastModified: result?.policies?.lastUpdated?.[index] || '',
})) || []