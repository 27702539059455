import React from 'react'
import { Route, Routes } from 'react-router-dom'
import { ConvertScreen } from '../Convert/ConvertScreen'
import { AuthScreen } from './AuthScreen'

export const UnauthorizedRoutes = () => {
    return <Routes>
        <Route path='/convert/*' element={<ConvertScreen />} />
        <Route path='*' element={<AuthScreen />} />
    </Routes>
}