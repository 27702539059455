import styled from "styled-components";

interface Props {
    direction: string;
    spacing?: number;
    wrapItems?: boolean;
}

export const Stack = styled.div<Props>`
  display: flex;
  flex-direction: ${props => props.direction};
  gap: ${props => props.spacing ? `${props.spacing}px` : null};
  flex-wrap: ${props => props.wrapItems ? 'wrap' : 'nowrap'};
  overflow: hidden;
`