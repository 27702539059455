import React, { useCallback, useMemo, useState } from "react";
import { DataGrid as EmeraldDataGrid, DataGridTypes, Input } from "@emerald/react";
import iconWarning from "../assets/icon-warning.svg"
import styled from "styled-components";
import { useDebounce } from "use-debounce";

const NoSearchResultsContainer = styled.div`
display: flex;
align-items: center;
justify-content: center;
gap: 8px;
color: #9EA7B8;
`

type Props = DataGridTypes.PropsConsumable;

export const DataGrid = ({
    itemMode = "fullList",
    columns,
    items,
    configInitial,
    configFullList,
    configAsyncPagination,
    configInfinite,
    toolbarCustomFilter: toolbarCustomFilterProp,
    showToolbarFilter,
    showToolbar,
    isItemExpandable,
    emptyFilterContent,
    emptyContent,
    onRowExpand,
    onRowCollapse,
    onSelectionChange,
    ...rest }: Props) => {
    const [filter, setFilter] = useState(configInitial?.filter || '');
    const [expandedItemIds, setExpandedItemIds] = useState(configInitial?.expandedItemIds || [])
    const [selectedItemIds, setSelectedItemIds] = useState(configInitial?.selectedItemIds || []);
    const [sortColumn, setSortColumn] = useState(configInitial?.sortColumn || undefined);
    const [sortDirection, setSortDirection] = useState(configInitial?.sortDirection || "descending");

    const handleSort = useCallback(({ column, direction }) => {
        setSortColumn(column)
        setSortDirection(direction)
    }, []);

    const isUncontrolledMode = !configFullList && itemMode === "fullList"

    const filterValue = useMemo(() => {
        if (itemMode === "asyncPagination") {
            return configAsyncPagination?.filter || ''
        } else if (itemMode === "infinite") {
            return configInfinite?.filter || ''
        }

        return configFullList?.filter || filter
    }, [filter,
        configAsyncPagination?.filter,
        configInfinite?.filter,
        configFullList?.filter,
        itemMode])

    const uncontrolledConfig = useMemo(() => ({
        filter: filterValue,
        expandedItemIds,
        selectedItemIds,
        sortColumn,
        sortDirection,
        onSort: handleSort,
    }), [filterValue,
        expandedItemIds,
        selectedItemIds,
        sortColumn,
        sortDirection,
        handleSort])

    const filterAccessors = useMemo(() =>
        (columns?.filter((item) => item.filterable).map((item) => item.accessor) || []) as DataGridTypes.ColumnAccessor<any>[]
        , [columns])

    const sortAccessor = useMemo(() =>
        sortColumn?.accessor as DataGridTypes.ColumnAccessor<any>
        , [sortColumn])

    const [debouncedFilterValue] = useDebounce(filterValue, 300)

    const filteredItems = useMemo(() => {
        if(filterAccessors.length === 0)
        return items;
        
        return items?.filter((item: any, itemIndex: any) => filterAccessors.some((accessor) => {
            const value = typeof accessor === "function" ? accessor(item) : item[accessor]?.toString()
            let isExpandable = false
            if (isItemExpandable) {
                isExpandable = typeof isItemExpandable === "boolean" ? isItemExpandable : isItemExpandable({ item, itemIndex })
            }
            return (!!value && value.toLowerCase().includes(debouncedFilterValue.toLocaleLowerCase())) || isExpandable
        }))
    }
        , [filterAccessors, debouncedFilterValue, items, isItemExpandable])

    const filteredAndSortedItems = useMemo(() => {
        let result = filteredItems;

        if (sortAccessor && result) {
            result.sort((a: any, b: any) => {
                const aValue = typeof sortAccessor === "function" ? sortAccessor(a) : a[sortAccessor]
                const bValue = typeof sortAccessor === "function" ? sortAccessor(b) : b[sortAccessor]
                const sortResult = aValue > bValue ? 1 : -1
                return sortDirection === "ascending" ? sortResult : -sortResult
            })
        }

        return result;
    }
        , [filteredItems, sortAccessor, sortDirection])

    const handleRowCollapse = useCallback((data) => {
        setExpandedItemIds(expandedItemIds.filter(id => id !== data.itemId))
        onRowCollapse && onRowCollapse(data)
    }, [onRowCollapse, expandedItemIds]);

    const handleRowExpand = useCallback((data) => {
        setExpandedItemIds([...expandedItemIds, data.itemId])
        onRowExpand && onRowExpand(data)
    }, [onRowExpand, expandedItemIds]);

    const handleSelectionChange = useCallback((changed, selected) => {
        setSelectedItemIds(selected)
        onSelectionChange && onSelectionChange(changed, selected)
    }, [onSelectionChange]);

    const handleFilterChange = useCallback((newValue) => {
        setFilter(newValue)
        configFullList?.onFilter && configFullList?.onFilter(newValue)
        configAsyncPagination?.onFilter && configAsyncPagination?.onFilter(newValue)
        configInfinite?.onFilter && configInfinite?.onFilter(newValue)
    }, [configFullList, configAsyncPagination, configInfinite]);

    const toolbarCustomFilter = useCallback(() =>
        <>
            {showToolbarFilter !== false && <Input icon="app.search" maxWidth={240} placeholder="Search" value={filterValue} action={filterValue ? {
                icon: "app.clear",
                title: "Clear",
                onClick: () => handleFilterChange(''),
            } : undefined} onChange={(e) => handleFilterChange(e.target.value)} />}
            {toolbarCustomFilterProp && toolbarCustomFilterProp({ filter: filterValue, selectedItemIds, expandedItemIds })}
        </>,
        [filterValue, expandedItemIds, selectedItemIds, showToolbarFilter, toolbarCustomFilterProp, handleFilterChange])

    const defaultEmptyContent = useMemo(() => ({
        icon: undefined,
        title: '',
        description: '',
        action: () => <NoSearchResultsContainer><img src={iconWarning} alt='No search results'/> No search results</NoSearchResultsContainer> 
    }), [])

    return <EmeraldDataGrid
        itemMode={itemMode}
        configFullList={isUncontrolledMode ? uncontrolledConfig : configFullList}
        configAsyncPagination={isUncontrolledMode ? configAsyncPagination : undefined}
        configInfinite={isUncontrolledMode ? configInfinite : undefined}
        toolbarCustomFilter={showToolbar ? toolbarCustomFilter : undefined}
        onSelectionChange={handleSelectionChange}
        onRowExpand={handleRowExpand}
        onRowCollapse={handleRowCollapse}
        showToolbar={showToolbar}
        showToolbarFilter={false}
        columns={columns}
        isItemExpandable={isItemExpandable}
        items={isUncontrolledMode ? filteredAndSortedItems : items}
        emptyFilterContent={emptyFilterContent || defaultEmptyContent}
        emptyContent={emptyContent || defaultEmptyContent}
        {...rest}
    />
}