import React, {useEffect} from "react";
import {useSelector} from "react-redux";
import {isLoadingSelector, userSelector} from "./selectors";
import {useAppDispatch} from "../../store";
import {fetchUser} from "./actions";
import {authSlice} from "./reducer";
import {ContentStatus} from "@emerald/react";
import { UnauthorizedRoutes } from "./UnauthorizedRoutes";

export const AuthLock = (props: {children: JSX.Element}) => {

    const user = useSelector(userSelector)
    const isLoading = useSelector(isLoadingSelector)
    const dispatch = useAppDispatch()

    useEffect(() => {
        dispatch(authSlice.actions.setIsLoading(true))
        dispatch(fetchUser()).finally(() => {
            dispatch(authSlice.actions.setIsLoading(false))
        })
    }, [dispatch])

    return isLoading ? <ContentStatus
        isVisible={true}
        spinnerSize={"medium"}
        defaultMessage={<span>Loading</span>}
        fillOuterContainer={false}
    /> : (user ? props.children : <UnauthorizedRoutes />)
}