/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { providerCreateRequest } from '../models/providerCreateRequest';
import type { providerListResponse } from '../models/providerListResponse';
import type { providerProviderStatus } from '../models/providerProviderStatus';
import type { providerProviderType } from '../models/providerProviderType';
import type { providerResponse } from '../models/providerResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ProvidersService {

    /**
     * @param orderName
     * @param orderType
     * @param orderUrl
     * @param orderStatus
     * @param orderCreatedAt
     * @param orderUpdatedAt
     * @param filterName
     * @param filterType
     * @param filterLabel
     * @param filterAgentId
     * @param filterSecretId
     * @param filterUrl
     * @param filterStatus
     * @param page
     * @param size
     * @returns providerListResponse A successful response.
     * @throws ApiError
     */
    public static providersListProviders(
        orderName: 'DESCENDING' | 'ASCENDING' = 'DESCENDING',
        orderType: 'DESCENDING' | 'ASCENDING' = 'DESCENDING',
        orderUrl: 'DESCENDING' | 'ASCENDING' = 'DESCENDING',
        orderStatus: 'DESCENDING' | 'ASCENDING' = 'DESCENDING',
        orderCreatedAt: 'DESCENDING' | 'ASCENDING' = 'DESCENDING',
        orderUpdatedAt: 'DESCENDING' | 'ASCENDING' = 'DESCENDING',
        filterName?: Array<string>,
        filterType?: Array<string>,
        filterLabel?: Array<string>,
        filterAgentId?: Array<string>,
        filterSecretId?: Array<string>,
        filterUrl?: Array<string>,
        filterStatus?: Array<string>,
        page?: number,
        size?: number,
    ): CancelablePromise<providerListResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/providers',
            query: {
                'order.name': orderName,
                'order.type': orderType,
                'order.url': orderUrl,
                'order.status': orderStatus,
                'order.createdAt': orderCreatedAt,
                'order.updatedAt': orderUpdatedAt,
                'filter.name': filterName,
                'filter.type': filterType,
                'filter.label': filterLabel,
                'filter.agentId': filterAgentId,
                'filter.secretId': filterSecretId,
                'filter.url': filterUrl,
                'filter.status': filterStatus,
                'page': page,
                'size': size,
            },
            errors: {
                400: `Invalid argument`,
                401: `Returned when the user does not have permission to access the resource.`,
                404: `Resource was not found.`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * @param body
     * @returns providerResponse A successful response.
     * @throws ApiError
     */
    public static providersCreateProvider(
        body: providerCreateRequest,
    ): CancelablePromise<providerResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/providers',
            body: body,
            errors: {
                400: `Invalid argument`,
                401: `Returned when the user does not have permission to access the resource.`,
                404: `Resource was not found.`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * @param id
     * @returns providerResponse A successful response.
     * @throws ApiError
     */
    public static providersGetProvider(
        id: string,
    ): CancelablePromise<providerResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/providers/{id}',
            path: {
                'id': id,
            },
            errors: {
                400: `Invalid argument`,
                401: `Returned when the user does not have permission to access the resource.`,
                404: `Resource was not found.`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * @param id
     * @param force
     * @returns any A successful response.
     * @throws ApiError
     */
    public static providersDeleteProvider(
        id: string,
        force?: boolean,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/v1/providers/{id}',
            path: {
                'id': id,
            },
            query: {
                'force': force,
            },
            errors: {
                400: `Invalid argument`,
                401: `Returned when the user does not have permission to access the resource.`,
                404: `Resource was not found.`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * @param id
     * @param body
     * @returns providerResponse A successful response.
     * @throws ApiError
     */
    public static providersUpdateProvider(
        id: string,
        body: {
            agentId?: string;
            secretId?: string;
            url?: string;
            name?: string;
            type?: providerProviderType;
            labels?: Array<string>;
            status?: providerProviderStatus;
            vaultAddress?: string;
            vaultToken?: string;
        },
    ): CancelablePromise<providerResponse> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/v1/providers/{id}',
            path: {
                'id': id,
            },
            body: body,
            errors: {
                400: `Invalid argument`,
                401: `Returned when the user does not have permission to access the resource.`,
                404: `Resource was not found.`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * @param id
     * @param body
     * @returns any A successful response.
     * @throws ApiError
     */
    public static providersRefreshToken(
        id: string,
        body: any,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/providers/{id}/refresh-token',
            path: {
                'id': id,
            },
            body: body,
            errors: {
                400: `Invalid argument`,
                401: `Returned when the user does not have permission to access the resource.`,
                404: `Resource was not found.`,
                500: `Internal server error`,
            },
        });
    }

}
