/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum reportReportType {
    DEPLOYMENT = 'DEPLOYMENT',
    CONVERSION = 'CONVERSION',
    INGESTION = 'INGESTION',
    NONE = 'NONE',
}
