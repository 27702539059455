import React, { useCallback, useEffect } from "react";
import { PolicyDeployDrawerComponent } from "../PolicyDeploy/PolicyDeployComponent";
import { policyDeploySlice } from "../PolicyDeploy/reducer";
import { PolicyConversionDrawerComponent } from "../PolicyConversion/PolicyConversionComponent";
import { policyConversionSlice } from "../PolicyConversion/reducer";
import { EditPolicyComponent } from "../PolicyEdit/EditPolicyComponent";
import { policyEditSlice } from "../PolicyEdit/reducer";
import { ProviderDetailsComponent } from "../ProviderDetails/ProviderDetailsComponent";
import { providerDetailsSlice } from '../ProviderDetails/reducer';
import { EditProviderForm } from "../ProviderEdit/EditProviderForm";
import { addProviderSlice } from "../ProviderEdit/reducer";
import { ProviderIngestionDrawerComponent } from "../ProviderIngestion/ProviderIngestionComponent";
import { providerIngestionSlice } from "../ProviderIngestion/reducer";
import { ReportDetailsComponent } from "../ReportDetails/ReportDetailsComponent";
import { reportDetailsSlice } from "../ReportDetails/reducer";
import { PolicyDetailsComponent } from "../PolicyDetails/PolicyDetailsComponent";
import { policyDetailsSlice } from "../PolicyDetails/reducer";
import { ImportPolicyComponent } from "../PolicyImport/ImportPolicyComponent";
import { policyImportSlice } from "../PolicyImport/reducer";
import { useLocation } from "react-router-dom";
import { useAppDispatch } from "../../store";
import { LOCATIONS, PATHS } from "../../navigation";

export const Drawers = () => {
    const dispatch = useAppDispatch();
    const location = useLocation();

    const closeRouteSpecificDrawers = useCallback((pathname: string) => {
        if (!pathname.includes(PATHS[LOCATIONS.policies])) {
            dispatch(policyDeploySlice.actions.clean())
            dispatch(policyConversionSlice.actions.clean())
            dispatch(policyEditSlice.actions.setPolicyId(null))
            dispatch(policyImportSlice.actions.close())
            dispatch(policyDetailsSlice.actions.setPolicyId(null))
        }
        if (!pathname.includes(PATHS[LOCATIONS.providers])) {
            dispatch(providerDetailsSlice.actions.setProviderId(''))
            // Comment out for mock flow (1 line)
            dispatch(providerIngestionSlice.actions.clean())
            dispatch(addProviderSlice.actions.close())
        }
        if (!pathname.includes(PATHS[LOCATIONS.reports])) {
            dispatch(reportDetailsSlice.actions.setReportId(''))
        }
    }, [dispatch]);

    useEffect(() => {
        closeRouteSpecificDrawers(location.pathname);
    }, [location, closeRouteSpecificDrawers]);

    return <>
        <ProviderDetailsComponent />
        <ReportDetailsComponent />
        <PolicyDeployDrawerComponent />
        <PolicyConversionDrawerComponent />
        <ProviderIngestionDrawerComponent />
        <EditProviderForm />
        <EditPolicyComponent />
        <PolicyDetailsComponent />
        <ImportPolicyComponent />
    </>

}
