import React, { useMemo, useState } from "react";
import { Row } from "../../../components/Row";
import styled from "styled-components";
import { Col } from "../../../components/Col";
import { Dot } from "../../../components/Dot";
import {
    commandConversionReportResponse,
    commandChildrenBucketReport,
    providerProviderType
} from "core-lib";
import { Button, Icon, Input } from "@emerald/react";
import { conversionReportError } from "./util/useGetConversionReportErrorData";
import { ConversionReportErrorComponent } from "./ConversionReportErrorComponent";

enum CONVERSION_BUCKET_ITEM_STATUS {
    CONVERTED = 'Converted',
    STORED = 'Stored',
    CONVERTED_FROM_CDP = 'Converted (from CDP)',
    CONVERTED_FROM_STORED = 'Converted (from Stored)',
    NOT_CONVERTED = 'Not Converted',
}

const CONVERSION_BUCKET_ITEM_INGESTION_STATUSES = [
    CONVERSION_BUCKET_ITEM_STATUS.CONVERTED,
    CONVERSION_BUCKET_ITEM_STATUS.STORED
]

const CONVERSION_BUCKET_DEPLOYMENT_ITEM_STATUS = [
    CONVERSION_BUCKET_ITEM_STATUS.CONVERTED_FROM_CDP,
    CONVERSION_BUCKET_ITEM_STATUS.CONVERTED_FROM_STORED,
    CONVERSION_BUCKET_ITEM_STATUS.NOT_CONVERTED
]

type bucketDisplayValues = {
    displayName?: string,
    statusText?: string,
    status: CONVERSION_BUCKET_ITEM_STATUS,
}

type bucketReportItem = {
    parentName?: string,
    childBuckets: bucketDisplayValues[]
} & Omit<bucketDisplayValues, 'status'>;

type bucketItemCount = { [key in CONVERSION_BUCKET_ITEM_STATUS]: number };


const ResultCountCol = styled(Col)`
  padding: 0 30px 15px 10px;
`
const ResultTypeLabel = styled.div`
  font-size: 12px;
  line-height: 18px;
  color: #6C778C;
`
const ResultTypeNumber = styled.div<{ color: string }>`
  font-size: 24px;
  line-height: 36px;
  font-weight: bold;
  color: ${props => props.color};
`
const ResultsHeader = styled(Col)`
  background-color: #F7F8FA;
  padding: 9px 0;
  font-size: 12px;
  line-height: 18px;
  color: #6C778C;
`
const ResultLine = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  vertical-align: center;
  word-wrap: anywhere;
  white-space: pre-wrap;
`

const ChildResultLine = styled(ResultLine)`
 padding-right: 24px;
`

const ResultRowStyled = styled(Row)`
  padding: 10px 0;
  border-bottom: 1px solid #E6E9F3;
`

const TabItem = styled.button`
  background: transparent;
  border: 0;
  cursor: pointer;
  font-size: 14px;
  line-height: 20px;
  color: #6C778C;
  padding: 0;
  padding-bottom: 5px;
`

const ActiveTabItem = styled(TabItem)`
  font-weight: 700;
  color: #4F73FF;
  border-bottom: 2px solid #4F73FF;
  padding-top: 2px;
`

const GreyIcon = styled(Icon)`
  color: #9EA7B8
`

const getStatusColor = (status: CONVERSION_BUCKET_ITEM_STATUS) => {
    switch (status) {
        case CONVERSION_BUCKET_ITEM_STATUS.CONVERTED:
        case CONVERSION_BUCKET_ITEM_STATUS.CONVERTED_FROM_CDP:
        case CONVERSION_BUCKET_ITEM_STATUS.CONVERTED_FROM_STORED:
            return '#11BD8D'
        case CONVERSION_BUCKET_ITEM_STATUS.STORED:
            return '#9EA7B8'
        case CONVERSION_BUCKET_ITEM_STATUS.NOT_CONVERTED:
            return '#FFC400'
        default:
            return ''
    }
}

const getBucketStatus = (item: commandChildrenBucketReport, isDeploymentConversion: boolean) => {
    if (item?.Converted === 0) {
        return isDeploymentConversion ? CONVERSION_BUCKET_ITEM_STATUS.NOT_CONVERTED : CONVERSION_BUCKET_ITEM_STATUS.STORED;
    } else {
        if(isDeploymentConversion) {
            return (item?.ConvertedFromPreconverted || 0) > 0 ? CONVERSION_BUCKET_ITEM_STATUS.CONVERTED_FROM_STORED : CONVERSION_BUCKET_ITEM_STATUS.CONVERTED_FROM_CDP;
        } else {
            return CONVERSION_BUCKET_ITEM_STATUS.CONVERTED;
        }
    }
}

const getBucketStatusTextWithCount = (status: CONVERSION_BUCKET_ITEM_STATUS, count: number) => `${status} (${count})`;
const cleanChildBucketName = (name: string = "") => (name).replaceAll(".i.", ".").replaceAll(".[i].", ".").replace(".[i]", "").replace(/i\.$/, "");

const getIntialDataCounts = (isDeploymentConversion: boolean) =>
    (isDeploymentConversion ? CONVERSION_BUCKET_DEPLOYMENT_ITEM_STATUS : CONVERSION_BUCKET_ITEM_INGESTION_STATUSES).reduce(
        (prev, current) => ({ ...prev, [current]: 0 }), {} as bucketItemCount)

const useConversionReportComponentData = (report?: commandConversionReportResponse) => {
    return useMemo(() => {
        const isDeploymentConversion = report?.ConversionSummary?.Source === providerProviderType.ONEWAF
        let counts = getIntialDataCounts(isDeploymentConversion)

        let buckets = [] as bucketReportItem[]

        if (report && (report?.ConversionBuckets || []).length > 0) {

            (report.ConversionBuckets || []).forEach((bucket) => {
                let childBucketCounts = getIntialDataCounts(isDeploymentConversion)

                let childBuckets = [] as bucketDisplayValues[]

                (bucket.ChildrenBucketReports || []).forEach((childBucket, index) => {
                    const childItemStatus = getBucketStatus(childBucket, isDeploymentConversion);
                    const childBucketName = cleanChildBucketName(childBucket.Name);
                    const childItem = { displayName: childBucketName, status: childItemStatus, statusText: childItemStatus }
                    childBucketCounts[childItemStatus]++
                    counts[childItemStatus]++
                    childBuckets.push(childItem);
                })

                let item = {
                    parentName: bucket.ParentName,
                    displayName: bucket.DisplayName,
                    childBuckets,
                    statusText: (Object.keys(childBucketCounts) as CONVERSION_BUCKET_ITEM_STATUS[]).map((key) => getBucketStatusTextWithCount(key, childBucketCounts[key])).join(", ")
                }
                buckets.push(item);
            })
        }

        return { counts, buckets }

    }, [report])
}

type ConversionReportHeaderProps = {
    counts: { [key in CONVERSION_BUCKET_ITEM_STATUS]: number };
    warnings: conversionReportError[];
}

const ConversionReportHeader = ({ counts, warnings }: ConversionReportHeaderProps) => <Col fullWidth>
    <ConversionReportErrorComponent mr={16} mb={44} errors={warnings} type="WARNING" />
    <Row fullWidth>
        {(Object.keys(counts) as CONVERSION_BUCKET_ITEM_STATUS[]).map((key) =>
            <ResultCountCol key={key} width={150}>
                <ResultTypeLabel>{key}</ResultTypeLabel>
                <ResultTypeNumber color={getStatusColor(key)}>{counts[key]}</ResultTypeNumber>
            </ResultCountCol>
        )}
    </Row>
</Col>

const ConversionReportBody = ({ buckets }: {
    buckets: bucketReportItem[],
}) => {
    const [filter, setFilter] = useState('');
    const [selectedParentIndex, setSelectedParentIndex] = useState(0);

    const bucketParents = useMemo(() => Array.from(new Set(buckets.map((item) => item.parentName))).sort((a, b) => (a || '').localeCompare(b || '')), [buckets])

    const itemsToDisplay = useMemo(() => {
        let items = [...buckets.filter((item) => item.parentName === bucketParents[selectedParentIndex])];

        if (filter) {
            const processedFilter = filter.toLowerCase()
            items = items.filter((item) => item.displayName?.toLowerCase().includes(processedFilter) || item.statusText?.toLowerCase().includes(processedFilter))
        }

        return items.sort((a, b) => (a.displayName || '').localeCompare(b.displayName || ''));
    }, [buckets, bucketParents, filter, selectedParentIndex])

    return <Col ml={13} mr={60}>
        <Col rightDirection ml={20}>
            <Input icon="app.search" maxWidth={180} placeholder="Search" value={filter} action={filter ? {
                icon: "app.clear",
                title: "Clear",
                onClick: () => setFilter(''),
            } : undefined} onChange={(e) => setFilter(e.target.value)} />
        </Col>
        <Row mt={21} spacing={32} mb={53} fullWidth>
            {bucketParents.map(
                (item, index) => {
                    const Component = selectedParentIndex === index ? ActiveTabItem : TabItem;
                    return <Component onClick={() => setSelectedParentIndex(index)} key={item}>{item}</Component>
                }
            )}
        </Row>
        <Col ml={20}>
            <Row fullWidth>
                <ResultsHeader width={48}></ResultsHeader>
                <ResultsHeader flex={1}>Feature Sets</ResultsHeader>
                <ResultsHeader flex={1}>Status</ResultsHeader>
            </Row>
            {itemsToDisplay.map(
                item => <ResultRow key={item.displayName} item={item} />
            )}
        </Col>
    </Col>
}

const ResultRow = ({ item }: {
    item: bucketReportItem,
}) => {
    const [childrenVisible, setChildrenVisible] = useState(false);

    return <> <ResultRowStyled center>
        <Col ml={20} width={28}>
            {item.childBuckets.length > 0 && <Button onClick={() => setChildrenVisible(!childrenVisible)} size='mini' displayMode="linkInk">
                <GreyIcon icon={childrenVisible ? 'app.chevronDown' : 'app.chevronRight'} size='xs' />
            </Button>}
        </Col>
        <Col flex={1}>
            <Row>
                <ResultLine>
                    {item.displayName}
                </ResultLine>
            </Row>
        </Col>
        <Col flex={1}>
            <Row>
                <Col>
                    <ResultLine>
                        {item.statusText}
                    </ResultLine>
                </Col>
            </Row>
        </Col>
    </ResultRowStyled>
        {childrenVisible && item.childBuckets.map((child) => <ResultRowStyled key={child.displayName} center>
            <Col ml={48} flex={1}>
                <Row>
                    <ChildResultLine>
                        {child.displayName}
                    </ChildResultLine>
                </Row>
            </Col>
            <Col flex={1}>
                <Row>
                    <Col>
                        <ResultLine>
                            <Dot size={10} color={getStatusColor(child.status)} style={{ marginRight: 5, marginTop: 4 }} />
                        </ResultLine>
                    </Col>
                    <Col flex={1}>
                        <ResultLine>
                            {child.statusText}
                        </ResultLine>
                    </Col>
                </Row>
            </Col>
        </ResultRowStyled >)}
    </>
}

export const ConversionReportNewComponent = ({ warnings, report, hideContentSection }: {
    warnings: conversionReportError[],
    report?: commandConversionReportResponse,
    hideContentSection?: boolean
}) => {
    const { counts, buckets } = useConversionReportComponentData(report);

    return <>
        <Col ml={13} mr={10} mt={6}>
            <ConversionReportHeader counts={counts} warnings={warnings} />
        </Col>
        {!hideContentSection && <ConversionReportBody buckets={buckets} />}
    </>
}