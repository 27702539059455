function leadingZero(n: number, length = 2) {
  let nStr = n + ''
  while (nStr.length < length) {
    nStr = '0' + nStr
  }
  return nStr
}

export function getElapsedTimeString(milliseconds: number) {
  const hours = Math.floor(milliseconds / (1000 * 60 * 60))
  const minutes = Math.floor(milliseconds / (1000 * 60)) - hours * 60
  const seconds =
    Math.floor(milliseconds / 1000) - (hours * 60 * 60 + minutes * 60)
  return `${leadingZero(hours)}:${leadingZero(minutes)}:${leadingZero(seconds)}`
}
