import {AppDispatch} from "../../store";
import {errorActionRequest} from "../Errors/actions";
import {
    AgentsService,
    commandJobStatus, CommandsService,
    providerResponse,
    ProvidersService, SecretsService, TokensService,
    permissionUserRole,
    providerProviderType
} from "core-lib";
import {addProviderSlice} from "./reducer";
import {CommandStatusCheck} from "../../core/commands/CommandStatusCheck";
import {AGENT_INSTALLERS_PACKAGES_LINK, GITLAB_URL, PAGINATION_FIRST_PAGE, PAGINATION_HUGE_SIZE} from "../../constants";

export const getProvider = (providerId: string) => {
    return async (_dispatch: AppDispatch) => {
        return ProvidersService.providersGetProvider(providerId)
            .catch((error) => {
                errorActionRequest(error)
                throw error
            })
    }
}

export const fetchAgents = () => {
    return async (dispatch: AppDispatch) => {
        dispatch(addProviderSlice.actions.setIsLoadingAgents(true));
        return AgentsService.agentsListAgents(
            undefined,
            undefined,
            undefined,
            undefined,
            PAGINATION_FIRST_PAGE,
            PAGINATION_HUGE_SIZE)
            .then(response => {
                dispatch(addProviderSlice.actions.loadAgents(response.agents || []));
            })
            .catch(error => {
                errorActionRequest(error);
            })
            .finally(() => {
                dispatch(addProviderSlice.actions.setIsLoadingAgents(false));
            })
    }
}

export const fetchSecrets = (onPremise: boolean, agentId: string | null | undefined) => {
    return async (dispatch: AppDispatch) => {
        dispatch(addProviderSlice.actions.setIsLoadingSecrets(true));
        let agentFilter = undefined
        if (onPremise && agentId) {
            agentFilter = [agentId]
        }
        return SecretsService.secretsListSecrets(
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            [onPremise],
            agentFilter,
            undefined,
            PAGINATION_FIRST_PAGE,
            PAGINATION_HUGE_SIZE)
            .then(response => {
                const secrets = response.secrets || []
                dispatch(addProviderSlice.actions.loadSecrets(secrets.filter(secret => secret.onPremise === onPremise)));
            })
            .catch(error => {
                errorActionRequest(error);
            })
            .finally(() => {
                dispatch(addProviderSlice.actions.setIsLoadingSecrets(false));
            })
    }
}

export const createProvider = (provider: providerResponse) => {
    return async (_dispatch: AppDispatch) => {
        try {
            const {id, ...providerToSend} = provider
            return await ProvidersService.providersCreateProvider(providerToSend)
        } catch (error) {
            throw errorActionRequest(error);
        }
    }
}

export const updateProvider = (providerId: string, provider: providerResponse) => {
    return async (_dispatch: AppDispatch) => {
        try {
            return await ProvidersService.providersUpdateProvider(providerId, provider)
        } catch (error) {
            throw errorActionRequest(error);
        }
    }
}

export const createToken = (tokenName: string, userRole: permissionUserRole) => {
    return async (_dispatch: AppDispatch) => {
        return TokensService.tokensCreateToken({
            name: tokenName,
            role: userRole
        })
            .catch(error => {
                throw errorActionRequest(error);
            })
    }
}

export const createSecretXC = (secretName: string, secretToken: string) => {
    return async (_dispatch: AppDispatch) => {
        return SecretsService.secretsCreateSecret({
            name: secretName,
            data: {token: secretToken},
            onPremise: false,
            providerType: providerProviderType.XC
        }).catch(error => {
            throw errorActionRequest(error);
        })
    }
}

export const testConnection = (provider: providerResponse) => {
    return async (_dispatch: AppDispatch) => {

        const providerId = provider.id
        const endpointsProviderType = provider.type

        return new Promise<boolean>((resolve, reject) => {

            CommandsService.commandsPostFindEndpoints({
                providerId: providerId
            }).then(response => {

                const commandId = response.id

                if (commandId && endpointsProviderType && providerId) {
                    CommandStatusCheck.getChecker(commandId).run()
                        .setOnResult((commandId, commandStatus) => {
                            if (commandStatus === commandJobStatus.JOB_SUCCESS) {
                                resolve(true)
                            } else {
                                reject()
                            }
                        })
                        .setOnError((commandId, e) => {
                            reject()
                            errorActionRequest(e)
                        })
                }
            }).catch(error => {
                errorActionRequest(error);
                reject()
            })
        })
    }
}

export const getAgentLink = async () => {
    try {
        const packagesResponse = await fetch(AGENT_INSTALLERS_PACKAGES_LINK)
        const packages = await packagesResponse.json()
        const lastPackage = packages[0]
        const webpath = lastPackage._links.web_path
        return GITLAB_URL + webpath
    } catch (error) {
        errorActionRequest(error);
    }
}