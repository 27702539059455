import {HTTP_ERROR_CODE_SESSION_EXPIRED} from "./constants";
import store, {AppDispatch} from "../../store";
import {errorSlice} from "./reducer";

export const errorActionRequest = (response: any) => {
    if(response) {
        if (response.status === HTTP_ERROR_CODE_SESSION_EXPIRED) {
            store.dispatch(errorSlice.actions.set401Error(true))
        } else {
            store.dispatch(errorSlice.actions.addError(response))
        }
        return response
    } else {
        console.error(response)
        return response
    }
}

export const removeErrorById = (errorId: number) => {
    return async (dispatch: AppDispatch) => {
        dispatch(errorSlice.actions.removeErrorById(errorId))
    }
}
