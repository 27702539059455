import {RootState} from "../../store";
import {providerResponse} from "core-lib";
import {createSelector} from "@reduxjs/toolkit";
import {PROVIDERS_INFO} from "../../constants";

export type ProviderWithGroups = providerResponse & {
    isGroup?: boolean,
    provider?: providerResponse,
    //TODO remove with real field
    deploymentsCount?: number
}

export const providersListSelector = (state: RootState): Array<providerResponse> | null => state.providersList?.data;
export const providersListWithGroupsSelector = createSelector(
    [providersListSelector],
    (providers): Array<ProviderWithGroups> | null => {
        if(providers === null)
            return null;

        return Object.values(PROVIDERS_INFO).reduce((result: ProviderWithGroups[], current) => {
            const sectionObjects = providers
                .filter(provider => provider.type === current.key)
                .map(provider => ({...provider, provider: provider}))

            return sectionObjects ? [
                ...result,
                {id: current.key, name: current.name, isGroup: true},
                ...sectionObjects
            ] : result
        }, [] as ProviderWithGroups[])
    }
)
