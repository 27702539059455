import React from "react";
import styled from "styled-components";
import emptyGroupImg from "../../assets/emptyProviders.png";
import {Align, Button, Card} from "@emerald/react";
import {Col} from "../../components/Col";
import {Row} from "../../components/Row";

const ContentCard = styled(Card)`
  justify-content: center;
  text-align: center;
  width: 100%;
`
const ButtonDiv = styled.div`
  margin-top: 20px;
`
const Content = styled.div`
  width: 100%;
`

type Props = {
    onAddClick: () => void
    onImportClick: () => void
}

export const EmptyPoliciesComponent = (props: Props) => {
    return <Content>
        <ContentCard>
            <div><img src={emptyGroupImg} alt='empty group'/></div>
            <div>
                <h3>No Policies added yet</h3>
            </div>
            <div>You can create a policy from scratch or import from a provider.</div>
            <ButtonDiv>
                <Col style={{alignItems: 'center'}}>
                    <Row spacing={5}>
                        <Align>
                            <Button accent={true} displayMode="outlined" onClick={props.onImportClick}>Import</Button>
                        </Align>
                        <Align>
                            <Button accent={true} displayMode="outlined" onClick={props.onAddClick}>Create New</Button>
                        </Align>
                    </Row>
                </Col>
            </ButtonDiv>
        </ContentCard>
    </Content>
}