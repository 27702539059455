import React from "react";
import { providerProviderType } from "core-lib";
import { Checkbox, Icon } from "@emerald/react";
import { Stack } from "../../../components/Stack";
import styled from "styled-components";
import { PROVIDERS_INFO } from "../../../constants";
import { Tag } from "../../../components/Tag";
import { useSelector } from "react-redux";
import {
    conversionResultsSelector,
    policyDeployCProvidersByTypeSelector
} from "../selectors";
import { RootState } from "../../../store";
import { ConversionReportNewComponent } from "../../../core/components/Conversion/ConversionReportNewComponent";
import { ConversionFailedComponent } from "../../../core/components/Conversion/ConversionFailedComponent";
import { Row } from "../../../components/Row";
import { useGetConversionReportErrorData } from "../../../core/components/Conversion/util/useGetConversionReportErrorData";
import { ConversionReportErrorComponent } from "../../../core/components/Conversion/ConversionReportErrorComponent";

const CheckboxStyled = styled(Checkbox)`
  span {
    font-size: 18px;
    font-weight: bold;
  }
`
const ErrorLabel = styled.div`
font-size: 14px;
`

const ProvidersLabel = styled.div`
  font-size: 14px;
  font-weight: bold;
  margin-top: 20px;
  margin-bottom: 5px;
`

type Props = {
    providerType: providerProviderType,
    checked: boolean,
    onCheckboxChange: (providerType: providerProviderType, isChecked: boolean) => void
}

export const ConversionSummaryItem = (props: Props) => {

    const { providerType, checked } = props
    const providersByType = useSelector((state: RootState) => policyDeployCProvidersByTypeSelector(state, providerType))
    const conversionResult = useSelector(conversionResultsSelector)[providerType]
    const isResultValid = !!conversionResult && conversionResult?.ConversionSummary?.ErrorConvert === 0

    const { warnings, errors } = useGetConversionReportErrorData(!!conversionResult ? [conversionResult] : [])

    const onCheckboxClick = (_event: any) => {
        if (conversionResult) {
            props.onCheckboxChange(props.providerType, !props.checked)
        }
    }

    return !providersByType.length ? null : <div style={{ marginBottom: 30 }}>
        <Row spacing={10}>
            <CheckboxStyled
                label={PROVIDERS_INFO[providerType].name}
                checked={checked}
                disabled={!isResultValid}
                onChange={onCheckboxClick} />
            {!isResultValid && <Row spacing={5} center>
                <Icon icon='status.error' />
                <ErrorLabel>Conversion Error</ErrorLabel>
            </Row>}
        </Row>
        <ProvidersLabel>Providers</ProvidersLabel>
        <Stack direction="row" spacing={5} wrapItems>
            {providersByType.map(
                (provider) => (<Tag key={provider.id} isActionable={false}>{provider.name}</Tag>)
            )}
        </Stack>
        {conversionResult && <>
            <ConversionReportErrorComponent ml={13} mr={26} mb={44} errors={errors} type="ERROR" />
            <ConversionReportNewComponent warnings={warnings[0]} report={conversionResult} />
        </>}
        {!conversionResult && <ConversionFailedComponent />}
    </div >
}