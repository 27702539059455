import { BodyFormFull, Header, HeaderText } from "../../components/styled/Drawer";
import { Button, ContentStatus, Drawer, Icon } from "@emerald/react";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useAppDispatch } from "../../store";
import { fetchReportDetails } from "./actions";
import { downloadConversionLogs } from "../ConversionsList/actions";
import {
    aggregate_reportReport,
} from "core-lib";
import { useSelector } from "react-redux";
import { reportIdSelector } from "./selectors";
import { reportDetailsSlice } from "./reducer";
import { ReportDetailsOldView } from "./ReportDetailsOldView";
import { FullHeight } from "../../components/styled";
import { ReportDetailsNewView } from "./ReportDetailsNewView";
import { Row } from "../../components/Row";
import { Col } from "../../components/Col";
import { Divider } from "../../components/Divider";
import styled from "styled-components";
import { ScrollableContent } from "../../components/PageScrollable";

const CloseIcon = styled(Icon)`
  color: #6C778C;
  font-size: 12.5px !important;
  height: 12.5px !important;
  line-height: 8px !important;
`

const ButtonIcon = styled(Icon)`
  font-size: 16px !important;
  height: 16px !important;
  line-height: 16px !important;
  color: #9EA7B8
`

type ReprortComponentProps = {
    report: aggregate_reportReport,
    onClose: () => void,
    isNewReport: boolean,
    onExportAll: () => void
}

const ReportComponent = ({ report, onClose, isNewReport, onExportAll }: ReprortComponentProps) => <>
    <Header pr={20} pl={20}>
        <Row fullHeight>
            <Col centerVertical flex={1} fullHeight>
                <HeaderText>Report</HeaderText>
            </Col>
            <Col centerVertical rightDirection>
                <Button size='small' displayMode="linkInk" onClick={onClose}><CloseIcon icon='app.close' /></Button>
            </Col>
        </Row>
    </Header>
    <Col fullWidth rightDirection mt={6} mb={6}>
        <Row>
            <Button displayMode="ghostInk" onClick={onExportAll}><ButtonIcon size="small" icon="app.export" /><span>Export All</span></Button>
        </Row>
    </Col>

    <ScrollableContent>
        {isNewReport ?
            <ReportDetailsNewView report={report} /> :
            <ReportDetailsOldView report={report} />}
    </ScrollableContent>
</>

export const ReportDetailsComponent = () => {

    const [report, setReport] = useState<aggregate_reportReport | null>(null)
    const reportId = useSelector(reportIdSelector);

    const dispatch = useAppDispatch()

    useEffect(() => {
        setReport(null);
        if (reportId) {
            fetchReportDetails(reportId).then((result: aggregate_reportReport) => {
                result && setReport(result)
            })
        }
    }, [dispatch, reportId])

    const onClose = useCallback(() => {
        dispatch(reportDetailsSlice.actions.setReportId(''))
    }, [dispatch])

    const onExportAll = useCallback(() => {
        report?.policies?.forEach(policy => {
            policy?.conversionReports && policy.conversionReports?.forEach(conversionReport => {
                conversionReport?.commandId && dispatch(downloadConversionLogs(conversionReport?.commandId, `${report?.name} ${policy?.policyName} ${conversionReport?.targetPolicyType} report.zip`))
            });
        })
    }, [report, dispatch, downloadConversionLogs])

    const isNewReport = useMemo(() => {
        return report?.policies?.some((item) =>
            item.conversionReports?.some((reportItem) => ((reportItem?.report?.ConversionBuckets?.length || 0) > 0)
            )) || false
    }, [report])

    return <Drawer hideOverlay {...(isNewReport ? { width: '950px' } : null)} hideActionsRow themeOverride={{ spacing: { horizontal: 0, vertical: 0 } }} open={!!reportId} onAfterClose={onClose} hideBreadcrumbs>
        <FullHeight>
            <BodyFormFull>
                {!!report && <ReportComponent report={report} onClose={onClose} isNewReport={isNewReport} onExportAll={onExportAll} />}
                <ContentStatus
                    isVisible={!report}
                    spinnerSize={"medium"}
                    defaultMessage={<span>Loading</span>}
                    fillOuterContainer={false}
                />
            </BodyFormFull>
        </FullHeight>
    </Drawer>
}