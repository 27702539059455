import React, {useCallback} from "react";
import {useSelector} from "react-redux";
import {error401Selector} from "./selectors";
import {useAppDispatch} from "../../store";
import {errorSlice} from "./reducer";
import {authSlice} from "../auth/reducer";
import { Dialog } from "../../components/Dialog";

export const Error401Component = () => {

    const dispatch = useAppDispatch()
    const opened = useSelector(error401Selector)

    const onClick = useCallback(() => {
        dispatch(errorSlice.actions.set401Error(false))
        dispatch(authSlice.actions.setUser(null))
    }, [dispatch])

    return <Dialog
        themeOverride={{font: {
            size: {
                title: 18
            },
            weight: {
                title: "bold"
            }
        }}}
        title="Your session has expired"
        open={opened}
        actions={[
            {
                accent:true,
                onClick,
                children: "Go to login"
            }
        ]}>
        Please login to continue using the app. 
    </Dialog>
}