import {AppDispatch} from "../../store";
import {
    commandConversionReportResponse,
    commandImportFromFileRequest,
    commandJobStatus, CommandsService, downloadFile, PoliciesService
} from "core-lib";
import {errorActionRequest} from "../Errors/actions";
import {policiesListSlice} from "./reducer";
import {PAGINATION_FIRST_PAGE, PAGINATION_HUGE_SIZE} from "../../constants";
import {CommandStatusCheckManager} from "../../core/commands/CommandStatusCheckManager";
import {createErrorObject} from "../Errors/util";

export const fetchPolicies = () => {
    return async (dispatch: AppDispatch) => {
        return PoliciesService.policiesListPolicies(
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            PAGINATION_FIRST_PAGE,
            PAGINATION_HUGE_SIZE)
            .then(response => {
                dispatch(policiesListSlice.actions.setPolicies(response.policyMetadata || []));
            })
            .catch(error => {
                errorActionRequest(error);
            })
    }
}

export const removePolicies = (policiesIds: string[]) => {
    return async (dispatch: AppDispatch) => {
        return Promise.all(policiesIds.map(id => PoliciesService.policiesDeletePolicy(id)
            .then(_response => {
                dispatch(policiesListSlice.actions.resetPolicies());
            }))).catch(error => {
            errorActionRequest(error);
        })
    }
}

export const importPolicy = (policy: commandImportFromFileRequest) => {
    return async (_dispatch: AppDispatch) => {
        return new Promise<{ policyId: string; report: commandConversionReportResponse, reportName: string, reportId: string }>((resolve, reject) => {
            CommandsService.commandsPostImportFromFile(policy).then(response => {

                const reportName = policy.notes || ''
                const commandId = response.conversionCommandId
                const policyId = response.policyId

                if (commandId) {
                    CommandStatusCheckManager.getCommandRunner(commandId)
                        .setOnResult((commandId, commandStatus) => {
                            if (commandStatus === commandJobStatus.JOB_SUCCESS) {
                                CommandsService.commandsGetConvertReport(commandId).then(resultReport => {
                                    resolve({policyId: policyId || '', report: resultReport, reportName, reportId: commandId})
                                }).catch(error => {
                                    reject(error)
                                })
                            } else {
                                reject({
                                    data: {
                                        message: `Command status: ${commandStatus}`
                                    }
                                })
                            }
                        })
                        .setOnError((commandId, e) => {
                            reject(e)
                            errorActionRequest(e)
                        })
                }

            }).catch(error => {
                reject(error)
                errorActionRequest(error);
                throw error
            })
        })
    }
}

export const downloadPolicy = (id: string, filename: string) => {
    return async (_dispatch: AppDispatch) => {
        return PoliciesService.policiesExportPolicy(id)
            .then(response => {
                if(response) {
                    downloadFile(JSON.stringify(response), filename)
                } else {
                    throw createErrorObject('Could not download the file', 'The server returned empty result')
                }
            })
            .catch(error => {
                errorActionRequest(error);
            })
    }
}