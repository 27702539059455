import {createSlice, PayloadAction} from '@reduxjs/toolkit'

const initialState = {
    policyId: null as string | null,
    shouldRefresh: false as boolean
}

type StateType = typeof initialState;

export const policyDetailsSlice = createSlice({
    name: 'POLICY_DETAILS',
    initialState: initialState as StateType,
    reducers: {
        setPolicyId: (state: StateType, action: PayloadAction<string | null>) => {
            state.policyId = action.payload;
        },
        setShouldRefresh: (state: StateType, action: PayloadAction<boolean>) => {
            state.shouldRefresh = action.payload;
        }
    }
});
