import {AppDispatch} from "../../store";
import {WorkspacesService} from "core-lib";
import {errorActionRequest} from "../Errors/actions";
import {workspacesSlice} from "./reducer";
import {PAGINATION_FIRST_PAGE, PAGINATION_HUGE_SIZE} from "../../constants";

export const fetchWorkspaces = () => {
    return async (dispatch: AppDispatch) => {
        return WorkspacesService.workspacesListWorkspaces(
            undefined,
            undefined,
            undefined,
            undefined,
            PAGINATION_FIRST_PAGE,
            PAGINATION_HUGE_SIZE)
            .then(response => {
                dispatch(workspacesSlice.actions.loadWorkspaces(response.workspaces || []));
            })
            .catch(error => {
                errorActionRequest(error);
            })
    }
}