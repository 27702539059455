import React from "react";
import { Scroller } from "@emerald/react";
import { Content, ScrollerContentFixed } from "./styled/Drawer";
import styled from "styled-components";

type Props = {
    children: React.ReactNode,
    mt?: number,
    mb?: number,
    ml?: number,
    mr?: number,
}

export const InnerContent = styled.div<Props>`
  margin-top: ${props => props.mt ? `${props.mt}px` : null};
  margin-bottom: ${props => props.mb ? `${props.mb}px` : null};
  margin-left: ${props => props.ml ? `${props.ml}px` : null};
  margin-right: ${props => props.mr ? `${props.mr}px` : null};
`

export const ScrollableContent = ({ children, ...rest }: Props) => {
    return <Content>
        <Scroller>
            <ScrollerContentFixed>
                <InnerContent {...rest} >
                    {children}
                </InnerContent>
            </ScrollerContentFixed>
        </Scroller>
    </Content>
}