import {RootState} from "../../store";
import {aggregate_reportReportListItem} from "core-lib";
import {createSelector} from "@reduxjs/toolkit";
import {reportsListItem} from "./types";

export const reportsListSelector = (state: RootState): Array<aggregate_reportReportListItem> => state.reportsList.data.reports || [];
export const reportsListTotalCount = (state: RootState): number =>
    state.reportsList.data.totalCount ? Number(state.reportsList.data.totalCount) : 0;
export const reportsListArraySelector = createSelector(
    [reportsListSelector],
    (reportsList) => {
    return reportsList.map((item: aggregate_reportReportListItem) => ({
        ...item,
        lastModifiedTimestamp: item.lastModified ? new Date(item.lastModified).getTime() : 0
    })) as reportsListItem[]
})
