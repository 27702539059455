import React, { useEffect, useMemo, useState } from "react";
import {
    BodyFormFull,
    Footer,
    Header,
    HeaderText,
} from "../../../components/styled/Drawer";
import { Divider } from "../../../components/Divider";
import { Align, Button, Tag, Select, Icon, Tooltip } from "@emerald/react";
import { Col } from "../../../components/Col";
import { Row } from "../../../components/Row";
import { Stack } from "@mui/material";
import styled from "styled-components";
import { ScrollableContent } from "../../../components/PageScrollable";
import { useSelector } from "react-redux";
import { providerIngestionPoliciesListSelector, providerIngestionProfilesBot, providerIngestionProfilesDos, providerIngestionProviderSelector, providerIngestionSelectedPoliciesSelector, providerIngestionSelectedPolicyProfilesSelector } from "../selectors";
import { providerProviderType } from "core-lib";
import { DataGrid } from "../../../components/DataGrid";
import { Accordion } from "../../../components/Accordion";
import { useAppDispatch } from "../../../store";
import { providerIngestionSlice } from "../reducer";
import { STAGES } from "../ProviderIngestionComponent";

const DataGridContainer = styled.div`
    padding: 15px;
    border: 0.5px solid #E6E9F3;
    border-radius: 3px;
    overflow: hidden;
    margin-top: 16px;

    & div[role="cell"]:hover  {
        background: white !important;
    }

    div[data-selector^="DataGrid.Styled.Footer"] {
        display: none;
    }
`

const DataGridTitle = styled.div`
    font-size: 16px;
    line-height: 19px;
    margin-bottom: 15px;
    color: #0F1E57;
`
const MutedText = styled.span`
    color: #6C778C;
`

const StyledTag = styled(Tag)`
    white-space: nowrap;
`

const StyledStack = styled(Stack)`
    width: 250px;
    overflow: hidden;
`
const SelectionsWrapper = styled.div`
    display: flex;
    overflow-x: hidden;
`
const SelectionContent = styled.div`
    flex: 1 0 50%;
`
const SelectionTitle = styled.div`
    font-size: 12px;
    line-height: 16px;
    font-weight: 700;
    margin-bottom: 12px;
`
const SelectionTag = styled(StyledTag)`
    background-color: #F7F8FA;
    color: #6C778C;
`
const FormItemWrapper = styled.div`
    width: 50%;
`
const FormItemLabel = styled.div`
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    display: flex;
    align-items: center;
    gap: 4px;
`
const FormItemLabelWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: baseline;
`
const FormItemLabelDescription = styled.div`
    color: #9EA7B8;
    font-size: 12px;
    font-weight: 400;
`
const InfoIcon = styled(Icon)`
    color: #6C778C;
`

const Selection = (props: {title: string, profile: string | undefined}) => {
    const {title, profile} = props
    return <SelectionContent>
        <SelectionTitle>{title}</SelectionTitle>
        {profile && <SelectionTag key={profile} isActionable={false}>
            {profile.length > 20 ? `${profile.substring(0, 20)}...` : profile}
        </SelectionTag>}
        {!profile && "--"}
    </SelectionContent>
}

type Props = {
    onBack: () => void,
    onNext: () => void,
    prevStage: string,
}

export const PolicySettingsScreen = (props: Props) => {
    const {onBack, onNext, prevStage} = props
    
    const [policyIndex, setPolicyIndex] = useState<number>(0)
    const [selectedServer, setSelectedServer] = useState<any>("all")

    const dispatch = useAppDispatch()

    const provider = useSelector(providerIngestionProviderSelector);
    const profilesBot = useSelector(providerIngestionProfilesBot);
    const profilesDos = useSelector(providerIngestionProfilesDos);
    const selectedPolicyIds = useSelector(providerIngestionSelectedPoliciesSelector)
    
    const currentId = selectedPolicyIds[policyIndex]
    const allPolicies = useSelector(providerIngestionPoliciesListSelector)

    const allProfiles = useSelector(providerIngestionSelectedPolicyProfilesSelector)
    const { dosProfile, botProfile } = allProfiles[currentId] || {}

    const isAWAF = provider?.type === providerProviderType.AWAF

    const currentPolicy = useMemo(() => {
        return allPolicies?.find(policy => policy.id === currentId)
    }, [currentId, allPolicies])

    useEffect(() => {
        if(prevStage === STAGES.SUMMARY) {
            setPolicyIndex(selectedPolicyIds.length - 1)
        }
    }, [prevStage])

    const handleBack = () => {
        setPolicyIndex(prev => {
            if(prev <= 0){
                onBack()
                return 0
            }
            return prev - 1
        })
    }

    const handleNext = () => {
        setPolicyIndex(prev => {
            if(prev >= selectedPolicyIds.length - 1){
                onNext()
                return selectedPolicyIds.length - 1
            }
            return prev + 1
        })
    }

    const handleBotSelectionChange = (_changed: any[]) => {
        const profile = _changed[0]
        dispatch(providerIngestionSlice.actions.selectPolicyProfileIds({
            policyId: currentId,
            botProfile: botProfile !== profile ? profile : undefined
        }))                   
    }

    const handleDosSelectionChange = (_changed: any[]) => {
        const profile = _changed[0]
        dispatch(providerIngestionSlice.actions.selectPolicyProfileIds({
            policyId: currentId,
            dosProfile: dosProfile !== profile ? profile : undefined
        }))             
    }

    const virtualServers = useMemo(() => {
        setSelectedServer("all")
        const rest = currentPolicy?.servers?.map((key) => ({ label: key, value: key })) || []
        return [{
            items: [{label: "All Profiles", value: "all"}, ...rest]
        }]
    }, [currentPolicy])

    const columns: any = useMemo(() => {
        switch (provider?.type) {
            case providerProviderType.AWAF:
                return [
                    {
                        accessor: (row: any) => row.name,
                        title: 'Policy name',
                        renderer: (row: any) => row.name
                    },
                    {
                        accessor: (row: any) => row.servers?.join(', '),
                        title: 'Virtual Servers',
                        renderer: (row: any) =>
                            <StyledStack direction="row" alignItems="center" spacing={1}>
                                {row.virtualServers?.map(
                                    (server: string) => (<StyledTag key={server} isActionable={false}>{server}</StyledTag>)
                                )}
                            </StyledStack>
                    },
                ]
            case providerProviderType.NAP:
                return [
                    {
                        accessor: (row: any) => row.name,
                        title: 'Policy name',
                        renderer: (row: any) => row.name
                    },
                ]
        }
    }, [provider?.type]);

    return <BodyFormFull>
        <Header pr={20} pl={20}>
            <Col centerVertical fullHeight>
                <HeaderText>
                    <MutedText>Policy:</MutedText>{" "}
                    {currentPolicy?.name} ({`${policyIndex + 1} of ${selectedPolicyIds.length}`})
                </HeaderText>
            </Col>
        </Header>
        <Divider />
        <ScrollableContent ml={0} mb={47} mt={18}>
            <Accordion title="Profiles" initialOpened>
                {isAWAF && <FormItemWrapper>
                    <FormItemLabelWrapper>
                        <FormItemLabel>
                            Virtual Servers{" "}                        
                            <Tooltip
                                content="Virtual Servers associated with the policy selected"
                                target={<InfoIcon icon="app.information" size="xs" />}
                                zIndex={999999}
                            />
                        </FormItemLabel>
                        <FormItemLabelDescription>
                            Optional
                        </FormItemLabelDescription>
                    </FormItemLabelWrapper>
                    <Select 
                        options={virtualServers || []}
                        value={selectedServer}
                        onChange={value => setSelectedServer(value)}
                        allowSelectAll={false}
                        multiSelect={false}
                        allowSearch={false}
                        allowClearSelection={false}
                    />
                </FormItemWrapper>}
                <DataGridContainer>
                    <DataGridTitle>DoS Protection Profile</DataGridTitle>
                    <DataGrid
                        height={isAWAF ? 160 : 400}
                        minHeight={isAWAF ? 160 : 400}
                        themeOverride={{ color: { border: "transparent", shadow:"none", header: { fill: "transparent", ink: "#111922" }, rowFocus: { outline: "transparent"} } }}
                        columns={columns}
                        items={(selectedServer === "all" ? profilesDos : profilesDos?.filter(item => item.virtualServers.includes(selectedServer))) || []}
                        itemIdAccessor='name'
                        showToolbar={false}
                        allowSelectRows={true}
                        allowSelectAll={false}
                        displayMode="compressed"
                        onSelectionChange={handleDosSelectionChange}
                        configFullList={{
                            selectedItemIds: dosProfile ? [dosProfile] : [],
                        }}
                    />
                </DataGridContainer>
                {isAWAF && <DataGridContainer>
                    <DataGridTitle>Bot Defense Profile</DataGridTitle>
                    <DataGrid
                        height={160}
                        minHeight={160}
                        themeOverride={{ color: { border: "transparent", shadow:"none", header: { fill: "transparent", ink: "#111922" }, rowFocus: { outline: "transparent"} } }}
                        columns={columns}
                        items={(selectedServer === "all" ? profilesBot : profilesBot?.filter(item => item.virtualServers.includes(selectedServer))) || []}
                        itemIdAccessor='name'
                        showToolbar={false}
                        allowSelectRows={true}
                        allowSelectAll={false}
                        displayMode="compressed"
                        onSelectionChange={handleBotSelectionChange}
                        configFullList={{
                            selectedItemIds: botProfile ? [botProfile] : [],
                        }}
                    />
                </DataGridContainer>}
            </Accordion>
            <Accordion title="Profile Selections" initialOpened>
                <SelectionsWrapper>
                    <Selection title="DoS Protection Profile" profile={dosProfile} />
                    {isAWAF && <Selection title="Bot Defense Profile" profile={botProfile} />}
                </SelectionsWrapper>
            </Accordion>
        </ScrollableContent>
        <Footer>
            <Divider />
            <Col flex={1} rightDirection mr={20}>
                <Row spacing={20} mt={10}>
                    <Align>
                        <Button accent={true} displayMode="outlined" onClick={handleBack}>Back</Button>
                    </Align>
                    <Align>
                        <Button accent={true} onClick={handleNext}>Next</Button>
                    </Align>
                </Row>
            </Col>
        </Footer>
    </BodyFormFull>
}