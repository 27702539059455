import { useMemo } from "react";
import { commandConversionReportResponse } from "core-lib";

export type conversionReportError = Record<'title' | 'text', string>;

export const useGetConversionReportErrorData = (reports: (commandConversionReportResponse| undefined)[] = []) => {
    return useMemo(() => {
        const errors = reports.flatMap((report) =>report?.ConversionLogs?.filter((log) => log?.Error)?.map((log) => ({title: log?.Name, text: log?.Description }))?.map((log) => {
            const resultText = log.text?.split(":") || [log.text]
            return { ...log, text: resultText[resultText.length - 1] }
        }) as conversionReportError[]).reduce((prev, current) => {
            const allKeys = prev.map((item) => item.title);    
            return allKeys.includes(current.title) ? prev : [...prev, current]
        } , [] as conversionReportError[])

        const errorKeys = errors.map((item) => item.title);

        const warnings = reports.map((report) =>report?.ConversionLogs?.filter((log) => log?.Warning)?.filter((log) => log.Name && !errorKeys.includes(log.Name))?.map((log) => ({title: log?.Name, text: log?.Description }))?.map((log) => {
            const resultText = log.text?.split(":") || [log.text]
            return { ...log, text: resultText[resultText.length - 1] }
        }) as conversionReportError[])

        return { warnings, errors }

    }, [reports])
}