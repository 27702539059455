import {RootState} from "../../store";
import {agentAgent, secretResponse} from "core-lib";

export const addProviderProviderIdSelector = (state: RootState): string | null => state.addProvider.data.providerId;
export const isEditProviderOpenSelector = (state: RootState): boolean => state.addProvider.data.providerId !== null;
export const closeConnectionTextSelector = (state: RootState): string | undefined => state.addProvider.data.closeConnectionText;
export const addProviderAgentsSelector = (state: RootState): Array<agentAgent> => state.addProvider.agents;
export const addProviderSecretsSelector = (state: RootState): Array<secretResponse> => state.addProvider.secrets;
export const addProviderIsLoadingAgentsSelector = (state: RootState): boolean => state.addProvider.isLoadingAgents;
export const addProviderIsLoadingSecretsSelector = (state: RootState): boolean => state.addProvider.isLoadingSecrets;
