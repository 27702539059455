const ENDPOINT_FULL_ID_SEPARATOR = ' ; '

export const getFullEndpointId = (group?: string, endpointName?: string) => `${group}${ENDPOINT_FULL_ID_SEPARATOR}${endpointName}`
export const getEndpointFromFullId = (fullEndpointId: string) => fullEndpointId.split(ENDPOINT_FULL_ID_SEPARATOR)

export const mapEndpointsByGroup = (providerEndpoints: string[], providerId: string) => {
    if (!providerEndpoints) {
        return {
            [providerId]: []
        } as { [groupId: string]: string[] }
    }
    return providerEndpoints.reduce((result, fullEndpointId) => {
        const [groupId, endpointId] = getEndpointFromFullId(fullEndpointId)
        const processedGroupId = endpointId ? groupId : providerId;
        const processedEndpointId = endpointId || groupId;
        return {
            ...result,
            [processedGroupId]: [
                ...(result[processedGroupId] ? result[processedGroupId] : []),
                processedEndpointId
            ]
        }
    }, {} as { [groupId: string]: string[] })
}