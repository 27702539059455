

export class PSIcons {
    static lookup(name?: string) {
        if (name === "help.buoy") {
            return {
                svgConfig: {
                    viewBox: "0 0 18 18",
                    fill: "currentColor",
                    stroke: "none",
                },
                paths: [
                    {
                        path: {
                            fillRule: "evenodd",
                            clipRule: "evenodd",
                            d: "M9 0.75C4.44365 0.75 0.75 4.44365 0.75 9C0.75 13.5563 4.44365 17.25 9 17.25C13.5563 17.25 17.25 13.5563 17.25 9C17.25 4.44365 13.5563 0.75 9 0.75ZM4.78673 3.72608C5.94149 2.80236 7.40624 2.25 9 2.25C10.5938 2.25 12.0585 2.80235 13.2133 3.72607L11.428 5.51136C10.7397 5.03144 9.90271 4.75 9 4.75C8.09729 4.75 7.2603 5.03144 6.57202 5.51136L4.78673 3.72608ZM3.72607 4.78674C2.80235 5.94149 2.25 7.40624 2.25 9C2.25 10.5938 2.80235 12.0585 3.72607 13.2133L5.51136 11.428C5.03144 10.7397 4.75 9.90271 4.75 9C4.75 8.09729 5.03144 7.26031 5.51136 6.57203L3.72607 4.78674ZM7.02853 7.08276C6.54673 7.5781 6.25 8.2544 6.25 9C6.25 9.74559 6.54672 10.4219 7.02852 10.9172C7.03795 10.9258 7.04722 10.9346 7.05632 10.9437C7.06542 10.9528 7.07423 10.962 7.08277 10.9715C7.57811 11.4533 8.2544 11.75 9 11.75C9.74556 11.75 10.4218 11.4533 10.9172 10.9716C10.9257 10.9621 10.9345 10.9528 10.9437 10.9437C10.9528 10.9345 10.9621 10.9257 10.9716 10.9172C11.4533 10.4218 11.75 9.74556 11.75 9C11.75 8.25444 11.4533 7.57817 10.9715 7.08283C10.9621 7.07428 10.9528 7.06545 10.9437 7.05633C10.9346 7.04721 10.9257 7.03792 10.9172 7.02846C10.4218 6.5467 9.74556 6.25 9 6.25C8.2544 6.25 7.5781 6.54673 7.08275 7.02854C7.07422 7.03797 7.06541 7.04724 7.05632 7.05633C7.04723 7.06542 7.03796 7.07423 7.02853 7.08276ZM4.78673 14.2739L6.57202 12.4886C7.2603 12.9686 8.09728 13.25 9 13.25C9.90271 13.25 10.7397 12.9686 11.428 12.4886L13.2133 14.2739C12.0585 15.1976 10.5938 15.75 9 15.75C7.40624 15.75 5.94148 15.1976 4.78673 14.2739ZM14.2739 13.2133C15.1976 12.0585 15.75 10.5938 15.75 9C15.75 7.40624 15.1976 5.94149 14.2739 4.78673L12.4886 6.57202C12.9686 7.26031 13.25 8.09729 13.25 9C13.25 9.90271 12.9686 10.7397 12.4886 11.428L14.2739 13.2133Z"
                        }
                    },
                ],
                meta: {
                    name: "help.buoy",
                },
            };
        }

        return undefined;
    }
}

