import {
    BodyForm,
    Header,
    HeaderHeader,
    HeaderSubText,
    Legend
} from "../../../components/styled/Drawer";
import {Divider} from "../../../components/Divider";
import {Row} from "../../../components/Row";
import React from "react";
import {CircularProgress} from "@mui/material";
import styled from "styled-components";
import {commandImportFromFileRequest} from "core-lib";
import {Col} from "../../../components/Col";
import {ScrollableContent} from "../../../components/PageScrollable";

const SpinnerRow = styled(Row)`
  padding: 40px;
  justify-content: center;
`
const ProgressText = styled(Col)`
  align-self: center;
  font-size: 18px;
  line-height: 26px;
  color: #4F73FF;
`

type Props = {
    policy: commandImportFromFileRequest
}

export const ConversionProcessComponent = (props: Props) => {

    return <BodyForm>
        <Header>
            <HeaderHeader>Conversion</HeaderHeader>
            <HeaderSubText>In progress...</HeaderSubText>
        </Header>
        <ScrollableContent>
            <Divider mb={17}></Divider>
            <Legend>Policy: {props.policy.name}</Legend>
            <div>
                <SpinnerRow>
                    <CircularProgress
                        variant="indeterminate"
                        disableShrink
                        sx={{
                            color: '#3183B2'
                        }}
                        size={85}
                        thickness={2}
                    />
                </SpinnerRow>
                <Row centerHorizontal mt={20}>
                    <ProgressText>
                        Converting metadata...<br/>
                        Converting HTTP protocol info...<br/>
                        Converting violations...<br/>
                        Converting filetypes...<br/>
                        Converting headers...<br/>
                        Converting methods...<br/>
                        Converting URLs...<br/>
                    </ProgressText>
                </Row>
            </div>
        </ScrollableContent>
    </BodyForm>
}