import React, {useMemo, useState} from "react";
import {useSelector} from "react-redux";
import {workspaceListSelector} from "./selectors";

import {getWorkspaceIdFromURL, setWorkspaceURL} from "core-lib";
import {Select} from "@emerald/react";
import {Row} from "../../components/Row";
import {Col} from "../../components/Col";
import {Dot} from "../../components/Dot";
import styled from "styled-components";

const WorkspaceLetter = styled.span`
  vertical-align: center;
  font-size: 18px;
  color: #6C778C;
  text-transform: capitalize;
`
const WorkspaceName = styled.span`
  font-size: 14px;
  color: #0F1E57;
`
const WorkspaceLabel = styled.span`
  font-size: 12px;
  color: #6C778C;
`

export function WorkspaceListComponent() {

    const dataSource = useSelector(workspaceListSelector);
    const [workspaceId, setWorkspaceId] = useState(getWorkspaceIdFromURL())

    const handleChange = (value: any) => {
        setWorkspaceId(value)
        setWorkspaceURL(value, true)
    }

    const current = useMemo(() => dataSource.find(item => item.id === workspaceId),
        [dataSource, workspaceId])

    const options = useMemo(() => ([{
        items: dataSource.map(workspace => ({
            value: workspace.id || '',
            label: workspace.name || '',
            icon: 'app.user'
        }))
    }]), [dataSource])

    const buttonConfig = useMemo(() => ({
        alignPopup: "rightMid" as "rightMid" | "center" | "left" | "leftMid" | "right",
        displayMode: "ghostInk" as "link" | "ghostInk" | "filled" | "outlined" | "ghost" | "linkInk" | "linkSubtle" | undefined,
    }), [])

    return <Row spacing={10}>
        <Col centerVertical>
            <Dot size={32} color='white'>
                <WorkspaceLetter>{current && current.name ? current.name[0] : ''}</WorkspaceLetter>
            </Dot>
        </Col>
        <Col flex={1} centerVertical>
            <WorkspaceName>{current ? current.name : ''}</WorkspaceName>
            <WorkspaceLabel>Namespace</WorkspaceLabel>
        </Col>
        <Col rightDirection>
            <Select
                options={options}
                value={dataSource.length ? workspaceId : ''}
                height="tall"
                multiSelect={false}
                displayMode="button"
                popupPlacement="below"
                buttonConfig={buttonConfig}
                onChange={handleChange}
                allowClearSelection={false}
                allowSelectAll={false}
            />
        </Col>
    </Row>
}
