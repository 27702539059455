import React from "react";
import { ButtonTypes, Scroller } from "@emerald/react";
import styled from "styled-components";
import { Dialog } from "./Dialog";

type Props = {
    title?: string;
    actions?: ButtonTypes.PropsConsumable[]
    description?: string;
    items: string[];
}

const DeleteDescription = styled.div`
font-size: 14px;
padding-top: 4px;
padding-bottom: 20px;
`

const DeleteContainer = styled.div`
height: 100px;
position: relative;
`

const DeleteScroller = styled(Scroller)`
  width: 388px !important;
  height: 100px !important;
`

const DeleteItemsList = styled.div`
  white-space: pre-wrap;
  font-size: 14px;
`

export const DeleteItemsDialog = ({ title, actions, description, items }: Props) => {

    const itemList = <DeleteItemsList>
        {items.map((item) => <div key={item}>{item}</div>)}
    </DeleteItemsList>

    return <Dialog
        title={title}
        open={items.length > 0}
        actions={actions}
    >
        <DeleteDescription>{description}</DeleteDescription>
        {items.length < 7 ? itemList :
            <DeleteContainer>
                <DeleteScroller>
                    {itemList}
                </DeleteScroller>
            </DeleteContainer>
        }
    </Dialog >
}