import React, {useCallback, useMemo, useRef, useState} from "react";
import {commandImportFromFileRequest, providerProviderType} from "core-lib";
import {Align, FormField, Input, Select, Button, TextArea} from "@emerald/react";
import {Divider} from "../../components/Divider";
import {Row} from "../../components/Row";
import {Col} from "../../components/Col";
import {BodyForm, Header, Footer, HeaderText, HeaderSubText} from "../../components/styled/Drawer";
import {TagsField} from "../../components/TagsField";
import {PROVIDER_TYPES_OPTIONS} from "../../constants";
import {FileInput, FileInputHandle} from "../../components/FileInput";
import {ScrollableContent} from "../../components/PageScrollable";

export const ImportPolicyForm = (props: {
    onCloseClick: () => void,
    onSuccess: (values: commandImportFromFileRequest) => void
}) => {

    const {onSuccess} = props
    const fileInputRef = useRef<FileInputHandle>(null);

    const [values, setValues] = useState<commandImportFromFileRequest>({
        name: '',
        labels: [] as Array<string>,
        providerType: undefined as providerProviderType | undefined,
        policy: '',
        notes: ''
    })

    const setProviderType = useCallback((value: any) => {
        fileInputRef.current && fileInputRef.current.resetInput()

        setValues(values => ({
            ...values,
            providerType: value,
            policy: ''
        }))
    }, [setValues])

    const setName = useCallback((event: any) => {
        const value = event.target.value
        setValues(values => ({
            ...values,
            name: value
        }))
    }, [setValues])
    
    const setLabels = useCallback((selectedTags: string[]) => {
        setValues(values => ({
            ...values,
            labels: selectedTags
        }))
    }, [setValues])

    const setNotes = useCallback((event: any) => {
        const value = event.target.value
        setValues(values => ({
            ...values,
            notes: value
        }))
    }, [setValues])

    const setFile = useCallback((file: File) => {
        setValues(values => ({...values, policy: ''}))
        const reader = new FileReader()
        reader.onload = async (e) => {
            const text = (e.target?.result)
            text && setValues(values => ({
                ...values,
                policy: text.toString()
            }))
        }
        reader.readAsText(file as Blob)
    }, [setValues])

    const onSubmit = useCallback(() => {
        onSuccess(values)
    }, [onSuccess, values])

    const selectOptions = useMemo(() => [{
        items: PROVIDER_TYPES_OPTIONS[0].items.map(option => ({
            ...option,
            disabled: option.value === providerProviderType.XC
        }))
    }], [])

    return <BodyForm>
        <Header>
            <HeaderText>Import Policy</HeaderText>
            <HeaderSubText>Option to include a brief description (approx. 80 characters).</HeaderSubText>
        </Header>
        <ScrollableContent>
            <Divider mb={17}></Divider>
            <FormField required={true} label="Policy Name">
                <Input onChange={setName}/>
            </FormField>
            <FormField label="Labels">
                <TagsField value={values.labels || []} onChange={setLabels}/>
            </FormField>
            <FormField required={true} label="Policy Type">
                <Select options={selectOptions}
                        onChange={setProviderType}
                        allowClearSelection={false}
                        allowSelectAll={false}
                        allowSearch={false}
                />
            </FormField>
            <FormField required={true} label={values.providerType === providerProviderType.AWAF ? "Import (JSON or XML format)" : "Import (JSON format)"}>
                <FileInput ref={fileInputRef} onChange={setFile} accept={values.providerType === providerProviderType.AWAF ?".json,.xml" : ".json"} />
            </FormField>
            <FormField required={true} label="Import Notes / Summary">
                <TextArea onChange={setNotes}/>
            </FormField>
        </ScrollableContent>
        <Footer>
            <Divider/>
            <Col flex={1} rightDirection>
                <Row spacing={20} style={{marginTop: 10}}>
                    <Align>
                        <Button accent={true} displayMode="outlined" onClick={props.onCloseClick}>Cancel</Button>
                    </Align>
                    <Align>
                        <Button
                            disabled={!values.name || !values.providerType || !values.policy || !values.notes}
                            accent={true}
                            onClick={onSubmit}>
                            Import
                        </Button>
                    </Align>
                </Row>
            </Col>
        </Footer>
    </BodyForm>
}