const workspaceRegex = /\/workspaces\/([^/]*)/i

export function getWorkspaceIdFromURL(): string {
  const pathname = window.location.pathname
  const matches = pathname.match(workspaceRegex)
  return matches && matches[1] ? matches[1] : ''
}

export function setWorkspaceURL(
  workspaceID: string,
  savePath: boolean = false
) {
  const pathname = window.location.pathname
  const matches = pathname.match(workspaceRegex)
  if (!savePath) {
    window.location.pathname = `/workspaces/${workspaceID}`
  } else if (matches && matches[0]) {
    window.location.pathname = pathname.replace(
      workspaceRegex,
      `/workspaces/${workspaceID}`
    )
  } else {
    window.location.pathname = `/workspaces/${workspaceID}${pathname}`
  }
}
