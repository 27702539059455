import React, {useCallback, useEffect, useMemo} from "react";
import {FormField, Select, Button, Icon} from "@emerald/react";
import {useSelector} from "react-redux";
import {
    addProviderIsLoadingSecretsSelector,
    addProviderSecretsSelector
} from "./selectors";
import {useAppDispatch} from "../../store";
import {fetchSecrets} from "./actions";
import {providerProviderType} from "core-lib";
import {Row} from "../../components/Row";
import {Col} from "../../components/Col";
import {CircularProgress} from "@mui/material";

type SecretSelectProps = {
    agentId?: string,
    type: providerProviderType,
    value?: string,
    onChange: (value: string) => void
    onAddSecretClick: () => void
}

export const SecretSelect = (props: SecretSelectProps) => {

    const dispatch = useAppDispatch()
    const secrets = useSelector(addProviderSecretsSelector)
    const isSecretsLoading = useSelector(addProviderIsLoadingSecretsSelector)

    const {onChange, onAddSecretClick, type, agentId} = props

    useEffect(() => {
        dispatch(fetchSecrets(type !== providerProviderType.XC, agentId))
    }, [dispatch, type, agentId])

    const onRefreshClick = useCallback(() => {
        dispatch(fetchSecrets(type !== providerProviderType.XC, agentId))
    }, [dispatch, type, agentId])

    const onChangeSelect = useCallback((secretId: any) => {
        onChange(secretId)
    }, [onChange])

    const options = useMemo(() => ([{
        items: secrets.map(item => ({
            value: item.id || '',
            label: item.name || ''
        }))
    }]), [secrets])

    return <FormField required={true} label="Secret">
        <Row fullWidth spacing={10}>
            <Col flex={1}>
                <Select
                    value={props.value}
                    options={options}
                    onChange={onChangeSelect}
                    allowClearSelection={false}
                    allowSelectAll={false}
                    allowSearch={true}
                />
            </Col>
            <Col centerVertical center width={32}>
                {!isSecretsLoading && <Button displayMode="ghostInk" onClick={onRefreshClick}>
                    <Icon icon="app.refresh" />
                </Button>}
                {isSecretsLoading && <CircularProgress
                    variant="indeterminate"
                    disableShrink
                    sx={{
                        color: '#0F1E57'
                    }}
                    size={16}
                    thickness={2}
                />}
            </Col>
        </Row>
        {type === providerProviderType.XC && <Button displayMode='link' size='slim' onClick={onAddSecretClick}>
            <Icon icon="app.plus"/>
            <span>Add secret</span>
        </Button>}
    </FormField>
}