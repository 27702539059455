import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {aggregate_reportReportList} from "core-lib";

const initialState = {
    data: {
        page: {index: 0, size: 0},
        totalCount: '0',
        reports: []
    } as aggregate_reportReportList,
}

type StateType = typeof initialState;

export const reportsListSlice = createSlice({
    name: 'REPORTS_LIST',
    initialState: initialState as StateType,
    reducers: {
        loadReports: (state: StateType , action: PayloadAction<aggregate_reportReportList>) => {
            state.data = action.payload;
        }
    }
});
